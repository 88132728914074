import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
    label: string;
}

const FieldWithLabel: FC<Props> = ({ label, children }) => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" className={classes.grid}>
            <Grid item xs={12} md={4}>
                <Typography className={classes.label}>{label}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                {children}
            </Grid>
        </Grid>
    );
};

export default FieldWithLabel;
