import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    removeProjectLabel: {
        color: '#F80000',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        lineHeight: 1.2,
    },
    generateReportButton: {
        backgroundColor: '#263644',
        '&:hover': {
            backgroundColor: '#1e2b35',
        },
    },
    uploadAvatarIcon: {
        color: '#E65216',
        fontSize: '1rem',
    },
    uploadAvatarIconWrapper: {
        borderBottom: '2px solid #E65216',
        width: '0.688rem',
        height: 0,
    },
    removeDialog: {
        backgroundColor: '#16212c',
    },
});
