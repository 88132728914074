import { makeStyles } from '@material-ui/styles';
import { theme } from '../../../config/theme';

export const useStyles = makeStyles({
    userInfoRow: {
        marginBottom: '1.25rem',
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    label: {
        minWidth: '12.5rem',
        color: '#708393',
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: 1.5,
    },
    value: {
        color: 'white',
        fontWeight: 500,
        fontSize: '1.375rem',
        lineHeight: 1.2,
        maxWidth: '30rem',
    },
    checkboxLabel: {
        color: '#C9CBCC',
        fontSize: '1.25rem',
        lineHeight: 1.4,
    },
    formControl: {
        marginLeft: 0,
        marginBottom: '0.813rem',
    },
    checkbox: {
        display: 'block',
        position: 'relative',
        height: '1.563rem',
        width: '1.563rem',
        marginRight: '0.719rem',

        '& input': {
            width: 0,
            height: 0,
            opacity: 0,
        },

        '& input:checked ~ .checkmark': {
            backgroundColor: '#40BF71',
        },

        '& .checkmark': {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '1.563rem',
            width: '1.563rem',
            backgroundColor: '#708393',
            borderRadius: '0.125rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    checkIcon: {
        fontSize: '1.25rem',
    },
    card: {
        backgroundColor: '#263644',
        borderRadius: '3.125rem 0px',
        [theme.breakpoints.up('md')]: {
            padding: '3.75rem 4.375rem',
        },
        [theme.breakpoints.down('md')]: {
            padding: '2.5rem',
        },
        marginTop: '4.375rem',
    },
});
