import React, { FC, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Button, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from '@tanstack/react-query';
import { useStyles } from './styles';
import NewPersonFields from '../NewPersonFields';
import { queryKeys } from '../../../../react-query/constants';
import { getStakeholderGroups } from '../../../../services/ProjectService';
import { StakeholderGroupsDto } from '../../../../api/data-contracts';

interface Props {
    group: string;
}

const PersonsSubGroup: FC<Props> = ({ group }) => {
    const classes = useStyles();

    const { data: stakeholderGroups } = useQuery([queryKeys.stakeholderGroups], () => getStakeholderGroups());

    const { control, setValue } = useFormContext();

    const groupValues = useWatch({
        control,
        name: `contactGroups`,
    });

    const subGroupValues = useMemo(() => {
        return groupValues.filter(({ topLevelGroup }: { topLevelGroup: string }) => topLevelGroup === group);
    }, [group, groupValues]);

    const addSubGroup = () => {
        if (!groupValues.map(({ role }: { role: string }) => role).includes('')) {
            setValue(`contactGroups`, [
                ...groupValues,
                { topLevelGroup: group, groupId: null, role: '', contacts: [] },
            ]);
        }
    };

    const allSubGroups: StakeholderGroupsDto[] = useMemo(() => {
        return (
            stakeholderGroups
                ?.filter(
                    stakeholderGroup =>
                        stakeholderGroup !== null && stakeholderGroup.stakeholderGroup === group,
                )
                ?.filter((value, index, self) => {
                    return self.indexOf(value) === index;
                }) || []
        );
    }, [group, stakeholderGroups]);

    const visibleSubGroups: StakeholderGroupsDto[] = useMemo(() => {
        return allSubGroups?.filter(subgroup =>
            subGroupValues?.map((subGroupValue: any) => subGroupValue.groupId)?.includes(subgroup.id),
        );
    }, [allSubGroups, subGroupValues]);

    const availableSubGroups: StakeholderGroupsDto[] = useMemo(() => {
        return allSubGroups?.filter(
            subGroup =>
                !visibleSubGroups?.map((subGroupValue: any) => subGroupValue.id)?.includes(subGroup.id),
        );
    }, [allSubGroups, visibleSubGroups]);

    const changeSubGroupName = (newId: number, oldId: number) => {
        const index = groupValues.findIndex(({ groupId }: { groupId: number }) => groupId === oldId);

        const newValues = [...groupValues];
        newValues[index].groupId = newId;
        newValues[index].topLevelGroup = group;
        newValues[index].contacts = [];
        newValues[index].role = allSubGroups?.find(subGroup => subGroup.id === newId)?.role || '';

        setValue(`contactGroups`, newValues);
    };

    const removeSubGroup = (id: number) => {
        setValue(`contactGroups`, groupValues?.filter((group: any) => group.groupId !== id) || []);
    };

    return (
        <>
            <Box style={{ marginBottom: 20, marginTop: 20 }} />

            {subGroupValues.map((subGroup: any, key: number) => (
                <Box key={key}>
                    <Grid container style={{ marginBottom: 40 }}>
                        <Grid item xs={12} md={3}>
                            <FormControl variant="filled" style={{ width: '100%' }}>
                                <Select
                                    value={subGroup.groupId}
                                    style={{ width: '100%' }}
                                    onChange={(e: any) =>
                                        changeSubGroupName(e.target.value, subGroup.groupId)
                                    }
                                >
                                    <MenuItem value={subGroup.groupId}>{subGroup.role}</MenuItem>
                                    {availableSubGroups.map((availableSubGroup: any) => (
                                        <MenuItem value={availableSubGroup.id}>
                                            {availableSubGroup.role}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <NewPersonFields key={subGroup.groupId} group={group} subGroup={subGroup} />
                        </Grid>
                    </Grid>

                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => removeSubGroup(subGroup.groupId)}
                        >
                            remove subgroup <CloseIcon />
                        </Button>
                    </Box>

                    {key + 1 < subGroupValues.length && <Box className={classes.divider} />}
                </Box>
            ))}

            {!!availableSubGroups.length && (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addSubGroup}
                    onClick={() => addSubGroup()}
                >
                    add subgroup <AddIcon />
                </Button>
            )}
        </>
    );
};

export default PersonsSubGroup;
