import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    popup: {
        '& p': {
            color: '#21130d',
        },
    },
    container: {
        backgroundColor: '#16212c',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '3rem',
        paddingLeft: '1.25rem',
        borderTopLeftRadius: '1rem',
        borderBottomRightRadius: '1rem',
        width: '100%',
        paddingRight: '0.75rem',
    },
    input: {
        '& .MuiInputBase-root': {
            height: '3rem',
            fontSize: '1rem',
            paddingLeft: '0.625rem',
            paddingRight: '0.313rem',
            '& input': {
                width: '5.625rem',
            },
        },
    },
    icon: {
        color: '#748291',
        fontSize: '1rem',
    },
    divider: {
        color: '#748291',
    },
});
