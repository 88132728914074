import { FC, useState, useEffect, useRef, useMemo } from 'react';
import {
    Typography,
    Dialog,
    DialogProps,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    IconButton,
    TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isArray, isNumber, isNaN } from 'lodash';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useStyles } from './styles';
import CustomButton from '../../../../../../components/CustomButton';
import { DATE_FORMAT, SUBMITTING_DATE_FORMAT } from '../../../../../../config/constants';
import CustomSelectMultiple from '../../../../../../components/CustomSelectMultiple';
import CustomSelect from '../../../../../../components/CustomSelect';
import { CurrentUserReducer, RootReducer, TasksReducer } from '../../../../../../models/Redux';
import { TaskBodyForm } from '../../../../../../models/Task';
import { TaskType } from '../../../../../../models/enums/TaskType';
import { UserRoles } from '../../../../../../models/enums/UserRoles';
import DeadlineChangeReasonDialog from '../../../DeadlineChangeReasonDialog';

interface Props {
    open: boolean;
    loading: boolean;
    isEditing?: boolean;
    handleClose: () => void;
}

const EditTaskDialogForm: FC<Props> = ({ open, handleClose, isEditing }) => {
    const [scroll] = useState<DialogProps['scroll']>('paper');
    const { t } = useTranslation();
    const classes = useStyles();
    const { user } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);
    const {
        projects,
        employeeAll,
        status,
        types,
        contractTypes,
        disciplineAll,
        disciplineToProject,
    } = useSelector<RootReducer, TasksReducer>(state => state.tasks);
    const { values, handleChange, setValues, submitForm, isValid, errors } = useFormikContext<TaskBodyForm>();
    const [availableDiscipline, setAvailableDiscipline] = useState(disciplineAll);
    const [availableEmployees, setAvailableEmployees] = useState(
        values?.discipline
            ? employeeAll.filter(item => {
                  return disciplineToProject.some(x => x.empId === item.id);
              })
            : employeeAll,
    );
    const [disableDiscipline, setDisableDiscipline] = useState(
        values.projectId == null || isNaN(values.projectId),
    );
    const [disableAssignedTo, setDisableAssignedTo] = useState(!isEditing);
    const { enqueueSnackbar } = useSnackbar();
    const isUserAssignedToTask = useMemo(() => {
        return values.assignedEmployees?.find(
            item => employeeAll.find(employeeData => employeeData.id === item)?.name === user?.name,
        );
    }, [employeeAll, user, values.assignedEmployees]);

    const [deadlineChangeDialogOpen, setDeadlineChangeDialogOpen] = useState<boolean>(false);
    const hasDeadlineChanged = useRef<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = () => {
        setLoading(true);
        submitForm()
            .then(() => {
                if (!isValid) {
                    const errorMessage = Object.values(errors).toString();
                    enqueueSnackbar(errorMessage, {
                        variant: 'error',
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSaveButtonClick = () => {
        if (hasDeadlineChanged.current && isEditing) {
            setDeadlineChangeDialogOpen(true);
        } else {
            handleSubmit();
        }
    };

    const handleDateChange = (date: Date | null, value: string) => {
        if (date) {
            setValues({ ...values, startDate: dayjs(value, DATE_FORMAT).format(SUBMITTING_DATE_FORMAT) });
        }
    };

    const handleDeadlineDateChange = (date: Date | null, value: string) => {
        if (date) {
            setValues({ ...values, deadline: dayjs(value, DATE_FORMAT).format(SUBMITTING_DATE_FORMAT) });
            hasDeadlineChanged.current = true;
        }
    };

    const handleSelectEmployee = (newValue: any[]) => {
        if (isArray(newValue)) {
            setValues({ ...values, assignedEmployees: newValue.map(item => item.id) });
        }
    };

    const handleSelectProject = (newValue: number) => {
        if (isNumber(newValue)) {
            setValues({ ...values, projectId: newValue, discipline: undefined, assignedEmployees: [] });

            setAvailableDiscipline(
                disciplineAll.filter(item => {
                    return disciplineToProject.some(x => x.projId === newValue && x.discId === item.id);
                }),
            );
            setDisableAssignedTo(true);
            setDisableDiscipline(false);
        }
    };

    const handleSelectContractType = (newValue: number) => {
        if (isNumber(newValue)) {
            setValues({ ...values, contractType: newValue });
        }
    };

    const handleSelectDiscipline = (newValue: number) => {
        if (isNumber(newValue)) {
            setAvailableEmployees(
                employeeAll.filter(item => {
                    return disciplineToProject.some(
                        filter =>
                            filter.empId === item.id &&
                            filter.discId === newValue &&
                            filter.projId === values.projectId,
                    );
                }),
            );
            setValues({ ...values, discipline: newValue });
            setDisableAssignedTo(false);
        }
    };

    const handleChangeStatus = (newValue: number) => {
        if (isNumber(newValue)) {
            setValues({ ...values, statusId: newValue });
        }
    };

    const handleCloseDialog = () => {
        handleClose();
        hasDeadlineChanged.current = false;
    };

    const descriptionElementRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const renderTextInput = (
        id: string,
        name: string,
        value: string,
        label?: string,
        placeholder?: string,
        multiline?: boolean,
    ) => {
        return (
            <TextField
                id={id}
                name={name}
                label={label}
                multiline={multiline}
                rowsMax={2}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                className={classes.textField}
                InputProps={{ disableUnderline: true, className: classes.textFieldWrapper }}
            />
        );
    };

    const renderSelectInput = (
        id: string,
        name: string,
        availableData: any[],
        value?: any,
        label?: string,
        onChange?: (data: any) => void,
        disableChange?: boolean,
        disabled?: boolean,
    ) => {
        return (
            <CustomSelect
                id={id}
                inputName={name}
                defaultValue={value || label}
                label={label}
                availableData={availableData}
                selectClass={classes.rootSelect}
                selectedClass={classes.selectedItem}
                // @ts-ignore
                onChange={onChange}
                fullWidth
                disableChange={disableChange}
                disabled={disabled}
            />
        );
    };

    const renderSelectMultipleInput = (
        id: string,
        name: string,
        availableData: any[],
        dataValues: any[] | undefined,
        label?: string,
        onChange?: (data: any) => void,
        disabled?: boolean,
    ) => {
        return (
            <CustomSelectMultiple
                id={id}
                inputName={name}
                values={dataValues}
                defaultValue={label}
                availableData={availableData}
                selectClass={classes.rootSelect}
                // @ts-ignore
                onChangeValue={onChange}
                fullWidth
                renderSelected
                disabled={disabled}
            />
        );
    };

    const renderTextField = (
        title: string,
        placeholder: string,
        id: string,
        value: string,
        multiline?: boolean,
    ) => {
        return (
            <Paper elevation={0} className={classes.paperContainer}>
                <Grid container alignContent="center" alignItems="center" justify="center">
                    <Grid item xs={3}>
                        <Typography className={classes.inputLabel}>{title}</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.textFieldWrapper}>
                        {renderTextInput(id, id, value, '', placeholder, multiline)}
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const renderSelectMultipleField = (
        title: string,
        placeholder: string,
        availableData: any[],
        dataValues: any[] | undefined,
        onChange: (value: any[]) => void,
        disabled?: boolean,
    ) => {
        return (
            <Paper elevation={0} className={classes.paperContainer}>
                <Grid container xs={12} alignContent="center" alignItems="center" justify="center">
                    <Grid item xs={3}>
                        <Typography className={classes.inputLabel}>{title}</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.textFieldWrapper}>
                        {renderSelectMultipleInput(
                            title,
                            title,
                            availableData,
                            dataValues,
                            placeholder,
                            onChange,
                            disabled,
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const renderSelectField = (
        title: string,
        placeholder: string,
        availableData: any[],
        value: any,
        onChange: (data: any) => void,
        disableChange?: boolean,
        disabled?: boolean,
    ) => {
        return (
            <Paper elevation={0} className={classes.paperContainer}>
                <Grid container xs={12} alignContent="center" alignItems="center" justify="center">
                    <Grid item xs={3}>
                        <Typography className={classes.inputLabel}>{title}</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.textFieldWrapper}>
                        {renderSelectInput(
                            title,
                            title,
                            availableData,
                            value,
                            placeholder,
                            onChange,
                            disableChange,
                            disabled,
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const renderDateField = (
        title: string,
        placeholder: string,
        icon: any,
        onChange: any,
        value: any,
        minDate?: any,
    ) => {
        return (
            <Grid container item xs={4} alignContent="center" alignItems="center">
                <Paper
                    elevation={0}
                    className={clsx(classes.paperContainer, classes.paperContainerDateField)}
                >
                    <Grid container item xs={12} alignContent="center" alignItems="center">
                        <Grid
                            container
                            item
                            xs={6}
                            justify="flex-start"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Typography className={classes.inputLabel}>{title}</Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            justify="center"
                            alignContent="center"
                            alignItems="center"
                        >
                            <KeyboardDatePicker
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                variant="inline"
                                format={DATE_FORMAT}
                                margin="none"
                                id="date-picker-inline"
                                value={value}
                                minDate={minDate}
                                // @ts-ignore
                                onChange={onChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                keyboardIcon={icon}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                scroll={scroll}
                aria-labelledby="Add-Task-Dialog"
                fullWidth
                maxWidth="lg"
                classes={{
                    paper: classes.rootDialog,
                }}
            >
                <DialogTitle
                    classes={{
                        root: classes.dialogTitleContainer,
                    }}
                >
                    <Typography className={classes.dialogTitle}>
                        {isEditing ? t('Edit task') : t('Add task')}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleCloseDialog}
                    >
                        <CloseIcon className={classes.closeButtonIcon} />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    classes={{
                        root: classes.dialogContentContainer,
                    }}
                >
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {renderTextField(t('Title'), t('Create report'), 'name', values.name || '')}
                        {renderSelectField(
                            t('Project'),
                            t('Select project(s)'),
                            projects,
                            !isNaN(values.projectId) && !!values.projectId ? values.projectId : null,
                            handleSelectProject,
                            false,
                            isEditing,
                        )}
                        {renderSelectField(
                            t('Discipline'),
                            t('Select discipline'),
                            availableDiscipline,
                            values.discipline,
                            handleSelectDiscipline,
                            false,
                            disableDiscipline,
                        )}
                        {renderSelectMultipleField(
                            t('Person(s)'),
                            t('Select user(s)'),
                            availableEmployees,
                            values.assignedEmployees || [user.employeeId],
                            handleSelectEmployee,
                            disableAssignedTo,
                        )}
                        {renderSelectField(
                            t('Contract type'),
                            t('Select contract type'),
                            contractTypes,
                            values.contractType,
                            handleSelectContractType,
                            false,
                            isEditing,
                        )}
                        {isEditing &&
                            renderSelectField(
                                t('Status'),
                                t('Select an status'),
                                status,
                                values.statusId,
                                handleChangeStatus,
                                false,
                                !user?.role?.includes(UserRoles.ADMIN) && !isUserAssignedToTask,
                            )}
                        {renderSelectField(
                            t('Type'),
                            t('Select a type'),
                            types,
                            isEditing
                                ? values.typeId
                                : types.find(
                                      type => type.name === TaskType.MT || type.name === TaskType.MT_ACTIE,
                                  )?.id,
                            handleChangeStatus,
                            true,
                            true,
                        )}
                        {renderTextField(
                            t('To do'),
                            t('+ add'),
                            'description',
                            values.description || '',
                            true,
                        )}
                        {renderTextField(
                            t('Remarks'),
                            t('Remark description'),
                            'remarks',
                            values.remarks || '',
                            true,
                        )}
                        <Grid xs={12} container item direction="row">
                            {renderDateField(
                                t('Start date'),
                                dayjs().format(DATE_FORMAT),
                                <TodayIcon className={classes.calendarIcon} />,
                                handleDateChange,
                                new Date(dayjs(values.startDate, SUBMITTING_DATE_FORMAT).valueOf()),
                            )}
                            {renderDateField(
                                t('Deadline'),
                                dayjs().format(DATE_FORMAT),
                                <TodayIcon className={classes.calendarIcon} />,
                                handleDeadlineDateChange,
                                new Date(dayjs(values.deadline, SUBMITTING_DATE_FORMAT).valueOf()),
                                new Date(dayjs(values.startDate, SUBMITTING_DATE_FORMAT).valueOf()),
                            )}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        label={t('Save')}
                        onClick={handleSaveButtonClick}
                        labelClassName={classes.addTaskButtonLabel}
                        buttonClassName={classes.addTaskButton}
                        loading={loading}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditTaskDialogForm;
