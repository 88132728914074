import axios from 'axios';
import { PerformanceController } from '../api/PerformanceController';
import { PerformanceFilterQuery, PerformanceRawResponse } from '../models/Performance';

export const getPerformanceData = async (
    performanceReportType: number,
    query: PerformanceFilterQuery,
): Promise<unknown> => {
    const performanceApi = new PerformanceController();

    const { data } = await performanceApi.apiV1PerformancesReportsIdUsingGet(performanceReportType, query);

    return (data as unknown) as PerformanceRawResponse[];
};

export const generatePDFReport = async (
    performanceReportType: number,
    year: number,
    file: File,
    month?: number,
    filters?: string,
): Promise<unknown> => {
    const data = new FormData();
    data.append('file', file);
    data.append('year', year.toString());
    if (filters) {
        data.append('filters', filters);
    }
    if (month) data.append('month', month.toString());

    const { data: responseData } = await axios.post(
        `/api/v1/performances/reports/${performanceReportType}`,
        data,
        { responseType: 'blob' },
    );
    return responseData;
};
