import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty, lowerCase } from 'lodash';
import clsx from 'clsx';
import dayjs from 'dayjs';
import CloseIcon from '@material-ui/icons/Close';
import Searchbox from '../Searchbox';
import { useStyles } from './styles';
import FilterSelectButton from '../../../../components/FilterSelectButton';
import { RootReducer, TasksReducer } from '../../../../models/Redux';
import { Filter } from '../../../../models/Filter';
import { chooseSorting } from '../../../../config/utils';
import { TaskSorting } from '../../../../models/enums/TaskSorting';
import { TaskSortingTypes } from '../../../../models/enums/TaskSortingTypes';
import CustomSelect from '../../../../components/CustomSelect';
import Toolbar from '../../../../components/Toolbar';

interface Props {
    handleAddTask: () => void;
    handleSort: (order: string) => void;
    handleNextWeek: () => void;
    handlePreviousWeek: () => void;
    handleNextMonth: () => void;
    handlePreviousMonth: () => void;
    handleNextYear: () => void;
    handlePreviousYear: () => void;
    handleExecuteQuery: (filters: string) => void;
    handleExecuteSearchQuery: (search: string) => void;
    groupBy: number;
    setGroupByValue: (value: number) => void;
    week?: number;
    month?: number;
    year: number;
    initialFilters: Record<string, string[] | undefined>;
    initialSearch?: string;
    disabled?: boolean;
}

enum FilterType {
    PROJECT = 'PROJECT',
    ASSIGNED_TO = 'ASSIGNED_TO',
    DISCIPLINE = 'DISCIPLINE',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
}

const TaskTableHeader: FC<Props> = ({
    handleSort,
    handleAddTask,
    handleNextWeek,
    handlePreviousWeek,
    handlePreviousMonth,
    handleNextMonth,
    handleNextYear,
    handlePreviousYear,
    handleExecuteQuery,
    handleExecuteSearchQuery,
    groupBy,
    setGroupByValue,
    week,
    month,
    year,
    initialFilters,
    initialSearch,
    disabled,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { discipline, projects, employeeAssigned, status, types } = useSelector<RootReducer, TasksReducer>(
        state => state.tasks,
    );

    const [sortOrder, setSortOrder] = useState<TaskSorting>(TaskSorting.EMPTY);
    const [search, setSearch] = useState<string>('');
    const [typeFilter, setTypeFilter] = useState<Filter[]>([]);
    const [statusFilter, setStatusFilter] = useState<Filter[]>([]);
    const [assignedToFilter, setAssignedToFilter] = useState<Filter[]>([]);
    const [projectsFilter, setProjectsFilter] = useState<Filter[]>([]);
    const [disciplineFilter, setDisciplineFilter] = useState<Filter[]>([]);

    useEffect(() => {
        setSearch(initialSearch || '');
        setAssignedToFilter(
            initialFilters?.assignedTo
                ? employeeAssigned.filter(type => initialFilters.assignedTo?.includes(type.id.toString()))
                : [],
        );
        setStatusFilter(
            initialFilters?.status
                ? status.filter(type => initialFilters.status?.includes(type.id.toString()))
                : [],
        );
        setTypeFilter(
            initialFilters?.taskType
                ? types.filter(type => initialFilters.taskType?.includes(type.id.toString()))
                : [],
        );
        setProjectsFilter(
            initialFilters?.projects
                ? projects.filter(type => initialFilters.projects?.includes(type.id.toString()))
                : [],
        );
        setDisciplineFilter(
            initialFilters?.discipline
                ? discipline.filter(type => initialFilters.discipline?.includes(type.id.toString()))
                : [],
        );
    }, [projects, initialFilters, employeeAssigned, status, types, discipline, initialSearch]);

    const selectedFilters = useRef<any>(initialFilters || {});
    const allSelectedFilters = useMemo(() => {
        return typeFilter
            .map(item => {
                return { ...item, filterType: FilterType.TYPE };
            })
            .concat(
                statusFilter.map(item => {
                    return { ...item, filterType: FilterType.STATUS };
                }),
                projectsFilter.map(item => {
                    return { ...item, filterType: FilterType.PROJECT };
                }),
                assignedToFilter.map(item => {
                    return { ...item, filterType: FilterType.ASSIGNED_TO };
                }),
                disciplineFilter.map(item => {
                    return { ...item, filterType: FilterType.DISCIPLINE };
                }),
            );
    }, [assignedToFilter, disciplineFilter, projectsFilter, statusFilter, typeFilter]);

    const isCurrentWeek = useMemo(() => {
        return week === dayjs().week() && year === dayjs().year();
    }, [week, year]);

    const parsedMonth = useMemo(() => {
        return month && month < 10 ? `0${month}` : month;
    }, [month]);

    const handleSortLocal = (filterType: TaskSortingTypes) => {
        const newSorting = chooseSorting(filterType, sortOrder);

        setSortOrder(newSorting);
        handleSort(newSorting);
    };

    const handleSelectFilter = (newArray: Filter[], filterName: string) => {
        /* eslint-disable */
        switch (filterName) {
            case 'project-select':
                const projectNewValues = parseSelectedFilters(projectsFilter, newArray);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }

                setProjectsFilter(projectNewValues.newFilterArray);

                break;
            case 'discipline-select':
                const assignToFilterNewValues = parseSelectedFilters(disciplineFilter, newArray);

                selectedFilters.current.discipline = assignToFilterNewValues.newValues;
                if (isEmpty(assignToFilterNewValues.newValues)) {
                    delete selectedFilters.current.discipline;
                }
                setDisciplineFilter(assignToFilterNewValues.newFilterArray);

                break;
            case 'assigned-to-select':
                const assignedToNewValues = parseSelectedFilters(assignedToFilter, newArray);

                selectedFilters.current.assignedTo = assignedToNewValues.newValues;
                if (isEmpty(assignedToNewValues.newValues)) {
                    delete selectedFilters.current.assignedTo;
                }
                setAssignedToFilter(assignedToNewValues.newFilterArray);

                break;
            case 'status-select':
                const statusNewValues = parseSelectedFilters(statusFilter, newArray);

                selectedFilters.current.status = statusNewValues.newValues;
                if (isEmpty(statusNewValues.newValues)) {
                    delete selectedFilters.current.status;
                }
                setStatusFilter(statusNewValues.newFilterArray);

                break;
            case 'type-select':
                const typeNewValues = parseSelectedFilters(typeFilter, newArray);

                selectedFilters.current.taskType = typeNewValues.newValues;
                if (isEmpty(typeNewValues.newValues)) {
                    delete selectedFilters.current.taskType;
                }

                setTypeFilter(typeNewValues.newFilterArray);

                break;
            default:
                break;
        }
        /* eslint-enable */
    };

    const handleRemoveFilter = (elementToRemove: Filter, filterName: FilterType) => {
        /* eslint-disable */
        switch (filterName) {
            case FilterType.PROJECT:
                const projectNewValues = removeFilterFromArray(projectsFilter, elementToRemove);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }

                setProjectsFilter(projectNewValues.newFilterArray);

                break;
            case FilterType.DISCIPLINE:
                const assignToFilterNewValues = removeFilterFromArray(disciplineFilter, elementToRemove);

                selectedFilters.current.discipline = assignToFilterNewValues.newValues;
                if (isEmpty(assignToFilterNewValues.newValues)) {
                    delete selectedFilters.current.discipline;
                }
                setDisciplineFilter(assignToFilterNewValues.newFilterArray);

                break;
            case FilterType.ASSIGNED_TO:
                const assignedToNewValues = removeFilterFromArray(assignedToFilter, elementToRemove);

                selectedFilters.current.assignedTo = assignedToNewValues.newValues;
                if (isEmpty(assignedToNewValues.newValues)) {
                    delete selectedFilters.current.assignedTo;
                }
                setAssignedToFilter(assignedToNewValues.newFilterArray);

                break;
            case FilterType.STATUS:
                const statusNewValues = removeFilterFromArray(statusFilter, elementToRemove);

                selectedFilters.current.status = statusNewValues.newValues;
                if (isEmpty(statusNewValues.newValues)) {
                    delete selectedFilters.current.status;
                }
                setStatusFilter(statusNewValues.newFilterArray);

                break;
            case FilterType.TYPE:
                const typeNewValues = removeFilterFromArray(typeFilter, elementToRemove);

                selectedFilters.current.taskType = typeNewValues.newValues;
                if (isEmpty(typeNewValues.newValues)) {
                    delete selectedFilters.current.taskType;
                }

                setTypeFilter(typeNewValues.newFilterArray);

                break;
            default:
                break;
        }
        /* eslint-enable */
    };

    const parseSelectedFilters = (
        previousFilters: Filter[],
        newFiltersArray: Filter[],
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...previousFilters];

        if (previousFilters.find(filter => filter.id === newFiltersArray[0].id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== newFiltersArray[0].id);
        } else {
            updatedFilter.push(newFiltersArray[0]);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    const removeFilterFromArray = (
        filterArray: Filter[],
        elementToRemove: Filter,
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...filterArray];

        if (filterArray.find(filter => filter.id === elementToRemove.id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== elementToRemove.id);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    const handleDelete = (item: { filterType: FilterType; id: any; name: string }) => {
        handleRemoveFilter({ id: item.id, name: item.name }, item.filterType);
        handleExecuteQuery(selectedFilters.current);
    };

    const executeQuery = () => {
        handleExecuteQuery(selectedFilters.current);
    };

    const renderSortingIcon = (sortingType: TaskSortingTypes) => {
        if (sortingType === TaskSortingTypes.STARTED) {
            if (sortOrder === TaskSorting.STARTED_DESC) {
                return <ArrowDownwardIcon className={classes.arrow} />;
            }
            if (sortOrder === TaskSorting.STARTED_ASC) {
                return <ArrowUpwardIcon className={classes.arrow} />;
            }
            return null;
        }

        if (sortOrder === TaskSorting.DEADLINE_DESC) {
            return <ArrowDownwardIcon className={classes.arrow} />;
        }
        if (sortOrder === TaskSorting.DEADLINE_ASC) {
            return <ArrowUpwardIcon className={classes.arrow} />;
        }
        return null;
    };

    const renderSelectInput = (
        id: string,
        name: string,
        availableData: any[],
        value: any,
        label?: string,
        onChange?: (data: any) => void,
        disableChange?: boolean,
        disabledInput?: boolean,
    ) => {
        return (
            <CustomSelect
                id={id}
                inputName={name}
                defaultValue={value || label}
                label={label}
                availableData={availableData}
                selectClass={classes.rootSelect}
                selectedClass={classes.selectedItem}
                // @ts-ignore
                onChange={onChange}
                disableChange={disableChange}
                disabled={disabledInput}
                iconClass={classes.iconClass}
            />
        );
    };

    const renderFilters = () => (
        <>
            <Toolbar>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FilterSelectButton
                            id="project-select"
                            name="project-select"
                            filterName={t('project')}
                            onChange={newValue => handleSelectFilter(newValue, 'project-select')}
                            onClose={executeQuery}
                            availableFilters={projects}
                            values={projectsFilter}
                        />
                        <FilterSelectButton
                            id="discipline-select"
                            name="discipline-select"
                            filterName={t('discipline')}
                            onChange={newValue => handleSelectFilter(newValue, 'discipline-select')}
                            onClose={executeQuery}
                            availableFilters={discipline}
                            values={disciplineFilter}
                        />
                        <FilterSelectButton
                            id="assigned-to-select"
                            name="assigned-to-select"
                            filterName={t('assigned to')}
                            onChange={newValue => handleSelectFilter(newValue, 'assigned-to-select')}
                            onClose={executeQuery}
                            availableFilters={employeeAssigned}
                            values={assignedToFilter}
                        />
                        <FilterSelectButton
                            id="status-select"
                            name="status-select"
                            filterName={t('status')}
                            onChange={newValue => handleSelectFilter(newValue, 'status-select')}
                            onClose={executeQuery}
                            availableFilters={status}
                            values={statusFilter}
                        />
                        <FilterSelectButton
                            id="type-select"
                            name="type-select"
                            filterName={t('type')}
                            onChange={newValue => handleSelectFilter(newValue, 'type-select')}
                            onClose={executeQuery}
                            availableFilters={types}
                            values={typeFilter}
                        />
                        <Searchbox
                            id="search-task"
                            search={search}
                            name="search-task"
                            onChange={e => {
                                setSearch(e.target.value);
                            }}
                            onSubmit={() => {
                                handleExecuteSearchQuery(search);
                            }}
                            onClear={() => {
                                setSearch('');
                                handleExecuteSearchQuery('');
                            }}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<AddIcon />}
                        className={classes.addTaskButton}
                        onClick={handleAddTask}
                    >
                        {t('add task')}
                    </Button>
                </div>
            </Toolbar>

            <Grid item xs={12} direction="row" className={classes.chipContainer}>
                {allSelectedFilters.map(item => (
                    <div className={classes.chip}>
                        <div>
                            <Typography className={classes.filterTypeText}>
                                {lowerCase(item.filterType)}:
                            </Typography>
                            <Typography className={classes.filterText}>{item.name}</Typography>
                        </div>
                        <div className={classes.closeIconContainer}>
                            <CloseIcon onClick={() => handleDelete(item)} className={classes.closeIcon} />
                        </div>
                    </div>
                ))}
            </Grid>
        </>
    );

    return (
        <>
            {renderFilters()}
            <Grid container alignContent="center" alignItems="center">
                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    className={classes.tableHeaderTitle}
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid container item xs={7} alignContent="center" alignItems="center">
                        {renderSelectInput(
                            'group-task-by',
                            'group-task-by',
                            [
                                { id: 1, name: t('Year') },
                                { id: 2, name: t('Month') },
                                { id: 3, name: t('Week') },
                            ],
                            groupBy,
                            undefined,
                            data => {
                                setGroupByValue(data);
                            },
                        )}
                        {groupBy === 1 && (
                            <>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handlePreviousYear}
                                    disabled={disabled}
                                >
                                    <ArrowBackIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                                <Typography className={classes.headerText}>{year}</Typography>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handleNextYear}
                                    disabled={disabled}
                                >
                                    <ArrowForwardIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                            </>
                        )}
                        {groupBy === 2 && (
                            <>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handlePreviousMonth}
                                    disabled={disabled}
                                >
                                    <ArrowBackIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                                <Typography className={classes.headerText}>
                                    {t(
                                        `${dayjs(`01/${parsedMonth}/2021`, 'DD/MM/YYYY').format(
                                            'MMM',
                                        )} - ${year}`,
                                    )}
                                </Typography>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handleNextMonth}
                                    disabled={disabled}
                                >
                                    <ArrowForwardIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                            </>
                        )}
                        {groupBy === 3 && (
                            <>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handlePreviousWeek}
                                    disabled={disabled}
                                >
                                    <ArrowBackIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                                <Typography className={classes.headerText}>
                                    {isCurrentWeek
                                        ? t(`This week (week ${week || 1} - ${year})`)
                                        : t(`Week ${week} - ${year}`)}
                                </Typography>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handleNextWeek}
                                    disabled={disabled}
                                >
                                    <ArrowForwardIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                            </>
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={3}
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                        justify="center"
                        style={{ marginLeft: 0 }}
                    >
                        <Grid
                            container
                            item
                            xs={6}
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Button
                                className={classes.sortButton}
                                disableRipple
                                onClick={() => handleSortLocal(TaskSortingTypes.STARTED)}
                                endIcon={renderSortingIcon(TaskSortingTypes.STARTED)}
                            >
                                <Typography className={clsx(classes.headerText, classes.headerTextDate)}>
                                    {t('Start date')}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={6}
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Button
                                className={classes.sortButton}
                                disableRipple
                                onClick={() => handleSortLocal(TaskSortingTypes.DEADLINE)}
                                endIcon={renderSortingIcon(TaskSortingTypes.DEADLINE)}
                            >
                                <Typography className={clsx(classes.headerText, classes.headerTextDate)}>
                                    {t('Deadline')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default TaskTableHeader;
