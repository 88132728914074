import { TableRow as MuiTableRow, TableCell as MuiTableCell } from '@material-ui/core';
import { flexRender, Row } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

interface Props<V> {
    row: Row<V>;
    onRowClick?: (row: V) => void;
    renderSubComponent?: (row: Row<V>) => ReactElement;
}

const TableRow = <V,>({ row, onRowClick, renderSubComponent }: Props<V>): ReactElement => {
    const classes = useStyles();

    return (
        <>
            <MuiTableRow
                className={clsx({ [classes.expandedRow]: row.getIsExpanded() && renderSubComponent })}
            >
                {row.getVisibleCells().map(cell => (
                    <MuiTableCell
                        key={cell.id}
                        onClick={() =>
                            onRowClick && !isEmpty(cell.column.columnDef.header) && onRowClick(row.original)
                        }
                        className={classes.tableCell}
                        style={{
                            cursor:
                                onRowClick && !isEmpty(cell.column.columnDef.header) ? 'pointer' : 'default',
                        }}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </MuiTableCell>
                ))}
            </MuiTableRow>

            {row.getIsExpanded() && renderSubComponent && (
                <MuiTableRow>
                    <MuiTableCell className={classes.rowSubComponent} colSpan={row.getVisibleCells().length}>
                        {renderSubComponent(row)}
                    </MuiTableCell>
                </MuiTableRow>
            )}
        </>
    );
};

export default TableRow;
