import { FC, createContext } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { readFromLocalStorage } from '../../services/config/LocalStorage';
import { Token } from '../../models/enums/Token';

const AxiosProvider: FC = ({ children }) => {
    const AxiosContext = createContext('__axiosContext');

    const getAuthorizationToken = () => readFromLocalStorage(Token.TOKEN);

    const isTokenExpired = () => {
        const token = readFromLocalStorage(Token.TOKEN);

        if (token) {
            const decodedToken = jwtDecode<JwtPayload>(token);
            const dateTime = new Date((decodedToken?.exp || 0) * 1000);
            return dayjs(dateTime).isBefore(dayjs());
        }
        return true;
    };

    axios.defaults.baseURL = process.env.REACT_APP_API;

    // Add a request interceptor
    axios.interceptors.request.use(
        function (config: AxiosRequestConfig) {
            // Do something before request is sent
            if (!isTokenExpired()) {
                config.headers.Authorization = `Bearer ${getAuthorizationToken()}`;
            }
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        },
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        function (response): AxiosResponse {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            if (error.response?.status === 401 && !originalRequest._retry) {
                // logoutUser();
                // here should be refresh token procedure!!
                // originalRequest._retry = true;
                // const access_token = await refreshAccessToken();
                // if (access_token) do sth... after this logout
                // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
                // return api(originalRequest);
                // const consumeRefreshToken = useCallback(async () => {
                // const {loginWithRefreshToken} = useAuthenticationService();
                // const tokens: IAuthTokens = await readFromStorage('tokens');
                // return false;
                // return tokens.refresh_token.length && tokens.time_received + tokens.refresh_expires_in < Date.now() && await loginWithRefreshToken(tokens.refresh_token);
                // }, []);
            }
            return Promise.reject(error);
        },
    );

    return <AxiosContext.Provider value="">{children}</AxiosContext.Provider>;
};

export default AxiosProvider;
