import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment, TextField as MuiTextField, TextFieldProps } from '@material-ui/core';

type Props = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    validation?: string | RegExp;
} & TextFieldProps;

const RhfTextField: FC<Props> = ({ name, disabled, validation, ...muiProps }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const type = useMemo(() => {
        if (muiProps.type === 'password') {
            return showPassword ? 'text' : 'password';
        }

        return muiProps.type;
    }, [muiProps.type, showPassword]);

    const onChange = useCallback(
        (field: ControllerRenderProps, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (validation && !RegExp(validation).test(e.target.value)) {
                return;
            }

            field.onChange(e.target.value);
        },
        [validation],
    );

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <MuiTextField
                        {...field}
                        {...muiProps}
                        type={type}
                        value={field.value}
                        onChange={e => onChange(field, e)}
                        disabled={isSubmitting || disabled}
                        error={Boolean(error)}
                        helperText={error?.message || ''}
                        autoComplete="off"
                        InputProps={{
                            ...muiProps.InputProps,
                            endAdornment:
                                muiProps.type === 'password' ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ backgroundColor: 'transparent' }}
                                            onClick={() => setShowPassword(prevState => !prevState)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    muiProps.InputProps?.endAdornment || null
                                ),
                        }}
                    />
                );
            }}
        />
    );
};

export default RhfTextField;
