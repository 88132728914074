import React, { FC } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { ProjectSimpleData } from '../../../../models/Project';

interface Props {
    item: ProjectSimpleData;
    handleOpenDetails: (project: ProjectSimpleData) => void;
}

export const ProjectsTableRow: FC<Props> = ({ item }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <Grid
                container
                direction="column"
                className={classes.rowContainer}
                onClick={() => history.push(`/projects/${item.id}`)}
            >
                <Paper className={clsx(classes.paperBackground)}>
                    <Grid container item xs={12} direction="row" alignItems="center">
                        <Grid container item xs={12}>
                            <div className={classes.defaultProjectLogo}>
                                {!!item.logoUrl && (
                                    <img
                                        alt="Project Logo"
                                        src={item.logoUrl}
                                        className={classes.projectLogoImg}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography className={classes.projectTitle}>{item.name}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};
export default ProjectsTableRow;
