import { FC } from 'react';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import DayJsUtils from '@date-io/dayjs';
import thunkMiddleware from 'redux-thunk';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { resources } from '../../config/resources';
import InternationalizationProvider from '../InternationalizationProvider';
import { theme } from '../../config/theme';
import rootReducer from '../../store/reducers';

import AxiosProvider from '../AxiosProvider';
import QueryProvider from '../../react-query/QueryProvider';

const AppProvider: FC = ({ children }) => {
    const composeEnhancers =
        process.env.NODE_ENV === 'production'
            ? compose
            : composeWithDevTools({
                  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
              });

    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

    return (
        <InternationalizationProvider resources={resources}>
            <AxiosProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Provider store={store}>
                            <MuiPickersUtilsProvider utils={DayJsUtils}>
                                <QueryProvider>{children}</QueryProvider>
                            </MuiPickersUtilsProvider>
                        </Provider>
                    </SnackbarProvider>
                </ThemeProvider>
            </AxiosProvider>
        </InternationalizationProvider>
    );
};

export default AppProvider;
