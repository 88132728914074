import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import axios, { AxiosError, AxiosResponse } from 'axios';
import reportWebVitals from './reportWebVitals';
import App from './components/App';
import AppProvider from './components/AppProvider';

Sentry.init({
    environment: `${process.env.REACT_APP_SENTRY_ENV}`,
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: Number.parseFloat(`${process.env.REACT_APP_SENTRY_SAMPLE_RATE}`),
});

axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        Sentry.captureException(error);
        return Promise.reject(error);
    },
);

ReactDOM.render(
    <StrictMode>
        <AppProvider>
            <App />
        </AppProvider>
    </StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
