import dayjs from 'dayjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useLocalStorage } from './useLocalStorage';
import { Token } from '../../models/enums/Token';

export const useTokenExpirationDate = (): {
    tokenExpirationDateValid: boolean;
    expiresInSeconds: number;
} => {
    const [token] = useLocalStorage(Token.TOKEN, null);

    if (token) {
        const decodedToken = jwtDecode<JwtPayload>(token);
        const dateTime = new Date((decodedToken?.exp || 0) * 1000);
        dayjs(dateTime).isBefore(dayjs());

        const now = new Date();
        const tokenExpirationDate = new Date((decodedToken?.exp || 0) * 1000);
        const tokenExpirationDateValid = dayjs(tokenExpirationDate).isAfter(now);
        const expiresInSeconds = dayjs(tokenExpirationDate).diff(now) / 1000;

        return { tokenExpirationDateValid, expiresInSeconds };
    }

    return { tokenExpirationDateValid: false, expiresInSeconds: 0 };
};
