import * as yup from 'yup';
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-named-default
import { default as defaultConfiguration } from '../../../../config/application_index.json';
import { SUBMITTING_DATE_FORMAT } from '../../../../config/constants';
import { Task, TaskBodyForm } from '../../../../models/Task';

export type ColumnSizes = number & (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12);

export const getTaskInitialValues = (initialTask?: TaskBodyForm): TaskBodyForm => {
    const result = {
        id: initialTask?.id || -1,
        assignedEmployees: initialTask?.assignedEmployees,
        deadline: initialTask?.deadline || dayjs().add(1, 'days').format(SUBMITTING_DATE_FORMAT),
        description: initialTask?.description || '',
        name: initialTask?.name || '',
        projectId: initialTask?.projectId || NaN,
        discipline: initialTask?.discipline || NaN,
        remarks: initialTask?.remarks || '',
        statusId: initialTask?.statusId,
        typeId: initialTask?.typeId,
        startDate: initialTask?.startDate || dayjs().format(SUBMITTING_DATE_FORMAT),
        contractType: initialTask?.contractType,
    };

    return result;
};

const { descriptionLength, remarksDescription, nameLength } = defaultConfiguration.configs.task;

export const validationSchema = yup.object().shape({
    assignedEmployees: yup
        .array()
        .min(1, 'Should assign at least one employee to the task')
        .required('Should assign at least one employee to the task'),
    description: yup.string().max(descriptionLength, `Maximum length is ${descriptionLength} characters`),
    name: yup
        .string()
        .max(nameLength, `Maximum length is ${nameLength} characters`)
        .required('Task name is required field'),
    projectId: yup
        .number()
        .typeError('Should select a project')
        .min(0, 'Should select a project')
        .required('Should select a project'),
    remarks: yup.string().max(remarksDescription, `Maximum length is ${remarksDescription} characters`),
    /* .required('Task remarks is required field') */
    startDate: yup.string().required('Start date is required field'),
    deadline: yup.string().required('Deadline date is required field'),
});
