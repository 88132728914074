import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TaskDto } from '../../../api/data-contracts';
import { useStyles } from './styles';

interface Props {
    task: TaskDto | undefined;
}

const ConformityTitle: FC<Props> = ({ task }) => {
    const classes = useStyles();

    return (
        <Box>
            <Typography className={classes.taskSubtitle}>conformity register:</Typography>
            <Typography className={classes.taskTitle}>{task?.name}</Typography>
        </Box>
    );
};

export default ConformityTitle;
