import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        margin: '0.313rem 0.625rem',
        padding: '0.188rem 0px',
        backgroundColor: '#13212D',
        borderTopLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',
    },
    rootSelect: {
        padding: '0.625rem 2.5rem',
        fontWeight: 'bold',
        '&:focus': {
            padding: '0.625rem 2.5rem',
            backgroundColor: 'transparent',
        },
    },
    rootSelectMenu: {
        borderRadius: 0,
    },
    menuListSelectMenu: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    selected: {
        paddingRight: '2.5rem !important',
    },
    selectedItem: {
        backgroundColor: 'transparent !important',
    },
    selectInputListItemIcon: {
        position: 'absolute',
        right: '0.625rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        justifyItems: 'end',
        color: '#E26A3A',
    },
    checkbox: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    iconComponent: {},
    icon: {
        color: 'black',
        marginRight: '0.625rem',
    },
    iconOpen: {
        color: 'black',
    },
});
