import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    personsTitle: {
        color: '#E65216',
        fontWeight: 'bold',
        fontSize: '1.75rem',
        lineHeight: 1.2,
        marginBottom: '0.75rem',
    },
    sectionTitle: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '1.625rem',
        lineHeight: 1.2,
    },
});
