import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import BackButton from '../../components/BackButton';
import { queryKeys } from '../../react-query/constants';
import { checkProjectAccess, getProjectById } from '../../services/ProjectService';
import { ProjectDetailsDto } from '../../api/data-contracts';
import ProjectTitle from './ProjectTitle';
import ProjectActions from './ProjectActions';
import ProjectDetailsTabs from './ProjectDetailsTabs';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import { UserRoles } from '../../models/enums/UserRoles';

const ProjectDetailsPage: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { user } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [hideDetails, setHideDetails] = useState<boolean>(false);

    const { data: project, isLoading } = useQuery<ProjectDetailsDto>(
        [queryKeys.users, id],
        () => getProjectById(parseInt(id as string, 10)),
        {
            enabled: !!id,
        },
    );

    useEffect(() => {
        if (id && !user?.role?.includes(UserRoles.ADMIN) && user?.role?.includes(UserRoles.FREELANCER)) {
            setHideDetails(true);

            checkProjectAccess(parseInt(id, 10)).then(allowed => {
                if (allowed) {
                    setHideDetails(false);
                } else {
                    enqueueSnackbar("You're not allowed to access this resource", { variant: 'error' });
                    history.push('/projects');
                }
            });
        }
    }, [enqueueSnackbar, history, id, user.id, user.role]);

    return hideDetails ? null : (
        <Box width="100%">
            <BackButton label="back to all projects" />
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
                    <CircularProgress />
                </Box>
            ) : project ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mt="40px"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <ProjectTitle
                            project={project}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                        {user?.role?.includes(UserRoles.ADMIN) && <ProjectActions project={project} />}
                    </Box>
                    <ProjectDetailsTabs project={project} selectedTab={selectedTab} />
                </Box>
            ) : null}
        </Box>
    );
};

export default ProjectDetailsPage;
