import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { FormFieldsBoxVariant } from '../../models/enums/FormFieldsBoxVariant';

export const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '2.125rem',
        lineHeight: 1.1,
        fontWeight: 700,
        color: 'white',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '1.185rem',
        },

        [theme.breakpoints.down('lg')]: {
            marginBottom: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: '1rem',
            fontSize: '1.5rem',
        },
    },
    container: ({ variant }: { variant: FormFieldsBoxVariant }) => ({
        backgroundColor:
            variant === FormFieldsBoxVariant.DARK
                ? theme.palette.secondary.dark
                : theme.palette.secondary.light,
        borderRadius: '3.125rem 0',
        display: 'grid',
        [theme.breakpoints.up('lg')]: {
            padding: '3.125rem 4.375rem',
        },

        [theme.breakpoints.down('lg')]: {
            padding: '1.185rem 3.125rem',
        },

        [theme.breakpoints.down('md')]: {
            padding: ' 1.185rem',
        },
        width: '100%',
    }),
    childrenWrapper: {
        display: 'grid',
        gap: '0.938rem',
    },
}));
