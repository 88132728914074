import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    companyLogo: {
        width: '10rem',
        height: 'fit-content',
        marginRight: '0.625rem',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '14.375rem',
    },
    logoText: {
        width: '3.75rem',
        color: '#c4c4c4',
        fontSize: '1rem',
        lineHeight: '18px',
    },
});
