import { FC } from 'react';
import { Box } from '@material-ui/core';
import GeneralTaskFormFields from './GeneralTaskFormFields';
import TodosTaskFormFields from './TodosTaskFormFields';
import PersonsTaskFormFields from './PersonsTaskFormFields';
import FormFieldsBox from '../../../components/FormFieldsBox';
import RhfTextField from '../../../components/form-fields/RhfTextField';
import { useStyles } from './styles';

interface Props {
    isEditing: boolean;
}
const TaskFormFields: FC<Props> = ({ isEditing }) => {
    const classes = useStyles();

    return (
        <Box className={classes.grid}>
            <Box className={classes.column}>
                <Box>
                    <GeneralTaskFormFields isEditing={isEditing} />
                </Box>
                <Box>
                    <PersonsTaskFormFields />
                </Box>
            </Box>
            <Box className={classes.column}>
                <Box>
                    <TodosTaskFormFields />
                </Box>
                <Box>
                    <FormFieldsBox title="description">
                        <RhfTextField name="description" variant="filled" multiline rows={7} rowsMax={10} />
                    </FormFieldsBox>
                </Box>
                <Box>
                    <FormFieldsBox title="remarks">
                        <RhfTextField name="remarks" variant="filled" multiline rows={7} rowsMax={10} />
                    </FormFieldsBox>
                </Box>
            </Box>
        </Box>
    );
};

export default TaskFormFields;
