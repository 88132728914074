import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ContactDto, ProjectGroupDto } from '../../../../api/data-contracts';
import InfoCard from '../../../../components/InfoCard';
import ProjectUser from './ProjectUser';
import { useStyles } from './styles';

interface Props {
    group: string;
    users: Record<string, Record<string, ProjectGroupDto>> | undefined;
}

const ProjectUsersCard: FC<Props> = ({ group, users }) => {
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.personsTitle}>{group}</Typography>
            <InfoCard marginBottom>
                {Object.keys(users?.[group] || {}).map((section: string, key: number) => (
                    <Grid key={key} container style={{ marginBottom: 38 }}>
                        <Grid item xs={12} md={3}>
                            <Typography className={classes.sectionTitle}>{section}</Typography>
                        </Grid>

                        <Grid item xs={12} md={9} lg={7}>
                            {users?.[group]?.[section].contacts?.map((user: ContactDto, key2: number) => (
                                <ProjectUser
                                    key={key2}
                                    user={user}
                                    hasDivider={
                                        (users?.[group]?.[section]?.contacts?.length || 0) > 1 &&
                                        key2 + 1 < (users?.[group]?.[section]?.contacts?.length || 0)
                                    }
                                />
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </InfoCard>
        </>
    );
};

export default ProjectUsersCard;
