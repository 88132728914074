import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    paper: {
        backgroundColor: '#263644',
        borderRadius: 0,
        borderBottomRightRadius: '1.5rem',
        maxWidth: '47.188rem',
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1.875rem 1.875rem 0 3.125rem',
    },
    title: {
        color: '#E65216',
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 700,
    },
    closeButton: {
        padding: 0,
    },
    closeButtonIcon: {
        color: '#708393',
    },
    dialogContent: {
        padding: '0 3.125rem 0 3.125rem',
        overflow: 'visible',
    },
    inviteUser: {
        fontWeight: 700,
        fontSize: '3.125rem',
        lineHeight: 1.12,
        marginBottom: '1.875rem',
    },
    dialogActions: {
        padding: '1.25rem 3.125rem 3.125rem 3.125rem',
    },
});
