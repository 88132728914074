import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    uploadAvatarIcon: {
        color: '#E65216',
        fontSize: '1rem',
    },
    logo: {
        backgroundColor: 'white',
        width: '13.75rem',
        height: '8.125rem',
        marginRight: '2.5rem',
        borderRadius: '1.25rem 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',

        '& img': {
            maxWidth: '13.75rem',
            maxHeight: '8.125rem',
        },
    },
    mouseOver: {
        backgroundColor: '#263644',
    },
    uploadAvatarIconWrapper: {
        borderBottom: '2px solid #E65216',
        width: '0.688rem',
        height: 0,
    },
    deleteAvatarButton: {
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
        backgroundColor: '#F80000',
        width: '1.25rem',
        height: '1.25rem',
        borderRadius: '1.25rem',
        padding: 0,
        minWidth: '1.25rem',

        '&:hover': {
            backgroundColor: '#F80000',
        },
    },
    deleteAvatarIcon: {
        color: 'white',
        fontSize: '0.875rem',
    },
    projectTitleLabel: {
        color: '#E65216',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        lineHeight: 1.2,
    },
    projectTitle: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '2.625rem',
        lineHeight: 1.15,
        marginBottom: '1.25rem',
        maxWidth: 400,
        overflowWrap: 'break-word',
    },
});
