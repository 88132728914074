/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { isString } from 'lodash';
import qs from 'qs';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
    extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null,
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
    private instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];

    constructor({ securityWorker, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    private mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig,
    ): AxiosRequestConfig {
        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.instance.defaults.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    public request = async <T = any, E = any>({
        secure,
        path,
        type,
        query,
        format = 'json',
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            (secure && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
        const requestParams = this.mergeRequestParams(params, secureParams);

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
                ...(requestParams.headers || {}),
            },
            params: query,
            paramsSerializer: params => {
                let encodedParams = '';
                if (params.hasOwnProperty('filters') && params.filters) {
                    let filters = params.filters;

                    delete params.filters;

                    if (!isString(filters)) {
                        filters = JSON.stringify(filters);
                    }

                    const filtersData = encodeURI(filters);
                    const stringifyParams = qs.stringify(params);

                    encodedParams = `${stringifyParams}&filters=${filtersData}`;
                    return encodedParams;
                }

                Object.keys(params).forEach(key => (params[key] === undefined ? delete params[key] : {}));

                return new URLSearchParams(params).toString();
            },
            responseType: format,
            data: body,
            url: path,
        });
    };
}
