import { makeStyles } from '@material-ui/core/styles';
import { APP_BAR_HEIGHT } from '../../config/theme';

export const useStyles = makeStyles(theme => ({
    app: {
        position: 'relative',
        backgroundColor: theme.palette.secondary.dark,
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        zIndex: 1,
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            padding: `30px 30px 0 30px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `0 80px 0 80px`,
        },
    },
    maxWidthXs: {
        maxWidth: '100%',
    },
    '@global': {
        '*::-webkit-scrollbar': {
            height: '0.6em',
            width: '0.6em',
            margin: '0px 10px',
            color: 'white',
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: '#44535e',
            border: 'solid 3px transparent',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            maxHeight: 50,
            outline: '1px solid #707070',
        },
    },
}));
