import { FC, useEffect } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldsBox from '../../../../components/FormFieldsBox';
import FieldWithLabel from '../../../../components/form-fields/FieldWithLabel';
import RhfSelect from '../../../../components/form-fields/RhfSelect';
import RhfDatePicker from '../../../../components/form-fields/RhfDatePicker';
import { DATE_FORMAT } from '../../../../config/constants';
import { RootReducer, TasksReducer } from '../../../../models/Redux';
import RhfTextField from '../../../../components/form-fields/RhfTextField';

interface Props {
    isEditing: boolean;
}

const GeneralTaskFormFields: FC<Props> = ({ isEditing }) => {
    const theme = useTheme();

    const { projects, employeeAll, status, contractTypes } = useSelector<RootReducer, TasksReducer>(
        state => state.tasks,
    );

    const { control, setValue } = useFormContext();

    const statusFieldValue = useWatch({
        name: 'statusId',
        control,
    });

    useEffect(() => {
        if (statusFieldValue === 1) {
            setValue('completed', null);
            setValue('completedBy', null);
        }
    }, [statusFieldValue, setValue]);

    return (
        <FormFieldsBox>
            {!isEditing && (
                <FieldWithLabel label="name">
                    <RhfTextField name="name" variant="filled" />
                </FieldWithLabel>
            )}

            <FieldWithLabel label="project">
                <RhfSelect
                    name="projectId"
                    variant="filled"
                    options={projects.map(({ id, name }) => ({
                        value: parseInt(id.toString(), 10),
                        label: name,
                    }))}
                    disabled={isEditing}
                />
            </FieldWithLabel>

            <FieldWithLabel label="status">
                <RhfSelect
                    name="statusId"
                    variant="filled"
                    options={status.map(({ id, name }) => ({
                        value: parseInt(id.toString(), 10),
                        label: name,
                    }))}
                />
            </FieldWithLabel>

            <FieldWithLabel label="start date">
                <RhfDatePicker
                    name="startDate"
                    inputVariant="filled"
                    format={DATE_FORMAT}
                    InputProps={{ endAdornment: <DateRangeIcon /> }}
                />
            </FieldWithLabel>

            <FieldWithLabel label="deadline">
                <RhfDatePicker
                    name="deadline"
                    inputVariant="filled"
                    format={DATE_FORMAT}
                    InputProps={{
                        endAdornment: <DateRangeIcon />,
                        style: { color: theme.palette.primary.main },
                    }}
                />
            </FieldWithLabel>

            <FieldWithLabel label="contract type">
                <RhfSelect
                    name="contractType"
                    variant="filled"
                    options={contractTypes.map(({ id, name }) => ({
                        value: parseInt(id.toString(), 10),
                        label: name,
                    }))}
                />
            </FieldWithLabel>

            <FieldWithLabel label="completed by">
                <RhfSelect
                    name="completedBy"
                    variant="filled"
                    options={employeeAll.map(({ id, name, avatar }) => ({
                        value: parseInt(id.toString(), 10),
                        avatar,
                        label: name,
                    }))}
                    disabled={statusFieldValue === 1}
                />
            </FieldWithLabel>

            <FieldWithLabel label="completed on">
                <RhfDatePicker
                    name="completed"
                    inputVariant="filled"
                    format={DATE_FORMAT}
                    InputProps={{ endAdornment: <DateRangeIcon /> }}
                    disabled={statusFieldValue === 1}
                />
            </FieldWithLabel>
        </FormFieldsBox>
    );
};

export default GeneralTaskFormFields;
