import { FC } from 'react';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@material-ui/core';

interface Props {
    avatar?: string | null;
    name: string | undefined;
}

const UserCell: FC<Props> = ({ avatar, name }) => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            {!isEmpty(avatar) ? (
                <img
                    src={`${process.env.REACT_APP_BLOB_PATH}${avatar}`}
                    alt="avatar"
                    style={{ width: 52, height: 52, borderRadius: 52, objectFit: 'cover' }}
                />
            ) : (
                <Box
                    style={{
                        width: 52,
                        height: 52,
                        borderRadius: 52,
                        backgroundColor: '#2a3643',
                    }}
                />
            )}

            <Typography
                style={{ color: 'white', fontSize: 20, lineHeight: 1.2, fontWeight: 500, marginLeft: 10 }}
            >
                {name}
            </Typography>
        </Box>
    );
};

export default UserCell;
