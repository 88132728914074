import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    avatarContainer: {
        width: '7.5rem',
        height: '7.5rem',
        borderRadius: '7.5rem',
        backgroundColor: '#263644',
        marginRight: '2rem',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& img': {
            width: '7.5rem',
            height: '7.5rem',
            borderRadius: '7.5rem',
            objectFit: 'cover',
        },
    },
    uploadAvatarButton: {
        width: '100%',
        height: '100%',
        borderRadius: '7.5rem',
    },
    uploadAvatarLabel: {
        color: 'white',
        fontSize: '1rem',
        lineHeight: 1.1,
        fontWeight: 700,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    uploadAvatarIcon: {
        color: '#E65216',
        fontSize: '1rem',
    },
    uploadAvatarIconWrapper: {
        borderBottom: '2px solid #E65216',
        width: '0.688rem',
        height: 0,
    },
    deleteAvatarButton: {
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
        backgroundColor: '#F80000',
        width: '1.25rem',
        height: '1.25rem',
        borderRadius: '1.25rem',
        padding: 0,
        minWidth: '1.25rem',

        '&:hover': {
            backgroundColor: '#F80000',
        },
    },
    deleteAvatarIcon: {
        color: 'white',
        fontSize: '0.875rem',
    },
    user: {
        color: '#E65216',
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 'bold',
    },
    userName: {
        color: 'white',
        fontSize: '3.125rem',
        lineHeight: 1.15,
        fontWeight: 'bold',
        marginBottom: '0.813rem',
    },
    phoneOrEmail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,

        '& p': {
            fontSize: '1.125',
            lineHeight: 1.2,
            fontWeight: 500,
            color: 'white',
        },
    },
    contactIcon: {
        fontSize: '1.25rem',
        color: '#708393',
    },
});
