import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { lowerCase } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { RootReducer, TasksReducer } from '../../../../models/Redux';
import RhfTextField from '../../../../components/form-fields/RhfTextField';
import RhfDateRangePicker from '../../../../components/form-fields/RhfDateRangePicker';
import { DATE_FORMAT } from '../../../../config/constants';
import FilterSelectButton from '../../../../components/FilterSelectButton';
import { Filter } from '../../../../models/Filter';
import { useStyles } from './styles';
import Toolbar from '../../../../components/Toolbar';

interface Props {
    inviteUser: () => void;
}

enum FilterType {
    DISCIPLINE = 'DISCIPLINE',
    START_DATE = 'START_DATE',
    END_DATE = 'END_DATE',
}

const FiltersFormFields: FC<Props> = ({ inviteUser }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { control, setValue } = useFormContext();

    const disciplines = useWatch({
        control,
        name: `disciplines`,
    });

    const startDate = useWatch({
        control,
        name: `startDate`,
    });

    const endDate = useWatch({
        control,
        name: `endDate`,
    });

    useEffect(() => {
        if (startDate && endDate && dayjs(startDate) > dayjs(endDate)) {
            enqueueSnackbar('Start date must be earlier than end date', { variant: 'error' });
            setValue('endDate', undefined);
        }
    }, [startDate, enqueueSnackbar, endDate, setValue]);

    const { disciplineAll } = useSelector<RootReducer, TasksReducer>(state => state.tasks);

    const availableFilters = useMemo(
        () =>
            disciplineAll.map(({ id, name }) => ({
                id,
                name,
            })),
        [disciplineAll],
    );

    const updateFilter = useCallback(
        (newValue: Filter[]) => {
            setValue(
                'disciplines',
                newValue.map(filter => filter.id),
            );
        },
        [setValue],
    );

    const handleDelete = (item: { filterType: FilterType; id: number; name: string }) => {
        if (item.filterType === FilterType.DISCIPLINE) {
            setValue(
                'disciplines',
                disciplines.filter((d: number) => d !== item.id),
            );
        } else if (item.filterType === FilterType.START_DATE) {
            setValue('startDate', undefined);
        } else {
            setValue('endDate', undefined);
        }
    };

    const allSelectedFilters = useMemo(() => {
        return disciplines
            .map((id: number) => {
                return {
                    id,
                    name: disciplineAll.find((d: Filter) => d.id === id)?.name,
                    filterType: FilterType.DISCIPLINE,
                };
            })
            .concat(startDate ? [{ id: startDate, name: startDate, filterType: FilterType.START_DATE }] : [])
            .concat(endDate ? [{ id: endDate, name: endDate, filterType: FilterType.END_DATE }] : []);
    }, [disciplines, startDate, endDate, disciplineAll]);

    return (
        <>
            <Toolbar>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ gap: 20 }}
                >
                    <FilterSelectButton
                        id="disciplines"
                        name="disciplines"
                        filterName={t('disciplines')}
                        onChange={newValue => updateFilter(newValue)}
                        availableFilters={availableFilters}
                        values={disciplines}
                    />
                    <RhfDateRangePicker names={['startDate', 'endDate']} format={DATE_FORMAT} />
                    <RhfTextField
                        name="search"
                        variant="filled"
                        placeholder="search name"
                        InputProps={{
                            endAdornment: <SearchIcon className={classes.searchIcon} />,
                        }}
                        style={{ minWidth: 300, height: 38 }}
                    />
                </Box>

                <Box display="flex" flexGrow={1} />

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<AddIcon />}
                    className={classes.inviteButton}
                    style={{ paddingTop: 11, paddingBottom: 11 }}
                    onClick={inviteUser}
                >
                    invite user
                </Button>
            </Toolbar>
            <Grid item xs={12} direction="row" className={clsx(classes.chipContainer, classes.chipsBar)}>
                {allSelectedFilters.map((item: { filterType: FilterType; id: number; name: string }) => (
                    <div className={classes.chip}>
                        <div>
                            <Typography className={classes.filterTypeText}>
                                {lowerCase(item.filterType)}:
                            </Typography>
                            <Typography className={classes.filterText}>{item.name}</Typography>
                        </div>
                        <div className={classes.closeIconContainer}>
                            <CloseIcon onClick={() => handleDelete(item)} className={classes.closeIcon} />
                        </div>
                    </div>
                ))}
            </Grid>
        </>
    );
};

export default FiltersFormFields;
