import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TableCell as MuiTableCell,
    TableRow as MuiTableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { ColumnDef, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { queryKeys } from '../../react-query/constants';
import BackButton from '../../components/BackButton';
import {
    ConformitiesQuery,
    getTasksByID,
    getTasksConformities,
    updateConformity,
} from '../../services/TasksService';
import ConformityTitle from './ConformityTitle';
import { ConformityDto, ConformityRowDto, TaskDto } from '../../api/data-contracts';
import ConformitiesActions from './ConformitiesActions';
import ConformitiesFilters from './ConformitiesFilters';
import CustomTable from '../../components/CustomTable';
import ConformityActions from './ConformityActions';
import { useStyles } from './styles';
import CustomSelect from '../../components/CustomSelect';
import { RootReducer, TasksReducer } from '../../models/Redux';
import { DATE_FORMAT, SUBMITTING_DATE_FORMAT } from '../../config/constants';

const ConformityRegisterPage: FC = () => {
    const classes = useStyles();
    const { id } = useParams<{ id?: string }>();
    const queryClient = useQueryClient();
    const { search, pathname } = useLocation();
    const { replace } = useHistory();
    const [startDate, setStartDate] = useState<string>();
    const [newStatus, setNewStatus] = useState<number>();
    const [comment, setComment] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [areFiltersLoaded, setAreFiltersLoaded] = useState<boolean>(false);

    const initialFilters = {
        search: undefined,
        projects: [],
        contractTypes: [],
        previousStatuses: [],
        currentStatuses: [],
        articles: [],
    };

    const [query, setQuery] = useState<ConformitiesQuery>({
        ...initialFilters,
        id: parseInt(id as string, 10),
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        setQuery({
            id: parseInt(id as string, 10),
            search: searchParams.get('search') || undefined,
            projects:
                searchParams
                    .get('projects')
                    ?.split(',')
                    ?.filter(project => !isEmpty(project))
                    ?.map(project => parseInt(project, 10)) || [],
            contractTypes:
                searchParams
                    .get('contractTypes')
                    ?.split(',')
                    ?.filter(contractType => !isEmpty(contractType))
                    ?.map(contractType => parseInt(contractType, 10)) || [],
            previousStatuses:
                searchParams
                    .get('previousStatuses')
                    ?.split(',')
                    ?.filter(previousStatus => !isEmpty(previousStatus))
                    ?.map(previousStatus => parseInt(previousStatus, 10)) || [],
            currentStatuses:
                searchParams
                    .get('currentStatuses')
                    ?.split(',')
                    ?.filter(currentStatus => !isEmpty(currentStatus))
                    ?.map(currentStatus => parseInt(currentStatus, 10)) || [],
            articles:
                searchParams
                    .get('articles')
                    ?.split(',')
                    ?.filter(article => !isEmpty(article)) || [],
        });

        setAreFiltersLoaded(true);
    }, [id, search]);

    const handlePageChange = (page: number, size: number) => {
        const searchParams = new URLSearchParams(search);

        replace({
            pathname,
            search: `?${Object.entries({
                search: searchParams.get('search') || '',
                week: searchParams.get('week') || query.week,
                month: searchParams.get('month') || query.month,
                year: searchParams.get('year') || query.year,
                projects: searchParams.get('projects') || '',
                contractTypes: searchParams.get('contractTypes') || '',
                previousStatuses: searchParams.get('previousStatuses') || '',
                currentStatuses: searchParams.get('currentStatuses') || '',
                articles: searchParams.get('articles') || '',
                page,
                size,
            })
                .map(e => e.join('='))
                .join('&')}`,
        });
    };

    const handleSearch = useCallback(
        (searchString: string, filters: Record<string, Array<string | number>>) => {
            const searchParams = new URLSearchParams(search);

            replace({
                pathname,
                search: `?${Object.entries({
                    search: searchString || '',
                    week: query.week,
                    month: query.month,
                    year: query.year,
                    projects: filters.projects || '',
                    contractTypes: filters.contractTypes || '',
                    previousStatuses: filters.previousStatuses || '',
                    currentStatuses: filters.currentStatuses || '',
                    articles: filters.articles || '',
                    page: 1,
                    size: searchParams.get('size') || 10,
                })
                    .map(e => e.join('='))
                    .join('&')}`,
            });
        },
        [search, replace, query, pathname],
    );

    const { conformityStatuses } = useSelector<RootReducer, TasksReducer>(state => state.tasks);

    const statusChangeMutation = useMutation(
        ({
            confId,
            status,
            comment,
            startDate,
            description,
        }: {
            confId: number;
            status: number;
            comment?: string;
            startDate?: string;
            description?: string;
        }) =>
            updateConformity(confId, parseInt(id as string, 10) || 0, {
                comment,
                status,
                description,
                date: startDate ? dayjs(startDate).format(SUBMITTING_DATE_FORMAT) : undefined,
            }),
        {
            onSuccess: (data: ConformityDto) => {
                queryClient.invalidateQueries([queryKeys.conformities]).finally(() => {});

                setNewStatus(data.status);
                setStartDate(data.startDate);
                setComment(data.comment);
                setDescription(data.description);
            },
        },
    );

    const handleRowExpanded = useCallback((row: Row<ConformityRowDto>) => {
        setStartDate(row.original.startDate);
        setNewStatus(row.original.status);
        setComment('');
        setDescription(row.original.description);
    }, []);

    const columns = useMemo<ColumnDef<ConformityRowDto>[]>(
        () => [
            {
                header: 'Name',
                id: 'name',
                enableSorting: false,
                cell: ({ row }) => (
                    <Typography className={classes.conformityName}>{row.original?.name}</Typography>
                ),
            },
            {
                header: '',
                accessorKey: 'contractType',
            },
            {
                header: '',
                accessorKey: 'article1',
            },
            {
                header: 'Previous status',
                id: 'previousStatus',
                cell: ({ row }) =>
                    row.original.previousStatus ? (
                        <Typography className={classes.conformityName}>
                            <Box
                                className={clsx(classes.statusIndicator, {
                                    [classes.openStatusIndicator]:
                                        row.original.previousStatus?.toLowerCase() !== 'conform',
                                    [classes.closedStatusIndicator]:
                                        row.original.previousStatus?.toLowerCase() === 'conform',
                                })}
                            />
                            {row.original.previousStatus}
                        </Typography>
                    ) : null,
            },
            {
                header: 'Current status',
                id: 'currentStatus',
                cell: ({ row }) => {
                    return (
                        <CustomSelect
                            id="current-status"
                            inputName="current-status"
                            availableData={conformityStatuses.map(status => ({
                                ...status,
                                name: (
                                    <Typography className={classes.statusSelectText}>
                                        <Box
                                            className={clsx(classes.statusIndicator, {
                                                [classes.openStatusIndicator]:
                                                    status.name?.toLowerCase() !== 'conform',
                                                [classes.closedStatusIndicator]:
                                                    status.name?.toLowerCase() === 'conform',
                                            })}
                                        />
                                        {status.name}
                                    </Typography>
                                ),
                            }))}
                            value={row.original.status}
                            variant="filled"
                            selectClass={clsx(classes.statusSelect, {
                                [classes.statusSelectExpandedRow]: row.getIsExpanded(),
                            })}
                            iconClass={classes.statusSelectIcon}
                            onChange={value =>
                                statusChangeMutation.mutate({
                                    confId: row.original.id as number,
                                    status: value as number,
                                })
                            }
                        />
                    );
                },
            },
            {
                id: 'actions',
                header: '',
                cell: ({ row }) => (
                    <ConformityActions
                        row={row}
                        onRowExpanded={handleRowExpanded}
                        confirmed={!!row.original.statusConfirmedBy}
                    />
                ),
            },
        ],
        [classes, conformityStatuses, handleRowExpanded, statusChangeMutation],
    );

    const { data: task, isLoading } = useQuery<TaskDto>(
        [queryKeys.task, id],
        () => getTasksByID(parseInt(id as string, 10)),
        {
            enabled: !!id,
        },
    );

    const renderEmptyTable = () => (
        <MuiTableRow>
            <MuiTableCell colSpan={columns.length}>
                <Typography>No conformities found.</Typography>
            </MuiTableCell>
        </MuiTableRow>
    );

    const renderSubComponent = (row: Row<ConformityRowDto>) => {
        return (
            <Box className={classes.expandedRow}>
                <Grid container spacing={4} justify="flex-end">
                    <Grid item xs={12} md={6}>
                        <Box className={classes.subComponentBox}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4}>
                                    <Typography className={classes.rowLabel}>project</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography className={classes.rowValue}>
                                        {row.original.projectName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography className={classes.rowLabel}>contract type</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography className={classes.rowValue}>
                                        {row.original.contractType}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography className={classes.rowLabel}>article</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography className={classes.rowValue}>
                                        {row.original.article1}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography className={classes.rowLabel}>previous status</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography className={classes.rowValue}>
                                        <Box
                                            className={clsx(classes.statusIndicator, {
                                                [classes.openStatusIndicator]:
                                                    row.original.previousStatus &&
                                                    row.original.previousStatus?.toLowerCase() !== 'conform',
                                                [classes.closedStatusIndicator]:
                                                    row.original.previousStatus?.toLowerCase() === 'conform',
                                            })}
                                        />{' '}
                                        {row.original.previousStatus}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box display="flex" flexDirection="row" alignItems="center" height="100%">
                                        <Typography className={classes.rowLabel}>start date</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <DatePicker
                                        allowKeyboardControl={false}
                                        inputVariant="filled"
                                        format={DATE_FORMAT}
                                        InputProps={{ endAdornment: <DateRangeIcon /> }}
                                        value={startDate}
                                        onChange={date => {
                                            if (date)
                                                setStartDate(dayjs(date).format(SUBMITTING_DATE_FORMAT));
                                        }}
                                        className={classes.datepicker}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box display="flex" flexDirection="row" alignItems="center" height="100%">
                                        <Typography className={classes.rowLabel}>conform</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <CustomSelect
                                        id="new-status"
                                        inputName="new-status"
                                        availableData={conformityStatuses.map(status => ({
                                            ...status,
                                            name: (
                                                <Typography className={classes.statusSelectText}>
                                                    <Box
                                                        className={clsx(classes.statusIndicator, {
                                                            [classes.openStatusIndicator]:
                                                                status.name?.toLowerCase() !== 'conform',
                                                            [classes.closedStatusIndicator]:
                                                                status.name?.toLowerCase() === 'conform',
                                                        })}
                                                    />
                                                    {status.name}
                                                </Typography>
                                            ),
                                        }))}
                                        value={newStatus}
                                        variant="filled"
                                        selectClass={clsx(classes.statusSelect, classes.selectInput)}
                                        iconClass={classes.statusSelectIcon}
                                        onChange={value => setNewStatus(value as number)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={classes.subComponentBox}>
                            <Typography className={classes.subComponentBoxTitle}>description</Typography>
                            <TextField
                                name="description"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                variant="filled"
                                multiline
                                rows={8}
                                rowsMax={8}
                                classes={{ root: classes.textField }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={classes.subComponentBox}>
                            <Typography className={classes.subComponentBoxTitle}>comment</Typography>
                            <TextField
                                name="comment"
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                variant="filled"
                                multiline
                                rows={6}
                                rowsMax={6}
                                classes={{ root: classes.textField }}
                                style={{ marginBottom: '2rem' }}
                            />
                            <Typography className={classes.subComponentBoxTitle}>previous comment</Typography>
                            <Typography className={classes.subComponentBoxText}>
                                {row.original.previousComment}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<CheckIcon />}
                    disabled={statusChangeMutation.isLoading}
                    onClick={() =>
                        statusChangeMutation.mutate({
                            confId: row.original.id as number,
                            status: newStatus as number,
                            comment,
                            startDate,
                            description,
                        })
                    }
                >
                    confirm
                </Button>
            </Box>
        );
    };

    return (
        <>
            {areFiltersLoaded && <ConformitiesFilters query={query} handleSearch={handleSearch} />}

            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mt="2rem"
                    width="100%"
                >
                    <BackButton label="back to task" />

                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="3rem"
                        marginBottom="3rem"
                    >
                        <ConformityTitle task={task} />
                        <ConformitiesActions taskId={parseInt(id as string, 10)} query={query} />
                    </Box>

                    <CustomTable<ConformityRowDto, ConformitiesQuery>
                        columns={columns}
                        fetchFunction={getTasksConformities}
                        query={query}
                        queryKeys={[queryKeys.conformities]}
                        getRowCanExpand={row => !!row.original.id}
                        renderEmptyTable={renderEmptyTable}
                        renderSubComponent={renderSubComponent}
                        handlePageChange={handlePageChange}
                    />
                </Box>
            )}
        </>
    );
};

export default ConformityRegisterPage;
