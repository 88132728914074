import { FC, useState, useEffect, useRef } from 'react';
import { Dialog, DialogProps, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import LeftProjectDetailsDialog from './Parts/LeftProjectDetailsDialog';
import ConformityRegisterDialog from '../../../DashboardPage/Parts/ConformityRegisterDialog';
import EditTaskDialog from '../../../DashboardPage/Parts/EditTaskDialog/EditTaskDialog';
import { Project } from '../../../../models/Project';

interface Props {
    open: boolean;
    currentProject?: Project;
    isEditing?: boolean;
    handleClose: () => void;
    refreshProjectsList: () => void;
}

const ProjectDetailsDialog: FC<Props> = ({
    open,
    handleClose,
    currentProject,
    isEditing,
    refreshProjectsList,
}) => {
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
    const { t } = useTranslation();
    const [conformityDialogOpen, setConformityDialogOpen] = useState<boolean>(false);
    const [editTaskDialogOpen, setEditTaskDialogOpen] = useState<boolean>(false);

    const classes = useStyles();

    const handleCloseDialog = () => {
        handleClose();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <ConformityRegisterDialog
                open={conformityDialogOpen}
                handleClose={() => setConformityDialogOpen(false)}
            />
            <EditTaskDialog
                open={editTaskDialogOpen}
                handleClose={() => setEditTaskDialogOpen(false)}
                isEditing
            />
            <Dialog
                open={open && !conformityDialogOpen && !editTaskDialogOpen}
                onClose={handleCloseDialog}
                scroll={scroll}
                aria-labelledby="Add-Task-Dialog"
                fullWidth
                maxWidth="lg"
                classes={{
                    paper: classes.rootDialog,
                }}
            >
                <Grid container>
                    <LeftProjectDetailsDialog
                        handleOpenEditTaskDialog={() => setEditTaskDialogOpen(true)}
                        handleOpenConformitykDialog={() => setConformityDialogOpen(true)}
                        currentProject={currentProject}
                        refreshProjectsList={refreshProjectsList}
                        handleCloseDialog={handleCloseDialog}
                    />
                </Grid>
            </Dialog>
        </div>
    );
};

export default ProjectDetailsDialog;
