import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    list: {
        border: 'none',
        width: '100%',
        margin: 'auto',
    },
    itemContainer: {
        margin: '0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0.625rem',
    },
    checkboxContainer: {
        padding: '0.938rem',
        width: '1.563rem',
    },
    remove: {
        width: '1.563rem',
        margin: '0 auto',
        height: '1.563rem',
        padding: '1.25rem',
        backgroundColor: 'transparent',
        color: '#708393',
        border: 'none',
        outline: 'none',
    },
    removeContainer: {
        padding: '0.938rem',
    },
    add: {
        color: '#708393',
        backgroundColor: 'transparent',
        border: 'none',
    },
});
