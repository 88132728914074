import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    rootDialog: {
        borderRadius: 0,
        borderTopLeftRadius: '1.875rem',
        borderBottomRightRadius: '1.875rem',
    },
    dialogTitleContainer: {
        padding: '1.875rem 7.5rem 6.25rem 3.75rem',
        width: '100%',
    },
    dialogTitle: {
        fontSize: '1.875rem',
        fontWeight: 'bold',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(5),
        top: theme.spacing(3),
    },
    closeButtonIcon: {
        width: '2.813rem',
        height: '2.813rem',
        color: 'black',
    },
    dialogContentContainer: {
        padding: '0px 3.75rem',
        border: 0,
    },
    projectFilesButton: {
        padding: '1.25rem 1.563rem',
    },
    projectFilesLabel: {
        fontSize: '1.188rem',
    },
    contractTypeContainer: {
        margin: '0.625rem 0.313rem',
    },
    contractTypeTitle: {
        color: 'black',
        fontSize: '1.25rem',
        fontWeight: 500,
    },
    contractTypeContent: {
        fontSize: '1rem',
        fontWeight: 400,
        color: '#C4C4C4',
    },
    accordionsContainer: {
        minHeight: '45vh',
        padding: '0.625rem 0',
    },
    accordionTitle: {
        fontSize: '1.125rem',
        fontWeight: 500,
        color: '#000',
    },
    detailsEntry: {
        marginBottom: '0.625rem',
        whiteSpace: 'nowrap',
    },
    actionButtonsContainer: {
        margin: '1.25rem 3.75rem',
        width: '100%',
    },
    dialogActions: {
        width: '100%',
    },
    paperContainer: {
        borderRadius: 0,
        backgroundColor: '#F5F5F5',
        padding: '1.25rem 1.875rem',
        margin: '0.938rem 0px',
        maxHeight: '3.25rem',
    },
    paperContainerDateField: {
        width: '90%',
        padding: '0.625rem 1.875rem',
    },
    paperQuarterContainer: {
        margin: '0px 0.625rem',
    },
    inputLabel: {
        fontSize: '1.25rem',
        fontWeight: 600,
    },
    loaderContainer: {
        height: '100%',
        position: 'absolute',
        zIndex: 10,
        top: 0,
        left: 0,
    },
    generatingPDFLoader: {
        minHeight: '12.5rem',
    },
    generatingPDFLoaderText: {
        padding: '1.25rem 0px',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    projectLogoContainer: {},
    dropzoneArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1.25rem',
        height: '10rem',
        justifyContent: 'space-between',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        minHeight: '6.25rem',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    projectLogoUploaderContainer: {
        position: 'relative',
    },
    logoContainer: {
        position: 'relative',
        width: '10rem',
        height: '10rem',
    },
    buttonEditProjectLogo: {
        right: '-1.25rem',
        width: '3.125rem',
        bottom: '-1.25rem',
        height: '3.125rem',
        position: 'absolute',
        background: theme.palette.primary.main,
        boxShadow: '1px 1px 4px 2px rgb(7 9 44 / 10%)',
        color: '#fff',
        '&:hover': {
            background: '#ececec',
        },
    },
    removeProjectLogoButton: {
        zIndex: 600,
        border: 'none',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '5.625rem',
        height: '2.188rem',
        fontWeight: 550,
        background: 'none',
        bottom: '-4.063rem',
        right: '1.875rem',
        cursor: 'pointer',
        position: 'absolute',
        color: theme.palette.error.main,
        textDecoration: 'underline',
        '&:hover': {
            background: 'none',
        },
    },
    projectLogoImg: {
        width: '10rem',
        height: '10rem',
    },
    defaultProjectLogo: {
        width: '10rem',
        height: '10rem',
        backgroundColor: 'gray',
    },
    memberRow: {
        padding: '0.313rem 0px',
    },
    memberName: {
        fontSize: '1rem',
        color: 'black',
    },
    companyName: {
        fontSize: '0.813rem',
        color: 'gray',
    },
    memberPosition: {
        fontSize: '0.813rem',
        fontWeight: 700,
        color: 'black',
    },
    contactButton: {
        color: 'white',
        backgroundColor: '#192B3A',
        padding: '0.469rem',
        marginRight: '0.313rem',

        '&:hover': {
            backgroundColor: '#192B3A',
        },
    },
    contactButtonDisabled: {
        cursor: 'default',
        color: 'white',
        backgroundColor: '#a3a3a3',
        padding: '0.469rem',
        marginRight: '0.313rem',

        '&:hover': {
            backgroundColor: '#a3a3a3',
        },
    },
    contactButtonIcon: {
        width: '80%',
        minHeight: '0.313rem',
        minWidth: '0.313rem',
        maxHeight: '1.25rem',
        maxWidth: '1.25rem',
        borderRadius: '50%',
    },
    contactGroupsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        padding: '0.625rem 0.625rem 3.125rem 0.625rem',
    },
    contactGroupName: {
        margin: '0.625rem 0',
        width: '100%',
    },
    memberCard: { width: '100%' },
    memberCardContainer: {
        margin: '0 0.625rem',
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    contactGroupAccordionContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    contactGroupTitle: {
        marginLeft: '1.25rem',
    },
    description: {
        marginLeft: '0.625rem',
    },
    memberCardLine: {
        margin: '0.625rem 0',
    },
}));
