import { Checkbox, MenuItem, Select } from '@material-ui/core';
import { isUndefined } from 'lodash';
import clsx from 'clsx';
import React, { FC, useState, ReactNode } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CustomSelectMultipleProps } from './config';
import { useStyles } from './styles';
import CustomCheckbox from '../CustomCheckbox';

const CustomSelectMultiple: FC<CustomSelectMultipleProps> = ({
    id,
    icon,
    label,
    inputName,
    defaultValue,
    values,
    selectClass,
    selectedClass,
    iconClass,
    paperClass,
    listClass,
    checkboxClass,
    availableData,
    selectedMenuItemClass,
    onChangeValue,
    onClose,
    fullWidth,
    renderSelected,
    disabled,
}) => {
    const classes = useStyles();
    const [selectedItems, setSelectedItems] = useState<unknown[]>(values || []);

    const onChangeSelect = (e: any) => {
        const { value } = e.target;
        // @ts-ignore
        const selectedFilters = availableData.filter(item => value.includes(item.id));
        onChangeValue(selectedFilters);
        setSelectedItems(value);
    };

    const renderValueSelect = () => {
        if (values) {
            return (
                availableData
                    // @ts-ignore
                    .filter(item => values.includes(item?.id))
                    // @ts-ignore
                    .map(item => item?.name)
                    .toString() as ReactNode
            );
        }
        return (
            availableData
                // @ts-ignore
                .filter(item => selectedItems.includes(item?.id))
                // @ts-ignore
                .map(item => item?.name)
                .toString() as ReactNode
        );
    };

    const renderMenuValueSelect = (item: any, itemIndex: number) => {
        if (!isUndefined(values)) {
            return (
                <MenuItem
                    key={itemIndex}
                    // @ts-ignore
                    value={item?.id}
                    disableRipple
                    classes={{
                        selected: clsx(classes.selectedItem, selectedMenuItemClass),
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CustomCheckbox
                            classes={{
                                root: clsx(classes.checkbox, checkboxClass),
                            }}
                            disableRipple
                            checked={
                                !!values.find(
                                    selectedFilter =>
                                        // @ts-ignore
                                        selectedFilter.id === item?.id || selectedFilter === item?.id,
                                )
                            }
                        />

                        <div>
                            {
                                // @ts-ignore
                                item?.name
                            }
                        </div>
                    </div>
                </MenuItem>
            );
        }
        return (
            <MenuItem
                key={itemIndex}
                // @ts-ignore
                value={item?.id}
                disableRipple
                classes={{
                    selected: clsx(classes.selectedItem, selectedMenuItemClass),
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        classes={{
                            root: clsx(classes.checkbox, checkboxClass),
                        }}
                        disableRipple
                        // @ts-ignore
                        checked={!!selectedItems.find(selectedFilter => selectedFilter === item?.id)}
                    />

                    <div>
                        {
                            // @ts-ignore
                            item?.name
                        }
                    </div>
                </div>
            </MenuItem>
        );
    };

    return (
        <Select
            id={id}
            disabled={disabled}
            name={inputName}
            multiple
            disableUnderline
            defaultValue={defaultValue || label}
            fullWidth={fullWidth}
            autoWidth
            onClose={onClose}
            // @ts-ignore
            IconComponent={icon || ExpandMoreIcon}
            classes={{
                root: clsx(classes.rootSelect, selectClass),
                select: clsx(classes.selected, selectedClass),
                icon: clsx(classes.icon, iconClass),
            }}
            MenuProps={{
                classes: {
                    paper: clsx(classes.rootSelectMenu, paperClass),
                    list: clsx(classes.menuListSelectMenu, listClass),
                },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                getContentAnchorEl: null,
            }}
            value={values || selectedItems}
            displayEmpty
            renderValue={() => {
                // @ts-ignore
                if (renderSelected && (selectedItems.length > 0 || values?.length > 0)) {
                    return renderValueSelect();
                }
                return defaultValue as ReactNode;
            }}
            onChange={onChangeSelect}
        >
            {availableData?.map((item, itemIndex) => renderMenuValueSelect(item, itemIndex))}
        </Select>
    );
};

export default CustomSelectMultiple;
