import { FC } from 'react';
import { ProjectDetailsDto } from '../../../../api/data-contracts';
import TabPanel from '../TabPanel';
import ProjectUsersCard from '../ProjectUsersCard';

interface Props {
    selectedTab: number;
    index: number;
    project: ProjectDetailsDto;
}

const PersonsTab: FC<Props> = ({ selectedTab, index, project }) => {
    const groups = Object.keys(project.contactGroups ? project.contactGroups : {});

    return groups?.length ? (
        <TabPanel value={selectedTab} index={index}>
            {groups.map(group => (
                <ProjectUsersCard group={group} users={project.contactGroups} />
            ))}
        </TabPanel>
    ) : null;
};

export default PersonsTab;
