import axios from 'axios';
import { ProjectController } from '../api/ProjectController';
import { ProjectQuery, ProjectResult, ReportQuery } from '../models/Project';
import { ProjectDetailsDto, ProjectUpdateRequestDto, StakeholderDto } from '../api/data-contracts';

export const getProjects = async (query: ProjectQuery): Promise<unknown> => {
    const projectApi = new ProjectController();
    const {
        data: { content, totalPages },
    } = await projectApi.apiV1ProjectsUsingGet(query);

    return ({ content, totalPages } as unknown) as ProjectResult;
};

export const getProjectById = async (id: number): Promise<ProjectDetailsDto> => {
    const projectApi = new ProjectController();

    const { data } = await projectApi.apiV1ProjectsIdUsingGet(id);
    return data;
};

export const checkProjectAccess = async (id: number): Promise<boolean> => {
    const projectApi = new ProjectController();

    const { data } = await projectApi.apiV1ProjectAccessUsingGet(id);
    return data;
};

export const updateProject = async (id: number, request: ProjectUpdateRequestDto): Promise<void> => {
    const projectApi = new ProjectController();

    await projectApi.apiV1ProjectsIdUsingPut(id, request);
};

export const generateProjectReport = async ({ year, projectID, quarter }: ReportQuery): Promise<unknown> => {
    const data = new FormData();
    data.append('quarter', quarter.toString());
    data.append('year', year.toString());

    const { data: responseData } = await axios.post(`/api/v1/projects/${projectID}`, data, {
        responseType: 'blob',
    });
    return responseData;
};

export const generateProjectDetails = async (projectId: number): Promise<unknown> => {
    const data = new FormData();

    const { data: responseData } = await axios.post(`/api/v1/projects/${projectId}/details`, data, {
        responseType: 'blob',
    });
    return responseData;
};

export const updateProjectLogo = async (projectId: number, logoMediaId: number) => {
    const projectApi = new ProjectController();

    const { data } = await projectApi.apiV1ProjectsProjectidLogoUsingPut(projectId, logoMediaId);

    return data;
};

export const removeProjectLogo = async (projectId: number) => {
    const projectApi = new ProjectController();

    const { data } = await projectApi.apiV1ProjectsProjectidLogoUsingDelete(projectId);

    return data;
};

export const getStakeholderGroups = async () => {
    const projectApi = new ProjectController();

    const { data } = await projectApi.apiV1ProjectsStakeholderGroupsUsingGet();

    return data;
};

export const getStakeholders = async (): Promise<StakeholderDto[]> => {
    const projectApi = new ProjectController();

    const { data } = await projectApi.apiV1ProjectsStakeholdersUsingGet();

    return data;
};
