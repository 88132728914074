import { AnyAction } from 'redux';
import dayjs from 'dayjs';
import { TasksReducer } from '../../models/Redux';
import {
    SET_EMPLOYEES_ASSIGNED_FILTERS,
    SET_PROJECTS_FILTERS,
    SET_TASKS,
    SET_DISCIPLINES_FILTERS,
    SET_TASK_STATUS_FILTERS,
    SET_TASK_TYPES_FILTERS,
    SET_CONTRACT_TYPE_FILTERS,
    ERROR_FETCHING,
    SET_LOADING_TASKS,
    SET_EMPLOYEES_ASSIGNED_FILTERS_ALL,
    SET_DISCIPLINES_FILTERS_ALL,
    SET_DISCIPLINES_TO_PROJECTS,
    SET_CONFORMITY_STATUES,
    SET_CONFORMITY_ARTICLE_FILTERS,
} from '../actions/Tasks.actions';

const initialState: TasksReducer = {
    tasks: [],
    discipline: [],
    projects: [],
    employeeAssigned: [],
    employeeAll: [],
    disciplineAll: [],
    disciplineToProject: [],
    status: [],
    conformityArticles: [],
    types: [],
    year: dayjs().year(),
    week: dayjs().week() - 1,
    totalPages: 0,
    loading: true,
    error: false,
    contractTypes: [],
    conformityStatuses: [],
};

const taskReducer = (state = initialState, action: AnyAction): TasksReducer => {
    switch (action.type) {
        case SET_LOADING_TASKS:
            return { ...state, loading: action.payload };
        case SET_DISCIPLINES_TO_PROJECTS:
            return {
                ...state,
                disciplineToProject: [...action.payload],
                loading: false,
            } as TasksReducer;
        case SET_CONFORMITY_STATUES:
            return {
                ...state,
                conformityStatuses: [...action.payload],
                loading: false,
            } as TasksReducer;
        case SET_DISCIPLINES_FILTERS:
            return {
                ...state,
                discipline: [...action.payload],
                loading: false,
            } as TasksReducer;
        case SET_DISCIPLINES_FILTERS_ALL:
            return {
                ...state,
                disciplineAll: [...action.payload],
                loading: false,
            } as TasksReducer;
        case SET_PROJECTS_FILTERS:
            return {
                ...state,
                projects: action.payload,
                loading: false,
            } as TasksReducer;
        case SET_EMPLOYEES_ASSIGNED_FILTERS:
            return {
                ...state,
                employeeAssigned: action.payload,
                loading: false,
            } as TasksReducer;
        case SET_EMPLOYEES_ASSIGNED_FILTERS_ALL:
            return {
                ...state,
                employeeAll: action.payload,
                loading: false,
            } as TasksReducer;
        case SET_TASK_STATUS_FILTERS:
            return {
                ...state,
                status: action.payload,
                loading: false,
            } as TasksReducer;
        case SET_CONFORMITY_ARTICLE_FILTERS:
            return {
                ...state,
                conformityArticles: action.payload,
                loading: false,
            } as TasksReducer;
        case SET_TASK_TYPES_FILTERS:
            return {
                ...state,
                types: action.payload,
                loading: false,
            } as TasksReducer;
        case SET_CONTRACT_TYPE_FILTERS:
            return {
                ...state,
                contractTypes: action.payload,
                loading: false,
            } as TasksReducer;
        case SET_TASKS:
            return {
                ...state,
                tasks: action.payload.content,
                totalPages: action.payload.totalPages,
                loading: false,
            };
        case ERROR_FETCHING:
            return { ...state, loading: false, error: true };
        default:
            return state;
    }
};
export default taskReducer;
