import { FC } from 'react';
import { useStyles } from './styles';
import LoginCard from './parts/LoginCard';

const LoginPage: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.loginContainer}>
            <LoginCard />
        </div>
    );
};

export default LoginPage;
