import { FC, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';
import InviteUsersDialogForm from './InviteUsersDialogForm';

interface Props {
    open: boolean;
    handleClose: () => void;
}

const InviteUserDialog: FC<Props> = ({ open, handleClose }) => {
    const classes = useStyles();

    const [submitForm, setSubmitForm] = useState<boolean>(false);

    return (
        <Dialog fullWidth open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
            <DialogTitle disableTypography className={classes.titleWrapper}>
                <Typography className={classes.title}>users:</Typography>
                <IconButton onClick={handleClose} className={classes.closeButton}>
                    <CloseIcon className={classes.closeButtonIcon} />
                </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.inviteUser}>Invite user</Typography>
                <InviteUsersDialogForm
                    submitForm={submitForm}
                    setSubmitForm={setSubmitForm}
                    handleClose={handleClose}
                />
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={() => setSubmitForm(true)}
                    variant="contained"
                    color="primary"
                    endIcon={<CheckIcon />}
                    disabled={submitForm}
                >
                    save user
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InviteUserDialog;
