export const queryKeys = {
    task: 'task',
    tasks: 'tasks',
    user: 'user',
    users: 'users',
    project: 'project',
    projects: 'projects',
    conformities: 'conformities',
    stakeholders: 'stakeholders',
    stakeholderGroups: 'stakeholderGroups',
};
