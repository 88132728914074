import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    pageSubTitle: {
        lineHeight: 1.2,
        [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    pageHeader: {
        [theme.breakpoints.up('md')]: {
            marginTop: '3.125rem',
            marginBottom: '3.75rem',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: '1.875rem',
            marginBottom: '1.875rem',
        },
    },
    headerRow: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 30,
        },
    },
    pageTitle: {
        lineHeight: 1.12,
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16,
        [theme.breakpoints.up('md')]: {
            fontSize: '3.125rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.875rem',
        },
    },
    buttons: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end',
        },
    },
    button: {
        color: '#4b5c6d',
        fontSize: '1.875rem',
    },
    saveButton: {
        marginLeft: '0.75rem',
    },
    statusButton: {
        minWidth: 0,
    },
    taskStatusBox: {
        borderRadius: '0.375rem',
        backgroundColor: '#708393',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            width: '1.75rem',
            height: '1.75rem',
        },
        [theme.breakpoints.down('md')]: {
            width: '1.25rem',
            height: '1.25rem',
        },
    },
    taskStatusBoxDone: {
        backgroundColor: '#40BF71',
    },
    taskStatusBoxIcon: {
        color: 'white',
    },
}));
