import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    actionIconButton: {
        padding: '0.313rem',
    },
    actionIcon: {
        width: '1.875rem',
        height: '1.875rem',
        color: '#708393',
    },
    actionButton: {
        margin: '0px 0.156rem',
    },
});
