import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomButtonProps } from './config';
import { useStyles } from './styles';
import LoaderSpinner from '../LoaderSpinner';

const CustomButton: FC<CustomButtonProps> = ({
    icon,
    label,
    buttonClassName,
    labelClassName,
    onClick,
    basic,
    disabled,
    loading,
    role,
    disabledClass,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Button
            role={role}
            variant={basic ? 'text' : 'outlined'}
            startIcon={!!icon && !!label && icon}
            disabled={disabled || loading}
            disableRipple
            classes={{
                root: clsx(classes.basicButton, buttonClassName, {
                    [classes.button]: !basic,
                }),
                label: clsx(classes.label, labelClassName, {
                    [classes.grayLabel]: basic,
                }),
                disabled: clsx(classes.disabled, disabledClass),
            }}
            onClick={onClick}
        >
            {!!icon && !label && !loading && icon}
            {!!label && !loading && t(label)}
            {!!loading && <LoaderSpinner />}
        </Button>
    );
};

export default CustomButton;
