import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    tableContainer: {
        maxHeight: '100vh',
        maxWidth: '87.5rem',
        rowGap: 66,
        columnGap: 69,
    },
    paginationContainer: {
        padding: '6.25rem 0px',
        width: '100%',
    },
    paginationSizeText: {
        color: 'white',
        width: '100%',
        padding: '0.625rem 0px',
        textAlign: 'center',
        margin: '0 auto',
    },
    paginationElement: {
        color: 'white',
        borderTopRightRadius: '0.063rem',
        borderBottomLeftRadius: '0.063rem',
    },
});
