import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { ConformityRegister, getConformityRegisterInitialValues, validationSchema } from './config';
import { getBackendErrorMessage } from '../../../../config/utils';
import ConformityRegisterForm from './Parts/ConformityRegisterForm';
import { Conformity } from '../../../../models/Conformity';
import { getTaskConformityById, updateConformity } from '../../../../services/TasksService';
import { SUBMITTING_DATE_FORMAT } from '../../../../config/constants';
import { ConformityDto, TaskDto } from '../../../../api/data-contracts';

interface Props {
    open: boolean;
    currentTask?: TaskDto;
    currentTaskConformities?: ConformityDto[];
    handleClose: () => void;
}

const ConformityRegisterDialog: FC<Props> = ({ open, handleClose, currentTask, currentTaskConformities }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const [currentConformity, setCurrentConformity] = useState<Conformity>();
    const initialValues = useMemo(() => {
        return getConformityRegisterInitialValues();
    }, []);

    const handleChangeConformity = useCallback(
        (id: number) => {
            if (currentTask?.id) {
                getTaskConformityById(id, currentTask.id)
                    .then(conformity => {
                        setCurrentConformity(conformity as Conformity);
                    })
                    .catch(error => {
                        enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                            variant: 'error',
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [currentTask, enqueueSnackbar, t],
    );

    const handleUpdateConformity = (conformity: Conformity): void => {
        setCurrentConformity(conformity);
    };

    useEffect(() => {
        if (
            open &&
            currentTaskConformities &&
            !isEmpty(currentTaskConformities) &&
            !currentConformity &&
            !loading
        ) {
            handleChangeConformity(currentTaskConformities[0].id!);
        }
    }, [currentConformity, currentTask, currentTaskConformities, handleChangeConformity, loading, open]);

    // @ts-ignore
    const handleSubmitForm = async (values: ConformityRegister, { resetForm }) => {
        try {
            const startDate = currentConformity?.startDate
                ? dayjs(currentConformity?.startDate).format(SUBMITTING_DATE_FORMAT)
                : dayjs(new Date()).format(SUBMITTING_DATE_FORMAT);
            await updateConformity(currentConformity?.id, currentTask?.id, {
                comment: currentConformity?.comment,
                date: startDate,
                status: currentConformity?.status || values.conformState,
            });
            enqueueSnackbar(t('Conformity register successfully confirmed.'), {
                variant: 'success',
            });
            resetForm();
        } catch (error) {
            enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                variant: 'error',
            });
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
        >
            <Form>
                <ConformityRegisterForm
                    open={open}
                    loading={loading}
                    handleClose={handleClose}
                    currentConformity={currentConformity}
                    handleChangeConformity={handleChangeConformity}
                    conformititesData={currentTaskConformities || []}
                    handleUpdateConformity={handleUpdateConformity}
                />
            </Form>
        </Formik>
    );
};

export default ConformityRegisterDialog;
