const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.src = url;
    });

export const getDataURL = async (imageSrc: string, isUsedByAvatarUploader = false): Promise<string> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    if (ctx) {
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.translate(-safeArea / 2, -safeArea / 2);
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    }

    if (ctx && isUsedByAvatarUploader) {
        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        canvas.width = image.width;
        canvas.height = image.height;

        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5),
            Math.round(0 - safeArea / 2 + image.height * 0.5),
        );
    }

    // As Base64 string
    return canvas.toDataURL('image/png');
};

export const dataURItoBlob = (dataURI: string): Blob => {
    let byteString: string;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ia = new Uint8Array(byteString.length);
    ia = ia.map((_, idx) => byteString.charCodeAt(idx));

    return new Blob([ia], {
        type: mimeString,
    });
};
