import { UserRoles } from '../models/enums/UserRoles';
import { ROUTES } from './constants';

import LoginPage from '../pages/LoginPage';
import BlankPage from '../pages/BlankPage';
import DashboardPage from '../pages/DashboardPage';
import ProjectsDashboardPage from '../pages/ProjectsDashboard';
import UsersDashboardPage from '../pages/UsersDashboard';
import PerformanceDashboardPage from '../pages/PerformanceDashboardPage';
import TaskFormPage from '../pages/TaskFormPage';
import UserInfoPage from '../pages/UserInfoPage';
import UserFormPage from '../pages/UserFormPage';
import ProjectDetailsPage from '../pages/ProjectDetailsPage';
import ProjectFormPage from '../pages/ProjectFormPage';
import ConformityRegisterPage from '../pages/ConformityRegisterPage';

export type LocationState = {
    page?: number;
};

export const publicRoutes = [
    {
        path: ROUTES.PUBLIC.BLANK,
        component: BlankPage,
        exact: true,
    },
    {
        path: ROUTES.PUBLIC.LOGIN,
        component: LoginPage,
        exact: true,
    },
];

export const authorizedRoutes = [
    {
        path: ROUTES.AUTHORIZED.EXAMPLE_PAGE,
        component: BlankPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.DASHBOARD,
        component: DashboardPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.PROJECTS,
        component: ProjectsDashboardPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.DISPLAY_PROJECT_INFO,
        component: ProjectDetailsPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.EDIT_PROJECT,
        component: ProjectFormPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.TASK_FORM,
        component: TaskFormPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.CONFORMITY_REGISTRY,
        component: ConformityRegisterPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.USERS,
        component: UsersDashboardPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.DISPLAY_USER_INFO,
        component: UserInfoPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.EDIT_USER,
        component: UserFormPage,
        requiredRoles: [UserRoles.ADMIN],
        exact: true,
    },
    {
        path: ROUTES.AUTHORIZED.PERFORMANCE,
        component: PerformanceDashboardPage,
        requiredRoles: [UserRoles.ADMIN, UserRoles.STAKEHOLDER, UserRoles.EMPLOYEE, UserRoles.FREELANCER],
        exact: true,
    },
];
