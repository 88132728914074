import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    rowLabel: {
        color: '#708393',
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 'bold',
    },
    text: {
        color: '#C4C4C4',
        fontSize: '1.125rem',
        lineHeight: 1.45,
        overflowWrap: 'break-word',
    },
}));
