import { Table as MuiTable } from '@material-ui/core';
import {
    ColumnDef,
    ColumnSort,
    getCoreRowModel,
    PaginationState,
    SortingState,
    useReactTable,
    getExpandedRowModel,
    Row,
} from '@tanstack/react-table';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import TableHead from './TableHead';
import TableBody from './TableBody';
import Pagination from './Pagination';

export interface ApiResponse<V extends {}> {
    content?: V[];
    number?: number;
    totalPages?: number;
    totalElements?: number;
    first?: boolean;
    last?: boolean;
}

export interface BaseQuery {
    page: number;
    size: number;
    sort: string;
}

interface Props<V extends {}, T extends {}> {
    columns: ColumnDef<V>[];
    fetchFunction: (query: BaseQuery & T) => Promise<ApiResponse<V>>;
    query: T;
    queryKeys?: string[];
    renderEmptyTable?: () => ReactNode;
    onRowClick?: (row: V) => void;
    getRowCanExpand?: (row: Row<V>) => boolean;
    renderSubComponent?: (row: Row<V>) => ReactElement;
    handlePageChange: (page: number, rowsPerPage: number) => void;
}

const CustomTable = <V extends {}, T extends {}>({
    columns,
    fetchFunction,
    query,
    queryKeys,
    renderEmptyTable,
    onRowClick,
    getRowCanExpand,
    renderSubComponent,
    handlePageChange,
}: Props<V, T>): ReactElement => {
    const location = useLocation();
    const queryClient = useQueryClient();

    const [sorting, setSorting] = useState<SortingState>([]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [areFiltersLoaded, setAreFiltersLoaded] = useState<boolean>(false);

    const fetchDataOptions: BaseQuery & T = useMemo(
        () => ({
            page: pageIndex,
            size: pageSize,
            sort: sorting.length
                ? sorting.map((sort: ColumnSort) => `${sort.id},${sort.desc ? 'desc' : 'asc'}`)?.[0] || ''
                : '',
            ...query,
        }),
        [pageIndex, pageSize, query, sorting],
    );

    const { data, isLoading } = useQuery<ApiResponse<V>>(
        [...(queryKeys || ['data']), ...Object.values(fetchDataOptions)],
        () => fetchFunction(fetchDataOptions),
        {
            keepPreviousData: true,
            enabled: areFiltersLoaded,
        },
    );

    const table = useReactTable({
        data: data?.content ?? [],
        columns,
        pageCount: data?.totalPages ?? -1,
        state: {
            pagination: {
                pageIndex,
                pageSize,
            },
            sorting,
        },
        sortDescFirst: false,
        onSortingChange: setSorting,
        getRowCanExpand,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        manualPagination: true,
        debugTable: true,
    });

    const pagesCount = useMemo(() => {
        return !data?.totalElements
            ? 0
            : Math.floor(data.totalElements / pageSize) + (data.totalElements % pageSize > 0 ? 1 : 0);
    }, [pageSize, data]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const pageIndex = searchParams.get('page') ? parseInt(searchParams.get('page') || '1', 10) - 1 : 0;
        const pageSize = searchParams.get('size') ? parseInt(searchParams.get('size') || '10', 10) : 10;

        setPagination({
            pageIndex,
            pageSize,
        });

        table.setPagination({
            pageIndex,
            pageSize,
        });

        setAreFiltersLoaded(true);
    }, [table, location.search]);

    useEffect(() => {
        const prefetchDataOptions = { ...fetchDataOptions };
        prefetchDataOptions.page += 1;
        const combinedQueryKeys = [...(queryKeys || ['data']), ...Object.values(prefetchDataOptions)];

        if (
            pagesCount > pageIndex + 1 &&
            data?.content?.length &&
            !queryClient.getQueryData(combinedQueryKeys)
        ) {
            queryClient.prefetchQuery(combinedQueryKeys, () => fetchFunction(prefetchDataOptions));
        }
    }, [data, fetchDataOptions, fetchFunction, pageIndex, pagesCount, queryClient, queryKeys]);

    return (
        <>
            <MuiTable>
                <TableHead table={table} />
                <TableBody
                    table={table}
                    isLoading={isLoading}
                    renderEmptyTable={renderEmptyTable}
                    onRowClick={onRowClick}
                    renderSubComponent={renderSubComponent}
                />
            </MuiTable>

            <Pagination
                handlePageChange={handlePageChange}
                table={table}
                pagesCount={pagesCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
            />
        </>
    );
};

export default CustomTable;
