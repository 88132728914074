import { Box } from '@material-ui/core';
import React, { FC } from 'react';

import { useStyles } from './styles';

const Toolbar: FC = ({ children }) => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="row" alignItems="center" className={classes.toolbar}>
            {children}
        </Box>
    );
};

export default Toolbar;
