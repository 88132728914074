import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    dialogTitleContainer: {
        padding: '1.875rem 7.5rem 6.25rem 3.75rem',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    dialogTitle: {
        fontSize: '1.875rem',
        fontWeight: 'bold',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    closeButtonIcon: {
        width: '1.813rem',
        height: '1.813rem',
        color: 'white',
    },
    dialogActions: {
        width: '100%',
        marginTop: '3rem',
    },
    rootDialog: {
        borderRadius: 0,
        borderTopLeftRadius: '1.875rem',
        borderBottomRightRadius: '1.875rem',
        backgroundColor: 'rgb(38, 54, 68)',
        padding: '2rem',
    },
    selectRoot: {
        marginBottom: '2rem',
        padding: '0.5rem',
    },
    select: {
        padding: '0.75rem 1.25rem ',
        fontSize: '1.1rem',
        color: 'white',
        opacity: 1,

        '&:focus': {
            padding: '0.75rem 1.25rem ',
        },
    },
}));
