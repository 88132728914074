import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../config/theme';

export const useStyles = makeStyles({
    container: {
        margin: '0.313rem 0.625rem',
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '25rem',
    },
    input: {
        marginLeft: '0.625rem',
        flex: 1,
        color: '#fff',
        opacity: '0.4',
    },
    closeIcon: {
        color: '#fff',
        opacity: '0.4',
        width: '0.875rem',
        height: '0.875rem',
    },
    iconButton: {
        padding: '0.5rem',
        color: '#fff',
        opacity: '0.4',
    },
    searchIcon: {
        width: '1rem',
        height: '1rem',
        transform: 'scale(-1, 1)',
        opacity: 1,
    },
    rootSelect: {
        height: '2.875rem',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.dark,
        color: '#626C74',
        padding: '0px 0.625rem',
        borderTopLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',
        '&:focus': {
            backgroundColor: theme.palette.secondary.dark,
            borderTopLeftRadius: '1.25rem',
            borderBottomRightRadius: '1.25rem',
        },
    },
    hidden: {
        visibility: 'hidden',
    },
    clearButton: {
        padding: 0,
    },
});
