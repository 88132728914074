import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    headerCell: {
        borderBottom: 'none',
        paddingBottom: '0.75rem',
        paddingTop: '0.75rem',
    },
    headerCellSortArrow: {
        color: '#E65216 !important',
    },
    headerCellText: {
        color: 'white',
        fontSize: '1.125rem',
        lineHeight: 1.2,
        fontWeight: 600,
    },
});
