import { FC } from 'react';
import { Box, IconButton, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';

interface Props {
    id: string;
    name: string;
    search: string;
    placeHolder?: string;
    onChange: (e: any) => void;
    onSubmit: () => void;
    onClear: () => void;
}

const Searchbox: FC<Props> = ({ id, name, placeHolder, search, onChange, onSubmit, onClear }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={classes.rootSelect}>
                <span className={search ? '' : classes.hidden}>
                    <IconButton onClick={onClear} className={classes.clearButton}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </span>
                <InputBase
                    className={classes.input}
                    placeholder={placeHolder || 'Search task'}
                    onChange={onChange}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            onSubmit();
                        }
                    }}
                    value={search}
                />
                <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={onSubmit}
                >
                    <SearchIcon className={classes.searchIcon} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Searchbox;
