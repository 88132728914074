import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../config/theme';

export const useStyles = makeStyles({
    tableContainer: {
        width: '100%',
        flexDirection: 'row',
    },
    loaderContainer: {
        width: '100%',
        height: '100%',
        minHeight: '60vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootSelectMenu: {
        borderRadius: 0,
    },
    menuListSelectMenu: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    selectedItem: {
        backgroundColor: 'transparent !important' as any,
    },
    selectInputListItemIcon: {
        position: 'absolute',
        right: '0.625rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        justifyItems: 'end',
        color: '#E26A3A',
    },
    checkbox: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent !important' as any,
        },
    },
    paginationContainer: {
        padding: '1.25rem 0px',
    },
    paginationSizeText: {
        color: '#fff',
        opacity: '0.4',
        padding: '0.625rem 0px',
        fontWeight: 400,
        fontSize: '1.125rem',
    },
    rootSelect: {
        color: '#626C74',
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
        paddingLeft: '0px !important',
        '&:focus': {
            padding: 0,
            paddingLeft: 0,
        },
    },
    selectedItemPagination: {
        color: '#626C74',
        padding: 0,
        paddingLeft: '0px !important',
        fontSize: '1rem',
        fontWeight: 400,
    },
    paginationElement: {
        color: 'white',
        borderRadius: '0.625rem 0',
        width: '2.625rem',
        height: '2.625rem',
        fontSize: '1.125rem',
        fontWeight: 'bold',
    },
    page: {
        backgroundColor: theme.palette.secondary.light,
    },
    selectedPage: {
        backgroundColor: `${theme.palette.primary.main}!important`,
        color: '#fff',
    },
    paginationEllipsis: {
        color: '#cdcdcd',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    paginationRoot: {
        '& ul li:last-child button': {
            backgroundColor: 'transparent',
        },
        '& ul li:first-child button': {
            backgroundColor: 'transparent',
        },
    },
    iconClass: {
        color: '#626C74',
    },
});
