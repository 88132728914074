import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../config/constants';

import { CurrentUserReducer, RootReducer } from '../../models/Redux';

const BlankPage: FC = () => {
    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const loading = useSelector<RootReducer, boolean>(state => state.currentUser.loading);

    if (loading) return null;

    if (!role) {
        return <Redirect to={{ pathname: ROUTES.PUBLIC.LOGIN }} />;
    }

    return <Redirect to={{ pathname: ROUTES.AUTHORIZED.DASHBOARD }} />;
};

export default BlankPage;
