import { FC, ReactNode } from 'react';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import useReactQuery from './queryClient';

type Props = {
    children: ReactNode;
};

const QueryProvider: FC<Props> = ({ children }) => {
    const queryClient = useReactQuery();
    const persister = createSyncStoragePersister({
        storage: window.localStorage,
    });

    return (
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            {children}
        </PersistQueryClientProvider>
    );
};

export default QueryProvider;
