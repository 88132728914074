import { FC } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { InView } from 'react-intersection-observer';
import { useStyles } from './styles';
import TaskTableRow from '../TaskTableRow';
import EmptyTableRow from '../../components/EmptyTableRow';
import { ConformityDto, TaskDto, TaskRowDto } from '../../api/data-contracts';

interface Props {
    data: TaskRowDto[];
    openConformity: (task: TaskDto | undefined, conformities: ConformityDto[] | undefined) => void;
    handleLoadMore: () => void;
    loading: boolean;
    hideDescription?: boolean;
    containerClass?: string;
    contentRowClass?: string;
    contentElevation?: number;
}

const TaskTableBody: FC<Props> = ({
    data,
    openConformity,
    hideDescription,
    containerClass,
    contentRowClass,
    contentElevation,
    loading,
    handleLoadMore,
}) => {
    const classes = useStyles();

    const renderViewLoadTrigger = () => {
        if (!isEmpty(data) && !loading) {
            return (
                <InView
                    threshold={1}
                    onChange={inView => {
                        if (inView) handleLoadMore();
                    }}
                >
                    <div className={classes.emptyContainer} />
                </InView>
            );
        }
        return null;
    };

    return (
        <Grid container className={clsx(containerClass)} justify="flex-start" alignContent="flex-start">
            {data.map((item, index) => (
                <TaskTableRow
                    key={index}
                    taskRow={item}
                    openConformity={openConformity}
                    hideDescription={hideDescription}
                    contentRowClass={contentRowClass}
                />
            ))}
            {renderViewLoadTrigger()}
            {isEmpty(data) && <EmptyTableRow paperElevation={contentElevation || 1} />}
        </Grid>
    );
};

export default TaskTableBody;
