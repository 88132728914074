import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import UserContact from '../../UserInfoPage/UserContact';
import { queryKeys } from '../../../react-query/constants';
import { getUserById, updateUser } from '../../../services/UserService';
import UserFormFields from '../UserFormFields';
import { UserRoles } from '../../../models/enums/UserRoles';

const UserForm: FC = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams<{ id?: string }>();

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);

    const { data: user, isLoading } = useQuery(
        [queryKeys.users, id],
        () => getUserById(parseInt(id as string, 10)),
        {
            enabled: !!id,
        },
    );

    const FormSchema = z.object({
        phoneNo: z
            .string()
            .regex(/^[+0-9]{7,16}$|^$/, 'Invalid phone number')
            .nullable(),
        email: z.string().email().optional(),
        authorities: z
            .object({
                admin: z.boolean(),
                employee: z.boolean(),
                freelancer: z.boolean(),
            })
            .refine(({ admin, employee, freelancer }) => admin || employee || freelancer, {
                message: 'Please select at least one role',
                path: ['admin'],
            }),
        weeklyNotifications: z.boolean().optional(),
        twoHoursNotifications: z.boolean().optional(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const defaultValues = useMemo(
        () => ({
            phoneNo: user?.phoneNo,
            email: user?.email,
            authorities: {
                admin: user?.authorities?.includes(UserRoles.ADMIN),
                freelancer: user?.authorities?.includes(UserRoles.FREELANCER),
                employee: user?.authorities?.includes(UserRoles.EMPLOYEE),
            },
            weeklyNotifications: user?.weeklyNotifications,
            twoHoursNotifications: user?.twoHoursNotifications,
        }),
        [user],
    );

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues,
    });

    useEffect(() => {
        methods.reset(defaultValues);
    }, [defaultValues, methods, user]);

    const userMutation = useMutation(
        (formValues: FormSchemaType) => {
            const authorities = [];
            if (formValues.authorities.admin) authorities.push(UserRoles.ADMIN);
            if (formValues.authorities.freelancer) authorities.push(UserRoles.FREELANCER);
            if (formValues.authorities.employee) authorities.push(UserRoles.EMPLOYEE);

            return updateUser(parseInt(id as string, 10), {
                ...formValues,
                // @ts-ignore
                authorities,
            });
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([queryKeys.user]);
                await queryClient.invalidateQueries([queryKeys.users]);

                enqueueSnackbar(id ? 'User updated' : 'User created', { variant: 'success' });

                if (!id) {
                    history.goBack();
                }
            },
            onSettled: () => {
                setIsSaving(false);
            },
        },
    );

    const onSubmit: SubmitHandler<FormSchemaType> = async (formValues: FormSchemaType) => {
        setIsSaving(true);
        userMutation.mutate(formValues);
    };

    return isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
            <CircularProgress />
        </Box>
    ) : (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            mt="40px"
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
            >
                <UserContact user={user} editMode />

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={isSaving ? null : <CheckIcon />}
                    disabled={isSaving}
                    onClick={() =>
                        formRef.current?.dispatchEvent(
                            new Event('submit', { cancelable: true, bubbles: true }),
                        )
                    }
                >
                    {isSaving ? <CircularProgress color="secondary" size="2rem" /> : 'save'}
                </Button>
            </Box>

            <FormProvider {...methods}>
                <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
                    <UserFormFields />
                </form>
            </FormProvider>
        </Box>
    );
};

export default UserForm;
