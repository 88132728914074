import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    rootDialog: {
        borderRadius: 0,
        borderTopLeftRadius: '1.875rem',
        borderBottomRightRadius: '1.875rem',
        backgroundColor: '#16212c',
    },
    dialogTitleContainer: {
        padding: '1.875rem 3.75rem',
    },
    dialogTitle: {
        fontSize: '1.875rem',
        fontWeight: 'bold',
    },
    counterTitle: {
        fontSize: '1.25rem',
        marginLeft: '0.625rem',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(5),
        top: theme.spacing(3),
    },
    closeButtonIcon: {
        width: '2.813rem',
        height: '2.813rem',
        color: 'black',
    },
    dialogContentContainer: {
        padding: '0px 3.75rem',
        border: 0,
    },
    paperContainer: {
        borderRadius: 0,
        backgroundColor: '#263644',
        padding: '1.25rem 1.875rem',
        margin: '0.938rem 0px',
    },
    paperContainerDateField: {
        width: '90%',
        padding: '0.625rem 1.875rem',
    },
    inputLabel: {
        fontSize: '1.25rem',
        fontWeight: 600,
    },
    inputText: {
        fontSize: '1rem',
        fontWeight: 400,
    },
    addTaskButton: {
        margin: '1.25rem 3.75rem',
        padding: '1.563rem 1.875rem',
    },
    confirmButton: {
        padding: '1.625rem 2.438rem',
    },
    conformityButton: {
        padding: '1.563rem 1.25rem',
    },
    arrowNavigator: {
        backgroundColor: '#263644',
        padding: '1.563rem 0px',
        marginBottom: '0.625rem',
        marginTop: '0.625rem',
    },
    actionButtonsContainer: {},
    addTaskButtonLabel: {
        fontSize: '1.25rem',
    },
    acceptConformity: {
        marginLeft: '0.625rem',
        marginBottom: 0,
    },
    calendarIcon: {
        color: 'black',
        width: '1.875rem',
        height: '1.875rem',
    },
    textFieldWrapper: {},
    textField: {
        width: '100%',
        backgroundColor: '#263644',
        '& textarea': {
            '&::-webkit-scrollbar': {
                width: '0.6em',
                margin: '0px 0.625rem',
                color: 'white',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#263644',
                border: 'solid 3px transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'white',
                maxHeight: '3.125rem',
                outline: '1px solid white',
            },
        },
    },
    rootSelect: {
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
        '&:focus': {
            padding: 0,
        },
    },
    selectedItem: {
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
    },
    extraPaperMargin: {
        padding: '1.25rem 1.875rem',
        marginRight: '0.313',
        width: '100%',
    },
    firstTextField: {
        padding: '1.25rem 1.875rem',
    },
    specialTextInput: {},
    conformSelect: {
        height: 'fit-content',
        padding: '0.406rem 0.313rem',
        marginRight: '0.625rem',
        backgroundColor: '#263644',
        borderTopLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',
    },
    skeleton: {
        width: '100%',
    },
}));
