import React, { FC } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';

const LoaderSpinner: FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.spinner}>
            <CircularProgress size={80} />
        </Box>
    );
};

export default LoaderSpinner;
