/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ConformityDto,
    ConformityUpdateRequestDto,
    DictionaryEntryDto,
    PageOfConformityRowDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ConformityController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags conformity-controller
     * @name ApiV1TasksConformitiesArticlesUsingGet
     * @summary viewArticlesDictionary
     * @request GET:/api/v1/tasks/conformities/articles
     * @secure
     */
    apiV1TasksConformitiesArticlesUsingGet = (params: RequestParams = {}) =>
        this.request<string[], void>({
            path: `/api/v1/tasks/conformities/articles`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags conformity-controller
     * @name ApiV1TasksConformitiesStatusesViewsDictionaryUsingGet
     * @summary viewStatusesDictionary
     * @request GET:/api/v1/tasks/conformities/statuses/views/dictionary
     * @secure
     */
    apiV1TasksConformitiesStatusesViewsDictionaryUsingGet = (params: RequestParams = {}) =>
        this.request<DictionaryEntryDto[], void>({
            path: `/api/v1/tasks/conformities/statuses/views/dictionary`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags conformity-controller
     * @name ApiV1TasksIdConformitiesUsingGet
     * @summary retrieveAll
     * @request GET:/api/v1/tasks/{id}/conformities
     * @secure
     */
    apiV1TasksIdConformitiesUsingGet = (
        id: number,
        query?: {
            articles?: string[];
            contractTypes?: number[];
            currentStatuses?: number[];
            month?: number;
            offset?: number;
            paged?: boolean;
            pageNumber?: number;
            pageSize?: number;
            previousStatuses?: number[];
            projects?: number[];
            search?: string;
            'sort.sorted'?: boolean;
            'sort.unsorted'?: boolean;
            unpaged?: boolean;
            week?: number;
            year?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<PageOfConformityRowDto, void>({
            path: `/api/v1/tasks/${id}/conformities`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags conformity-controller
     * @name ApiV1TasksIdConformitiesUsingPut
     * @summary updateAll
     * @request PUT:/api/v1/tasks/{id}/conformities
     * @secure
     */
    apiV1TasksIdConformitiesUsingPut = (
        id: number,
        query?: {
            articles?: string[];
            contractTypes?: number[];
            currentStatuses?: number[];
            month?: number;
            offset?: number;
            paged?: boolean;
            pageNumber?: number;
            pageSize?: number;
            previousStatuses?: number[];
            projects?: number[];
            search?: string;
            'sort.sorted'?: boolean;
            'sort.unsorted'?: boolean;
            unpaged?: boolean;
            week?: number;
            year?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<void, void>({
            path: `/api/v1/tasks/${id}/conformities`,
            method: 'PUT',
            query: query,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags conformity-controller
     * @name ApiV1TasksIdConformitiesConfidUsingGet
     * @summary retrieve
     * @request GET:/api/v1/tasks/{id}/conformities/{confId}
     * @secure
     */
    apiV1TasksIdConformitiesConfidUsingGet = (confId: number, id: number, params: RequestParams = {}) =>
        this.request<ConformityDto, void>({
            path: `/api/v1/tasks/${id}/conformities/${confId}`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags conformity-controller
     * @name ApiV1TasksIdConformitiesConfidUsingPut
     * @summary update
     * @request PUT:/api/v1/tasks/{id}/conformities/{confId}
     * @secure
     */
    apiV1TasksIdConformitiesConfidUsingPut = (
        confId: number,
        id: number,
        request: ConformityUpdateRequestDto,
        params: RequestParams = {},
    ) =>
        this.request<ConformityDto, void>({
            path: `/api/v1/tasks/${id}/conformities/${confId}`,
            method: 'PUT',
            body: request,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
}
