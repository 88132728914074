import { FC } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { BarChartData } from '../../../../models/Performance';

interface Props {
    graphData: BarChartData;
    title: string;
}

const InTimeChart: FC<Props> = ({ graphData }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const YAxisLabel = t('number of tasks');

    return (
        <div className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={650}
                    data={graphData.data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis
                        axisLine={false}
                        tick={{ fill: '#FFFFFF' }}
                        tickLine={false}
                        dataKey={graphData.xDataKey}
                        label={{
                            value: t(`${graphData.xDataKey}`),
                            position: 'insideBottom',
                            offset: -5,
                            fill: '#FFFFFF',
                        }}
                    />
                    <YAxis
                        tickCount={8}
                        domain={['dataMin', 'dataMax']}
                        tickLine={false}
                        axisLine={false}
                        className={classes.AxisClass}
                        tick={{ fill: '#FFFFFF' }}
                        label={{
                            value: t(`${YAxisLabel}`),
                            angle: -90,
                            position: 'insideLeft',
                            fill: '#FFFFFF',
                        }}
                    />
                    <CartesianGrid vertical={false} strokeDasharray="4 0" stroke="#263644" />
                    {graphData.bars.map(bar => {
                        return <Bar dataKey={bar.dataKey} stackId={bar.id} fill={bar.color} />;
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default InTimeChart;
