import { FC, useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Tabs, Tab, Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useStyles } from './styles';
import { ROUTES } from '../../config/constants';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import { thunkLogoutUser } from '../../store/actions';
import { useLocalStorage } from '../../config/hooks/useLocalStorage';
import { Token } from '../../models/enums/Token';
import { UserRoles } from '../../models/enums/UserRoles';
import CompanyLogo from '../CompanyLogo';

const Header: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [token] = useLocalStorage(Token.TOKEN, null);
    const {
        user: { role },
        authenticated,
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);
    const parseInitialCurrentTab = useMemo(() => {
        if (location.pathname.includes(ROUTES.AUTHORIZED.PROJECTS)) {
            return 1;
        }

        if (location.pathname.includes(ROUTES.AUTHORIZED.PERFORMANCE)) {
            return 2;
        }

        if (location.pathname.includes(ROUTES.AUTHORIZED.USERS)) {
            return 3;
        }

        return 0;
    }, [location.pathname]);

    const [currentTab, setCurrentTab] = useState<number>(parseInitialCurrentTab);

    useEffect(() => {
        setCurrentTab(parseInitialCurrentTab);
    }, [parseInitialCurrentTab]);

    const userHasRequiredRole = !isEmpty(role);

    return (
        <AppBar variant="outlined" color="inherit" position="static" className={clsx(classes.appBar)}>
            <CompanyLogo />
            <Toolbar className={clsx(classes.appBarBrandName)}>
                {(!!token || authenticated) && userHasRequiredRole && (
                    <>
                        <Tabs
                            value={currentTab}
                            onChange={(event, newValue) => setCurrentTab(newValue)}
                            classes={{
                                root: classes.tabMenu,
                                indicator: classes.indicator,
                                flexContainer: classes.flexContainer,
                            }}
                            aria-label={t('Header tab menu')}
                        >
                            <Tab
                                label={t('tasks')}
                                onClick={() => {
                                    history.push(ROUTES.AUTHORIZED.DASHBOARD);
                                }}
                            />
                            <Tab
                                label={t('projects')}
                                onClick={() => {
                                    history.push(ROUTES.AUTHORIZED.PROJECTS);
                                }}
                            />

                            <Tab
                                label={t('performances')}
                                onClick={() => {
                                    history.push(ROUTES.AUTHORIZED.PERFORMANCE);
                                }}
                            />

                            {role?.includes(UserRoles.ADMIN) && (
                                <Tab
                                    label={t('users')}
                                    onClick={() => {
                                        history.push(ROUTES.AUTHORIZED.USERS);
                                    }}
                                />
                            )}
                        </Tabs>
                    </>
                )}
            </Toolbar>
            <div className={classes.logOutButtonContainer}>
                <Button
                    className={clsx(classes.logOutButton)}
                    onClick={() => {
                        dispatch(thunkLogoutUser());
                    }}
                >
                    {t('logout')}
                </Button>
            </div>
        </AppBar>
    );
};

export default Header;
