import React, { FC, useCallback, useMemo } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from '@tanstack/react-query';
import { useStyles } from './styles';
import RhfTextField from '../../../../components/form-fields/RhfTextField';
import { Contact, ContactDto, StakeholderDto } from '../../../../api/data-contracts';
import { queryKeys } from '../../../../react-query/constants';
import { getStakeholders } from '../../../../services/ProjectService';
import RhfSelect from '../../../../components/form-fields/RhfSelect';

interface Props {
    group: string;
    subGroup: { groupId: number | undefined; role: string; contacts: ContactDto[] | undefined };
}

const NewPersonFields: FC<Props> = ({ subGroup }) => {
    const classes = useStyles();

    const { data: stakeholders } = useQuery<StakeholderDto[]>([queryKeys.stakeholders], () =>
        getStakeholders(),
    );

    const { control } = useFormContext();

    const groupValues = useWatch({
        control,
        name: `contactGroups`,
    });

    const groupIndex = useMemo(
        () => groupValues.findIndex((groupValue: any) => groupValue.groupId === subGroup.groupId),
        [groupValues, subGroup.groupId],
    );

    const { fields, append, remove } = useFieldArray({
        control,
        // @ts-ignore
        name: `contactGroups.${groupIndex}.contacts`,
    });

    const renderNewContact = useCallback(
        (field: FieldArrayWithId, index: number) => {
            return (
                <Box key={field.id}>
                    <input type="hidden" name={`contactGroups.${groupIndex}.contacts.${index}.contactId`} />

                    <Typography variant="subtitle1">company</Typography>
                    <RhfSelect
                        // @ts-ignore
                        name={`contactGroups.${groupIndex}.contacts.${index}.companyId`}
                        variant="filled"
                        options={
                            stakeholders?.map((stakeholder: StakeholderDto) => ({
                                value: stakeholder.id as number,
                                label: stakeholder.name || '',
                            })) || []
                        }
                        style={{ marginBottom: 16 }}
                    />

                    <Typography variant="subtitle1">name</Typography>
                    <RhfTextField
                        // @ts-ignore
                        name={`contactGroups.${groupIndex}.contacts.${index}.contactName`}
                        variant="filled"
                        placeholder="contact name"
                        style={{ marginBottom: 16 }}
                    />

                    <Typography variant="subtitle1">phone number</Typography>
                    <RhfTextField
                        // @ts-ignore
                        name={`contactGroups.${groupIndex}.contacts.${index}.phoneNo`}
                        variant="filled"
                        placeholder="phone number"
                        style={{ marginBottom: 16 }}
                    />

                    <Typography variant="subtitle1">email</Typography>
                    <RhfTextField
                        // @ts-ignore
                        name={`contactGroups.${groupIndex}.contacts.${index}.email`}
                        variant="filled"
                        placeholder="e-mail address"
                        style={{ marginBottom: 16 }}
                    />

                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => remove(index)}
                        >
                            remove <CloseIcon />
                        </Button>
                    </Box>

                    <Box className={classes.divider} />
                </Box>
            );
        },
        [classes.divider, groupIndex, remove, stakeholders],
    );

    const renderSelectContact = useCallback(
        (field: FieldArrayWithId, index: number) => {
            return (
                <Box key={field.id}>
                    <Typography variant="subtitle1">company</Typography>
                    <RhfSelect
                        // @ts-ignore
                        name={`contactGroups.${groupIndex}.contacts.${index}.companyId`}
                        variant="filled"
                        options={
                            stakeholders
                                ?.filter(stakeholder => {
                                    const existingContacts = groupValues[groupIndex].contacts?.map(
                                        (contact: ContactDto) => contact.contactId,
                                    );

                                    return (
                                        !!stakeholder.contacts?.length &&
                                        !!stakeholder.contacts?.filter(
                                            (contact: Contact) =>
                                                !existingContacts.includes(contact.id) ||
                                                contact.id ===
                                                    groupValues[groupIndex].contacts[index]?.contactId,
                                        ).length
                                    );
                                })
                                .map((stakeholder: StakeholderDto) => ({
                                    value: stakeholder.id as number,
                                    label: stakeholder.name || '',
                                })) || []
                        }
                        style={{ marginBottom: 16 }}
                    />

                    <Typography variant="subtitle1">contact</Typography>
                    <RhfSelect
                        // @ts-ignore
                        name={`contactGroups.${groupIndex}.contacts.${index}.contactId`}
                        variant="filled"
                        options={
                            stakeholders
                                ?.find(
                                    stakeholder =>
                                        stakeholder.id === groupValues[groupIndex].contacts[index]?.companyId,
                                )
                                ?.contacts?.filter(contact => {
                                    const existingContacts = groupValues[groupIndex].contacts?.map(
                                        (contact: ContactDto) => contact.contactId,
                                    );

                                    return (
                                        !existingContacts.includes(contact.id) ||
                                        contact.id === groupValues[groupIndex].contacts[index]?.contactId
                                    );
                                })
                                .map((contact: Contact) => ({
                                    value: contact.id as number,
                                    label: contact.name || '',
                                })) || []
                        }
                        style={{ marginBottom: 16 }}
                    />

                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => remove(index)}
                        >
                            remove <CloseIcon />
                        </Button>
                    </Box>

                    <Box className={classes.divider} />
                </Box>
            );
        },
        [classes.divider, groupIndex, groupValues, remove, stakeholders],
    );

    const renderContactFields = useCallback(
        (field: FieldArrayWithId, index: number) =>
            groupValues[groupIndex].contacts[index]?.contactType !== 'new'
                ? renderSelectContact(field, index)
                : renderNewContact(field, index),
        [groupValues, groupIndex, renderNewContact, renderSelectContact],
    );

    return (
        <Grid item xs={12} style={{ marginLeft: 32 }}>
            {fields.map((field, index) => {
                return renderContactFields(field, index);
            })}

            {!!subGroup.groupId && (
                <>
                    <Button
                        style={{ marginTop: 15, marginRight: 15 }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                            append({
                                contactId: null,
                                companyId: null,
                                contactName: null,
                                phoneNo: null,
                                email: null,
                                contactType: undefined,
                            });
                        }}
                    >
                        select existing contact
                    </Button>

                    <Button
                        style={{ marginTop: 15 }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                            append({
                                contactId: null,
                                companyId: null,
                                contactName: null,
                                phoneNo: null,
                                email: null,
                                contactType: 'new',
                            });
                        }}
                    >
                        add new contact
                    </Button>
                </>
            )}
        </Grid>
    );
};

export default NewPersonFields;
