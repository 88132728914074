import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    button: {
        height: 31,
        backgroundColor: '#d55b2e',
        boxShadow: 'none',
        border: 0,
        padding: '1.25rem 1.25rem',
        lineHeight: 1.1,
        borderRadius: 0,
        borderTopLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.light,
        },
    },
    basicButton: {},
    label: {
        color: 'white',
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    grayLabel: {
        color: '#979EA6',
        '& svg': {
            fill: '#979EA6',
        },
        '& svg > path': {
            fill: '#979EA6',
        },
    },
    disabled: {
        backgroundColor: '#979EA6',
        color: 'white',
    },
}));
