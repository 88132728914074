import { AxiosError } from 'axios';
import { FormikErrors } from 'formik';
import { isNumber } from 'lodash';
import { TaskSorting } from '../models/enums/TaskSorting';
import { TaskSortingTypes } from '../models/enums/TaskSortingTypes';
import { Task, TaskBodyForm } from '../models/Task';
import { TaskStatusValues } from '../models/enums/TaskStatusValues';
import { TaskStatusNames } from '../models/enums/TaskStatusNames';
import { TaskType } from '../models/enums/TaskType';
import { Filter } from '../models/Filter';
import { Employee } from '../models/Project';

type BackendFormikError = { field: string; errorCode: string }[];

export const getBackendErrorMessage = <T,>(error: AxiosError): string | FormikErrors<T> => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error.response.data.detail || error.response.data.message;
    }
    if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return error.request;
    }
    // Something happened in setting up the request that triggered an Error
    return error.message;
};

export const getBackendFormikErrors = <T,>(error: AxiosError): FormikErrors<T> => {
    if (error?.response?.data.errors) {
        return (error.response.data.errors as BackendFormikError).reduce(
            (prev, { field, errorCode }) => ({ ...prev, [field]: errorCode }),
            {},
        );
    }
    return {};
};

export const chooseSorting = (filterType: string, sortOrder: string): TaskSorting => {
    switch (sortOrder) {
        case TaskSorting.EMPTY:
            if (filterType === TaskSortingTypes.STARTED) {
                return TaskSorting.STARTED_DESC;
            }
            return TaskSorting.DEADLINE_DESC;

        case TaskSorting.STARTED_ASC:
            if (filterType === TaskSortingTypes.STARTED) {
                return TaskSorting.EMPTY;
            }
            return TaskSorting.DEADLINE_DESC;

        case TaskSorting.STARTED_DESC:
            if (filterType === TaskSortingTypes.STARTED) {
                return TaskSorting.STARTED_ASC;
            }
            return TaskSorting.DEADLINE_DESC;

        case TaskSorting.DEADLINE_ASC:
            if (filterType === TaskSortingTypes.STARTED) {
                return TaskSorting.STARTED_DESC;
            }
            return TaskSorting.EMPTY;
        case TaskSorting.DEADLINE_DESC:
            if (filterType === TaskSortingTypes.STARTED) {
                return TaskSorting.STARTED_DESC;
            }
            return TaskSorting.DEADLINE_ASC;

        default:
            return TaskSorting.EMPTY;
    }
};

export const parseTaskToTaskBodyForm = (
    task: Task | undefined,
    projects: Filter[],
    employees: Filter[],
    types: Filter[],
): TaskBodyForm | undefined => {
    if (task === undefined) return undefined;

    const taskStatus =
        // @ts-ignore
        task?.status?.id !== TaskStatusValues.OPEN && task.status !== TaskStatusNames.OPEN
            ? TaskStatusValues.CLOSED
            : TaskStatusValues.OPEN;

    let taskTypeIdValue = TaskType.MT_ACTIE;

    switch (task?.type) {
        case TaskType.MT:
            taskTypeIdValue = TaskType.MT_ACTIE;
            break;

        case TaskType.MT_ACTIE:
            taskTypeIdValue = TaskType.MT_ACTIE;
            break;
        case TaskType.Proc:
            taskTypeIdValue = TaskType.Proces;
            break;
        case TaskType.Proces:
            taskTypeIdValue = TaskType.Proces;
            break;
        case TaskType.Deliv:
            taskTypeIdValue = TaskType.Deliverable;
            break;
        case TaskType.Deliverable:
            taskTypeIdValue = TaskType.Deliverable;
            break;
        default:
            break;
    }

    const taskTypeItem = types.find(typeItem => typeItem.name === taskTypeIdValue);

    const taskType = isNumber(taskTypeItem?.id) ? taskTypeItem?.id : NaN;

    const result: TaskBodyForm = {
        id: task.id,
        assignedEmployees: [1, 2, 3],
        deadline: task?.deadline || '',
        description: task?.description || '',
        name: task?.name || '',
        projectId: 0,
        remarks: task?.remarks || '',
        startDate: task?.started || '',
        statusId: taskStatus,
        typeId: taskType,
        // @ts-ignore
        contractType: task?.contractType?.id,
        // @ts-ignore
        discipline: task?.discipline?.id,
    };

    const projectItem = projects.find(project => project.name === task?.projectName);

    // @ts-ignore
    result.projectId = isNumber(projectItem?.id) ? projectItem.id : NaN;

    const assignedEmployees: number[] = [];
    employees.forEach(employee => {
        // @ts-ignore
        const matchingEmployee = task?.assignedEmployees?.find(
            (assignedEmployee: Employee | string) =>
                // @ts-ignore
                (assignedEmployee?.name || assignedEmployee) === employee.name,
        );

        if (matchingEmployee) {
            assignedEmployees.push(isNumber(employee.id) ? employee.id : NaN);
        }
    });

    result.assignedEmployees = assignedEmployees;

    return result;
};
