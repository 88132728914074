import React, { Dispatch, FC, SetStateAction, ChangeEvent } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CustomSelect from '../../../../components/CustomSelect';
import { ProjectDetailsDto } from '../../../../api/data-contracts';
import CustomButton from '../../../../components/CustomButton';
import { useStyles } from './styles';
import FormFieldsBox from '../../../../components/FormFieldsBox';

interface Props {
    project: ProjectDetailsDto | undefined;
    handleClickGenerateReport: () => void;
    open: boolean;
    onClose: () => void;
    disabled: boolean;
    year: number;
    setYear: Dispatch<SetStateAction<number>>;
    quarter: number;
    setQuarter: Dispatch<SetStateAction<number>>;
}

const ProjectReportDialog: FC<Props> = ({
    project,
    handleClickGenerateReport,
    open,
    onClose,
    disabled,
    year,
    setYear,
    quarter,
    setQuarter,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="md"
            fullWidth
            classes={{
                paper: classes.rootDialog,
            }}
        >
            <DialogTitle>
                <div className={classes.titleContainer}>
                    <Typography className={classes.dialogTitle}>
                        {t('Project:')} {project?.projectName || t('No project name found')}
                    </Typography>
                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className={classes.closeButtonIcon} />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>quarter</Typography>
                        <CustomSelect
                            id="quarter"
                            inputName="quarter"
                            fullWidth
                            variant="filled"
                            defaultValue={quarter}
                            availableData={[
                                { id: 1, name: 1 },
                                { id: 2, name: 2 },
                                { id: 3, name: 3 },
                                { id: 4, name: 4 },
                            ]}
                            onChangeSpecial={(e: unknown) =>
                                setQuarter(parseInt((e as ChangeEvent<HTMLInputElement>).target.value, 10))
                            }
                            inputRootClass={classes.selectRoot}
                            selectClass={classes.select}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>year</Typography>
                        <DatePicker
                            views={['year']}
                            inputVariant="filled"
                            value={year.toString()}
                            format="YYYY"
                            allowKeyboardControl={false}
                            InputProps={{ endAdornment: <DateRangeIcon /> }}
                            onChange={date => {
                                if (date) setYear(dayjs(date).get('year'));
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <CustomButton basic label={t('cancel')} onClick={onClose} />
                <CustomButton
                    label={t('generate report')}
                    onClick={handleClickGenerateReport}
                    variant="contained"
                    color="primary"
                    disabled={disabled}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ProjectReportDialog;
