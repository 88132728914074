import React, { FC } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

interface Props {
    label: string;
}

const BackButton: FC<Props> = ({ label }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Button startIcon={<ArrowBackIcon color="primary" />} onClick={() => history.goBack()}>
            <Typography className={classes.text}>{label}</Typography>
        </Button>
    );
};

export default BackButton;
