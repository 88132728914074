import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        height: '100%',
        width: '100%',
    },
    YAxisLabel: {
        // transform: 'rotate(-90deg)',
    },
    AxisClass: {
        '.recharts-label': {
            fontSize: '1.125rem',
        },
    },
    chartContainer: {
        height: '34.375rem',
        width: '100%',
        // position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    chartTitle: {
        // position: 'absolute',
        justifyContent: 'left',
        textAlign: 'left',
        marginTop: '-4.688rem',
        zIndex: 10,
    },
});
