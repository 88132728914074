import React, { FC, useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { Box, Button, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getTasksByID, updateTaskStatus } from '../../services/TasksService';
import { queryKeys } from '../../react-query/constants';
import TaskForm from './TaskForm';
import LoaderSpinner from '../../components/LoaderSpinner';
import BackButton from '../../components/BackButton';
import { useStyles } from './styles';
import { TaskDto } from '../../api/data-contracts';
import { TaskStatusValues } from '../../models/enums/TaskStatusValues';
import { getBackendErrorMessage } from '../../config/utils';

const TaskFormPage: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { id } = useParams<{ id?: string }>();

    const [submitForm, setSubmitForm] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { data: task, isLoading } = useQuery<TaskDto>(
        [queryKeys.task, id],
        () => getTasksByID(parseInt(id as string, 10)),
        {
            enabled: !isEmpty(id),
        },
    );

    const toggleStatus = useCallback(() => {
        if (task?.id) {
            const nextStatus =
                task?.status?.id === TaskStatusValues.CLOSED
                    ? TaskStatusValues.OPEN
                    : TaskStatusValues.CLOSED;

            updateTaskStatus(task.id, nextStatus)
                .then(() => {
                    queryClient.invalidateQueries([queryKeys.task]);
                    enqueueSnackbar(t('Status updated '), { variant: 'success' });
                })
                .catch(error => {
                    enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                        variant: 'error',
                    });
                });
        }
    }, [enqueueSnackbar, t, queryClient, task]);

    const renderToggleStatusButton = useCallback(
        () => (
            <Button className={classes.statusButton} onClick={toggleStatus}>
                <Box
                    className={clsx(classes.taskStatusBox, {
                        [classes.taskStatusBoxDone]: task?.status?.name === 'Closed',
                    })}
                >
                    {task?.status?.id === TaskStatusValues.CLOSED && (
                        <CheckIcon className={classes.taskStatusBoxIcon} />
                    )}
                </Box>
            </Button>
        ),
        [toggleStatus, classes, task],
    );

    const key = useMemo(() => `task-${task?.id}-${task?.status?.name}`, [task]);

    return (
        <Box width="100%">
            <Box>
                <BackButton label="back to tasks" />

                <Box className={classes.pageHeader}>
                    <Typography color="primary" className={classes.pageSubTitle}>
                        {task?.id ? 'editing task:' : 'adding task'}
                    </Typography>

                    <Box className={classes.headerRow}>
                        {task?.id ? (
                            <Typography className={classes.pageTitle}>
                                {task.name} {renderToggleStatusButton()}
                            </Typography>
                        ) : (
                            <Box />
                        )}

                        <Box className={classes.buttons}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<CheckIcon />}
                                className={classes.saveButton}
                                onClick={() => {
                                    setSubmitForm(true);
                                }}
                                disabled={(isLoading && !isEmpty(id)) || isSubmitting}
                            >
                                save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {isLoading && !isEmpty(id) ? (
                <LoaderSpinner />
            ) : (
                <TaskForm
                    key={key}
                    task={task}
                    submitForm={submitForm}
                    setSubmitForm={setSubmitForm}
                    setIsSubmitting={setIsSubmitting}
                />
            )}
        </Box>
    );
};

export default TaskFormPage;
