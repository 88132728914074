import * as yup from 'yup';
// eslint-disable-next-line import/no-named-default
import { default as defaultConfiguration } from '../../../../config/application_index.json';

export type ColumnSizes = number & (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12);

export interface ConformityRegister {
    projectName: string;
    description: string;
    previousState: string;
    previousComment: string;
    comment: string;
    conformState: number;
    startDate: string;
}

export const getConformityRegisterInitialValues = (): ConformityRegister => ({
    projectName: '',
    description: '',
    previousState: '',
    previousComment: '',
    comment: '',
    conformState: 1,
    startDate: new Date().toString(),
});

const { descriptionLength, commentLength } = defaultConfiguration.configs.conformityRegister;

export const validationSchema = yup.object().shape({
    description: yup.string().max(descriptionLength, `Maximum length is ${descriptionLength} characters.`),
    comment: yup.string().max(commentLength, `Maximum length is ${commentLength} characters.`),
    startDate: yup.string().required('Start date is required field.'),
});
