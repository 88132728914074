import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../config/theme';

export const useStyles = makeStyles({
    emptyDataPlaceholder: {
        width: '100%',
        padding: '0.625rem 1.25rem',
        borderRadius: 0,
        margin: '0.625rem 0px',
        marginRight: '1.875rem',
        backgroundColor: theme.palette.secondary.light,
    },
});
