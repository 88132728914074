import { makeStyles } from '@material-ui/core';
import { TABLE_TOOLBAR_HEIGHT, theme } from '../../config/theme';

export const useStyles = makeStyles(() => ({
    toolbar: {
        backgroundColor: theme.palette.secondary.light,
        width: '100vw',
        padding: '1rem 5rem',
        height: TABLE_TOOLBAR_HEIGHT,
        position: 'relative',
    },
}));
