import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    grid: {
        rowGap: '1rem',
    },
    label: {
        color: '#708393',
        fontSize: '1.25rem',
        lineHeight: 1.2,
    },
});
