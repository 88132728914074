import React, { FC } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';
import { Box, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';
import { useStyles } from './styles';

type Props = {
    names: `${string}`[];
} & Omit<KeyboardDatePickerProps, 'value' | 'onChange'>;

const RhfDatePicker: FC<Props> = ({ names, inputVariant, disabled, ...props }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Controller
                name={names[0]}
                render={({ field, fieldState: { error }, formState: { isSubmitting } }) => (
                    <DatePicker
                        {...field}
                        {...props}
                        placeholder="start date"
                        className={classes.input}
                        value={field.value || null}
                        format={props.format}
                        onChange={e => field.onChange(e?.format('MM/DD/YY') || '')}
                        error={Boolean(error)}
                        helperText={error?.message || ''}
                        disabled={isSubmitting || disabled}
                        allowKeyboardControl={false}
                        autoOk
                        DialogProps={{ className: classes.popup }}
                        views={['year', 'month', 'date']}
                        minDate={dayjs('2000-01-01')}
                        maxDate={dayjs(`${dayjs().add(20, 'year').year()}-12-31`)}
                    />
                )}
            />
            <Typography className={classes.divider}>-</Typography>
            <Controller
                name={names[1]}
                render={({ field, fieldState: { error }, formState: { isSubmitting } }) => (
                    <DatePicker
                        {...field}
                        {...props}
                        placeholder="end date"
                        className={classes.input}
                        value={field.value || null}
                        format={props.format}
                        onChange={e => field.onChange(e?.format('MM/DD/YY') || '')}
                        error={Boolean(error)}
                        helperText={error?.message || ''}
                        disabled={isSubmitting || disabled}
                        allowKeyboardControl={false}
                        autoOk
                        DialogProps={{ className: classes.popup }}
                        views={['year', 'month', 'date']}
                        minDate={dayjs('2000-01-01')}
                        maxDate={dayjs(`${dayjs().add(20, 'year').year()}-12-31`)}
                    />
                )}
            />
            <DateRangeIcon className={classes.icon} />
        </Box>
    );
};

export default RhfDatePicker;
