export const ROUTES = {
    PUBLIC: {
        BLANK: '/',
        LOGIN: '/login',
        MANAGER_SET_PASSWORD: '/verification/:token',
    },
    AUTHORIZED: {
        EXAMPLE_PAGE: '/example-page',
        DASHBOARD: '/dashboard',
        PROJECTS: '/projects',
        DISPLAY_PROJECT_INFO: '/projects/:id',
        EDIT_PROJECT: '/projects/form/:id',
        USERS: '/users',
        DISPLAY_USER_INFO: '/users/:id',
        EDIT_USER: '/users/form/:id?',
        PERFORMANCE: '/performance',
        TASK_FORM: '/tasks/form/:id?',
        CONFORMITY_REGISTRY: '/tasks/:id/conformity',
    },
};

export const DATE_FORMAT = 'DD/MM/YY';
export const PROJECT_FORM_DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YY HH:mm';
export const SUBMITTING_DATE_FORMAT = 'YYYY-MM-DD';
export const SUBMITTING_DATETIME_FORMAT = 'YYYY-MM-DD 00:00:00';
