import { FC, useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import TaskTableHeader from '../../pages/DashboardPage/Parts/TaskTableHeader';
import TaskTableHeaderReduced from '../TaskTableHeaderReduced';
import TaskTableBody from '../TaskTableBody';
import ConformityRegisterDialog from '../../pages/DashboardPage/Parts/ConformityRegisterDialog';
import { RootReducer, TasksReducer } from '../../models/Redux';
import { thunkGetAllTasks } from '../../store/actions/Tasks.actions';
import { TaskFilterQuery } from '../../models/TaskFilterQuery';
import LoaderSpinner from '../../components/LoaderSpinner';
import CustomSelect from '../../components/CustomSelect';
import { ConformityDto, TaskDto } from '../../api/data-contracts';

interface Props {
    reducedHeader?: boolean;
    tableBodyProps?: any;
    loaderContainerClass?: string;
}

const TasksTable: FC<Props> = ({ reducedHeader, tableBodyProps, loaderContainerClass }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isConformityModalOpen, setIsConformityModalOpen] = useState<boolean>(false);
    const [groupBy, setGroupBy] = useState<number>(3);
    const dispatch = useDispatch();
    const history = useHistory();
    const { replace } = useHistory();
    const { search } = useLocation();
    const { tasks, loading, totalPages } = useSelector<RootReducer, TasksReducer>(state => state.tasks);
    const [currentTask, setCurrentTask] = useState<TaskDto>();
    const [currentTaskConformities, setCurrentTaskConformities] = useState<ConformityDto[]>([]);
    const [query, setQuery] = useState<TaskFilterQuery>({
        week: dayjs().week(),
        month: undefined,
        year: dayjs().year(),
        size: 10,
        page: 1,
    });
    const [areFiltersLoaded, setAreFiltersLoaded] = useState<boolean>(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);

        const page = parseInt(searchParams.get('page') || '1', 10);
        const size = parseInt(searchParams.get('size') || '10', 10);

        // @ts-ignore
        const week =
            searchParams.get('week') === undefined
                ? dayjs().week()
                : searchParams.get('week') === ''
                ? undefined
                : parseInt(searchParams.get('week') || dayjs().week().toString(), 10);
        const month = searchParams.get('month') ? parseInt(searchParams.get('month') || '1', 10) : undefined;
        const year = parseInt(searchParams.get('year') || dayjs().year().toString(), 10);

        const projects = (searchParams.get('projects') || undefined)?.split(',');
        const discipline = (searchParams.get('discipline') || undefined)?.split(',');
        const assignedTo = (searchParams.get('assignedTo') || undefined)?.split(',');
        const status = (searchParams.get('status') || undefined)?.split(',');
        const taskType = (searchParams.get('taskType') || undefined)?.split(',');
        const searchString = searchParams.get('search') || undefined;

        if (week === undefined && month === undefined) {
            setGroupBy(1);
        } else if (week === undefined) {
            setGroupBy(2);
        } else {
            setGroupBy(3);
        }

        setQuery({
            week,
            month,
            year,
            page,
            size,
            search: searchString,
            // @ts-ignore
            filters: {
                projects,
                discipline,
                assignedTo,
                status,
                taskType,
            },
        });

        setAreFiltersLoaded(true);
    }, [search]);

    const updateQueryState = (newQuery: TaskFilterQuery) => {
        replace({
            pathname: '/dashboard',
            search: `?${Object.entries(newQuery)
                .map(e => {
                    if (e[0] === 'filters') {
                        return Object.entries(e[1])
                            .map(v => v.join('='))
                            .join('&');
                    }
                    return e.join('=');
                })
                .join('&')}`,
        });
    };

    const getAllTasks = useCallback(() => {
        const page = query.page > 0 ? query.page - 1 : 0;
        dispatch(
            thunkGetAllTasks({
                ...query,
                page,
            }),
        );
    }, [dispatch, query]);

    useEffect(() => {
        if (areFiltersLoaded) {
            getAllTasks();
        }
    }, [getAllTasks, areFiltersLoaded]);

    const setPageSize = (pageSize: number) => {
        updateQueryState({
            ...query,
            size: pageSize,
            page: 1,
        });
    };

    const renderSelectInput = (
        id: string,
        name: string,
        availableData: any[],
        value: any,
        label?: string,
        onChange?: (data: any) => void,
        disableChange?: boolean,
        disabled?: boolean,
    ) => {
        return (
            <CustomSelect
                id={id}
                inputName={name}
                defaultValue={value || label}
                label={label}
                availableData={availableData}
                selectClass={classes.rootSelect}
                selectedClass={classes.selectedItemPagination}
                // @ts-ignore
                onChange={onChange}
                disableChange={disableChange}
                disabled={disabled}
                iconClass={classes.iconClass}
            />
        );
    };

    const handleCloseConformityDialog = () => {
        setIsConformityModalOpen(false);
    };

    const handleOpenConformityDialog = (
        task: TaskDto | undefined,
        conformities: ConformityDto[] | undefined,
    ) => {
        setCurrentTask(task);
        setCurrentTaskConformities(conformities || []);

        setIsConformityModalOpen(true);
    };

    const handleNextWeek = () => {
        if (query.week !== undefined) {
            if (query.week + 1 > dayjs(`01/01/${query.year}`).isoWeeksInYear()) {
                updateQueryState({
                    ...query,
                    page: 1,
                    week: 1,
                    year: query.year + 1,
                });
            } else {
                updateQueryState({
                    ...query,
                    page: 1,
                    week: query.week + 1,
                });
            }
        }
    };

    const handleNextYear = () => {
        updateQueryState({
            ...query,
            page: 1,
            week: undefined,
            month: undefined,
            year: query.year + 1,
        });
    };

    const handlePreviousWeek = () => {
        if (query.week !== undefined) {
            if (query.week - 1 < 1) {
                updateQueryState({
                    ...query,
                    page: 1,
                    week: dayjs(`01/01/${query.year - 1}`).isoWeeksInYear(),
                    year: query.year - 1,
                });
            } else {
                updateQueryState({
                    ...query,
                    page: 1,
                    week: query.week - 1,
                });
            }
        }
    };

    const handlePreviousYear = () => {
        updateQueryState({
            ...query,
            page: 1,
            week: undefined,
            month: undefined,
            year: query.year - 1,
        });
    };

    const handleNextMonth = () => {
        if (query.month !== undefined) {
            if (query.month + 1 < 13) {
                updateQueryState({
                    ...query,
                    page: 1,
                    month: query.month + 1,
                });
            } else {
                updateQueryState({
                    ...query,
                    page: 1,
                    month: 1,
                    year: query.year + 1,
                });
            }
        }
    };

    const handlePreviousMonth = () => {
        if (query.month !== undefined) {
            if (query.month - 1 > 0) {
                updateQueryState({
                    ...query,
                    page: 1,
                    month: query.month - 1,
                });
            } else {
                updateQueryState({
                    ...query,
                    page: 1,
                    month: 12,
                    year: query.year - 1,
                });
            }
        }
    };

    const handleExecuteQuery = (newQuery: string) => {
        if (newQuery !== '{}') {
            updateQueryState({
                ...query,
                page: 1,
                filters: newQuery,
            });
        } else {
            updateQueryState({
                ...query,
                page: 1,
                filters: undefined,
            });
        }
    };

    const handleSort = (order: string) => {
        updateQueryState({
            ...query,
            sort: order || undefined,
        });
    };

    const handleLoadMore = (newPage?: number) => {
        if (isUndefined(newPage)) {
            if (query.page < totalPages - 1) {
                updateQueryState({
                    ...query,
                    page: query.page + 1,
                });
            }
        } else {
            updateQueryState({
                ...query,
                page: newPage,
            });
        }
    };

    const handleChangeGroupBy = (newValue: number) => {
        switch (newValue) {
            case 1:
                updateQueryState({
                    ...query,
                    page: 1,
                    week: undefined,
                    month: undefined,
                    year: dayjs().year(),
                });
                break;
            case 2:
                updateQueryState({
                    ...query,
                    page: 1,
                    week: undefined,
                    month: dayjs().month() + 1,
                    year: dayjs().year(),
                });
                break;
            case 3:
                updateQueryState({
                    ...query,
                    page: 1,
                    week: dayjs().week() - 1,
                    month: undefined,
                    year: dayjs().year(),
                });
                break;
            default:
                break;
        }
        setGroupBy(newValue);
    };

    const handleExecuteSearchQuery = (newSearch: string) => {
        updateQueryState({
            ...query,
            search: newSearch,
            page: 1,
        });
    };

    const handleChangePagination = (event: React.ChangeEvent<unknown>, newPage: number) => {
        handleLoadMore(newPage);
    };

    return (
        <>
            {reducedHeader ? (
                <TaskTableHeaderReduced handleSort={handleSort} />
            ) : areFiltersLoaded ? (
                <TaskTableHeader
                    handleAddTask={() => history.push('/tasks/form')}
                    handleSort={handleSort}
                    handleNextWeek={handleNextWeek}
                    handlePreviousWeek={handlePreviousWeek}
                    handleExecuteQuery={handleExecuteQuery}
                    handleNextMonth={handleNextMonth}
                    handlePreviousMonth={handlePreviousMonth}
                    handleNextYear={handleNextYear}
                    handlePreviousYear={handlePreviousYear}
                    handleExecuteSearchQuery={handleExecuteSearchQuery}
                    groupBy={groupBy}
                    setGroupByValue={handleChangeGroupBy}
                    week={query.week}
                    year={query.year}
                    month={query.month}
                    // @ts-ignore
                    initialFilters={query.filters}
                    initialSearch={query.search}
                    disabled={loading}
                />
            ) : null}

            <Grid
                container
                direction="column"
                justify="center"
                alignContent="center"
                alignItems="center"
                className={classes.tableContainer}
            >
                <ConformityRegisterDialog
                    open={isConformityModalOpen}
                    currentTask={currentTask}
                    currentTaskConformities={currentTaskConformities}
                    handleClose={handleCloseConformityDialog}
                />

                {!loading ? (
                    <>
                        <TaskTableBody
                            data={tasks}
                            openConformity={handleOpenConformityDialog}
                            handleLoadMore={() => {}}
                            loading={loading}
                            {...tableBodyProps}
                        />
                        <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            justify="center"
                            alignContent="center"
                            alignItems="center"
                            className={classes.paginationContainer}
                        >
                            <Grid item xs={3} />
                            <Grid
                                container
                                item
                                xs={6}
                                direction="row"
                                justify="center"
                                alignContent="center"
                                alignItems="center"
                            >
                                <Pagination
                                    count={totalPages}
                                    page={query.page}
                                    onChange={handleChangePagination}
                                    classes={{ root: classes.paginationRoot }}
                                    renderItem={item => (
                                        <PaginationItem
                                            {...item}
                                            classes={{
                                                root: classes.paginationElement,
                                                page: classes.page,
                                                selected: classes.selectedPage,
                                                ellipsis: classes.paginationEllipsis,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={3}
                                direction="row"
                                justify="flex-end"
                                alignContent="center"
                                alignItems="center"
                            >
                                <Typography className={classes.paginationSizeText}>{t('items:')}</Typography>
                                {renderSelectInput(
                                    'page-size',
                                    'page-size',
                                    [
                                        { id: 10, name: 10 },
                                        { id: 25, name: 25 },
                                        { id: 50, name: 50 },
                                    ],
                                    query.size,
                                    undefined,
                                    data => {
                                        setPageSize(data);
                                    },
                                )}
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <div className={clsx(classes.loaderContainer, loaderContainerClass)}>
                        <LoaderSpinner />
                    </div>
                )}
            </Grid>
        </>
    );
};

export default TasksTable;
