import axios, { AxiosResponse } from 'axios';
import { writeToLocalStorage } from './LocalStorage';
import { Token } from '../../models/enums/Token';
import { AzureAuthController } from '../../api/AzureAuthController';
import { AuthenticationResponse } from '../../models/Authentication';

export const login = async (socialLoginParams: { code: string }): Promise<unknown> => {
    const azureApi = new AzureAuthController();

    const { data } = await azureApi.apiV1Oauth2MicrosoftSigninUsingPost(socialLoginParams);

    return (data as unknown) as AuthenticationResponse;
};

export const loginWithRefreshToken = async (refreshToken: string): Promise<boolean> => {
    try {
        const response: AxiosResponse = await axios.post(`${process.env.REACT_APP_API}login`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        });

        if (response.status !== 200) return false;

        writeToLocalStorage(Token.REFRESH_TOKEN, { ...response.data, time_received: Date.now() });
        return true;
    } catch (e) {
        return false;
    }
};
