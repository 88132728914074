import React, { FC } from 'react';
import { Box, FormControlLabel, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { UserInfoDto } from '../../../api/data-contracts';
import { useStyles } from './styles';
import { UserRoles } from '../../../models/enums/UserRoles';

interface Props {
    user: UserInfoDto | undefined;
}

const UserCard: FC<Props> = ({ user }) => {
    const classes = useStyles();

    const renderRow = (label: string, value: string | number | undefined) => (
        <Box className={classes.userInfoRow}>
            <Typography className={classes.label}>{label}:</Typography>
            <Typography className={classes.value}>{value}</Typography>
        </Box>
    );

    const renderCheckbox = (label: string, checked?: boolean | undefined) => (
        <FormControlLabel
            classes={{ label: classes.checkboxLabel, root: classes.formControl }}
            control={
                <Box className={classes.checkbox}>
                    <input type="checkbox" checked={checked} />
                    <span className="checkmark">
                        {checked && <CheckIcon className={classes.checkIcon} />}
                    </span>
                </Box>
            }
            label={label}
        />
    );

    return user?.id ? (
        <Box className={classes.card}>
            {renderRow('discipline', user?.discipline?.map(discipline => discipline.name)?.join(', ') || '')}
            {renderRow('upcoming deadlines', user.upcomingDeadlines)}
            {renderRow('completed projects', user.tasksCompleted)}
            {renderRow('open tasks', user.openTasks)}
            {renderRow('application id', user.id)}

            <Box className={classes.userInfoRow}>
                <Typography className={classes.label}>roles:</Typography>
                <Box display="flex" flexDirection="column">
                    {renderCheckbox('team member', user.authorities?.includes(UserRoles.EMPLOYEE))}
                    {renderCheckbox('freelancer', user.authorities?.includes(UserRoles.FREELANCER))}
                    {renderCheckbox('admin', user.authorities?.includes(UserRoles.ADMIN))}
                </Box>
            </Box>

            <Box className={classes.userInfoRow}>
                <Typography className={classes.label}>notifications:</Typography>
                <Box display="flex" flexDirection="column">
                    {renderCheckbox('send weekly notifications', user?.weeklyNotifications)}
                    {renderCheckbox('send notifications every 2 hours', user?.twoHoursNotifications)}
                </Box>
            </Box>
        </Box>
    ) : null;
};

export default UserCard;
