import { makeStyles } from '@material-ui/styles';
import { theme } from '../../../../config/theme';

export const useStyles = makeStyles({
    paperBackground: ({ showDetails, showAudit }: { showDetails: boolean; showAudit: boolean }) => ({
        width: '100%',
        borderRadius: 0,
        backgroundColor:
            showDetails || showAudit ? theme.palette.secondary.light : theme.palette.secondary.dark,
        borderBottom: `${showDetails || showAudit ? 'none' : '1px solid #2C3842'}`,
        padding: `1rem 1rem 1rem ${showDetails || showAudit ? '1rem' : 0}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    noBorder: {
        border: 0,
    },
    auditRemarkList: {
        paddingBottom: '0.938rem',
    },
    auditRemarkListItem: {
        width: '90%',
    },
    detailsTitle: {
        fontSize: '1rem',
        margin: '0.938rem 0.625rem',
    },
    detailsContent: {
        fontSize: '1.125rem',
        margin: '0.313rem 0.688rem',
        paddingBottom: 0,
        fontFamily: 'Ubuntu',
    },
    collapseDetailsButton: {
        padding: '1.25rem 0.625rem',
        margin: ' 0px 0.625rem',
        minWidth: 'unset',
        backgroundColor: theme.palette.secondary.light,
    },
});
