import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    tabButton: {
        borderBottomWidth: 0,
        padding: '0.938rem 1.875rem',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '1.125rem',
        lineHeight: 1.2,
    },
    activeTabButton: {
        backgroundColor: '#263644',
        borderRadius: '1rem 0',
    },
});
