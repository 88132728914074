/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ReportDto } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PerformanceController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags performance-controller
   * @name ApiV1PerformancesReportsIdUsingGet
   * @summary retrieve
   * @request GET:/api/v1/performances/reports/{id}
   * @secure
   */
  apiV1PerformancesReportsIdUsingGet = (
    id: number,
    query: { filters?: string; month?: number; year: number },
    params: RequestParams = {},
  ) =>
    this.request<ReportDto[], void>({
      path: `/api/v1/performances/reports/${id}`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags performance-controller
   * @name ApiV1PerformancesReportsIdUsingPost
   * @summary retrievePdf
   * @request POST:/api/v1/performances/reports/{id}
   * @secure
   */
  apiV1PerformancesReportsIdUsingPost = (
    id: number,
    data: { file?: File; month?: number; year?: number; filters?: string },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/api/v1/performances/reports/${id}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
}
