import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    rootSelect: {
        color: '#fff',
        opacity: '0.4',
        padding: '0.9rem 0rem',
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
    rootSelectMenu: {
        borderRadius: 0,
    },
    menuListSelectMenu: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    selected: {
        paddingRight: '2.5rem !important',
    },
    selectedItem: {
        backgroundColor: 'transparent !important',
    },
    selectInputListItemIcon: {
        position: 'absolute',
        right: '0.625rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        justifyItems: 'end',
        color: '#E26A3A',
    },
    checkbox: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    iconComponent: {},
    icon: {
        color: '#c4c4c4',
        opacity: 1,
        marginRight: '0.625rem',
        width: '0.875rem',
        height: '0.875rem',
        position: 'inherit',
        cursor: 'pointer',
    },
    iconOpen: {
        color: '#c4c4c4',
        opacity: 1,
        width: '0.875rem',
        height: '0.875rem',
    },
});
