import { FC, useCallback, useEffect, useState } from 'react';
import { AppBar, Box, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { Carousel } from 'react-responsive-carousel';
import { useStyles } from '../../ProjectFormPage/ProjectPicturesForm/styles';
import { MediaDto, ProjectDetailsDto } from '../../../api/data-contracts';
import { useWindowSize } from '../../../config/hooks/useWindowSize';

interface Props {
    project?: ProjectDetailsDto;
}

const FIRST_PROJECT_PICTURE_SIZE = 250;
const OTHER_PROJECT_PICTURE_SIZE = 110;
const PICTURE_MARGIN = 10;

const ProjectPictures: FC<Props> = ({ project }) => {
    const classes = useStyles({
        firstImgSize: FIRST_PROJECT_PICTURE_SIZE,
        otherImgSize: OTHER_PROJECT_PICTURE_SIZE,
        pictureMargin: PICTURE_MARGIN,
    });
    const { width } = useWindowSize();

    const [firstPicture, setFirstPicture] = useState<MediaDto>();
    const [visibleOtherPictures, setVisibleOtherPictures] = useState<MediaDto[]>();
    const [hiddenOtherPicturesCount, setHiddenOtherPicturesCount] = useState<number>(0);
    const [picturesContainerWidth, setPicturesContainerWidth] = useState<number>(0);
    const [showCarousel, setShowCarousel] = useState<boolean>(false);

    const getAvailableColumnsCount = useCallback(() => {
        const widthForOtherPictures =
            picturesContainerWidth - FIRST_PROJECT_PICTURE_SIZE - 2 * PICTURE_MARGIN;
        return Math.floor(widthForOtherPictures / (OTHER_PROJECT_PICTURE_SIZE + 2 * PICTURE_MARGIN));
    }, [picturesContainerWidth]);

    const preparePicturesToRender = useCallback(() => {
        const columnsAvailable = getAvailableColumnsCount();
        const allPictures = [...(project?.projectPictures || [])];

        setFirstPicture(allPictures[0]);

        const otherPictures = allPictures.slice(1);
        let picturesToShow = otherPictures.slice(0, columnsAvailable * 2);
        if (otherPictures.length > picturesToShow.length) {
            picturesToShow = picturesToShow.slice(0, picturesToShow.length - 1);
        }
        setHiddenOtherPicturesCount(otherPictures.length - picturesToShow.length);
        setVisibleOtherPictures(picturesToShow);
    }, [getAvailableColumnsCount, project]);

    useEffect(() => {
        if (width) setPicturesContainerWidth(width);
        if ((project?.projectPictures?.length || 0) > 0) preparePicturesToRender();
    }, [width, preparePicturesToRender, project]);

    const renderPicture = (picture: MediaDto, isFirstImg = false) => (
        <Box
            className={clsx(classes.projectPictureContainer, {
                [classes.projectOtherPictureContainer]: !isFirstImg,
            })}
            key={picture.id}
            onClick={() => setShowCarousel(true)}
        >
            <img
                src={picture.url}
                alt="Project"
                className={clsx(isFirstImg ? classes.projectFirstPictureImg : classes.projectPictureImg)}
            />
        </Box>
    );

    const renderCarousel = () => (
        <Dialog
            fullScreen
            open={showCarousel}
            onClose={() => setShowCarousel(false)}
            classes={{ paperFullScreen: classes.paper }}
        >
            <AppBar className={classes.dialogAppBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Project pictures
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setShowCarousel(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Carousel className={classes.carousel}>
                {project?.projectPictures?.map(({ url, id }) => (
                    <Box key={id}>
                        <img src={url} alt="Large img" className={classes.carouselImg} />
                    </Box>
                ))}
            </Carousel>
        </Dialog>
    );

    return showCarousel ? (
        renderCarousel()
    ) : (
        <div className={classes.container}>
            <Box className={classes.readOnlyContainer}>
                <Box className={classes.firstPictureContainer}>
                    {firstPicture && renderPicture(firstPicture, true)}
                </Box>
                <Box className={classes.otherPicturesContainer}>
                    {visibleOtherPictures && visibleOtherPictures.map(picture => renderPicture(picture))}

                    {hiddenOtherPicturesCount > 0 && (
                        <Box
                            className={classes.moreImagesTileContainer}
                            onClick={() => setShowCarousel(true)}
                        >
                            {hiddenOtherPicturesCount}+
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default ProjectPictures;
