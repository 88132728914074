import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import InfoCard from '../../../../../components/InfoCard';
import { ProjectDetailsDto } from '../../../../../api/data-contracts';
import { useStyles } from './styles';

interface Props {
    project: ProjectDetailsDto;
}

const ProjectInfoCard: FC<Props> = ({ project }) => {
    const classes = useStyles();

    const renderRow = (label: string, value?: string) =>
        value ? (
            <>
                <Grid item xs={12} md={6}>
                    <Typography className={classes.rowLabel}>{label}:</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className={classes.rowValue}>{value}</Typography>
                </Grid>
            </>
        ) : null;

    return (
        <InfoCard>
            {project.projectDetailsLines &&
                project.projectDetailsLines
                    .filter(item => item.name !== 'description')
                    .map(item => (
                        <Grid container>
                            {renderRow(item.name === undefined ? 'N/A' : item.name, item.description)}
                        </Grid>
                    ))}
            {project.projectDateLines &&
                project.projectDateLines.map(item => (
                    <Grid container>
                        {renderRow(
                            item.name === undefined ? 'N/A' : item.name,
                            item.date ? dayjs(item.date).format('DD-MM-YYYY') : '',
                        )}
                    </Grid>
                ))}
        </InfoCard>
    );
};

export default ProjectInfoCard;
