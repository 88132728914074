import React, { FC, useState } from 'react';
import { Button, Grid, InputAdornment, List, TextField as MuiTextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import FormFieldsBox from '../../../../components/FormFieldsBox';
import { useStyles } from './styles';
import RhfCheckbox from '../../../../components/form-fields/RhfCheckbox';
import RhfTextField from '../../../../components/form-fields/RhfTextField';

const TodosTaskFormFields: FC = () => {
    const classes = useStyles();

    const [task, setTask] = useState<string>('');

    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: `taskRequirements`,
    });

    const handleInput = () => {
        if (!isEmpty(task)) {
            append({ ready: false, description: task });
            setTask('');
        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            append({ ready: false, description: task });
            setTask('');
        }
    };

    return (
        <FormFieldsBox title="to do">
            <List className={classes.list}>
                {fields.map((field, index) => (
                    <Grid container key={field.id}>
                        <Grid item xs={1} className={classes.checkboxContainer}>
                            <RhfCheckbox
                                // @ts-ignore
                                name={`taskRequirements.${index}.ready`}
                            />
                        </Grid>
                        <Grid item xs={10} className={classes.itemContainer}>
                            <RhfTextField
                                // @ts-ignore
                                name={`taskRequirements.${index}.description`}
                                multiline
                                variant="filled"
                                rows={2}
                                rowsMax={7}
                                placeholder="add todo"
                            />
                        </Grid>

                        <Grid item xs={1} className={classes.removeContainer}>
                            <Button onClick={() => remove(index)} className={classes.remove}>
                                <ClearIcon />
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </List>

            <MuiTextField
                variant="filled"
                type="text"
                value={task}
                onKeyDown={handleKeyDown}
                onChange={e => setTask(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button className={classes.add} onClick={handleInput}>
                                <AddIcon />
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
        </FormFieldsBox>
    );
};

export default TodosTaskFormFields;
