import { MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CustomSelectProps } from './config';
import { useStyles } from './styles';
import CustomCheckbox from '../CustomCheckbox';

const CustomSelect: FC<CustomSelectProps> = ({
    id,
    icon,
    label,
    inputName,
    defaultValue,
    selectClass,
    selectedClass,
    iconClass,
    paperClass,
    listClass,
    checkboxClass,
    availableData,
    selectedMenuItemClass,
    onChange,
    onChangeSpecial,
    fullWidth,
    disableChange,
    value,
    disabled,
    ...others
}) => {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState<unknown>(defaultValue);

    const onChangeSelect = (e: any) => {
        const { value } = e.target;
        if (onChange) onChange(value);
        setSelectedItem(value);
    };

    return (
        <Select
            variant={others.variant || 'standard'}
            id={id}
            name={inputName}
            disableUnderline
            defaultValue={defaultValue}
            fullWidth={fullWidth}
            autoWidth
            disabled={disabled}
            // @ts-ignore
            IconComponent={icon || ExpandMoreIcon}
            className={others.inputRootClass}
            classes={{
                root: clsx(classes.rootSelect, selectClass),
                select: clsx(classes.selected, selectedClass),
                icon: clsx(classes.icon, iconClass),
            }}
            MenuProps={{
                classes: {
                    paper: clsx(classes.rootSelectMenu, paperClass),
                    list: clsx(classes.menuListSelectMenu, listClass),
                },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                getContentAnchorEl: null,
            }}
            value={value || selectedItem}
            displayEmpty
            onChange={e => {
                if (!disableChange) {
                    if (onChangeSpecial) {
                        onChangeSpecial(e);
                    }
                    onChangeSelect(e);
                }
            }}
            // @ts-ignore
            renderValue={() => {
                if (value) {
                    // @ts-ignore
                    return availableData.find(item => value === item?.id)?.name;
                }
                // @ts-ignore
                return availableData.find(item => selectedItem === item?.id)?.name;
            }}
        >
            {availableData?.map((item, itemIndex) => (
                <MenuItem
                    key={itemIndex}
                    // @ts-ignore
                    value={item?.id}
                    disableRipple
                    classes={{
                        selected: clsx(classes.selectedItem, selectedMenuItemClass),
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CustomCheckbox
                            classes={{
                                root: clsx(classes.checkbox, checkboxClass),
                            }}
                            disableRipple
                            // @ts-ignore
                            checked={value ? value === item?.id : selectedItem === item?.id}
                        />

                        <div>
                            {
                                // @ts-ignore
                                item?.name
                            }
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Select>
    );
};

export default CustomSelect;
