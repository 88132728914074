import { FC } from 'react';
import { useStyles } from './styles';
import CustomSelect from '../CustomSelect';
import { Filter } from '../../models/Filter';

interface Props {
    id: string;
    name: string;
    filterName: string;
    onChange: (newSelection: number) => void;
    availableFilters: Filter[];
    onClose?: () => void;
    value?: number | string | Filter;
}

const FilterSelectUniqueButton: FC<Props> = ({
    id,
    name,
    filterName,
    onChange,
    availableFilters,
    onClose,
    value,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <CustomSelect
                id={id}
                inputName={name}
                defaultValue={value || 1}
                availableData={availableFilters}
                // @ts-ignore
                onChangeSpecial={onChange}
                value={value}
            />
        </div>
    );
};

export default FilterSelectUniqueButton;
