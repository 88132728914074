import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    rowLabel: {
        color: '#708393',
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 'bold',
    },
    rowValue: {
        color: 'white',
        fontSize: '1.375rem',
        lineHeight: 1.2,
        fontWeight: 600,
        marginBottom: '1.25rem',
        overflowWrap: 'break-word',
    },
});
