import { FC, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import FormFieldsBox from '../../../../components/FormFieldsBox';
import RhfSelect from '../../../../components/form-fields/RhfSelect';
import { RootReducer, TasksReducer } from '../../../../models/Redux';

const PersonsTaskFormFields: FC = () => {
    const { disciplineAll, employeeAssigned } = useSelector<RootReducer, TasksReducer>(state => state.tasks);

    const { control } = useFormContext();

    const { fields, append } = useFieldArray({
        control,
        name: `assignedEmployees`,
    });

    const value = useWatch({
        name: 'assignedEmployees',
        control,
    });

    useEffect(() => {
        if (!value.length || (value.length && value[value.length - 1].userId)) {
            append({ disciplineId: 0, userId: 0 });
        }
    }, [append, value]);

    const showUserField = useCallback(
        (index: number) => {
            return index < value.length - 1 || (value.length > 0 && value[value.length - 1].disciplineId > 0);
        },
        [value],
    );

    return (
        <FormFieldsBox title="discipline & person(s)">
            {fields.map((field, index) => (
                <Grid container spacing={2} key={field.id}>
                    <Grid item xs={12} md={6}>
                        <RhfSelect
                            // @ts-ignore
                            name={`assignedEmployees.${index}.disciplineId`}
                            variant="filled"
                            placeholder="Choose discipline"
                            options={disciplineAll.map(({ id, name }) => ({
                                value: parseInt(id.toString(), 10),
                                label: name,
                            }))}
                        />
                    </Grid>
                    {showUserField(index) && (
                        <Grid item xs={12} md={6}>
                            <RhfSelect
                                // @ts-ignore
                                name={`assignedEmployees.${index}.userId`}
                                variant="filled"
                                placeholder="Choose person"
                                options={employeeAssigned.map(({ id, name, avatar }) => ({
                                    value: parseInt(id.toString(), 10),
                                    avatar,
                                    label: name,
                                }))}
                            />
                        </Grid>
                    )}
                </Grid>
            ))}
        </FormFieldsBox>
    );
};

export default PersonsTaskFormFields;
