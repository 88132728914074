import { DisciplineController } from '../api/DisciplineController';
import {
    ConformityDto,
    ConformityUpdateRequestDto,
    CreateTaskRequestDto,
    DictionaryEntryDto,
    EmployeeDiscipline,
    PageOfConformityRowDto,
    PageOfTaskAuditDto,
    TaskDto,
    UpdateTaskRequestDto,
} from '../api/data-contracts';
import { ProjectController } from '../api/ProjectController';
import { TaskStatusController } from '../api/TaskStatusController';
import { EmployeeController } from '../api/EmployeeController';
import { TaskController } from '../api/TaskController';
import { TaskFilterQuery } from '../models/TaskFilterQuery';
import { TaskTypeController } from '../api/TaskTypeController';
import { Task, TaskResult } from '../models/Task';
import { TaskStatusValues } from '../models/enums/TaskStatusValues';
import { ConformityController } from '../api/ConformityController';
import { ContractTypeController } from '../api/ContractTypeController';
import { Conformity } from '../models/Conformity';
import { ShowActiveQuery } from '../models/ShowActiveQuery';
import { TaskAuditController } from '../api/TaskAuditController';

export const getDisciplineFilters = async (query: ShowActiveQuery): Promise<unknown> => {
    const disciplinesApi = new DisciplineController();

    const { data } = await disciplinesApi.apiV1DisciplinesViewsDictionaryUsingGet(query);

    return (data as unknown) as DictionaryEntryDto[];
};

export const getConformityStatues = async (): Promise<unknown> => {
    const conformityController = new ConformityController();

    const { data } = await conformityController.apiV1TasksConformitiesStatusesViewsDictionaryUsingGet();

    return (data as unknown) as DictionaryEntryDto[];
};

export const getDisciplineToProject = async (): Promise<unknown> => {
    const disciplinesApi = new DisciplineController();

    const { data } = await disciplinesApi.apiV1DisciplinesViewsDictionaryMappedUsingGet();

    return (data as unknown) as EmployeeDiscipline[];
};

export const getProjectFilters = async (): Promise<unknown> => {
    const projectsApi = new ProjectController();

    const { data } = await projectsApi.apiV1ProjectsViewsDictionaryUsingGet();

    return (data as unknown) as DictionaryEntryDto[];
};

export const getStatusFilters = async (): Promise<unknown> => {
    const taskStatusApi = new TaskStatusController();

    const { data } = await taskStatusApi.apiV1TaskStatusesViewsDictionaryUsingGet();

    return (data as unknown) as DictionaryEntryDto[];
};

export const getEmployeesAssignedFilters = async (query: ShowActiveQuery): Promise<DictionaryEntryDto[]> => {
    const employeesAssignedApi = new EmployeeController();

    const { data } = await employeesAssignedApi.apiV1EmployeesUsingGet(query);
    return data.map(employee => ({
        id: employee.id,
        name: employee.name,
        avatar: employee.avatar,
    }));
};

export const getTaskTypesFilters = async (): Promise<unknown> => {
    const taskTypesApi = new TaskTypeController();

    const { data } = await taskTypesApi.apiV1TaskTypesViewsDictionaryUsingGet();

    return (data as unknown) as DictionaryEntryDto[];
};

export const getContractTypesFilters = async (): Promise<unknown> => {
    const contractTypeController = new ContractTypeController();

    const { data } = await contractTypeController.apiV1ContracttypeViewsDictionaryUsingGet();

    return (data as unknown) as DictionaryEntryDto[];
};

export const getTasks = async (query: TaskFilterQuery): Promise<unknown> => {
    const tasksApi = new TaskController();

    const {
        data: { content, totalPages },
    } = await tasksApi.apiV1TasksUsingGet(query);

    return ({ content, totalPages } as unknown) as TaskResult;
};

export const getTasksByID = async (id: number): Promise<TaskDto> => {
    const tasksApi = new TaskController();

    const { data } = await tasksApi.apiV1TasksIdUsingGet(id);
    return data;
};

export const updateTaskStatus = async (id: number, status: TaskStatusValues): Promise<unknown> => {
    const tasksApi = new TaskController();

    const { data } = await tasksApi.apiV1TasksIdStatusUsingPatch(id, { statusId: status });

    return (data as unknown) as Task;
};

export interface ConformitiesQuery {
    id: number;
    projects?: number[];
    contractTypes?: number[];
    articles?: string[];
    previousStatuses?: number[];
    currentStatuses?: number[];
    search?: string;
    week?: number;
    month?: number;
    year?: number;
    page?: number;
}

export const getTasksConformities = async (query: ConformitiesQuery): Promise<PageOfConformityRowDto> => {
    const conformityApi = new ConformityController();

    const { data } = await conformityApi.apiV1TasksIdConformitiesUsingGet(query.id, query);
    return data;
};

export const getConformityArticleFilters = async (): Promise<string[]> => {
    const conformityApi = new ConformityController();

    const { data } = await conformityApi.apiV1TasksConformitiesArticlesUsingGet();
    return data;
};

export const getTaskConformityById = async (conformityId: number, taskId: number): Promise<unknown> => {
    const conformityApi = new ConformityController();

    const { data } = await conformityApi.apiV1TasksIdConformitiesConfidUsingGet(conformityId, taskId);

    return data as Conformity;
};

export const createTask = async (taskBody: CreateTaskRequestDto): Promise<TaskDto> => {
    const tasksApi = new TaskController();

    const { data } = await tasksApi.apiV1TasksUsingPost(taskBody);
    return data;
};

export const updateTask = async (taskBody: { id: number } & UpdateTaskRequestDto): Promise<TaskDto> => {
    const tasksApi = new TaskController();

    const { data } = await tasksApi.apiV1TasksIdUsingPut(taskBody.id, taskBody);
    return data;
};

export const getTaskAudit = async (taskId: number, page: number): Promise<PageOfTaskAuditDto> => {
    const taskAuditApi = new TaskAuditController();

    const { data } = await taskAuditApi.apiV1TasksTaskidAuditsUsingGet(taskId, {
        page,
        size: 5,
        sorted: true,
    });

    return (data as unknown) as PageOfTaskAuditDto;
};

export const updateConformity = async (
    confId: number | undefined,
    taskId: number | undefined,
    dto: ConformityUpdateRequestDto,
): Promise<ConformityDto> => {
    const conformityApi = new ConformityController();

    const { data } = await conformityApi.apiV1TasksIdConformitiesConfidUsingPut(
        confId as number,
        taskId as number,
        dto,
    );
    return (data as unknown) as ConformityDto;
};

export const confirmAllConformities = async (
    taskId: number | undefined,
    query?: {
        articles?: string[];
        contractTypes?: number[];
        currentStatuses?: number[];
        month?: number;
        offset?: number;
        paged?: boolean;
        pageNumber?: number;
        pageSize?: number;
        previousStatuses?: number[];
        projects?: number[];
        search?: string;
        'sort.sorted'?: boolean;
        'sort.unsorted'?: boolean;
        unpaged?: boolean;
        week?: number;
        year?: number;
    },
): Promise<unknown> => {
    const conformityApi = new ConformityController();

    const { data } = await conformityApi.apiV1TasksIdConformitiesUsingPut(taskId as number, query);
    return (data as unknown) as ConformityDto;
};

export const updateTaskRequirementStatus = async (
    taskRequirementId: number,
    ready: boolean,
): Promise<void> => {
    const taskApi = new TaskController();
    await taskApi.apiV1TasksTodoIdStatusUsingPut(taskRequirementId, { ready });
};
