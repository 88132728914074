import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import TaskFormFields from '../TaskFormFields';
import { queryKeys } from '../../../react-query/constants';
import { TaskDto } from '../../../api/data-contracts';
import { createTask, updateTask } from '../../../services/TasksService';
import DeadlineChangeReasonDialog from '../../DashboardPage/Parts/DeadlineChangeReasonDialog';

interface Props {
    task?: TaskDto;
    submitForm: boolean;
    setSubmitForm: Dispatch<SetStateAction<boolean>>;
    setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

const TaskForm: FC<Props> = ({ task, submitForm, setSubmitForm, setIsSubmitting }) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [deadlineRemarksDialogOpen, setDeadlineRemarksDialogOpen] = useState<boolean>(false);

    const formRef = useRef<HTMLFormElement>(null);

    const FormSchema = z
        .object({
            name: z.string().trim().min(1, 'Required field').max(100, 'Maximum length is 100 characters'),
            description: z.string().trim().max(2000, 'Maximum length is 2000 characters'),
            projectId: z.number().min(1, 'Required field'),
            assignedEmployees: z
                .object({
                    userId: z.number(),
                    disciplineId: z.number(),
                })
                .refine(
                    ({ disciplineId, userId }) => (!disciplineId && !userId) || (disciplineId && userId),
                    {
                        message: 'Required field',
                        path: ['userId'],
                    },
                )
                .array(),
            contractType: z.number().min(1, 'Required field'),
            deadline: z.string().nullable(),
            deadlineRemarks: z.string(),
            startDate: z.string().nullable(),
            remarks: z.string().trim().max(1000, 'Maximum length is 1000 characters'),
            statusId: z.number().min(1, 'Required field'),
            completed: z.string().optional().nullable(),
            completedBy: z.number().optional().nullable(),
            taskRequirements: z
                .object({
                    id: z.number().optional(),
                    description: z.string().trim().max(255, 'Maximum length is 255 characters'),
                    ready: z.boolean().optional(),
                })
                .array(),
        })
        .refine(
            ({ assignedEmployees }) => {
                return !(
                    !assignedEmployees.length ||
                    (assignedEmployees.length === 1 &&
                        (!assignedEmployees[0].userId || !assignedEmployees[0].disciplineId))
                );
            },
            {
                message: 'Please assign at least one discipline and employee',
                path: ['assignedEmployees.0.disciplineId'],
            },
        )
        .refine(
            ({ startDate, completed }) => {
                return !(startDate && completed && dayjs(startDate) > dayjs(completed));
            },
            {
                message: 'Start date must be earlier than completion date',
                path: ['startDate'],
            },
        )
        .refine(
            ({ startDate, deadline }) => {
                return !(startDate && deadline && dayjs(startDate) > dayjs(deadline));
            },
            {
                message: 'Start date must be earlier than deadline',
                path: ['startDate'],
            },
        )
        .refine(
            ({ deadline, deadlineRemarks }) =>
                !task?.id ||
                deadlineRemarks?.length > 0 ||
                (dayjs(deadline || undefined).format('YYYY-MM-DD') === task?.deadline
                    ? dayjs(task.deadline).format('YYYY-MM-DD')
                    : undefined),
            () => {
                setDeadlineRemarksDialogOpen(true);

                return {
                    message: 'Required field',
                    path: ['deadlineRemarks'],
                };
            },
        );

    type FormSchemaType = z.infer<typeof FormSchema>;

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            name: task?.name || '',
            description: task?.description || '',
            projectId: task?.projectId || 0,
            assignedEmployees:
                task?.assignedEmployees?.map(({ id, discipline }) => ({
                    userId: id,
                    disciplineId: discipline?.id,
                })) || [],
            contractType: task?.contractType?.id || 0,
            deadline: task?.deadline || null,
            deadlineRemarks: '',
            startDate: task?.started || null,
            remarks: task?.remarks || '',
            statusId: task?.status?.id || 0,
            completed: task?.completed || null,
            completedBy: task?.completedBy?.id,
            taskRequirements:
                task?.taskRequirements?.map(({ id, description, ready }) => ({
                    id,
                    description,
                    ready,
                })) || [],
        },
    });

    const taskMutation = useMutation(
        (formValues: FormSchemaType) =>
            // @ts-ignore
            task?.id ? updateTask({ id: task.id, ...formValues }) : createTask(formValues),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([queryKeys.task]);
                await queryClient.invalidateQueries([queryKeys.tasks]);

                enqueueSnackbar(task?.id ? 'Task updated' : 'Task created', { variant: 'success' });

                history.goBack();
            },
            onSettled: () => {
                setDeadlineRemarksDialogOpen(false);
            },
        },
    );

    const onSubmit: SubmitHandler<FormSchemaType> = async (formValues: FormSchemaType) => {
        setIsSubmitting(true);
        taskMutation.mutate({
            ...formValues,
            completed: formValues.completed ? dayjs(formValues.completed).format('YYYY-MM-DD') : undefined,
            startDate: dayjs(formValues.startDate || undefined).format('YYYY-MM-DD'),
            deadline: dayjs(formValues.deadline || undefined).format('YYYY-MM-DD'),
            assignedEmployees: formValues.assignedEmployees.filter(
                ({ disciplineId, userId }) => disciplineId && userId,
            ),
        });
    };

    const handleSubmit = () => {
        formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    };

    const handleClose = () => {
        setDeadlineRemarksDialogOpen(false);
        methods.setValue('deadlineRemarks', '');
    };

    const handleCancel = () => {
        setDeadlineRemarksDialogOpen(false);
        methods.setValue('deadlineRemarks', '');
    };

    useEffect(() => {
        if (submitForm && formRef && !deadlineRemarksDialogOpen) {
            formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            setSubmitForm(false);
        }
    }, [deadlineRemarksDialogOpen, setSubmitForm, submitForm]);

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
                <TaskFormFields isEditing={!!task?.id} />
                <DeadlineChangeReasonDialog
                    open={deadlineRemarksDialogOpen}
                    handleCloseDialog={handleClose}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    disabled={taskMutation.isLoading}
                />
            </form>
        </FormProvider>
    );
};

export default TaskForm;
