import { FC } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { Box, CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { queryKeys } from '../../react-query/constants';
import { getUserById } from '../../services/UserService';
import UserContact from './UserContact';
import BackButton from '../../components/BackButton';
import UserActions from './UserActions';
import UserCard from './UserCard';

const UserInfoPage: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { state } = useLocation<{ startDate?: string; endDate?: string }>();

    const { data: user, isLoading } = useQuery(
        [queryKeys.users, id],
        () => getUserById(parseInt(id as string, 10), state?.startDate, state?.endDate),
        {
            enabled: !!id,
        },
    );

    return (
        <Box width="100%">
            <BackButton label="back to users" />
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mt="40px"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <UserContact user={user} />
                        <UserActions user={user} />
                    </Box>

                    <UserCard user={user} key={`user${user?.id}`} />
                </Box>
            )}
        </Box>
    );
};

export default UserInfoPage;
