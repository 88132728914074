import { ChangeEvent, Dispatch, FC, MouseEvent, SetStateAction, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { ProjectDetailsDto } from '../../../api/data-contracts';
import { useStyles } from './styles';
import ProjectDetailsTabsButtons from '../ProjectDetailsTabsButtons';
import { uploadMediaFile } from '../../../services/MediaService';
import Media from '../../../models/Media';
import { queryKeys } from '../../../react-query/constants';
import { removeProjectLogo, updateProjectLogo } from '../../../services/ProjectService';

interface Props {
    editMode?: boolean;
    project: ProjectDetailsDto | undefined;
    selectedTab: number;
    setSelectedTab: Dispatch<SetStateAction<number>>;
}

const ProjectTitle: FC<Props> = ({ editMode, project, selectedTab, setSelectedTab }) => {
    const classes = useStyles();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const inputRef = useRef<HTMLInputElement>(null);

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [showDeleteAvatarButton, setShowDeleteAvatarButton] = useState<boolean>(false);
    const [showUploadAvatarButton, setShowUploadAvatarButton] = useState<boolean>(false);

    const onMouseEnter = () => {
        if (editMode) {
            setShowUploadAvatarButton(true);
            setShowDeleteAvatarButton(true);
        }
    };

    const onMouseLeave = () => {
        setShowUploadAvatarButton(false);
        setShowDeleteAvatarButton(false);
    };

    const uploadLogo = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length && e.target.files?.[0]) {
            if (e.target.files[0].size > 10000000) {
                enqueueSnackbar('Maximum file size is 10MB', { variant: 'error' });
                return;
            }

            setIsUploading(true);
            setShowUploadAvatarButton(false);
            setShowDeleteAvatarButton(false);

            const uploadImage = (await uploadMediaFile(e.target.files[0])) as Media;

            if (uploadImage?.id) {
                await updateProjectLogo(project?.id as number, uploadImage.id);
                await queryClient.invalidateQueries([queryKeys.project]);
                await queryClient.invalidateQueries([queryKeys.projects]);
                setIsUploading(false);
            }
        }
    };
    const deleteLogo = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsUploading(true);
        setShowUploadAvatarButton(false);
        setShowDeleteAvatarButton(false);

        await removeProjectLogo(project?.id as number);
        await queryClient.invalidateQueries([queryKeys.project]);
        await queryClient.invalidateQueries([queryKeys.projects]);

        setIsUploading(false);
    };

    const renderLogo = () =>
        isUploading ? (
            <CircularProgress />
        ) : project?.projectLogo ? (
            <img
                alt="logo"
                src={project.projectLogo}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
        ) : null;

    if (!project) return null;

    return (
        <Box display="flex" flexDirection="row" alignItems="center" mb={5}>
            <Box
                className={clsx(classes.logo, { [classes.mouseOver]: showUploadAvatarButton })}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {showUploadAvatarButton ? (
                    <Button
                        onClick={() => inputRef.current?.click()}
                        style={{ color: 'white', fontSize: 16, lineHeight: 1.1, fontWeight: 700 }}
                    >
                        upload picture
                        <Box display="flex" flexDirection="column" alignItems="center" ml={1}>
                            <ArrowUpwardOutlinedIcon className={classes.uploadAvatarIcon} />
                            <Box className={classes.uploadAvatarIconWrapper} />
                        </Box>
                    </Button>
                ) : (
                    renderLogo()
                )}

                {showDeleteAvatarButton && project?.projectLogo && (
                    <Button onClick={e => deleteLogo(e)} className={classes.deleteAvatarButton}>
                        <CloseIcon className={classes.deleteAvatarIcon} />
                    </Button>
                )}

                <input
                    type="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    accept="image/jpeg, image/png"
                    onChange={uploadLogo}
                />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                <Typography className={classes.projectTitleLabel}>project:</Typography>
                <Typography className={classes.projectTitle}>{project.projectName}</Typography>
                <ProjectDetailsTabsButtons selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            </Box>
        </Box>
    );
};

export default ProjectTitle;
