import { Dispatch, FC, SetStateAction } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

interface Props {
    selectedTab: number;
    setSelectedTab: Dispatch<SetStateAction<number>>;
}

const ProjectDetailsTabsButtons: FC<Props> = ({ selectedTab, setSelectedTab }) => {
    const classes = useStyles();

    const tabs = ['overview', 'persons'];

    return (
        <Tabs value={selectedTab} onChange={(event, value: number) => setSelectedTab(value)}>
            {tabs.map((tab: string, index: number) => (
                <Tab
                    key={index}
                    label={tab}
                    className={clsx(classes.tabButton, {
                        [classes.activeTabButton]: selectedTab === index,
                    })}
                />
            ))}
        </Tabs>
    );
};

export default ProjectDetailsTabsButtons;
