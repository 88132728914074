import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    contactName: {
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 600,
    },
    companyName: {
        color: '#E65216',
        fontSize: '1rem',
        lineHeight: 1.4,
    },
    emailOrPhoneNumber: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.375rem',
        marginTop: '0.375rem',
    },
    contactIcon: {
        fontSize: '1.25rem',
        color: '#708393',
    },
    emailOrPhoneNumberText: {
        fontSize: '1.125rem',
        lineHeight: 1.2,
        fontWeight: 600,
        alignItems: 'center',
        gap: '0.375rem',
    },
    divider: {
        width: '100%',
        backgroundColor: '#454c51',
        marginTop: '0.875rem',
        marginBottom: '0.875rem',
        height: '0.125rem',
    },
});
