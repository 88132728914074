import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import companyLogo from '../../assets/img/company/rivoirLogo.png';

import { useStyles } from './styles';

const CompanyLogo: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.logoContainer}>
            <img className={classes.companyLogo} src={companyLogo} alt={t('Rivoir logo')} />
            <Typography className={classes.logoText}>{t('CMore Control Better')}</Typography>
        </div>
    );
};

export default CompanyLogo;
