import { Pagination as MuiPagination, PaginationItem } from '@material-ui/lab';

import { Table } from '@tanstack/react-table';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import CustomSelect from '../../CustomSelect';

interface Props<V> {
    table: Table<V>;
    pagesCount: number;
    pageIndex: number;
    pageSize: number;
    handlePageChange: (page: number, rowsPerPage: number) => void;
}

const Pagination = <V,>({ pageIndex, pagesCount, pageSize, handlePageChange }: Props<V>) => {
    const classes = useStyles();
    const { search } = useLocation();

    const handleChangeRowsPerPage = (pageSize: number) => {
        handlePageChange(1, pageSize);
    };

    const goToPage = (page: number) => {
        const searchParams = new URLSearchParams(search);

        handlePageChange(
            page,
            searchParams.get('size') ? parseInt(searchParams.get('size') || '10', 10) : 10,
        );
    };

    return (
        <Grid
            container
            item
            xs={12}
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
            className={classes.paginationContainer}
        >
            <Grid item xs={2} />
            <Grid
                container
                item
                xs={8}
                direction="row"
                justify="center"
                alignContent="center"
                alignItems="center"
            >
                <MuiPagination
                    count={pagesCount}
                    page={pageIndex + 1}
                    onChange={(e, pageIndex) => goToPage(pageIndex)}
                    renderItem={item => (
                        <PaginationItem
                            {...item}
                            classes={{
                                root: classes.paginationElement,
                                page: classes.page,
                                selected: classes.selectedPage,
                                ellipsis: classes.paginationEllipsis,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid
                container
                item
                xs={2}
                direction="row"
                justify="flex-end"
                alignContent="center"
                alignItems="center"
            >
                <CustomSelect
                    id="page-size"
                    inputName="page-size"
                    label="items"
                    defaultValue={pageSize}
                    availableData={[
                        { id: 10, name: '10 items' },
                        { id: 25, name: '25 items' },
                        { id: 50, name: '50 items' },
                    ]}
                    selectClass={classes.rootSelect}
                    selectedClass={classes.selectedItemPagination}
                    onChange={(args: unknown) => handleChangeRowsPerPage(args as number)}
                    iconClass={classes.iconClass}
                />
            </Grid>
        </Grid>
    );
};

export default Pagination;
