import { FC, useEffect, useState } from 'react';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import dayjs from 'dayjs';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AuditIcon from '@material-ui/icons/Timelapse';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { DATE_FORMAT } from '../../config/constants';
import { useStyles } from './styles';
import { TaskStatusValues } from '../../models/enums/TaskStatusValues';
import { updateTaskStatus } from '../../services/TasksService';
import { getBackendErrorMessage } from '../../config/utils';
import { CurrentUserReducer, RootReducer } from '../../models/Redux';
import { UserRoles } from '../../models/enums/UserRoles';
import TaskDetailsCollapse from './parts/TaskDetailsCollapse';
import TaskAuditCollapse from './parts/TaskAuditCollapse';
import CustomCheckbox from '../../components/CustomCheckbox';
import { queryKeys } from '../../react-query/constants';
import { ConformityDto, TaskDto, TaskRowDto } from '../../api/data-contracts';

interface Props {
    taskRow: TaskRowDto;
    openConformity: (task: TaskDto | undefined, conformities: ConformityDto[] | undefined) => void;
    hideDescription?: boolean;
    contentRowClass?: string;
}

const TaskTableRow: FC<Props> = ({ taskRow, openConformity, hideDescription, contentRowClass }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const history = useHistory();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [showAudit, setShowAudit] = useState<boolean>(false);
    const { user } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(taskRow?.status !== 'Open');

    useEffect(() => {
        setCheckboxChecked(taskRow?.status !== 'Open');
    }, [taskRow]);

    const classes = useStyles({ showDetails, showAudit });

    const editTask = () => {
        history.push(`/tasks/form/${taskRow.id}`);
    };

    const handleToggleAudit = () => {
        setShowAudit(!showAudit);
        setShowDetails(false);
    };

    const handleShowDetails = () => {
        setShowDetails(!showDetails);
        setShowAudit(false);
    };

    return (
        <Grid container direction="column">
            <Paper elevation={0} className={clsx(classes.paperBackground, contentRowClass)}>
                <Grid container item xs={12} direction="row" alignContent="center" alignItems="center">
                    <Grid container item xs={!hideDescription ? 4 : 5} alignItems="center">
                        <div
                            className={classes.colorIndicator}
                            style={{
                                backgroundColor: `#${taskRow.color}`,
                            }}
                        />
                        <Typography className={classes.taskTitle}>{taskRow?.name}</Typography>
                    </Grid>
                    {!hideDescription && (
                        <Grid container item xs={1}>
                            <Typography className={classes.taskDescription}>
                                {taskRow?.projectName}
                            </Typography>
                        </Grid>
                    )}
                    <Grid container item xs={2}>
                        <Typography className={classes.taskProject}>
                            {taskRow?.assignedEmployees?.map((employee: string, i) => {
                                let employeeName = employee;
                                if (i + 1 < (taskRow?.assignedEmployees?.length || 0)) {
                                    employeeName += ', ';
                                }
                                return employeeName;
                            })}
                        </Typography>
                    </Grid>

                    <Grid container item xs={3} direction="row" justify="center">
                        <Grid item xs={6}>
                            {taskRow?.started && (
                                <Typography
                                    className={clsx(
                                        classes.dateField,
                                        classes.fullWidthCentered,
                                        classes.fullWidthCentered,
                                    )}
                                >
                                    {dayjs(taskRow.started).format(DATE_FORMAT)}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {taskRow?.deadline && (
                                <Typography
                                    className={clsx(
                                        classes.dateField,
                                        classes.fullWidthCentered,
                                        !checkboxChecked && dayjs(taskRow.deadline).isBefore(dayjs())
                                            ? classes.dateFailed
                                            : '',
                                    )}
                                >
                                    {dayjs(taskRow.deadline).format(DATE_FORMAT)}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>

                    {(user?.role?.includes(UserRoles.ADMIN) ||
                        taskRow?.assignedEmployees?.find((employee: string) => employee === user?.name)) && (
                        <Grid
                            container
                            item
                            xs={!hideDescription ? 2 : 3}
                            justify="flex-end"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Tooltip title="Close task">
                                <CustomCheckbox
                                    tooltip="Close task"
                                    checked={checkboxChecked}
                                    disabled={
                                        !user?.role?.includes(UserRoles.ADMIN) &&
                                        !taskRow?.assignedEmployees?.find(
                                            (employee: string) => employee === user?.name,
                                        )
                                    }
                                    onClick={() => {
                                        const nextStatus = checkboxChecked
                                            ? TaskStatusValues.OPEN
                                            : TaskStatusValues.CLOSED;

                                        updateTaskStatus(taskRow.id as number, nextStatus)
                                            .then(() => {
                                                enqueueSnackbar(t('Status updated '), { variant: 'success' });
                                                queryClient.invalidateQueries([queryKeys.task, taskRow.id]);
                                                setCheckboxChecked(nextStatus === TaskStatusValues.CLOSED);
                                            })
                                            .catch(error => {
                                                enqueueSnackbar(
                                                    t(`errorsEn:${getBackendErrorMessage(error)}`),
                                                    {
                                                        variant: 'error',
                                                    },
                                                );
                                            });
                                    }}
                                    disableRipple
                                />
                            </Tooltip>

                            {(user?.role?.includes(UserRoles.ADMIN) ||
                                user?.role?.includes(UserRoles.EMPLOYEE) ||
                                user?.role?.includes(UserRoles.FREELANCER)) && (
                                <IconButton
                                    aria-label="edit"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={editTask}
                                >
                                    <Tooltip title="Edit task">
                                        <EditIcon
                                            className={clsx(classes.actionIcon, classes.actionButton)}
                                        />
                                    </Tooltip>
                                </IconButton>
                            )}

                            <IconButton
                                aria-label="deadline-remarks"
                                className={classes.actionIconButton}
                                disableRipple
                                onClick={handleToggleAudit}
                            >
                                <Tooltip title="Audit trial">
                                    <AuditIcon className={clsx(classes.actionIcon, classes.actionButton)} />
                                </Tooltip>
                            </IconButton>

                            <IconButton
                                aria-label="more"
                                className={classes.actionIconButton}
                                disableRipple
                                onClick={handleShowDetails}
                            >
                                <Tooltip title="Open task">
                                    <MoreVertIcon className={clsx(classes.actionButton, classes.more)} />
                                </Tooltip>
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Paper>

            <TaskDetailsCollapse
                taskId={taskRow.id}
                showDetails={showDetails}
                showAudit={showAudit}
                openConformity={openConformity}
                handleShowDetails={handleShowDetails}
            />

            <TaskAuditCollapse
                showAudit={showAudit}
                showDetails={showDetails}
                handleCloseTaskAudit={() => setShowAudit(false)}
                taskId={taskRow.id as number}
            />
        </Grid>
    );
};

export default TaskTableRow;
