import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    addTaskButton: {
        margin: '0.313rem',
    },
    tableHeaderTitle: {
        color: 'white',
        marginTop: '1.25rem',
        marginRight: '2.438rem',
    },
    nextPreviousIcon: {
        width: '0.938rem',
        height: '0.938rem',
        color: '#E65216',
    },
    headerText: {
        fontWeight: 'bold',
        color: '#FFFFFF',
        textAlign: 'center',
        padding: '0.938rem',
        opacity: 1,
    },
    headerTextDate: {
        width: '100%',
        textAlign: 'center',
    },
    actionIconButton: {
        padding: '0.938rem',
        color: 'white',
    },
    sortButton: {
        color: 'white',
        textTransform: 'none',
    },
    rootSelect: {
        color: 'white',
        backgroundColor: '#13212D',
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
        paddingLeft: '0px!important',
        '&:focus': {
            padding: 0,
            paddingLeft: 0,
        },
    },
    selectedItem: {
        color: 'white',
        padding: 0,
        paddingLeft: '0px!important',
        fontSize: '1rem',
        fontWeight: 400,
    },
    iconClass: {
        color: 'white !important',
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',

        marginRight: 0,
        overflowX: 'auto',
        overflowY: 'hidden',
        flexWrap: 'initial',
        maxHeight: '4.25rem',
        paddingBottom: '0.625rem',
        width: '100%',
    },
    chipClass: {
        margin: '0.313rem 0.156rem',
    },
    exportPDFButton: {
        padding: '1.25rem 0.625rem',
        margin: ' 0px 0.625rem',
        width: '9.75rem',
        height: '2.625rem',
        backgroundColor: '#E65216',
        textTransform: 'lowercase',
        '& span': {
            fontSize: '1.125rem',
        },
    },
    generatingPDFLoader: {
        minHeight: '12.5rem',
    },
    generatingPDFLoaderText: {
        padding: '1.25rem 0px',
        color: '#13212D',
    },
});
