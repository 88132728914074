import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Box, FormControl, FormControlProps, FormHelperText, MenuItem, Select } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isUndefined } from 'lodash';

interface SelectOption {
    value: number | string;
    label: string;
    avatar?: string;
}

type Props = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
    options: SelectOption[];
} & FormControlProps;

const RhfSelect: FC<Props> = ({ name, options, placeholder, disabled, ...muiProps }) => {
    const renderAvatar = (option: SelectOption) =>
        !isUndefined(option.avatar) &&
        (option.avatar ? (
            <img
                src={`${process.env.REACT_APP_BLOB_PATH}${option.avatar}`}
                alt="avatar"
                style={{ width: 20, height: 20, borderRadius: 20 }}
            />
        ) : (
            <Box
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    backgroundColor: '#2a3643',
                }}
            />
        ));

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl fullWidth error={!!error} {...muiProps}>
                    <Select
                        {...field}
                        disabled={isSubmitting || disabled || !options.length}
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        renderValue={value =>
                            value ? (
                                options.find(option => option.value === value)?.label || 'select'
                            ) : (
                                <div style={{ color: '#748291' }}>{placeholder || 'select'}</div>
                            )
                        }
                    >
                        {options.map((option, key) => (
                            <MenuItem key={key} value={option.value}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 10,
                                    }}
                                >
                                    {renderAvatar(option)}
                                    {option.label}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                    {!!error && <FormHelperText>{error?.message || ''}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};

export default RhfSelect;
