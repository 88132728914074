import React, { FC, Fragment, useMemo } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useStyles } from '../../ProjectDetailsPage/styles';
import TabPanel from '../../ProjectDetailsPage/tabs/TabPanel';
import InfoCard from '../../../components/InfoCard';
import RhfTextField from '../../../components/form-fields/RhfTextField';
import RhfDatePicker from '../../../components/form-fields/RhfDatePicker';
import { PROJECT_FORM_DATE_FORMAT } from '../../../config/constants';
import ProjectPicturesForm from '../ProjectPicturesForm';

interface Props {
    selectedTab: number;
    index: number;
}

const OverviewTabForm: FC<Props> = ({ selectedTab, index }) => {
    const classes = useStyles();

    const { control } = useFormContext();

    const { fields: detailsFields } = useFieldArray({
        control,
        name: `projectDetailsLines`,
    });

    const { fields: dateFields } = useFieldArray({
        control,
        name: `projectDateLines`,
    });

    const renderLabel = (label: string) => (
        <Typography className={classes.rowLabel} style={{ marginTop: 10 }}>
            {label}:
        </Typography>
    );

    return (
        <TabPanel value={selectedTab} index={index}>
            <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                    <InfoCard>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                {renderLabel('project')}
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <RhfTextField name={`projectName` as `${string}`} variant="filled" />
                            </Grid>

                            {detailsFields.map((field, index) => {
                                return (
                                    <Fragment key={field.id}>
                                        <Grid item xs={12} md={4}>
                                            {
                                                // @ts-ignore
                                                renderLabel(field.name)
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <RhfTextField
                                                name={
                                                    `projectDetailsLines.${index}.description` as `${string}.${string}`
                                                }
                                                variant="filled"
                                            />
                                        </Grid>
                                    </Fragment>
                                );
                            })}

                            {dateFields.map((field, index) => {
                                return (
                                    <Fragment key={field.id}>
                                        <Grid item xs={12} md={4}>
                                            {
                                                // @ts-ignore
                                                renderLabel(field.name)
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <RhfDatePicker
                                                name={
                                                    `projectDateLines.${index}.date` as `${string}.${string}`
                                                }
                                                inputVariant="filled"
                                                format={PROJECT_FORM_DATE_FORMAT}
                                                InputProps={{ endAdornment: <DateRangeIcon /> }}
                                            />
                                        </Grid>
                                    </Fragment>
                                );
                            })}
                        </Grid>
                    </InfoCard>
                </Grid>

                <Grid item xs={12} md={6}>
                    <InfoCard title="description">
                        <RhfTextField
                            name="projectDescription"
                            variant="filled"
                            multiline
                            rows={6}
                            rowsMax={6}
                        />
                    </InfoCard>

                    <InfoCard title="project pictures">
                        <ProjectPicturesForm />
                    </InfoCard>
                </Grid>
            </Grid>
        </TabPanel>
    );
};

export default OverviewTabForm;
