/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PageOfTaskAuditDto } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class TaskAuditController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags task-audit-controller
   * @name ApiV1TasksTaskidAuditsUsingGet
   * @summary retrieveAll
   * @request GET:/api/v1/tasks/{taskId}/audits
   * @secure
   */
  apiV1TasksTaskidAuditsUsingGet = (
    taskId: number,
    query: { page: number; size: number; sorted?: boolean; unsorted?: boolean },
    params: RequestParams = {},
  ) =>
    this.request<PageOfTaskAuditDto, void>({
      path: `/api/v1/tasks/${taskId}/audits`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
}
