import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import TabPanel from '../TabPanel';
import { ProjectDetailsDto } from '../../../../api/data-contracts';
import ProjectInfoCard from './ProjectInfoCard';
import InfoCard from '../../../../components/InfoCard';
import { useStyles } from '../../styles';
import ProjectPictures from '../../ProjectPictures';

interface Props {
    selectedTab: number;
    index: number;
    project: ProjectDetailsDto;
}

const OverviewTab: FC<Props> = ({ selectedTab, index, project }) => {
    const classes = useStyles();

    return (
        <TabPanel value={selectedTab} index={index}>
            <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                    <ProjectInfoCard project={project} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InfoCard title="description">
                        <Typography className={classes.text}>{project.projectDescription}</Typography>
                    </InfoCard>

                    <InfoCard title="project pictures">
                        <ProjectPictures
                            project={project}
                            key={`photos${project?.projectPictures?.length}`}
                        />
                    </InfoCard>
                </Grid>
            </Grid>
        </TabPanel>
    );
};

export default OverviewTab;
