import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../config/theme';

export const useStyles = makeStyles({
    addTaskButton: {
        padding: '1.25rem 0.625rem',
        margin: ' 0px 0.625rem',
        width: '9.75rem',
        height: '2.625rem',
        backgroundColor: '#E65216',
        textTransform: 'lowercase',
        '& span': {
            fontSize: '1.125rem',
        },
    },
    tableHeaderTitle: {
        color: 'white',
        marginTop: '1.25rem',
        marginRight: '2.438rem',
    },
    nextPreviousIcon: {
        width: '0.938rem',
        height: '0.938rem',
        color: theme.palette.primary.main,
    },
    headerText: {
        fontWeight: 'bold',
    },
    headerTextDate: {
        width: '100%',
        textAlign: 'center',
    },
    actionIconButton: {
        padding: '0.938rem',
        color: 'white',
    },
    sortButton: {
        color: 'white',
        textTransform: 'none',
    },
    rootSelect: {
        color: '#626C74',
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
        paddingLeft: '0px!important',
        '&:focus': {
            padding: 0,
            paddingLeft: 0,
        },
    },
    selectedItem: {
        color: '#626C74',
        padding: 0,
        paddingLeft: '0px!important',
        fontSize: '1rem',
        fontWeight: 400,
    },
    iconClass: {
        color: '#626C74 !important',
    },
    arrow: {
        color: theme.palette.primary.main,
    },
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '0.625rem',
        marginRight: 0,
        overflowX: 'auto',
        overflowY: 'hidden',
        flexWrap: 'initial',
        maxHeight: '4.25rem',
        paddingBottom: '0.625rem',
        width: '100%',
    },
    chip: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.main,
        margin: 5,
        borderRadius: '0.938rem',
        color: '#fff',
        padding: '0.5rem 1rem',
    },
    filterTypeText: {
        fontSize: 12,
        fontWeight: 300,
    },
    filterText: {
        fontSize: '0.75rem',
        fontWeight: 700,
    },
    closeIconContainer: {
        width: '1.25rem',
        height: '1.25rem',
        backgroundColor: theme.palette.background.default,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5rem',
    },
    closeIcon: {
        width: '0.938rem',
        height: '0.938rem',
        cursor: 'pointer',
    },
});
