import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isNumber, parseInt } from 'lodash';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import PerformanceHeader from './parts/PerformanceHeader';
import InTimeChart from './parts/InTimeChart';
import {
    BarChartData,
    PerformanceFilterQuery,
    PerformanceQuery,
    PerformanceRawResponse,
} from '../../models/Performance';
import { parsePerformanceData } from './config/utils';
import { generatePDFReport, getPerformanceData } from '../../services/PerformanceService';
import { getBackendErrorMessage } from '../../config/utils';
import LoaderSpinner from '../../components/LoaderSpinner';
import { exportComponentAsPNG } from './utils/NodeToPng';
import { DATE_TIME_FORMAT } from '../../config/constants';

const titles = [
    {
        id: 1,
        name: 'Performance on planned tasks',
        legend: ``,
    },
    {
        id: 2,
        name: 'Performance on closed tasks',
        legend: `This graph provides insight in the performance on closed tasks. Closing date (x-axis) compared to the count of task status (in time, too late)`,
    },
    {
        id: 3,
        name: 'Conformities',
        legend: undefined,
    },
];

const PerformanceDashboardPage: FC = () => {
    const componentRef = useRef<HTMLDivElement | undefined>();
    const { search } = useLocation();
    const { replace } = useHistory();

    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [rawData, setRawData] = useState<PerformanceRawResponse[]>([]);
    const [year, setYear] = useState(dayjs().year());
    const [month, setMonth] = useState(dayjs().month());
    const [groupBy, setGroupBy] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingExportPDF, setLoadingExportPDF] = useState<boolean>(false);
    const [areFiltersLoaded, setAreFiltersLoaded] = useState<boolean>(false);

    const [query, setQuery] = useState<PerformanceQuery>({
        graphType: 1,
        filters: {
            year: dayjs().year(),
            month: undefined,
            filters: undefined,
        },
    });

    const updateQueryState = useCallback(
        (newQuery: PerformanceQuery) => {
            replace({
                pathname: '/performance',
                search: `?${Object.entries(newQuery)
                    .map(e => {
                        if (e[0] === 'filters') {
                            return Object.entries(e[1])
                                .map(v => v.join('='))
                                .join('&');
                        }
                        return e.join('=');
                    })
                    .join('&')}`,
            });
        },
        [replace],
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(search);

        const month = searchParams.get('month') ? parseInt(searchParams.get('month') || '1', 10) : undefined;
        const year = parseInt(searchParams.get('year') || dayjs().year().toString(), 10);

        const graphType = parseInt(searchParams.get('graphType') || '1', 10);

        const filters = searchParams.get('filters') || undefined;

        if (month === undefined) {
            setGroupBy(1);
        } else {
            setGroupBy(2);
        }

        setQuery({
            graphType,
            // @ts-ignore
            filters: {
                month,
                year,
                filters,
            },
        });

        setAreFiltersLoaded(true);
    }, [search]);

    const data: BarChartData = useMemo(() => {
        return parsePerformanceData(query.graphType, rawData);
    }, [query.graphType, rawData]);

    const getPerformance = useCallback(
        (typeOfPerformanceData: number, filterQuery: PerformanceFilterQuery) => {
            setLoading(true);
            getPerformanceData(typeOfPerformanceData, filterQuery)
                .then(rawPerformanceResponse => {
                    const newRawData = rawPerformanceResponse as PerformanceRawResponse[];
                    setRawData(newRawData);
                })
                .catch(error => {
                    enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [enqueueSnackbar, t],
    );

    useEffect(() => {
        getPerformance(query.graphType, query.filters);
    }, [getPerformance, query]);

    const onChangeGraphType = useCallback(
        (newValue: any) => {
            const { value } = newValue.target;

            if (isNumber(value)) {
                updateQueryState({
                    ...query,
                    graphType: value,
                    filters: {
                        filters: undefined,
                        year: query.filters.year,
                        month: query.filters.month,
                    },
                });
            }
        },
        [query, updateQueryState],
    );

    const handleExecuteQuery = (newQuery: string) => {
        if (newQuery !== '{}') {
            updateQueryState({
                ...query,
                graphType: query.graphType,
                filters: {
                    filters: newQuery,
                    year: query.filters.year,
                    month: query.filters.month,
                },
            });
        } else {
            updateQueryState({
                ...query,
                filters: {
                    filters: undefined,
                    year: query.filters.year,
                    month: query.filters.month,
                },
            });
        }
    };

    const handleChangeGroupBy = (newValue: number) => {
        // eslint-disable-next-line default-case
        switch (newValue) {
            case 1:
                updateQueryState({
                    ...query,
                    filters: {
                        filters: query.filters.filters,
                        month: undefined,
                        year: dayjs().year(),
                    },
                });
                setYear(dayjs().year());
                break;
            case 2:
                updateQueryState({
                    ...query,
                    filters: {
                        filters: query.filters.filters,
                        month: dayjs().month() + 1,
                        year: dayjs().year(),
                    },
                });
                setMonth(dayjs().month() + 1);
                setYear(dayjs().year());
                break;
        }
        setGroupBy(newValue);
    };

    const handleNextYear = () => {
        updateQueryState({
            ...query,
            filters: {
                filters: query.filters.filters,
                year: year + 1,
            },
        });
        setYear(year + 1);
    };

    const handlePreviousYear = () => {
        updateQueryState({
            ...query,
            filters: {
                filters: query.filters.filters,
                year: year - 1,
            },
        });
        setYear(year - 1);
    };

    const handleNextMonth = () => {
        if (query.filters.month) {
            if (query.filters.month + 1 < 13) {
                updateQueryState({
                    ...query,
                    filters: {
                        filters: query.filters.filters,
                        year: query.filters.year,
                        month: month + 1,
                    },
                });
                setMonth(month + 1);
            } else {
                updateQueryState({
                    ...query,
                    filters: {
                        filters: query.filters.filters,
                        year: query.filters.year + 1,
                        month: 1,
                    },
                });
                setYear(year + 1);
                setMonth(1);
            }
        }
    };

    const handlePreviousMonth = () => {
        if (query.filters.month) {
            if (query.filters.month - 1 > 0) {
                updateQueryState({
                    ...query,
                    filters: {
                        filters: query.filters.filters,
                        year: query.filters.year,
                        month: month - 1,
                    },
                });
                setMonth(month - 1);
            } else {
                updateQueryState({
                    ...query,
                    filters: {
                        filters: query.filters.filters,
                        year: query.filters.year - 1,
                        month: 12,
                    },
                });
                setYear(year - 1);
                setMonth(12);
            }
        }
    };

    const handleSetYear = useCallback(
        (newValue: any) => {
            const { value } = newValue.target;
            setYear(value);
            updateQueryState({
                ...query,
                filters: {
                    filters: query.filters.filters,
                    year: value,
                },
            });
        },
        [query, updateQueryState],
    );

    const handleExportPDF = () => {
        if (componentRef) {
            setLoadingExportPDF(true);
            exportComponentAsPNG(componentRef, file => {
                if (file) {
                    generatePDFReport(
                        query.graphType,
                        query.filters.year,
                        file,
                        query.filters.month,
                        query.filters.filters,
                    )
                        .then(response => {
                            const dataBlob = response as ArrayBuffer;
                            fileDownload(dataBlob, `${t('Report:')} ${dayjs().format(DATE_TIME_FORMAT)}.pdf`);
                        })
                        .catch(error => {
                            enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                                variant: 'error',
                            });
                        })
                        .finally(() => {
                            setLoadingExportPDF(false);
                        });
                } else {
                    enqueueSnackbar('Could not create PNG file', { variant: 'error' });
                }
            }).catch(error => {
                setLoadingExportPDF(false);
                enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                    variant: 'error',
                });
            });
        }
    };

    const { graphType } = query;

    return (
        <>
            {areFiltersLoaded && (
                <PerformanceHeader
                    key={graphType}
                    onChangeGraphType={onChangeGraphType}
                    graphType={graphType}
                    handleExecuteQuery={handleExecuteQuery}
                    handleSetYear={handleSetYear}
                    year={year}
                    groupBy={groupBy}
                    month={query.filters.month}
                    setGroupByValue={handleChangeGroupBy}
                    handleNextYear={handleNextYear}
                    handlePreviousYear={handlePreviousYear}
                    handleNextMonth={handleNextMonth}
                    handlePreviousMonth={handlePreviousMonth}
                    loadingExportPDF={loadingExportPDF}
                    setLoadingExportPDF={setLoadingExportPDF}
                    handleExportPDF={handleExportPDF}
                    initialFilters={query.filters.filters}
                />
            )}

            <div className={classes.container}>
                <div className={classes.graphPaperContainer}>
                    <div
                        // @ts-ignore
                        ref={componentRef}
                        style={{
                            height: '60%',
                            minHeight: 500,
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                height: '60%',
                                minHeight: 450,
                                position: 'relative',
                            }}
                        >
                            {loading && (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    className={classes.loaderContainer}
                                >
                                    <LoaderSpinner />
                                </Grid>
                            )}
                            <InTimeChart
                                graphData={data}
                                title={titles.find(it => it.id === graphType)?.name || ''}
                            />
                        </div>
                    </div>
                </div>
                <Grid container xs={12} direction="row">
                    {data.bars.map(bar => {
                        return (
                            <Grid container item xs direction="row" className={classes.barLegend}>
                                <div
                                    style={{
                                        backgroundColor: bar.color,
                                        width: 24,
                                        height: 24,
                                        marginRight: 10,
                                        borderRadius: '8px 0px',
                                    }}
                                />
                                <Typography>{t(`${bar.label}`)}</Typography>
                            </Grid>
                        );
                    })}
                    <Grid container item xs={8} direction="row" className={classes.graphLegend}>
                        {titles.find(it => it.id === graphType)?.legend}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default PerformanceDashboardPage;
