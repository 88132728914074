import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    nextButton: {
        display: 'block',
        margin: '1.25rem auto',
    },
    buttonsWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '2rem',
        margin: '0 1.5rem 0 auto',
    },
});
