import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    rootSelect: {
        padding: '0.7rem 0rem',
        fontWeight: 400,
        color: '#fff',
        textAlign: 'right',
        textTransform: 'lowercase',
        fontSize: '1rem',
        opacity: '0.5',
        '&:focus': {
            padding: '0.7rem 2.5rem',
            backgroundColor: 'transparent',
        },
    },
    rootSelectMenu: {
        borderRadius: 0,
    },
    menuListSelectMenu: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    selected: {
        paddingRight: '2.5rem !important',
    },
    selectedItem: {
        backgroundColor: 'transparent !important',
    },
    selectInputListItemIcon: {
        position: 'absolute',
        right: '0.625rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        justifyItems: 'end',
        color: '#E26A3A',
    },
    checkbox: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    iconComponent: {},
    icon: {
        color: '#fff',
        opacity: '0.5',
        fontWeight: 'bold',
    },
    iconOpen: {
        color: '#fff',
        opacity: '0.5',
        fontWeight: 'bold',
    },
});
