import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    avatar: {
        objectFit: 'cover',
        borderRadius: '50%',
        aspectRatio: '1/1',
    },
    usersPageAvatar: {
        width: 'auto',
        maxWidth: '4.063rem',
    },
    taskPageAvatar: {
        width: '2.188rem',
        height: '2.188rem',
    },
    userDetailsPageAvatar: {
        width: '80%',
        maxWidth: '6.25rem',
    },
    projectDetailsAvatar: {
        width: '80%',
        height: '80%',

        minHeight: '1.875rem',
        minWidth: '1.875rem',
        maxHeight: '3.75rem',
        maxWidth: '3.75rem',
        borderRadius: '50%',
    },
});
