import React, { FC, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEqual } from 'lodash';
import { useStyles } from './styles';
import { UsersQuery } from '../../../services/UserService';
import FiltersFormFields from './FiltersFormFields';

interface Props {
    values: UsersQuery;
    setValues: (filters: UsersQuery) => void;
    inviteUser: () => void;
}

const Filters: FC<Props> = ({ values, setValues, inviteUser }) => {
    const classes = useStyles();

    const formRef = useRef<HTMLFormElement>(null);
    const isFirstRender = useRef<boolean>(true);

    const FormSchema = z.object({
        disciplines: z.number().array().optional(),
        search: z.string().optional(),
        startDate: z.string().optional(),
        endDate: z.string().optional(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            ...values,
        },
    });

    const watchAllFields = methods.watch();

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!isEqual(values, watchAllFields) && !isFirstRender.current) {
                formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            } else {
                isFirstRender.current = false;
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [values, watchAllFields]);

    const onSubmit: SubmitHandler<FormSchemaType> = async (formValues: FormSchemaType) => {
        setValues({
            ...formValues,
            disciplines: formValues.disciplines?.map(d => d) || [],
        });
    };

    return (
        <Box className={classes.container}>
            <FormProvider {...methods}>
                <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
                    <FiltersFormFields inviteUser={inviteUser} />
                </form>
            </FormProvider>
        </Box>
    );
};

export default Filters;
