import { TableCell as MuiTableCell, TableSortLabel, Typography } from '@material-ui/core';
import { flexRender, Header } from '@tanstack/react-table';
import { useStyles } from './styles';

interface Props<V> {
    header: Header<V, unknown>;
}

const TableHeadCell = <V,>({ header }: Props<V>) => {
    const classes = useStyles();

    return (
        <MuiTableCell key={header.id} colSpan={header.colSpan} className={classes.headerCell}>
            {header.isPlaceholder ? null : header.column.getCanSort() ? (
                <TableSortLabel
                    classes={{
                        icon: classes.headerCellSortArrow,
                    }}
                    active={!!header.column.getIsSorted()}
                    direction={
                        header.column.getIsSorted()
                            ? (header.column.getIsSorted() as 'desc' | 'asc')
                            : undefined
                    }
                    onClick={header.column.getToggleSortingHandler()}
                >
                    <Typography className={classes.headerCellText}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                    </Typography>
                </TableSortLabel>
            ) : (
                <Typography className={classes.headerCellText}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                </Typography>
            )}
        </MuiTableCell>
    );
};

export default TableHeadCell;
