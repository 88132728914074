import { combineReducers } from 'redux';
import currentUserReducer from './CurrentUser.reducer';
import tasksReducer from './Tasks.reducer';

const rootReducer = combineReducers({
    currentUser: currentUserReducer,
    tasks: tasksReducer,
});

export default rootReducer;
