import { FC } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import CustomSelectMultiple from '../CustomSelectMultiple';
import { Filter } from '../../models/Filter';

interface Props {
    id: string;
    name: string;
    filterName: string;
    onChange: (newSelection: Filter[]) => void;
    availableFilters: Filter[];
    onClose?: () => void;
    values?: Filter[];
}

const FilterSelectButton: FC<Props> = ({
    id,
    name,
    filterName,
    onChange,
    availableFilters,
    onClose,
    values,
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <CustomSelectMultiple
                id={id}
                inputName={name}
                defaultValue={filterName}
                // @ts-ignore
                onChangeValue={onChange}
                onClose={onClose}
                availableData={availableFilters}
                values={values}
            />
        </Box>
    );
};

export default FilterSelectButton;
