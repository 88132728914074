import React, { FC, useMemo, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty, lowerCase } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import FilterSelectButton from '../../../../components/FilterSelectButton';
import { RootReducer, TasksReducer } from '../../../../models/Redux';
import { Filter } from '../../../../models/Filter';
import Toolbar from '../../../../components/Toolbar';
import { useStyles } from '../../../DashboardPage/Parts/TaskTableHeader/styles';

interface Props {
    handleExecuteQuery: (filters: string) => void;
    isAdmin?: boolean;
}

enum FilterType {
    PROJECT = 'PROJECT',
    ASSIGNED_TO = 'ASSIGNED_TO',
    DISCIPLINE = 'DISCIPLINE',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
}

const ProjectsTableHeader: FC<Props> = ({ isAdmin, handleExecuteQuery }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { projects, employeeAll } = useSelector<RootReducer, TasksReducer>(state => state.tasks);

    const [projectsFilter, setProjectsFilter] = useState<Filter[]>([]);
    const [assignedToFilter, setAssignedToFilter] = useState<Filter[]>([]);

    const selectedFilters = useRef<any>({});

    const allSelectedFilters = useMemo(() => {
        return projectsFilter
            .map(item => {
                return { ...item, filterType: FilterType.PROJECT };
            })
            .concat(
                assignedToFilter.map(item => {
                    return { ...item, filterType: FilterType.ASSIGNED_TO };
                }),
            );
    }, [assignedToFilter, projectsFilter]);

    const handleSelectFilter = (newArray: Filter[], filterName: string) => {
        /* eslint-disable */
        switch (filterName) {
            case 'project-select':
                const projectNewValues = parseSelectedFilters(projectsFilter, newArray);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }

                setProjectsFilter(projectNewValues.newFilterArray);

                break;
            case 'assigned-to-select':
                const assignedToNewValues = parseSelectedFilters(assignedToFilter, newArray);

                selectedFilters.current.assignedTo = assignedToNewValues.newValues;
                if (isEmpty(assignedToNewValues.newValues)) {
                    delete selectedFilters.current.assignedTo;
                }
                setAssignedToFilter(assignedToNewValues.newFilterArray);

                break;
            default:
                break;
        }
        /* eslint-enable */
    };

    const handleRemoveFilter = (elementToRemove: Filter, filterName: FilterType) => {
        /* eslint-disable */
        switch (filterName) {
            case FilterType.PROJECT:
                const projectNewValues = removeFilterFromArray(projectsFilter, elementToRemove);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }

                setProjectsFilter(projectNewValues.newFilterArray);

                break;
            case FilterType.ASSIGNED_TO:
                const assignedToNewValues = removeFilterFromArray(assignedToFilter, elementToRemove);

                selectedFilters.current.assignedTo = assignedToNewValues.newValues;
                if (isEmpty(assignedToNewValues.newValues)) {
                    delete selectedFilters.current.assignedTo;
                }
                setAssignedToFilter(assignedToNewValues.newFilterArray);

                break;
            default:
                break;
        }
        /* eslint-enable */
    };

    const parseSelectedFilters = (
        previousFilters: Filter[],
        newFiltersArray: Filter[],
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...previousFilters];

        if (previousFilters.find(filter => filter.id === newFiltersArray[0].id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== newFiltersArray[0].id);
        } else {
            updatedFilter.push(newFiltersArray[0]);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    const removeFilterFromArray = (
        filterArray: Filter[],
        elementToRemove: Filter,
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...filterArray];

        if (filterArray.find(filter => filter.id === elementToRemove.id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== elementToRemove.id);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    const handleDelete = (item: { filterType: FilterType; id: any; name: string }) => {
        handleRemoveFilter({ id: item.id, name: item.name }, item.filterType);
        handleExecuteQuery(selectedFilters.current);
    };

    const executeQuery = () => {
        // handleExecuteQuery(JSON.stringify(selectedFilters.current));
        handleExecuteQuery(selectedFilters.current);
    };

    return (
        <Grid container alignContent="center" alignItems="center" style={{ width: '100vw' }}>
            <Toolbar>
                <FilterSelectButton
                    id="project-select"
                    name="project-select"
                    filterName={t('project')}
                    onChange={newValue => handleSelectFilter(newValue, 'project-select')}
                    onClose={executeQuery}
                    availableFilters={projects}
                    values={projectsFilter}
                />
                <FilterSelectButton
                    id="assigned-to-select"
                    name="assigned-to-select"
                    filterName={t('assigned to')}
                    onChange={newValue => handleSelectFilter(newValue, 'assigned-to-select')}
                    onClose={executeQuery}
                    availableFilters={employeeAll}
                    values={assignedToFilter}
                />
            </Toolbar>

            <Grid item xs={12} direction="row" className={classes.chipContainer}>
                {allSelectedFilters.map(item => (
                    <div className={classes.chip}>
                        <div>
                            <Typography className={classes.filterTypeText}>
                                {lowerCase(item.filterType)}:
                            </Typography>
                            <Typography className={classes.filterText}>{item.name}</Typography>
                        </div>
                        <div className={classes.closeIconContainer}>
                            <CloseIcon onClick={() => handleDelete(item)} className={classes.closeIcon} />
                        </div>
                    </div>
                ))}
            </Grid>
        </Grid>
    );
};

export default ProjectsTableHeader;
