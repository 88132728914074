/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DictionaryEntryDto, EmployeeDiscipline } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class DisciplineController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags discipline-controller
   * @name ApiV1DisciplinesViewsDictionaryUsingGet
   * @summary viewDictionary
   * @request GET:/api/v1/disciplines/views/dictionary
   * @secure
   */
  apiV1DisciplinesViewsDictionaryUsingGet = (query: { showActive: boolean }, params: RequestParams = {}) =>
    this.request<DictionaryEntryDto[], void>({
      path: `/api/v1/disciplines/views/dictionary`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags discipline-controller
   * @name ApiV1DisciplinesViewsDictionaryMappedUsingGet
   * @summary viewDictionary2
   * @request GET:/api/v1/disciplines/views/dictionary/mapped
   * @secure
   */
  apiV1DisciplinesViewsDictionaryMappedUsingGet = (params: RequestParams = {}) =>
    this.request<EmployeeDiscipline[], void>({
      path: `/api/v1/disciplines/views/dictionary/mapped`,
      method: "GET",
      secure: true,
      ...params,
    });
}
