import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    paperBackground: {
        width: '18rem',
        height: '16rem',
        padding: '1.25rem 1.875rem',
        borderRadius: '1.25rem 0px',
        margin: '3.375rem 0.625rem 0.625rem',
        justifyContent: 'space-between',
        backgroundColor: '#263644',
    },
    noBorder: {
        border: 0,
    },
    rowContainer: {
        paddingRight: '1.875rem',
        width: '17.5rem',
        height: '13.75rem',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        columnGap: 30,
        cursor: 'pointer',
    },
    projectTitle: {
        fontWeight: 'bolder',
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        color: 'white',
        paddingTop: '0.438rem',
        margin: '0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',

        '-webkitLineClamp': 2,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        wordWrap: 'break-word',
    },
    projectDescription: {
        color: '#cdcdcd',
        fontWeight: 500,
        fontSize: '0.938rem',
        padding: '0px 1.563rem',
        overflow: 'hidden',
        maxWidth: '25rem',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
    },
    fullWidthCentered: {
        width: '100%',
        textAlign: 'center',
    },
    conformityRegisterButton: {},
    projectLogoImg: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        border: '1px solid white',
        borderRadius: '1.25rem 0px',
    },
    defaultProjectLogo: {
        width: '16rem',
        height: '10rem',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '1.25rem 0px',
        backgroundColor: 'white',
    },
});
