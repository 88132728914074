import { ChangeEvent, FC } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import CustomButton from '../../../../components/CustomButton';
import { useStyles } from '../EditTaskDialog/Parts/EditTaskDialogForm/styles';

interface Props {
    open: boolean;
    disabled: boolean;
    handleCloseDialog: () => void;
    handleSubmit: () => void;
    handleCancel: () => void;
}

const DeadlineChangeReasonDialog: FC<Props> = ({
    open,
    disabled,
    handleCloseDialog,
    handleSubmit,
    handleCancel,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { control, setValue } = useFormContext();
    const deadlineRemarks = useWatch({
        control,
        name: 'deadlineRemarks',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('deadlineRemarks', e.target.value);
    };

    const handleSaveButtonClick = () => {
        if (deadlineRemarks?.trim()?.length && deadlineRemarks.length < 4000) {
            handleSubmit();
        } else if (!deadlineRemarks?.trim()?.length) {
            enqueueSnackbar(t('Please enter the remarks about the deadline change.'), { variant: 'error' });
        } else {
            enqueueSnackbar(t('Maximum length is 4000 characters.'), { variant: 'error' });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="lg"
            classes={{
                paper: classes.rootDialog,
            }}
        >
            <DialogTitle
                classes={{
                    root: classes.dialogTitleContainer,
                }}
            >
                <Typography className={classes.dialogTitle}>{t('Deadline Remarks')}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
                    <CloseIcon className={classes.closeButtonIcon} />
                </IconButton>
            </DialogTitle>

            <DialogContent
                classes={{
                    root: classes.dialogContentContainer,
                }}
            >
                <TextField
                    name="deadlineRemarks"
                    variant="filled"
                    label=""
                    placeholder={t('Type your remarks here')}
                    multiline
                    rows={5}
                    rowsMax={10}
                    value={deadlineRemarks}
                    onChange={handleChange}
                    className={classes.textField}
                    InputProps={{ disableUnderline: true, className: classes.textFieldWrapper }}
                />
            </DialogContent>

            <DialogActions>
                <CustomButton
                    basic
                    label={t('Cancel')}
                    onClick={handleCancel}
                    labelClassName={classes.addTaskButtonLabel}
                    buttonClassName={clsx(classes.addTaskButton, classes.smallerRightMargin)}
                />
                <CustomButton
                    label={t('Save')}
                    onClick={handleSaveButtonClick}
                    labelClassName={classes.addTaskButtonLabel}
                    buttonClassName={classes.addTaskButton}
                    disabled={disabled}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DeadlineChangeReasonDialog;
