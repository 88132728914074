import React, { FC, ReactElement } from 'react';

import Button from '@material-ui/core/Button';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { useStyles } from './ConfirmDialog.styles';

interface ConfirmDialogProps {
    open: boolean;
    confirmLabel: string;
    cancelLabel?: string;
    title: string;
    onClickConfirm: (data: unknown) => void;
    onClickCancel?: (data: unknown) => void;
    className?: string;
    actionContainer?: ReactElement;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
    cancelLabel,
    children,
    className,
    confirmLabel,
    onClickCancel = () => null,
    onClickConfirm,
    open,
    title,
    actionContainer,
}) => {
    const classes = useStyles();

    return (
        <Dialog classes={{ paperWidthSm: className }} onClose={onClickCancel} open={open}>
            <DialogTitle>{title}</DialogTitle>
            {!!children && <DialogContent>{children}</DialogContent>}
            <div className={classes.buttonsWrapper}>
                {actionContainer || (
                    <>
                        {cancelLabel && (
                            <Button color="primary" className={classes.nextButton} onClick={onClickCancel}>
                                {cancelLabel}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onClickConfirm}
                            className={classes.nextButton}
                        >
                            {confirmLabel}
                        </Button>
                    </>
                )}
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
