import React, { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { ContactDto } from '../../../../../api/data-contracts';
import { useStyles } from './styles';

interface Props {
    user: ContactDto;
    hasDivider?: boolean;
}

const ProjectUser: FC<Props> = ({ user, hasDivider }) => {
    const classes = useStyles();

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Box>
                        <Typography className={classes.contactName}>{user.contactName}</Typography>
                        <Typography className={classes.companyName}>{user.companyName}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    {!!user?.phoneNo?.length && (
                        <Box className={classes.emailOrPhoneNumber}>
                            <CallOutlinedIcon className={classes.contactIcon} />
                            <Typography className={classes.emailOrPhoneNumberText}>{user.phoneNo}</Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    {!!user?.email?.length && (
                        <Box className={classes.emailOrPhoneNumber}>
                            <EmailOutlinedIcon className={classes.contactIcon} />
                            <Typography className={classes.emailOrPhoneNumberText}>{user.email}</Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>

            {hasDivider && <Box className={classes.divider} />}
        </>
    );
};

export default ProjectUser;
