import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    headerCellText: {
        color: 'white',
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1.2,
    },
});
