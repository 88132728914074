import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    title: {
        color: '#E65216',
        fontWeight: 'bold',
        fontSize: 28,
        lineHeight: 1.2,
        marginBottom: 12,
    },
    divider: {
        width: '100%',
        backgroundColor: '#454c51',
        marginTop: 14,
        marginBottom: 14,
        height: 2,
    },
    addSubGroup: {
        marginBottom: 20,
        marginTop: 20,
    },
});
