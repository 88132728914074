import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    paginationContainer: {
        padding: '1.25rem 0px',
    },
    paginationSizeText: {
        color: '#fff',
        opacity: '0.4',
        padding: '0.625rem 0px',
        fontWeight: 400,
        fontSize: '1.125rem',
    },
    selectedItemPagination: {
        color: '#626C74',
        padding: 0,
        paddingLeft: '0px!important',
        fontSize: '1rem',
        fontWeight: 400,
    },
    paginationElement: {
        color: 'white',
        borderRadius: '0.625rem 0',
        width: '2.625rem',
        height: '2.625rem',
        fontSize: '1.125rem',
        fontWeight: 'bold',
    },
    page: {
        backgroundColor: theme.palette.secondary.light,
    },
    selectedPage: {
        backgroundColor: `${theme.palette.primary.main}!important`,
        color: '#fff',
    },
    paginationEllipsis: {
        color: '#cdcdcd',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    paginationRoot: {
        '& ul li:last-child button': {
            backgroundColor: 'transparent',
        },
        '& ul li:first-child button': {
            backgroundColor: 'transparent',
        },
    },

    headerTextDate: {
        width: '100%',
        textAlign: 'center',
    },
    conformityName: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        lineHeight: 1.2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.4rem',
    },
    statusIndicator: {
        borderRadius: '9px 0px 10px 0px',
        width: 6,
        height: '1rem',
    },
    openStatusIndicator: {
        background: '#36C5F1 0% 0% no-repeat padding-box',
    },
    closedStatusIndicator: {
        background: '#57AD40 0% 0% no-repeat padding-box',
    },
    statusSelect: {
        backgroundColor: '#263644 !important',
        borderRadius: '1rem 0 !important',
        padding: '0.75rem 1.25rem !important',
        minWidth: '12.5rem',
        marginLeft: -20,
        opacity: 1,
    },
    statusSelectExpandedRow: {
        backgroundColor: '#13212D !important',
    },
    statusSelectText: {
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: 1.2,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.4rem',
    },
    statusSelectIcon: {
        fontSize: '2rem',
        top: 'calc(50% - 1rem)',
        right: '0.6rem',
        fontWeight: 'bold',
    },
    expandedRow: {
        backgroundColor: '#263644',
        padding: '4rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '2rem',
    },
    subComponentBox: {
        backgroundColor: '#13212D',
        borderRadius: '3rem 0',
        padding: '3rem',
    },
    subComponentBoxTitle: {
        fontSize: '1.5rem',
        lineHeight: 1.15,
        fontWeight: 'bold',
        marginBottom: '1.2rem',
    },
    subComponentBoxText: {
        fontSize: '1.1rem',
        lineHeight: 1.5,
        opacity: 0.8,
    },
    textField: {
        '& .MuiInputBase-root ': {
            backgroundColor: '#263644 !important',
            borderRadius: '1rem 0',
        },
    },
    rowLabel: {
        color: '#708393',
        fontSize: '1.1rem',
        lineHeight: 1.5,
        fontWeight: 600,
    },
    rowValue: {
        color: 'white',
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.3rem',
    },
    datepicker: {
        width: '20rem',
        '& .MuiInputBase-root ': {
            backgroundColor: '#263644 !important',
        },
    },
    selectInput: {
        width: '17.5rem',
        paddingTop: '1.1rem !important',
        paddingBottom: '1.1rem !important',
    },
}));
