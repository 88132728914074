import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useStyles } from './styles';
import imageUserPlaceHolder from '../../assets/img/userPlaceholders/profile-user.svg';
import { getUserAvatarById } from '../../services/UserService';

interface Props {
    avatarUrl?: string;
    userId?: number;
    page?: 'task' | 'users' | 'userDetails' | 'project';
}

const UserAvatar: FC<Props> = ({ avatarUrl, userId, page }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isCustomAvatarAvailable = !avatarUrl?.includes('api/v1/users/');

    const [avatar, setAvatar] = useState<string>(isCustomAvatarAvailable ? avatarUrl || '' : '');

    useEffect(() => {
        if (userId && avatar === '') {
            getUserAvatarById(userId)
                .then(response => setAvatar(URL.createObjectURL(response)))
                .catch(() => {});
        }
    }, [userId, avatar]);

    return (
        <img
            className={clsx(classes.avatar, {
                [classes.usersPageAvatar]: page === 'users' || !page,
                [classes.taskPageAvatar]: page === 'task',
                [classes.userDetailsPageAvatar]: page === 'userDetails',
                [classes.projectDetailsAvatar]: page === 'project',
            })}
            src={avatar || imageUserPlaceHolder}
            alt={t('User avatar')}
        />
    );
};

export default UserAvatar;
