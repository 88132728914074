import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';

const fileType = {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
};

const DEFAULT_PNG = {
    fileName: 'component.png',
    type: fileType.PNG,
    html2CanvasOptions: {},
};

const DEFAULT_JPEG = {
    fileName: 'component.jpg',
    type: fileType.JPEG,
    html2CanvasOptions: {},
};

const dataURLToBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
};

const blobToFile = (blob: Blob, filename?: string): File => {
    const mimetype = blob.type.includes('jpg') ? 'jpg' : 'png';
    return new File([blob], filename || `image.${mimetype}`, {
        type: blob.type,
    });
};

const exportComponent = (node: any, { type, html2CanvasOptions }: any, callback: (data: File) => void) => {
    if (!node.current) {
        throw new Error("'node' must be a RefObject");
    }

    // eslint-disable-next-line react/no-find-dom-node
    const element = ReactDOM.findDOMNode(node.current);
    // @ts-ignore
    return html2canvas(element, {
        scrollY: -window.scrollY,
        useCORS: true,
        ...html2CanvasOptions,
    }).then(canvas => {
        callback(blobToFile(dataURLToBlob(canvas.toDataURL(type, 1.0))));
    });
};

const exportComponentAsPNG = (node: any, callback: (data: File) => void, parameters = {}) =>
    exportComponent(node, { ...DEFAULT_PNG, ...parameters }, callback);

const exportComponentAsJPEG = (node: any, callback: (data: File) => void, parameters = {}) =>
    exportComponent(node, { ...DEFAULT_JPEG, ...parameters }, callback);

export { exportComponentAsJPEG, exportComponentAsPNG };
