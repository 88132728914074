import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { z } from 'zod';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import InviteUsersDialogFormFields from '../InviteUsersDialogFormFields';
import { inviteUser } from '../../../../services/UserService';
import { queryKeys } from '../../../../react-query/constants';

interface Props {
    submitForm: boolean;
    setSubmitForm: Dispatch<SetStateAction<boolean>>;
    handleClose: () => void;
}

const InviteUsersDialogForm: FC<Props> = ({ submitForm, setSubmitForm, handleClose }) => {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const formRef = useRef<HTMLFormElement>(null);

    const FormSchema = z.object({
        firstName: z.string().trim().min(1, 'required field').max(50, 'Maximum length is 50 characters'),
        lastName: z.string().trim().min(1, 'required field').max(50, 'Maximum length is 50 characters'),
        email: z
            .string()
            .min(1, 'required field')
            .max(50, 'Maximum length is 50 characters')
            .email('invalid email'),
        userType: z.string().min(1, 'required field'),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            userType: '',
        },
    });

    const inviteMutation = useMutation(
        (formValues: FormSchemaType) => {
            const authorities = [formValues.userType];

            return inviteUser({
                ...formValues,
                // @ts-ignore
                authorities,
            });
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([queryKeys.users]);
                handleClose();

                enqueueSnackbar('User invited', { variant: 'success' });
            },
            onSettled: () => {
                setSubmitForm(false);
            },
        },
    );

    const onSubmit: SubmitHandler<FormSchemaType> = async (formValues: FormSchemaType) => {
        inviteMutation.mutate(formValues);
    };

    useEffect(() => {
        if (submitForm && formRef) {
            formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

            setSubmitForm(false);
        }
    }, [setSubmitForm, submitForm]);

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
                <InviteUsersDialogFormFields />
            </form>
        </FormProvider>
    );
};
export default InviteUsersDialogForm;
