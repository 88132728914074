import React, { FC, useCallback } from 'react';
import { Box, Button, Collapse, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { Employee } from '../../../../models/Project';
import UserAvatar from '../../../../components/UserAvatar';
import CustomButton from '../../../../components/CustomButton';
import FormFieldsBox from '../../../../components/FormFieldsBox';
import { FormFieldsBoxVariant } from '../../../../models/enums/FormFieldsBoxVariant';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import {
    ConformityDto,
    PageOfConformityRowDto,
    TaskDto,
    TaskRequirementDtoRes,
} from '../../../../api/data-contracts';
import { queryKeys } from '../../../../react-query/constants';
import {
    getTasksByID,
    getTasksConformities,
    updateTaskRequirementStatus,
} from '../../../../services/TasksService';
import { useStyles } from './styles';

interface Props {
    taskId: number | undefined;
    showDetails: boolean;
    showAudit: boolean;
    openConformity: (task: TaskDto | undefined, conformities: ConformityDto[] | undefined) => void;
    handleShowDetails: () => void;
}

const TaskDetailsCollapse: FC<Props> = ({
    taskId,
    showDetails,
    showAudit,
    openConformity,
    handleShowDetails,
}) => {
    const history = useHistory();
    const classes = useStyles({ showDetails, showAudit });
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { data: taskData, isLoading: isTaskLoading } = useQuery<TaskDto>(
        [queryKeys.task, taskId],
        () => getTasksByID(taskId as number),
        { enabled: showDetails && !!taskId },
    );

    const { data: conformities, isLoading: areConformitiesLoading } = useQuery<PageOfConformityRowDto>(
        [queryKeys.task, taskId, 'conformities'],
        () => getTasksConformities({ id: taskId as number }),
        { enabled: showDetails && !!taskId },
    );

    const taskRequirementMutation = useMutation(
        ({ id, ready }: { id: number; ready: boolean }) => updateTaskRequirementStatus(id, !ready),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([queryKeys.task, taskId]);
                enqueueSnackbar(t('Status updated '), { variant: 'success' });
            },
        },
    );

    const renderToggleTaskRequirementStatusButton = useCallback(
        (id: number | undefined, ready: boolean) => (
            <Button
                className={classes.statusButton}
                disabled={taskRequirementMutation.isLoading}
                onClick={() => {
                    if (id) {
                        taskRequirementMutation.mutate({ id, ready });
                    }
                }}
            >
                <Box
                    className={clsx(classes.taskStatusBox, {
                        [classes.taskStatusBoxDone]: ready,
                    })}
                >
                    {ready && <CheckIcon className={classes.taskStatusBoxIcon} />}
                </Box>
            </Button>
        ),
        [taskRequirementMutation, classes],
    );

    const renderTaskRequirement = useCallback(
        (taskRequirement: TaskRequirementDtoRes) => (
            <div key={taskRequirement.id} className={classes.listContainer}>
                <div className={classes.checkboxContainer}>
                    {renderToggleTaskRequirementStatusButton(
                        taskRequirement.id,
                        taskRequirement.ready || false,
                    )}
                </div>
                <p
                    className={classes.todoListText}
                    style={{
                        textDecoration: taskRequirement.ready ? 'line-through' : '',
                        color: taskRequirement.ready ? '#708393' : '#C9CBCC',
                    }}
                >
                    {taskRequirement.description}
                </p>
            </div>
        ),
        [classes, renderToggleTaskRequirementStatusButton],
    );

    return (
        <Collapse
            in={showDetails}
            timeout="auto"
            unmountOnExit
            style={{
                width: '100%',
            }}
        >
            <Paper elevation={0} className={clsx(classes.paperBackground, classes.noBorder)}>
                {!(isTaskLoading || areConformitiesLoading) ? (
                    <>
                        <Grid container direction="row" alignItems="flex-start" justify="center" spacing={4}>
                            <Grid item container xs={6} direction="row" alignItems="flex-start" spacing={4}>
                                <Grid item xs={12}>
                                    <FormFieldsBox variant={FormFieldsBoxVariant.DARK}>
                                        <Box className={classes.container}>
                                            <Typography>
                                                <span>{t('Contract type: ')}</span>
                                                <span>{taskData?.contractType?.name || 'N/A'}</span>
                                            </Typography>

                                            <Typography>
                                                <span>{t('Article: ')}</span>
                                                <span>{taskData?.article || 'N/A'}</span>
                                            </Typography>

                                            {dayjs(taskData?.completed).isValid() && (
                                                <Typography>
                                                    <span>{t('Completed by: ')}</span>
                                                    <span>
                                                        <UserAvatar
                                                            avatarUrl={taskData?.completedBy?.avatar}
                                                            userId={taskData?.completedBy?.id}
                                                            page="task"
                                                        />
                                                        <span style={{ marginLeft: 8 }}>
                                                            {taskData?.completedBy?.name || 'N/A'}
                                                        </span>
                                                    </span>
                                                </Typography>
                                            )}
                                        </Box>
                                        {!isEmpty(conformities?.content) && (
                                            <Box>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    endIcon={<CheckIcon />}
                                                    onClick={() =>
                                                        history.push(`/tasks/${taskId}/conformity`)
                                                    }
                                                    disabled={isEmpty(conformities)}
                                                >
                                                    {t('conformity register')}
                                                </Button>
                                            </Box>
                                        )}
                                    </FormFieldsBox>
                                </Grid>
                                {!!taskData?.assignedEmployees && taskData.assignedEmployees.length > 0 && (
                                    <Grid item xs={12}>
                                        <FormFieldsBox
                                            variant={FormFieldsBoxVariant.DARK}
                                            title={t('Person(s)')}
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                direction="row"
                                                justify="flex-start"
                                                alignContent="flex-start"
                                                alignItems="flex-start"
                                            >
                                                {
                                                    // @ts-ignore
                                                    taskData?.assignedEmployees?.map((employee: Employee) => (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={5}
                                                            direction="column"
                                                            className={classes.userAssignedItem}
                                                            justify="flex-start"
                                                            alignContent="flex-start"
                                                            alignItems="flex-start"
                                                            key={employee.id}
                                                        >
                                                            <UserAvatar
                                                                avatarUrl={employee.avatar}
                                                                userId={employee.id}
                                                                page="project"
                                                            />
                                                            <Box ml={2}>
                                                                <Typography
                                                                    className={classes.userAssignedName}
                                                                >
                                                                    {employee.name || 'N/A'}
                                                                </Typography>

                                                                <Typography
                                                                    className={classes.userAssignedPosition}
                                                                >
                                                                    {employee.jobTitle || 'N/A'}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </FormFieldsBox>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                direction="row"
                                justify="flex-end"
                                alignContent="flex-end"
                                alignItems="flex-end"
                                spacing={4}
                            >
                                <Grid item xs={12}>
                                    <FormFieldsBox variant={FormFieldsBoxVariant.DARK} title={t('To do')}>
                                        {taskData?.taskRequirements ? (
                                            taskData?.taskRequirements?.map(item =>
                                                renderTaskRequirement(item),
                                            )
                                        ) : (
                                            <Typography className={classes.detailsContent}>N/A</Typography>
                                        )}
                                    </FormFieldsBox>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldsBox
                                        variant={FormFieldsBoxVariant.DARK}
                                        title={t('Description')}
                                    >
                                        <Typography className={classes.detailsContent}>
                                            {taskData?.description}
                                        </Typography>
                                    </FormFieldsBox>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldsBox variant={FormFieldsBoxVariant.DARK} title={t('Remarks')}>
                                        <Typography className={classes.detailsContent}>
                                            {taskData?.remarks || 'N/A'}
                                        </Typography>
                                    </FormFieldsBox>
                                </Grid>
                                <div className={clsx(classes.confirmityRegisterButtons)}>
                                    <CustomButton
                                        onClick={handleShowDetails}
                                        icon={<KeyboardArrowUpIcon />}
                                        buttonClassName={classes.collapseDetailsButton}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                    >
                        <LoaderSpinner />
                    </Grid>
                )}
            </Paper>
        </Collapse>
    );
};

export default TaskDetailsCollapse;
