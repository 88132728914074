import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    addTaskButton: {
        margin: '0.313rem',
    },
    tableHeaderTitle: {
        color: 'black',
        marginTop: '1.25rem',
        marginRight: '2.438rem',
    },
    nextPreviousIcon: {
        width: '0.938rem',
        height: '0.938rem',
    },
    headerText: {
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    headerTextDate: {
        width: '100%',
        margin: '0px 0.094rem',
        textAlign: 'center',
    },
    actionIconButton: {
        padding: '0.938rem',
        color: 'black',
    },
    sortButton: {
        color: 'black',
        textTransform: 'none',
    },
});
