import React, { FC, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { isUndefined } from 'lodash';
import { useStyles } from './styles';
import ProjectsTableHeader from '../ProjectsTableHeader';
import ProjectsTableBody from '../ProjectsTableBody';
import ProjectDetailsDialog from '../ProjectDetailsDialog';
import { Project, ProjectQuery, ProjectResult, ProjectSimpleData } from '../../../../models/Project';
import { getProjectById, getProjects } from '../../../../services/ProjectService';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { getBackendErrorMessage } from '../../../../config/utils';
import { CurrentUserReducer, RootReducer } from '../../../../models/Redux';
import { UserRoles } from '../../../../models/enums/UserRoles';
import CustomSelect from '../../../../components/CustomSelect';

interface Props {
    customQuery?: ProjectQuery;
}

const ProjectsTable: FC<Props> = ({ customQuery }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isProjectDetailsDialogOpen, setIsProjectDetailsDialogOpen] = useState<boolean>(false);
    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);
    const [currentProject, setCurrentProject] = useState<Project>();
    const [loading, setLoading] = useState<boolean>(false);
    const [query, setQuery] = useState<ProjectQuery>({
        page: 1,
        size: isUndefined(customQuery) ? 10 : 100,
    });
    const [projectsData, setProjectsData] = useState<ProjectSimpleData[]>([]);
    const [refetch, setRefetch] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState(Math.ceil(projectsData.length / query.size));
    const handleLoadMore = (newPage?: number) => {
        setQuery({
            ...query,
            page: newPage || 1,
        });
    };
    const handleChangePagination = (event: React.ChangeEvent<unknown>, newPage: number) => {
        handleLoadMore(newPage);
    };

    useEffect(() => {
        setLoading(true);
        const preparedQuery = { ...query, page: query.page > 0 ? query.page - 1 : 0 };
        getProjects(preparedQuery)
            .then(result => {
                const projectsResult = result as ProjectResult;
                setProjectsData(projectsResult.content);
                setTotalPages(projectsResult.totalPages);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [query, refetch]);

    const handleProjectDetailsClose = () => {
        setIsProjectDetailsDialogOpen(false);
    };

    const handleOpenDetails = (currentProject: ProjectSimpleData) => {
        setLoading(true);
        getProjectById(currentProject.id)
            .then(result => {
                const projectResult = (result as unknown) as Project;

                setCurrentProject(projectResult);
                setIsProjectDetailsDialogOpen(true);
            })
            .catch(error => {
                enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleExecuteQuery = (newQuery: string) => {
        if (newQuery !== '{}') {
            setQuery({
                ...query,
                filters: newQuery,
            });
        } else {
            setQuery({
                ...query,
                page: 1,
                filters: undefined,
            });
        }
    };
    const setPageSize = (pageSize: number) => {
        setQuery({
            ...query,
            page: 1,
            size: pageSize,
        });
    };
    const renderSelectInput = (
        id: string,
        name: string,
        availableData: any[],
        value: any,
        label?: string,
        onChange?: (data: any) => void,
        disableChange?: boolean,
        disabled?: boolean,
    ) => {
        return (
            <CustomSelect
                id={id}
                inputName={name}
                defaultValue={value || label}
                label={label}
                availableData={availableData}
                selectClass={classes.rootSelect}
                selectedClass={classes.selectedItemPagination}
                // @ts-ignore
                onChange={onChange}
                disableChange={disableChange}
                disabled={disabled}
                iconClass={classes.iconClass}
            />
        );
    };
    const renderPagination = () => (
        <Grid
            container
            item
            xs={12}
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
            className={classes.paginationContainer}
        >
            <Grid
                container
                item
                xs={7}
                direction="row"
                justify="flex-end"
                alignContent="center"
                alignItems="center"
            >
                <Pagination
                    count={totalPages}
                    page={query.page}
                    onChange={handleChangePagination}
                    classes={{ root: classes.paginationRoot }}
                    renderItem={item => (
                        <PaginationItem
                            {...item}
                            classes={{
                                root: classes.paginationElement,
                                page: classes.page,
                                selected: classes.selectedPage,
                                ellipsis: classes.paginationEllipsis,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid
                container
                item
                xs={5}
                direction="row"
                justify="flex-end"
                alignContent="center"
                alignItems="center"
            >
                <Typography className={classes.paginationSizeText}>{t('items:')}</Typography>
                {renderSelectInput(
                    'page-size',
                    'page-size',
                    [
                        { id: 10, name: 10 },
                        { id: 15, name: 15 },
                        { id: 25, name: 25 },
                    ],
                    query.size,
                    undefined,
                    data => {
                        setPageSize(data);
                    },
                )}
            </Grid>
        </Grid>
    );
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
            className={classes.tableContainer}
        >
            <ProjectDetailsDialog
                open={isProjectDetailsDialogOpen}
                handleClose={handleProjectDetailsClose}
                currentProject={currentProject}
                refreshProjectsList={() => setRefetch(!refetch)}
            />
            <ProjectsTableHeader
                isAdmin={role?.includes(UserRoles.ADMIN)}
                handleExecuteQuery={handleExecuteQuery}
            />
            {!loading ? (
                <ProjectsTableBody
                    data={projectsData}
                    handleOpenDetails={handleOpenDetails}
                    loading={loading}
                />
            ) : (
                <Grid
                    container
                    item
                    xs={12}
                    className={clsx(classes.loaderContainer)}
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                >
                    <LoaderSpinner />
                </Grid>
            )}
            {isUndefined(customQuery) && renderPagination()}
        </Grid>
    );
};

export default ProjectsTable;
