import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    expandedRow: {
        backgroundColor: '#263644',
        marginBottom: 0,

        '& td': {
            marginBottom: 0,
            borderBottomWidth: 0,
        },
    },
    tableCell: {
        color: '#C4C4C4',
        fontSize: '1rem',
        lineHeight: 1.5,
        borderBottom: '1px solid #263644',
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
    },
    rowSubComponent: {
        padding: 0,
        border: 'none',
        color: 'white',
    },
});
