import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

interface Props {
    title?: string;
    marginBottom?: boolean;
}

const InfoCard: FC<Props> = ({ title, children, marginBottom }) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.card, { [classes.cardMarginBottom]: marginBottom })}>
            <Typography className={classes.title}>{title}</Typography>
            {children}
        </Box>
    );
};

export default InfoCard;
