import React, { FC, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Checkbox, FormControl, FormControlProps, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

type Props = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
} & FormControlProps;

const RhfCheckbox: FC<Props> = ({ name, disabled, ...muiProps }) => {
    const classes = useStyles();

    const { control, setValue } = useFormContext();
    const checkboxValue = useWatch({
        control,
        name,
    });

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl fullWidth error={!!error} {...muiProps}>
                    <Checkbox
                        {...field}
                        checked={checkboxValue}
                        onChange={e => setValue(name, e.currentTarget.checked)}
                        disabled={isSubmitting || disabled}
                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                        icon={<span className={classes.icon} />}
                    />
                    {!!error && <FormHelperText>{error?.message || ''}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};

export default RhfCheckbox;
