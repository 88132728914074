/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    DictionaryEntryDto,
    PageOfProjectRowDto,
    ProjectDetailsDto,
    ProjectDto,
    ProjectUpdateRequestDto,
    StakeholderDto,
    StakeholderGroupsDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ProjectController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsUsingGet
     * @summary retrieveAll
     * @request GET:/api/v1/projects
     * @secure
     */
    apiV1ProjectsUsingGet = (
        query?: {
            filters?: string;
            offset?: number;
            paged?: boolean;
            pageNumber?: number;
            pageSize?: number;
            'sort.sorted'?: boolean;
            'sort.unsorted'?: boolean;
            unpaged?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<PageOfProjectRowDto, void>({
            path: `/api/v1/projects`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsStakeholderGroupsUsingGet
     * @summary retrieveStakeholderGroups
     * @request GET:/api/v1/projects/stakeholder-groups
     * @secure
     */
    apiV1ProjectsStakeholderGroupsUsingGet = (params: RequestParams = {}) =>
        this.request<StakeholderGroupsDto[], void>({
            path: `/api/v1/projects/stakeholder-groups`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsStakeholdersUsingGet
     * @summary retrieveStakeholders
     * @request GET:/api/v1/projects/stakeholders
     * @secure
     */
    apiV1ProjectsStakeholdersUsingGet = (params: RequestParams = {}) =>
        this.request<StakeholderDto[], void>({
            path: `/api/v1/projects/stakeholders`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsViewsDictionaryUsingGet
     * @summary viewDictionary
     * @request GET:/api/v1/projects/views/dictionary
     * @secure
     */
    apiV1ProjectsViewsDictionaryUsingGet = (params: RequestParams = {}) =>
        this.request<DictionaryEntryDto[], void>({
            path: `/api/v1/projects/views/dictionary`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsIdUsingGet
     * @summary retrieveDetails
     * @request GET:/api/v1/projects/{id}
     * @secure
     */
    apiV1ProjectsIdUsingGet = (id: number, params: RequestParams = {}) =>
        this.request<ProjectDetailsDto, void>({
            path: `/api/v1/projects/${id}`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsIdUsingGet
     * @summary retrieveDetails
     * @request GET:/api/v1/projects/{id}
     * @secure
     */
    apiV1ProjectAccessUsingGet = (id: number, params: RequestParams = {}) =>
        this.request<boolean, void>({
            path: `/api/v1/projects/${id}/access`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsIdUsingPost
     * @summary retrievePdf
     * @request POST:/api/v1/projects/{id}
     * @secure
     */
    apiV1ProjectsIdUsingPost = (
        id: number,
        data: { year?: number; quarter?: number },
        params: RequestParams = {},
    ) =>
        this.request<void, void>({
            path: `/api/v1/projects/${id}`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsIdUsingPut
     * @summary updateDetails
     * @request PUT:/api/v1/projects/{id}
     * @secure
     */
    apiV1ProjectsIdUsingPut = (id: number, request: ProjectUpdateRequestDto, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/v1/projects/${id}`,
            method: 'PUT',
            body: request,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsIdDetailsUsingPost
     * @summary retrieveProjectDetailsPdf
     * @request POST:/api/v1/projects/{id}/details
     * @secure
     */
    apiV1ProjectsIdDetailsUsingPost = (id: number, data?: any, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/v1/projects/${id}/details`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsProjectidLogoUsingPut
     * @summary updateLogo
     * @request PUT:/api/v1/projects/{projectId}/logo
     * @secure
     */
    apiV1ProjectsProjectidLogoUsingPut = (
        projectId: number,
        logoMediaId: number,
        params: RequestParams = {},
    ) =>
        this.request<ProjectDto, void>({
            path: `/api/v1/projects/${projectId}/logo`,
            method: 'PUT',
            body: logoMediaId,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags project-controller
     * @name ApiV1ProjectsProjectidLogoUsingDelete
     * @summary removeLogo
     * @request DELETE:/api/v1/projects/{projectId}/logo
     * @secure
     */
    apiV1ProjectsProjectidLogoUsingDelete = (projectId: number, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/v1/projects/${projectId}/logo`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
}
