import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        height: '100vh',
        width: '100%',
    },
    graphPaperContainer: {
        minHeight: '37.5rem',
        borderRadius: 0,
        background: '#13212D',
        padding: '2.5rem',
        position: 'relative',
    },
    YAxisLabel: {
        // transform: 'rotate(-90deg)',
    },
    AxisClass: {
        '.recharts-label': {
            fontSize: '1.25rem',
        },
    },
    graphLegend: {
        padding: '0px 0.625rem',
        margin: ' 0px 0.625rem',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginLeft: '0.625rem',
        fontSize: '1.125rem',
        color: '#708393',
    },
    barLegend: {
        width: '9%',
    },
    yDataKeyLabel: {
        marginRight: '7.5rem',
    },
    loaderContainer: {
        height: '100%',
        position: 'absolute',
        zIndex: 10,
        top: 0,
        left: 0,
    },
    generatingPDFLoader: {
        minHeight: '12.5rem',
    },
    generatingPDFLoaderText: {
        padding: '1.25rem 0px',
    },
});
