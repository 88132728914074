import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        border: 0,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '1.875rem 5rem 2.5rem 5rem',
    },
    appBarBrandName: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '1.25rem',
    },
    routerLink: {
        color: 'inherit',
        textDecoration: 'none',
    },
    backIcon: {
        fontSize: '0.625rem',
    },
    tabMenu: {
        color: '#fff',
    },
    scroller: {},
    indicator: {
        backgroundColor: '#fff',
    },
    flexContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectedTab: {},
    tabItem: {
        minHeight: '0 !important',
    },
    logOutButton: {
        color: theme.palette.primary.main,
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '1.25rem',
    },
    logOutButtonContainer: {
        width: '14.375rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));
