import { AnyAction } from 'redux';
import { CurrentUserReducer } from '../../models/Redux';
import {
    ERROR_FETCHING,
    RESET_CURRENT_USER,
    SET_CURRENT_USER,
    SET_LOADING,
    SET_AUTHENTICATED,
    LOG_OUT,
} from '../actions';

const initialState: CurrentUserReducer = {
    user: {},
    authenticated: false,
    loading: true,
    error: false,
};

const currentUserReducer = (state = initialState, action: AnyAction): CurrentUserReducer => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case SET_CURRENT_USER:
            return { ...state, user: action.payload, loading: false, authenticated: true };
        case SET_AUTHENTICATED:
            return { ...state, authenticated: true };
        case LOG_OUT:
            return { ...state, authenticated: false };
        case ERROR_FETCHING:
            return { ...state, loading: false, error: true };
        case RESET_CURRENT_USER:
            return initialState;
        default:
            return state;
    }
};
export default currentUserReducer;
