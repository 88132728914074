import React, { FC } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { useStyles } from './styles';

type Props = {
    name: `${string}` | `${string}.${string}` | `${string}.${number}`;
} & Omit<KeyboardDatePickerProps, 'value' | 'onChange'>;

const RhfDatePicker: FC<Props> = ({ name, disabled, ...props }) => {
    const classes = useStyles();

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error }, formState: { isSubmitting } }) => (
                <DatePicker
                    {...field}
                    {...props}
                    views={['year', 'month', 'date']}
                    value={field.value}
                    format={props.format}
                    onChange={e => field.onChange(e?.format('MM/DD/YY') || '')}
                    error={Boolean(error)}
                    helperText={error?.message || ''}
                    disabled={isSubmitting || disabled}
                    allowKeyboardControl={false}
                    DialogProps={{ className: classes.popup }}
                    minDate={dayjs('2000-01-01')}
                    maxDate={dayjs(`${dayjs().add(20, 'year').year()}-12-31`)}
                />
            )}
        />
    );
};

export default RhfDatePicker;
