import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Chip, Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import FilterSelectButton from '../../../../components/FilterSelectButton';
import { CurrentUserReducer, RootReducer, TasksReducer } from '../../../../models/Redux';
import { Filter } from '../../../../models/Filter';
import FilterSelectUniqueButton from '../../../../components/FilterSelectUniqueButton';
import CustomSelect from '../../../../components/CustomSelect';
import { useStyles } from './styles';
import Toolbar from '../../../../components/Toolbar';
import CustomButton from '../../../../components/CustomButton';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { UserRoles } from '../../../../models/enums/UserRoles';

const mockedFilterData: Filter[] = [
    {
        id: 1,
        name: 'Performance on planned tasks',
    },
    {
        id: 2,
        name: 'Performance on closed tasks',
    },
    {
        id: 3,
        name: 'Conformities',
    },
];

interface Props {
    onChangeGraphType: (graphType: number) => void;
    graphType: number;
    handleExecuteQuery: (filters: string) => void;
    isAdmin?: boolean;
    handleSetYear: (year: number) => void;
    year: number;
    groupBy: number;
    setGroupByValue: (value: number) => void;
    month?: number;
    handleNextYear: () => void;
    handlePreviousYear: () => void;
    handleNextMonth: () => void;
    handlePreviousMonth: () => void;
    loadingExportPDF: boolean;
    setLoadingExportPDF: Dispatch<SetStateAction<boolean>>;
    handleExportPDF: () => void;
    initialFilters: any;
}

const PerformanceHeader: FC<Props> = ({
    onChangeGraphType,
    graphType,
    handleExecuteQuery,
    year,
    groupBy,
    setGroupByValue,
    month,
    handleNextYear,
    handlePreviousYear,
    handleNextMonth,
    handlePreviousMonth,
    loadingExportPDF,
    setLoadingExportPDF,
    handleExportPDF,
    initialFilters,
}) => {
    const { t } = useTranslation();
    const { projects, disciplineAll, discipline, employeeAll, types } = useSelector<
        RootReducer,
        TasksReducer
    >(state => state.tasks);
    const { user } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);
    const classes = useStyles();

    const [projectsFilter, setProjectsFilter] = useState<Filter[]>([]);
    const [disciplineFilter, setDisciplineFilter] = useState<Filter[]>([]);
    const [assignedFilter, setAssignedFilter] = useState<Filter[]>([]);
    const [typesFilter, setTypesFilter] = useState<Filter[]>([]);

    useEffect(() => {
        if (initialFilters) {
            const initialFiltersObject = JSON.parse(initialFilters);

            setTypesFilter(
                initialFiltersObject?.taskType
                    ? types.filter(type => initialFiltersObject.taskType?.includes(type.id.toString()))
                    : [],
            );

            setProjectsFilter(
                initialFiltersObject?.projects
                    ? projects.filter(type => initialFiltersObject.projects?.includes(type.name.toString()))
                    : [],
            );

            setDisciplineFilter(
                initialFiltersObject?.discipline
                    ? discipline.filter(type =>
                          initialFiltersObject.discipline?.includes(type.name.toString()),
                      )
                    : [],
            );
        }
    }, [projects, initialFilters, types, discipline]);

    const renderLoadingDialog = () => {
        return (
            <Dialog
                onBackdropClick={() => setLoadingExportPDF(false)}
                open={loadingExportPDF}
                keepMounted
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <Grid
                        container
                        item
                        xs={12}
                        direction="column"
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                        className={classes.generatingPDFLoader}
                    >
                        <LoaderSpinner />
                        <Typography className={classes.generatingPDFLoaderText}>
                            {t('Generating PDF...')}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => setLoadingExportPDF(false)}
                            style={{ backgroundColor: '#E6521A', color: '#FFFFFF' }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    };

    const allSelectedFilters = useMemo(() => {
        return projectsFilter
            .map(item => {
                return { ...item, filterType: 'project-select' };
            })
            .concat(
                disciplineFilter.map(item => {
                    return { ...item, filterType: 'discipline-select' };
                }),
                assignedFilter.map(item => {
                    return { ...item, filterType: 'assigned-to-select' };
                }),
                typesFilter.map(item => {
                    return { ...item, filterType: 'types-select' };
                }),
            );
    }, [projectsFilter, disciplineFilter, assignedFilter, typesFilter]);

    const selectedFilters = useRef<any>(initialFilters || {});

    useEffect(() => {
        selectedFilters.current = {};
    }, [graphType]);

    const removeFilterFromArray = (
        filterArray: Filter[],
        elementToRemove: Filter,
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...filterArray];

        if (filterArray.find(filter => filter.id === elementToRemove.id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== elementToRemove.id);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    const parseSelectedFilters = (
        previousFilters: Filter[],
        newFiltersArray: Filter[],
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...previousFilters];

        if (previousFilters.find(filter => filter.id === newFiltersArray[0].id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== newFiltersArray[0].id);
        } else {
            updatedFilter.push(newFiltersArray[0]);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    const handleSelectFilter = (newArray: Filter[], filterName: string) => {
        switch (filterName) {
            /* eslint-disable */
            case 'project-select':
                const projectNewValues = parseSelectedFilters(projectsFilter, newArray);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }

                setProjectsFilter(projectNewValues.newFilterArray);
                break;
            case 'discipline-select':
                const disciplineNewValues = parseSelectedFilters(disciplineFilter, newArray);

                selectedFilters.current.discipline = disciplineNewValues.newValues;
                if (isEmpty(disciplineNewValues.newValues)) {
                    delete selectedFilters.current.discipline;
                }

                setDisciplineFilter(disciplineNewValues.newFilterArray);
                break;
            case 'assigned-to-select':
                const assignedToNewValues = parseSelectedFilters(assignedFilter, newArray);

                selectedFilters.current.assignedTo = assignedToNewValues.newValues;
                if (isEmpty(assignedToNewValues.newValues)) {
                    delete selectedFilters.current.assignedTo;
                }

                setAssignedFilter(assignedToNewValues.newFilterArray);
                break;
            case 'types-select':
                const typeNewValues = parseSelectedFilters(typesFilter, newArray);

                selectedFilters.current.taskType = typeNewValues.newValues;
                if (isEmpty(typeNewValues.newValues)) {
                    delete selectedFilters.current.taskType;
                }
                setTypesFilter(typeNewValues.newFilterArray);
                break;
            default:
                break;
        }
    };
    /* eslint-enable */

    const handleRemoveFilter = (elementToRemove: Filter, filterName: string) => {
        /* eslint-disable */
        switch (filterName) {
            case 'project-select':
                const projectNewValues = removeFilterFromArray(projectsFilter, elementToRemove);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }
                setProjectsFilter(projectNewValues.newFilterArray);
                break;
            case 'discipline-select':
                const disciplineFilterNewValues = removeFilterFromArray(disciplineFilter, elementToRemove);

                selectedFilters.current.discipline = disciplineFilterNewValues.newValues;
                if (isEmpty(disciplineFilterNewValues.newValues)) {
                    delete selectedFilters.current.discipline;
                }
                setDisciplineFilter(disciplineFilterNewValues.newFilterArray);

                break;
            case 'assigned-to-select':
                const assignedToNewValues = removeFilterFromArray(assignedFilter, elementToRemove);

                selectedFilters.current.assignedTo = assignedToNewValues.newValues;
                if (isEmpty(assignedToNewValues.newValues)) {
                    delete selectedFilters.current.assignedTo;
                }
                setAssignedFilter(assignedToNewValues.newFilterArray);

                break;
            case 'types-select':
                const typeNewValues = removeFilterFromArray(typesFilter, elementToRemove);
                selectedFilters.current.taskType = typeNewValues.newValues;
                if (isEmpty(typeNewValues.newValues)) {
                    delete selectedFilters.current.taskType;
                }
                setTypesFilter(typeNewValues.newFilterArray);

                break;
            default:
                break;
        }
        /* eslint-enable */
    };

    const handleDelete = (item: { filterType: string; id: any; name: string }) => {
        handleRemoveFilter({ id: item.id, name: item.name }, item.filterType);
        handleExecuteQuery(JSON.stringify(selectedFilters.current));
    };

    const executeQuery = () => {
        handleExecuteQuery(JSON.stringify(selectedFilters.current));
    };

    const renderSelectInput = (
        id: string,
        name: string,
        availableData: any[],
        value: any,
        label?: string,
        onChange?: (data: any) => void,
        disableChange?: boolean,
        disabled?: boolean,
    ) => {
        return (
            <CustomSelect
                id={id}
                inputName={name}
                defaultValue={value || label}
                label={label}
                availableData={availableData}
                selectClass={classes.rootSelect}
                selectedClass={classes.selectedItem}
                // @ts-ignore
                onChange={onChange}
                disableChange={disableChange}
                disabled={disabled}
                iconClass={classes.iconClass}
            />
        );
    };

    const parsedMonth = useMemo(() => {
        return month && month < 10 ? `0${month}` : month;
    }, [month]);

    return (
        <>
            <Toolbar>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FilterSelectUniqueButton
                        id="time-select"
                        name="time-select"
                        filterName={t('in time')}
                        onChange={onChangeGraphType}
                        availableFilters={mockedFilterData}
                        value={graphType}
                    />
                    <FilterSelectButton
                        id="project-select"
                        name="project-select"
                        filterName={t('project')}
                        onChange={newValue => handleSelectFilter(newValue, 'project-select')}
                        onClose={executeQuery}
                        availableFilters={projects.map(item => {
                            return { id: item.name, name: item.name };
                        })}
                        values={projectsFilter.map(item => {
                            return { id: item.name, name: item.name };
                        })}
                    />

                    {graphType === 2 && (
                        <FilterSelectButton
                            id="assigned-to-select"
                            name="assigned-to-select"
                            filterName={t('assigned to')}
                            onChange={newValue => handleSelectFilter(newValue, 'assigned-to-select')}
                            onClose={executeQuery}
                            availableFilters={employeeAll.map(item => {
                                return { id: item.name, name: item.name };
                            })}
                            values={assignedFilter}
                        />
                    )}

                    <FilterSelectButton
                        id="discipline-select"
                        name="discipline-select"
                        filterName={t('discipline')}
                        onChange={newValue => handleSelectFilter(newValue, 'discipline-select')}
                        onClose={executeQuery}
                        availableFilters={disciplineAll.map(item => {
                            return { id: item.name, name: item.name };
                        })}
                        values={disciplineFilter.map(item => {
                            return { id: item.name, name: item.name };
                        })}
                    />
                    {graphType !== 3 && (
                        <FilterSelectButton
                            id="types-select"
                            name="types-select"
                            filterName={t('deliverables')}
                            onChange={newValue => handleSelectFilter(newValue, 'types-select')}
                            onClose={executeQuery}
                            availableFilters={types}
                            values={typesFilter}
                        />
                    )}
                </div>
                <Grid container>
                    <Grid item xs>
                        <Grid container direction="row-reverse" style={{ padding: 5 }}>
                            <Grid item>
                                {user?.role?.includes(UserRoles.ADMIN) && (
                                    <CustomButton
                                        // @ts-ignore
                                        onClick={handleExportPDF}
                                        label={t('export pdf')}
                                        buttonClassName={clsx(classes.exportPDFButton)}
                                    />
                                )}
                            </Grid>
                            <Grid />
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>

            <Grid container>
                {renderLoadingDialog()}

                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignContent="center"
                    justify="flex-start"
                    alignItems="center"
                >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {renderSelectInput(
                            'group-task-by',
                            'group-task-by',
                            [
                                { id: 1, name: t('Year') },
                                { id: 2, name: t('Month') },
                            ],
                            groupBy,
                            undefined,
                            data => {
                                setGroupByValue(data);
                            },
                        )}
                        {groupBy === 1 && (
                            <>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handlePreviousYear}
                                >
                                    <ArrowBackIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                                <Typography className={classes.headerText}>{year}</Typography>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handleNextYear}
                                >
                                    <ArrowForwardIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                            </>
                        )}
                        {groupBy === 2 && (
                            <>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handlePreviousMonth}
                                >
                                    <ArrowBackIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                                <Typography className={classes.headerText}>
                                    {t(
                                        `${dayjs(`01/${parsedMonth}/2021`, 'DD/MM/YYYY').format(
                                            'MMM',
                                        )} - ${year}`,
                                    )}
                                </Typography>
                                <IconButton
                                    aria-label="more"
                                    className={classes.actionIconButton}
                                    disableRipple
                                    onClick={handleNextMonth}
                                >
                                    <ArrowForwardIcon className={classes.nextPreviousIcon} />
                                </IconButton>
                            </>
                        )}
                    </div>
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    className={classes.chipContainer}
                    alignContent="center"
                    alignItems="center"
                >
                    {allSelectedFilters.map(item => {
                        return (
                            <Chip
                                label={item.name}
                                size="small"
                                color="primary"
                                className={classes.chipClass}
                                onDelete={() => handleDelete(item)}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
};

export default PerformanceHeader;
