/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateTaskRequestDto,
  PageOfTaskRowDto,
  TaskDto,
  TaskRequirementStatusRequestDto,
  UpdateTaskRequestDto,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class TaskController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags task-controller
   * @name ApiV1TasksUsingGet
   * @summary retrieveAll
   * @request GET:/api/v1/tasks
   * @secure
   */
  apiV1TasksUsingGet = (
    query: {
      filters?: string;
      month?: number;
      page: number;
      search?: string;
      size: number;
      sorted?: boolean;
      unsorted?: boolean;
      week?: number;
      year: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<PageOfTaskRowDto, void>({
      path: `/api/v1/tasks`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags task-controller
   * @name ApiV1TasksUsingPost
   * @summary create
   * @request POST:/api/v1/tasks
   * @secure
   */
  apiV1TasksUsingPost = (request: CreateTaskRequestDto, params: RequestParams = {}) =>
    this.request<TaskDto, void>({
      path: `/api/v1/tasks`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags task-controller
   * @name ApiV1TasksTodoIdStatusUsingPut
   * @summary updateTaskRequirementStatus
   * @request PUT:/api/v1/tasks/todo/{id}/status
   * @secure
   */
  apiV1TasksTodoIdStatusUsingPut = (id: number, request: TaskRequirementStatusRequestDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/v1/tasks/todo/${id}/status`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags task-controller
   * @name ApiV1TasksIdUsingGet
   * @summary retrieve
   * @request GET:/api/v1/tasks/{id}
   * @secure
   */
  apiV1TasksIdUsingGet = (id: number, params: RequestParams = {}) =>
    this.request<TaskDto, void>({
      path: `/api/v1/tasks/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags task-controller
   * @name ApiV1TasksIdUsingPut
   * @summary update
   * @request PUT:/api/v1/tasks/{id}
   * @secure
   */
  apiV1TasksIdUsingPut = (id: number, request: UpdateTaskRequestDto, params: RequestParams = {}) =>
    this.request<TaskDto, void>({
      path: `/api/v1/tasks/${id}`,
      method: "PUT",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags task-controller
   * @name ApiV1TasksIdStatusUsingPatch
   * @summary updateTaskStatus
   * @request PATCH:/api/v1/tasks/{id}/status
   * @secure
   */
  apiV1TasksIdStatusUsingPatch = (id: number, query: { statusId: number }, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/v1/tasks/${id}/status`,
      method: "PATCH",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
