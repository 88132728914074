import { makeStyles } from '@material-ui/styles';
import { theme } from '../../../config/theme';

export const useStyles = makeStyles({
    chipContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '0.625rem',
        marginRight: 0,
        overflowX: 'auto',
        overflowY: 'hidden',
        flexWrap: 'initial',
        maxHeight: '4.25rem',
        paddingBottom: '0.625rem',
        width: '100%',
    },
    chip: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.main,
        margin: 5,
        borderRadius: '0.938rem',
        color: '#fff',
        padding: '0.5rem 1rem',
    },
    filterTypeText: {
        fontSize: 12,
        fontWeight: 300,
    },
    filterText: {
        fontSize: '0.75rem',
        fontWeight: 700,
    },
    closeIconContainer: {
        width: '1.25rem',
        height: '1.25rem',
        backgroundColor: theme.palette.background.default,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5rem',
    },
    closeIcon: {
        width: '0.938rem',
        height: '0.938rem',
        cursor: 'pointer',
    },
});
