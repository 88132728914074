import { FC } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface Props {
    paperElevation: number;
}

const EmptyTableRow: FC<Props> = ({ paperElevation }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Paper elevation={paperElevation} className={classes.emptyDataPlaceholder}>
            <Typography>{t('There is no content to show')}</Typography>
        </Paper>
    );
};

export default EmptyTableRow;
