import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { FormFieldsBoxVariant } from '../../models/enums/FormFieldsBoxVariant';
import { useStyles } from './styles';

interface Props {
    title?: string;
    variant?: FormFieldsBoxVariant;
}

const FormFieldsBox: FC<Props> = ({ title, variant = FormFieldsBoxVariant.LIGHT, children }) => {
    const classes = useStyles({ variant });

    return (
        <Box className={classes.container}>
            {!!title && <Typography className={classes.title}>{title}</Typography>}
            <Box className={classes.childrenWrapper}>{children}</Box>
        </Box>
    );
};

export default FormFieldsBox;
