import { red } from '@material-ui/core/colors';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
// @ts-ignore
import CarnasRegular from '../assets/fonts/Carnas-W03-Regular.ttf';

export const APP_BAR_HEIGHT = 132;
export const TABLE_TOOLBAR_HEIGHT = 82;

const carnasRegular = {
    fontFamily: 'CarnasW03-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
    local('CarnasW03-Regular'),
    url(${CarnasRegular}) format('truetype')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const theme: Theme = createMuiTheme({
    typography: {
        fontFamily: 'Ubuntu',
        allVariants: {
            color: '#FFFFFF',
        },
        subtitle1: {
            color: '#DA4E17',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [carnasRegular],
            },
        },
        MuiTab: {
            root: {
                minWidth: '50px!important',
                borderBottom: '2px solid #fff',
                textTransform: 'none',
                opacity: '0.6',
                padding: '6px 20px',
                fontSize: 20,
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '1rem',
            },
        },
        MuiCheckbox: {
            root: {
                margin: '0px 2.5px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiInputBase: {
            root: {
                color: 'white',
                paddingLeft: 20,
            },
        },
        MuiFilledInput: {
            root: {
                borderTopLeftRadius: 16,
                borderBottomRightRadius: 16,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
                overflow: 'hidden',
                backgroundColor: '#16212c',

                '& .MuiSvgIcon-root': {
                    color: '#748291',
                    fontSize: 17,
                },

                '& .MuiIconButton-root': {
                    padding: 0,
                },
            },
            input: {
                paddingTop: 10,
                fontSize: '1.063rem',
                height: 27,
                fontWeight: 400,
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        MuiSelect: {
            root: {
                paddingTop: 14,
                paddingBottom: 10,
                paddingLeft: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',

                '& .MuiList-root': {
                    backgroundColor: '#16212c',
                },
                '&.Mui-disabled': {
                    color: '#748291',
                },
            },
            icon: {
                color: '#748291',
                marginRight: 5,
            },
        },
        MuiTextField: {
            root: {
                width: '100%',
                color: 'white',
            },
        },
        MuiMenu: {
            list: {
                backgroundColor: '#16212c',
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
            },
            contained: {
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 16,
                padding: '14px 26px',

                '&.Mui-disabled': {
                    backgroundColor: '#DA4E17',
                    color: '#fff',
                    opacity: '0.2',
                    padding: '14px 26px',
                },

                '& span': {
                    fontWeight: 600,
                    fontSize: 18,
                    lineHeight: 1.15,
                },
            },
        },
        MuiTypography: {
            root: {
                '& svg': {
                    fontSize: 'inherit',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#DA4E17',
        },
        secondary: {
            main: '#16212c',
            dark: '#13212D',
            light: '#263644',
        },
        background: {
            default: '#16212c',
        },
        error: {
            main: red[500],
        },
        warning: {
            main: '#f57c00',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 321,
            md: 641,
            lg: 1284,
            xl: 1441,
        },
    },
});
