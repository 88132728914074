import { FC } from 'react';
import { Box, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { useHistory } from 'react-router-dom';
import { UserInfoDto } from '../../../api/data-contracts';
import { useStyles } from './styles';

interface Props {
    user: UserInfoDto | undefined;
}

const UserActions: FC<Props> = ({ user }) => {
    const classes = useStyles();

    const history = useHistory();

    return user?.id ? (
        <Box display="flex" flexDirection="row" alignItems="center" gridColumnGap="20px">
            {!!user?.phoneNo && (
                <Button
                    variant="contained"
                    className={classes.contactButton}
                    onClick={e => {
                        e.preventDefault();
                        window.open(`tel:${user.phoneNo}`);
                    }}
                >
                    <CallOutlinedIcon />
                </Button>
            )}

            {!!user?.email && (
                <Button
                    variant="contained"
                    className={classes.contactButton}
                    onClick={e => {
                        e.preventDefault();
                        window.open(`mailto:${user.email}`);
                    }}
                >
                    <EmailOutlinedIcon />
                </Button>
            )}

            <Button
                variant="contained"
                color="primary"
                endIcon={<EditIcon />}
                onClick={() => history.push(`/users/form/${user.id}`)}
            >
                edit user
            </Button>
        </Box>
    ) : null;
};

export default UserActions;
