import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        display: 'grid',
        [theme.breakpoints.up('lg')]: {
            rowGap: 70,
            columnGap: 70,
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.down('lg')]: {
            rowGap: 50,
            columnGap: 50,
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('md')]: {
            rowGap: 30,
            columnGap: 30,
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('lg')]: {
            rowGap: 70,
        },
        [theme.breakpoints.down('lg')]: {
            rowGap: 50,
        },
        [theme.breakpoints.down('md')]: {
            rowGap: 30,
        },
    },
}));
