import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Pagination, PaginationItem } from '@material-ui/lab';
import dayjs from 'dayjs';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useStyles } from './styles';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { getTaskAudit } from '../../../../services/TasksService';
import { getBackendErrorMessage } from '../../../../config/utils';
import { TaskAuditDto } from '../../../../api/data-contracts';
import { DATE_TIME_FORMAT } from '../../../../config/constants';
import CustomButton from '../../../../components/CustomButton';
import FormFieldsBox from '../../../../components/FormFieldsBox';
import { FormFieldsBoxVariant } from '../../../../models/enums/FormFieldsBoxVariant';

interface Props {
    taskId: number;
    showAudit: boolean;
    showDetails: boolean;
    handleCloseTaskAudit: () => void;
}

const TaskAuditCollapse: FC<Props> = ({ taskId, showAudit, showDetails, handleCloseTaskAudit }) => {
    const classes = useStyles({ showAudit, showDetails });
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);

    const [tasks, setTasks] = useState<TaskAuditDto[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const fetchTaskAuditById = useCallback(
        (page = 1) => {
            setLoading(true);

            getTaskAudit(taskId, page - 1)
                .then(response => {
                    setTasks(response.content || []);
                    setTotalPages(response.totalPages || 0);
                    setCurrentPage(response.number || 0);
                })
                .catch(error => {
                    enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                        variant: 'error',
                    });

                    handleCloseTaskAudit();
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [enqueueSnackbar, handleCloseTaskAudit, t, taskId],
    );

    useEffect(() => {
        if (showAudit) {
            fetchTaskAuditById();
        }
    }, [fetchTaskAuditById, showAudit]);

    const handleChangePagination = (event: ChangeEvent<unknown>, newPage: number) => {
        fetchTaskAuditById(newPage);
    };

    return (
        <Collapse
            in={showAudit}
            timeout="auto"
            unmountOnExit
            style={{
                width: '100%',
            }}
        >
            <Paper elevation={0} className={clsx(classes.paperBackground, classes.noBorder)}>
                {!loading ? (
                    <Grid
                        container
                        xs={12}
                        direction="column"
                        alignItems="center"
                        className={classes.auditRemarkList}
                    >
                        {tasks.length ? (
                            <>
                                {tasks.map((task, key) => (
                                    <Grid item xs={12} key={key} className={classes.auditRemarkListItem}>
                                        {dayjs(task.time).isValid() && (
                                            <div style={{ margin: 30 }}>
                                                <FormFieldsBox variant={FormFieldsBoxVariant.DARK}>
                                                    <Typography className={classes.detailsTitle}>
                                                        {t('Edited by: ')}
                                                        {task.employee?.name || 'N/A'}
                                                        {t(' on ')}
                                                        {dayjs(task.time).format(DATE_TIME_FORMAT)}
                                                    </Typography>

                                                    <Typography className={classes.detailsContent}>
                                                        {t('Remarks: ')}
                                                        {task.remarks || ''}
                                                    </Typography>
                                                </FormFieldsBox>
                                            </div>
                                        )}
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <Typography style={{ marginTop: 15 }}>No audit logs available</Typography>
                        )}

                        <Grid item container xs={12} direction="row" justify="space-between">
                            <Grid item style={{ width: 40 }} />
                            {!!tasks.length && (
                                <Pagination
                                    count={totalPages}
                                    page={currentPage + 1}
                                    variant="outlined"
                                    color="primary"
                                    onChange={handleChangePagination}
                                    renderItem={item => <PaginationItem {...item} />}
                                />
                            )}

                            <CustomButton
                                onClick={handleCloseTaskAudit}
                                icon={<KeyboardArrowUpIcon />}
                                buttonClassName={classes.collapseDetailsButton}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                    >
                        <LoaderSpinner />
                    </Grid>
                )}
            </Paper>
        </Collapse>
    );
};

export default TaskAuditCollapse;
