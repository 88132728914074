import { FC } from 'react';
import { Route, RouteProps, RouteComponentProps, Redirect } from 'react-router-dom';
import { useLocalStorage } from '../../config/hooks/useLocalStorage';
import { Token } from '../../models/enums/Token';
import { ROUTES } from '../../config/constants';

interface PublicRouteProps extends RouteProps {
    component: FC<RouteComponentProps>;
}

const PublicRoute: FC<PublicRouteProps> = ({ component: Component, path, ...rest }) => {
    const [token] = useLocalStorage(Token.TOKEN, null);

    if (path === ROUTES.PUBLIC.LOGIN && token) {
        return <Redirect to={{ pathname: ROUTES.AUTHORIZED.DASHBOARD }} />;
    }

    return <Route {...rest} path={path} render={routeProps => <Component {...routeProps} />} />;
};

export default PublicRoute;
