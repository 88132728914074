import axios from 'axios';
import Media from '../models/Media';
import { MediaDto } from '../api/data-contracts';

export type ServerError = {
    response: {
        data: {
            title: string;
            code: number;
            detail: string;
        };
    };
};

export const uploadMediaFile = async (file: File): Promise<Media | string[]> => {
    const data = new FormData();
    data.append('file', file);
    const { data: responseData } = await axios.post(`api/v1/media`, data);
    return responseData;
};

export const uploadCsvFile = async (file: File): Promise<string[]> => {
    const data = new FormData();
    data.append('file', file);
    const { data: responseData } = await axios.post(`admin/venues`, data);
    return responseData;
};

export const getMediaById = async (id: number): Promise<Media> => {
    const { data: responseData } = await axios.get(`api/v1/media/${id}`);
    return responseData;
};

export const uploadImageBlob = async (blob: Blob | string): Promise<MediaDto> => {
    const formData = new FormData();
    formData.append('file', blob, 'blob.png');
    const { data: responseData } = await axios.post(`api/v1/media`, formData);
    return responseData;
};
