import { isEmpty, isNumber, isString } from 'lodash';
import {
    BarChartData,
    BarData,
    ConformitiesData,
    InTimeData,
    PerformanceRawResponse,
    TooLateData,
} from '../../../models/Performance';

enum DataTypes {
    INTIMEDATA = 'IN_TIME_DATA',
    TOOLATEDATA = 'TOO_LATE_DATA',
    CONFORMITYDATA = 'CONFORMITY_DATA',
    UNKWNOWN = 'UNKWNOWN',
}

const parseTypeOfGraphFromData = (data: PerformanceRawResponse): DataTypes => {
    if (isString(data?.date)) {
        return DataTypes.CONFORMITYDATA;
    }

    if (
        isNumber(data?.inTime) &&
        isNumber(data?.tooLate) &&
        isNumber(data?.notClosed) &&
        isNumber(data?.week)
    ) {
        return DataTypes.TOOLATEDATA;
    }

    if (isNumber(data?.inTime) && isNumber(data?.numberOfTasks) && isNumber(data?.week)) {
        return DataTypes.INTIMEDATA;
    }

    return DataTypes.UNKWNOWN;
};

const parseTypeOfGraphFromIndex = (index: number): DataTypes => {
    switch (index) {
        case 1:
            return DataTypes.INTIMEDATA;
        case 2:
            return DataTypes.TOOLATEDATA;
        case 3:
            return DataTypes.CONFORMITYDATA;
        default:
            return DataTypes.UNKWNOWN;
    }
};

const parseGraphBars = (graphType: DataTypes): BarData[] => {
    switch (graphType) {
        case DataTypes.INTIMEDATA:
            return [
                {
                    label: 'In time',
                    dataKey: 'inTime',
                    id: 'a',
                    color: '#00ac0e',
                },
                {
                    label: 'Too late',
                    dataKey: 'tooLate',
                    id: 'a',
                    color: '#e6521a',
                },
                {
                    label: 'Not closed',
                    dataKey: 'notClosed',
                    id: 'a',
                    color: '#263644',
                },
            ];
        case DataTypes.TOOLATEDATA:
            return [
                {
                    label: 'In time',
                    dataKey: 'inTime',
                    id: 'a',
                    color: '#00ac0e',
                },
                {
                    label: 'Too late',
                    dataKey: 'tooLate',
                    id: 'a',
                    color: '#e6521a',
                },
            ];
        case DataTypes.CONFORMITYDATA:
            return [
                {
                    label: 'conform',
                    dataKey: 'conform',
                    id: 'a',
                    color: '#65c2ec',
                },
                {
                    label: 'niet conform',
                    dataKey: 'notConform',
                    id: 'a',
                    color: '#e6521a',
                },
                {
                    label: 'no more testing',
                    dataKey: 'numberOfTasks',
                    id: 'a',
                    color: '#ebebeb',
                },
            ];
        default:
            return [];
    }
};

const parseGraphXDataKey = (graphType: DataTypes): string => {
    switch (graphType) {
        case DataTypes.INTIMEDATA:
            return 'week';
        case DataTypes.TOOLATEDATA:
            return 'week';
        case DataTypes.CONFORMITYDATA:
            return 'date';
        default:
            return '';
    }
};

const parseGraphYDataKey = (graphType: DataTypes): string => {
    switch (graphType) {
        case DataTypes.INTIMEDATA:
            return 'week';
        case DataTypes.TOOLATEDATA:
            return 'week';
        case DataTypes.CONFORMITYDATA:
            return 'date';
        default:
            return '';
    }
};

const parseDataByType = (
    graphType: DataTypes,
    data: PerformanceRawResponse[],
): InTimeData[] | TooLateData[] | ConformitiesData[] => {
    switch (graphType) {
        case DataTypes.INTIMEDATA:
            return data.map(dataItem => {
                const item = dataItem as InTimeData;
                return {
                    week: item.week,
                    tooLate: item.tooLate,
                    notClosed: item.notClosed,
                    inTime: item.inTime,
                };
            });
        case DataTypes.TOOLATEDATA:
            return data.map(dataItem => {
                const item = dataItem as TooLateData;
                return {
                    week: item.week,
                    tooLate: item.tooLate,
                    inTime: item.inTime,
                };
            });
        case DataTypes.CONFORMITYDATA:
            return data.map(dataItem => {
                const item = dataItem as ConformitiesData;
                return {
                    date: item.date,
                    conform: item.conform,
                    notConform: item.notConform,
                    numberOfTasks: item.numberOfTasks,
                };
            });
        default:
            return [];
    }
};

export const parsePerformanceData = (
    graphTypeIndex: number,
    data: PerformanceRawResponse[],
): BarChartData => {
    const graphType = !isEmpty(data) ? parseTypeOfGraphFromIndex(graphTypeIndex) : DataTypes.UNKWNOWN;

    const parsedResult = {
        bars: parseGraphBars(graphType),
        xDataKey: parseGraphXDataKey(graphType),
        yDataKey: parseGraphYDataKey(graphType),
        data: parseDataByType(graphType, data),
    };

    return parsedResult;
};
