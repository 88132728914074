import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
    Box,
    Button,
    TableCell as MuiTableCell,
    TableRow as MuiTableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { UserRowDto } from '../../api/data-contracts';
import CustomTable from '../../components/CustomTable';
import { getUsers, UsersQuery } from '../../services/UserService';
import { queryKeys } from '../../react-query/constants';
import UserCell from './UserCell';
import Filters from './Filters';
import InviteUserDialog from './InviteUserDialog';
import { useStyles } from './styles';

const UsersDashboard: FC = () => {
    const classes = useStyles();
    const { push, replace } = useHistory();
    const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState<boolean>(false);
    const [areFiltersLoaded, setAreFiltersLoaded] = useState<boolean>(false);

    const { search } = useLocation();

    const [query, setQuery] = useState<UsersQuery>({
        disciplines: [],
        search: undefined,
        startDate: undefined,
        endDate: undefined,
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(search);

        setQuery({
            disciplines:
                searchParams
                    .get('disciplines')
                    ?.split(',')
                    ?.filter(discipline => !isEmpty(discipline))
                    ?.map(discipline => parseInt(discipline, 10)) || [],
            search: searchParams.get('search') || '',
            startDate: searchParams.get('startDate') || undefined,
            endDate: searchParams.get('endDate') || undefined,
        });

        setAreFiltersLoaded(true);
    }, [search]);

    const setFilters = useCallback(
        (filters: UsersQuery) => {
            const searchParams = new URLSearchParams(search);

            replace({
                pathname: '/users',
                search: `?${Object.entries({
                    ...filters,
                    page: 1,
                    size: searchParams.get('size') || 10,
                })
                    .map(e => e.join('='))
                    .join('&')}`,
            });
        },
        [replace, search],
    );

    const handlePageChange = (page: number, size: number) => {
        const searchParams = new URLSearchParams(search);

        replace({
            pathname: '/users',
            search: `?${Object.entries({
                disciplines: searchParams.get('disciplines') || '',
                search: searchParams.get('search') || '',
                startDate: searchParams.get('startDate') || '',
                endDate: searchParams.get('endDate') || '',
                page,
                size,
            })
                .map(e => e.join('='))
                .join('&')}`,
        });
    };

    const columns = useMemo<ColumnDef<UserRowDto>[]>(
        () => [
            {
                id: 'name',
                header: 'name',
                cell: ({ row }) => <UserCell avatar={row.original.avatar} name={row.original.name} />,
            },
            {
                id: 'upcomingDeadlines',
                accessorKey: 'upcomingDeadlines',
                header: () => (
                    <Tooltip title="all open tasks with deadline date between start/end of selected dates">
                        <Typography className={classes.headerCellText}>upcoming deadlines</Typography>
                    </Tooltip>
                ),
                cell: ({ row }) => (
                    <Typography style={{ color: row.original.upcomingDeadlines ? '#F80000' : '#C4C4C4' }}>
                        {row.original.upcomingDeadlines}
                    </Typography>
                ),
            },
            {
                id: 'tasksCompleted',
                accessorKey: 'tasksCompleted',
                header: () => (
                    <Tooltip title="all tasks with a status “closed” and deadline date between start/end of selected dates">
                        <Typography className={classes.headerCellText}>completed tasks</Typography>
                    </Tooltip>
                ),
            },
            {
                id: 'openTasks',
                accessorKey: 'openTasks',
                header: () => (
                    <Tooltip title="all open tasks with deadline date before the end date of selected dates">
                        <Typography className={classes.headerCellText}>open tasks</Typography>
                    </Tooltip>
                ),
            },
            {
                id: 'actions',
                header: () => null,
                cell: ({ row }) => (
                    <Box display="flex" justifyContent="flex-end">
                        <Tooltip title="edit user">
                            <Button
                                onClick={() => {
                                    push(`/users/form/${row.original.id}`);
                                }}
                            >
                                <EditIcon style={{ fontSize: 24, color: '#708393' }} />
                            </Button>
                        </Tooltip>
                    </Box>
                ),
            },
        ],
        [classes, push],
    );

    const renderEmptyTable = () => (
        <MuiTableRow>
            <MuiTableCell colSpan={columns.length}>
                <Typography>No users found.</Typography>
            </MuiTableCell>
        </MuiTableRow>
    );

    return (
        <>
            {areFiltersLoaded && (
                <Filters
                    values={query}
                    setValues={setFilters}
                    inviteUser={() => setInviteUserDialogOpen(true)}
                />
            )}
            <CustomTable<UserRowDto, UsersQuery>
                columns={columns}
                fetchFunction={getUsers}
                query={query}
                queryKeys={[queryKeys.users]}
                renderEmptyTable={renderEmptyTable}
                onRowClick={(user: UserRowDto) => {
                    push(`/users/${user.id}`, {
                        startDate: query.startDate,
                        endDate: query.endDate,
                    });
                }}
                handlePageChange={handlePageChange}
            />
            <InviteUserDialog
                open={inviteUserDialogOpen}
                handleClose={() => setInviteUserDialogOpen(false)}
            />
        </>
    );
};

export default UsersDashboard;
