import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useStyles } from './styles';
import ProjectsTableRow from '../ProjectsTableRow';
import { ProjectSimpleData } from '../../../../models/Project';
import EmptyTableRow from '../../../../components/EmptyTableRow';

interface Props {
    data: ProjectSimpleData[];
    handleOpenDetails: (project: ProjectSimpleData) => void;
    loading: boolean;
}

const ProjectsTableBody: FC<Props> = ({ data, handleOpenDetails, loading }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.tableContainer}>
            {data.map((item, index) => (
                <ProjectsTableRow key={index} item={item} handleOpenDetails={handleOpenDetails} />
            ))}
            {isEmpty(data) && <EmptyTableRow paperElevation={1} />}
        </Grid>
    );
};

export default ProjectsTableBody;
