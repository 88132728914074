import { Checkbox, CheckboxProps, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

import { useStyles } from './styles';

interface Props extends CheckboxProps {
    tooltip?: string;
    disableTooltip?: boolean;
    defaultCursor?: boolean;
}

const CustomCheckbox: FC<Props> = props => {
    const classes = useStyles();
    const { disableTooltip, tooltip, defaultCursor } = props;

    const renderCheckbox = () => (
        <Checkbox
            className={clsx({
                [classes.defaultCursor]: defaultCursor,
            })}
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );

    return disableTooltip ? renderCheckbox() : <Tooltip title={tooltip || ''}>{renderCheckbox()}</Tooltip>;
};

export default CustomCheckbox;
