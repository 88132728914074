import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getTaskInitialValues, validationSchema } from './config';
import { getBackendErrorMessage, parseTaskToTaskBodyForm } from '../../../../config/utils';
import { Task, TaskBodyForm } from '../../../../models/Task';
import { Conformity } from '../../../../models/Conformity';
import { createTask, getTaskConformityById, updateTask } from '../../../../services/TasksService';
import EditTaskDialogForm from './Parts/EditTaskDialogForm';
import { RootReducer, TasksReducer } from '../../../../models/Redux';
import { CreateTaskRequestDto } from '../../../../api/data-contracts';

interface Props {
    open: boolean;
    currentTask?: Task;
    isEditing: boolean;
    handleClose: () => void;
}

const EditTaskDialog: FC<Props> = ({ open, handleClose, currentTask, isEditing }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const { projects, employeeAll, types } = useSelector<RootReducer, TasksReducer>(state => state.tasks);
    const [currentConformity, setCurrentConformity] = useState<Conformity>();

    const initialValues = useMemo(() => {
        if (currentTask)
            return getTaskInitialValues(parseTaskToTaskBodyForm(currentTask, projects, employeeAll, types));
        return getTaskInitialValues();
    }, [currentTask, employeeAll, types, projects]);

    const handleChangeConformity = useCallback(
        (id: number) => {
            if (currentTask) {
                getTaskConformityById(id, currentTask.id)
                    .then(conformity => {
                        setCurrentConformity(conformity as Conformity);
                    })
                    .catch(error => {
                        enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                            variant: 'error',
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [currentTask, enqueueSnackbar, t],
    );

    useEffect(() => {
        if (
            open &&
            currentTask?.conformities &&
            !isEmpty(currentTask?.conformities) &&
            !currentConformity &&
            !loading
        ) {
            handleChangeConformity(currentTask?.conformities[0]);
        }
    }, [currentConformity, currentTask, handleChangeConformity, loading, open]);

    // @ts-ignore
    const handleSubmitForm = async (values: TaskBodyForm, { resetForm }) => {
        try {
            if (isEditing) {
                // await updateTask(values);
            } else {
                await createTask(values as CreateTaskRequestDto);
            }

            enqueueSnackbar(isEditing ? t('Task successfully updated.') : t('Task successfully created.'), {
                variant: 'success',
            });
            resetForm();
            handleClose();
        } catch (error) {
            enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                variant: 'error',
            });
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
        >
            <Form>
                <EditTaskDialogForm
                    open={open}
                    loading={loading}
                    handleClose={handleClose}
                    isEditing={isEditing}
                />
            </Form>
        </Formik>
    );
};

export default EditTaskDialog;
