import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    container: {
        width: '100vw',
        marginBottom: '2.5rem',
        marginTop: '0',
    },
    filtersBar: {
        backgroundColor: '#263644',
        paddingTop: '1.188rem',
        paddingBottom: '0.938rem',
        paddingLeft: '6.25rem',
        paddingRight: '6.25rem',
        height: '5.125rem',
    },
});
