import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    contactButton: {
        backgroundColor: '#708393',
        minWidth: 0,
        minHeight: 0,
        padding: '0.625rem',

        '& svg': {
            color: 'white',
            fontSize: '1.25rem',
        },
    },
});
