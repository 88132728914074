import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { getBackendErrorMessage } from '../config/utils';

const useReactQuery = (): QueryClient => {
    const { enqueueSnackbar } = useSnackbar();

    const queryErrorHandler = (err: unknown): void => {
        const axiosError = err as AxiosError;

        if (![401].includes(axiosError?.response?.status as number)) {
            enqueueSnackbar(getBackendErrorMessage(axiosError), { variant: 'error' });
        }
    };

    const defaultQueryClientOptions = {
        queries: {
            onError: queryErrorHandler,
            staleTime: 0,
            cacheTime: 1000 * 60 * 60 * 24,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
        mutations: {
            onError: queryErrorHandler,
        },
    };

    return new QueryClient({
        defaultOptions: defaultQueryClientOptions,
    });
};

export default useReactQuery;
