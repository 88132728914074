import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    taskSubtitle: {
        color: '#E65216',
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 'bold',
    },
    taskTitle: {
        color: '#FFF',
        fontSize: '3.125rem',
        lineHeight: 1.15,
        fontWeight: 'bold',
    },
});
