import { FC } from 'react';
import { Box } from '@material-ui/core';
import BackButton from '../../components/BackButton';
import UserForm from './UserForm';

const UserFormPage: FC = () => {
    return (
        <Box width="100%">
            <BackButton label="back to users" />

            <UserForm />
        </Box>
    );
};

export default UserFormPage;
