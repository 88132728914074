import { FC, useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import clsx from 'clsx';
import { useStyles } from './styles';
import { TaskSortingTypes } from '../../models/enums/TaskSortingTypes';
import { chooseSorting } from '../../config/utils';
import { TaskSorting } from '../../models/enums/TaskSorting';

interface Props {
    handleSort: (order: string) => void;
}

const TaskTableHeaderReduced: FC<Props> = ({ handleSort }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [sortOrder, setSortOrder] = useState<string>('');

    const handleSortLocal = (filterType: TaskSortingTypes) => {
        const newSorting = chooseSorting(filterType, sortOrder);

        setSortOrder(newSorting);
        handleSort(newSorting);
    };

    const renderSortingIcon = (sortingType: TaskSortingTypes) => {
        if (sortingType === TaskSortingTypes.STARTED) {
            if (sortOrder === TaskSorting.STARTED_DESC) {
                return <ArrowDownwardIcon />;
            }
            if (sortOrder === TaskSorting.STARTED_ASC) {
                return <ArrowUpwardIcon />;
            }
            return null;
        }

        if (sortOrder === TaskSorting.DEADLINE_DESC) {
            return <ArrowDownwardIcon />;
        }
        if (sortOrder === TaskSorting.DEADLINE_ASC) {
            return <ArrowUpwardIcon />;
        }
        return null;
    };

    return (
        <Grid container alignContent="center" alignItems="center">
            <Grid container item xs={12} direction="row" className={classes.tableHeaderTitle}>
                <Grid container item xs={6} alignContent="center" alignItems="center">
                    <Typography className={classes.headerText}>{t('Tasks')}</Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={4}
                    direction="row"
                    alignContent="center"
                    alignItems="center"
                    justify="flex-start"
                    style={{ marginLeft: 0 }}
                >
                    <Button
                        className={classes.sortButton}
                        disableRipple
                        onClick={() => handleSortLocal(TaskSortingTypes.STARTED)}
                        endIcon={renderSortingIcon(TaskSortingTypes.STARTED)}
                    >
                        <Typography className={clsx(classes.headerText, classes.headerTextDate)}>
                            {t('Start date')}
                        </Typography>
                    </Button>

                    <Button
                        className={classes.sortButton}
                        disableRipple
                        onClick={() => handleSortLocal(TaskSortingTypes.DEADLINE)}
                        endIcon={renderSortingIcon(TaskSortingTypes.DEADLINE)}
                    >
                        <Typography className={clsx(classes.headerText, classes.headerTextDate)}>
                            {t('Deadline')}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TaskTableHeaderReduced;
