import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    rootDialog: {
        borderRadius: 0,
        borderTopLeftRadius: '1.875rem',
        borderBottomRightRadius: '1.875rem',
    },
    dialogTitleContainer: {
        padding: '1.875rem 3.75rem',
    },
    dialogTitle: {
        fontSize: '1.875rem',
        fontWeight: 'bold',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(5),
        top: theme.spacing(3),
    },
    closeButtonIcon: {
        width: '2.813rem',
        height: '2.813rem',
        color: 'black',
    },
    dialogContentContainer: {
        padding: '0px 3.75rem',
        border: 0,
    },
    projectFilesButton: {
        margin: '1.25rem 3.75rem',
        padding: '1.25rem 1.563rem',
    },
    projectFilesLabel: {
        fontSize: '1.188rem',
    },
}));
