export const dictionaryEn = {
    'Welcome to React': 'Welcome to React and react-i18next',
    header: {
        title: 'Cappaciti admin',
        login: 'Log in',
        logout: 'Log out',
        usersLink: 'Users',

        '/example-page': 'Example page',
    },
    'Please enter the remarks about the deadline change.':
        'Please enter the remarks about the deadline change.',
    'Type your remarks here': 'Type your remarks here',
    'Deadline Remarks': 'Deadline Remarks',
    'Edited by: ': 'Edited by: ',
    'Remarks: ': 'Remarks: ',
};
