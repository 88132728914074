import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../config/theme';

export const useStyles = makeStyles({
    paperBackground: ({ showDetails, showAudit }: { showDetails: boolean; showAudit: boolean }) => ({
        width: '100%',
        borderRadius: 0,
        backgroundColor:
            showDetails || showAudit ? theme.palette.secondary.light : theme.palette.secondary.dark,
        borderBottom: `${showDetails || showAudit ? 'none' : '1px solid #2C3842'}`,
        padding: `1rem 1rem 1rem ${showDetails || showAudit ? '1rem' : 0}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    colorIndicator: {
        height: '2.5rem',
        width: '0.75rem',
        borderRadius: '1.25rem 0',
    },
    taskTitle: {
        fontSize: '1.25rem',
        padding: '0px 1.25rem',
        overflow: 'hidden',
        color: '#fff',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 30px)',
    },
    taskDescription: {
        color: '#c4c4c4',
        fontSize: '1.063rem',
        display: 'inline-block',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    taskProject: {
        color: '#c4c4c4',
        fontSize: '1.063rem',
        overflow: 'hidden',
        maxWidth: '25rem',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
    },
    fullWidthCentered: {
        width: '100%',
        textAlign: 'center',
    },
    dateField: {
        color: '#fff',
        fontSize: 17,
        fontWeight: 600,
    },
    dateFailed: {
        color: theme.palette.error.main,
        fontWeight: 'bold',
    },
    taskGreen: {
        borderColor: '#67bb78',
    },
    taskYellow: {
        borderColor: '#f0c552',
    },
    taskBlue: {
        borderColor: '#64c3ec',
    },
    actionIconButton: {
        padding: '0.313rem',
    },
    actionIcon: {
        width: '1.875rem',
        height: '1.875rem',
        color: '#708393',
    },
    actionButton: {
        margin: '0px 0.156rem',
    },
    more: {
        width: '1.6rem',
        height: '1.6rem',
        color: '#708393',
    },
    paperDetailsBackground: {
        width: '100%',
        backgroundColor: '#13212D',
        padding: '0.625rem 1.25rem',
        borderRadius: 0,
    },
    todoListText: {
        fontSize: '1.125rem',
        color: '#C9CBCC',
    },
    checkboxContainer: {
        padding: 0,
        marginTop: '0.938rem',
        width: '1.563rem',
        flexDirection: 'row',
        marginRight: '2.5rem',
    },
    listContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    todoListIconElement: {
        minWidth: '2.188rem',
    },
    todoListIcon: {
        color: 'black',
        width: '1.25rem',
        height: '1.25rem',
    },
    taskStatusBoxIcon: {
        color: 'white',
    },
    statusButton: {
        minWidth: 0,
    },
    taskStatusBox: {
        borderRadius: '0.375rem',
        backgroundColor: '#708393',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            width: '1.75rem',
            height: '1.75rem',
        },
        [theme.breakpoints.down('md')]: {
            width: '1.25rem',
            height: '1.25rem',
        },
    },
    taskStatusBoxDone: {
        backgroundColor: '#40BF71',
    },
    confirmityRegisterButtons: {
        paddingBottom: '0.938rem',
    },
    userAssignedItem: {
        margin: '0.625rem 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    userAssignedName: {
        fontSize: '1.25rem',
    },
    userAssignedPosition: {
        fontSize: '1rem',
        color: theme.palette.primary.main,
    },
    conformityButton: {
        width: '15.625rem',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTypography-root': {
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
            '& span:first-child': {
                color: '#708393',
                fontSize: '1.063rem',
                fontWeight: 600,
                textTransform: 'lowercase',
                width: '10rem',
                display: 'inline-block',
            },
            '& span:last-child': {
                fontSize: '1.25rem',
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
});
