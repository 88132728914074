import { FC, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PublishIcon from '@material-ui/icons/Publish';
import CancelIcon from '@material-ui/icons/Cancel';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import { useStyles } from './styles';
import { theme } from '../../../config/theme';
import { MediaDto } from '../../../api/data-contracts';
import { uploadMediaFile } from '../../../services/MediaService';
import { getBackendErrorMessage } from '../../../config/utils';

const FIRST_PROJECT_PICTURE_SIZE = 250;
const OTHER_PROJECT_PICTURE_SIZE = 110;
const PICTURE_MARGIN = 10;

const ProjectPicturesForm: FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isUploading, setIsUploading] = useState(false);

    const classes = useStyles({
        firstImgSize: FIRST_PROJECT_PICTURE_SIZE,
        otherImgSize: OTHER_PROJECT_PICTURE_SIZE,
        pictureMargin: PICTURE_MARGIN,
    });

    const { control } = useFormContext();
    const projectPictures = useFieldArray({
        control,
        name: 'projectPictures',
    });

    const handleFileUpload = async (file: File) => {
        setIsUploading(true);
        try {
            const compressedFile = await imageCompression(file, {
                maxWidthOrHeight: 1280,
                initialQuality: 0.8,
            });

            const uploadImageBlobResponse = await uploadMediaFile(compressedFile);
            projectPictures.append(uploadImageBlobResponse);
        } catch (error) {
            enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                variant: 'error',
            });
        } finally {
            setIsUploading(false);
        }
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        noDrag: true,
        maxFiles: 5 - (projectPictures?.fields?.length || 0),
        maxSize: 10000000,
        accept: ['image/jpeg', 'image/png'],
        onDropRejected: fileRejections => {
            if (fileRejections.length + (projectPictures?.fields?.length || 0) > 5) {
                enqueueSnackbar('Maximum pictures number is 5', { variant: 'error' });
            } else if (fileRejections?.[0]?.errors?.[0]?.code === 'file-too-large') {
                enqueueSnackbar('Maximum file size is 10MB', { variant: 'error' });
            }
        },
        onDropAccepted: files => {
            files.forEach(file => handleFileUpload(file));
        },
    });

    const renderPicture = (picture: MediaDto, index: number | null = null) => {
        return (
            <Box className={classes.projectPictureContainer} key={picture.id}>
                <CancelIcon
                    className={classes.removeBtn}
                    onClick={() => index !== null && projectPictures.remove(index)}
                />
                <img src={picture.url} alt="Project" className={classes.projectPictureImg} />
            </Box>
        );
    };

    const renderAddImgTile = () => (
        <Box
            className={classes.moreImagesTileContainer}
            {...getRootProps}
            onClick={!isUploading ? open : undefined}
        >
            {isUploading ? (
                <CircularProgress />
            ) : (
                <span style={{ fontSize: 18, fontWeight: 600 }}>
                    add
                    <PublishIcon style={{ height: 18, color: theme.palette.primary.main }} />
                    <input {...getInputProps()} />
                </span>
            )}
        </Box>
    );

    return (
        <div className={classes.container}>
            <Box className={classes.otherPicturesContainer}>
                {/* @ts-ignore */}
                {projectPictures.fields.map((picture: MediaDto, index) => renderPicture(picture, index))}
                {(projectPictures?.fields?.length || 0) < 5 && renderAddImgTile()}
            </Box>
        </div>
    );
};

export default ProjectPicturesForm;
