import { FC, useState, useEffect, useRef } from 'react';
import {
    Typography,
    Dialog,
    DialogProps,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    IconButton,
    TextField,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@material-ui/icons/Today';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import CustomButton from '../../../../../../components/CustomButton';
import { DATE_FORMAT, SUBMITTING_DATE_FORMAT } from '../../../../../../config/constants';
import CustomSelect from '../../../../../../components/CustomSelect';
import { ColumnSizes, ConformityRegister } from '../../config';
import { Conformity } from '../../../../../../models/Conformity';
import { RootReducer, TasksReducer } from '../../../../../../models/Redux';
import { ConformityDto } from '../../../../../../api/data-contracts';

interface Props {
    open: boolean;
    loading: boolean;
    conformititesData: ConformityDto[];
    currentConformity: Conformity | undefined;
    handleChangeConformity: (id: number) => void;
    handleClose: () => void;
    handleUpdateConformity: (conf: Conformity) => void;
}

const ConformityRegisterForm: FC<Props> = ({
    open,
    loading,
    handleClose,
    currentConformity,
    conformititesData,
    handleChangeConformity,
    handleUpdateConformity,
}) => {
    const [scroll] = useState<DialogProps['scroll']>('paper');
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        values,
        handleChange,
        setValues,
        submitForm,
        isValid,
        errors,
    } = useFormikContext<ConformityRegister>();
    const { enqueueSnackbar } = useSnackbar();
    const { conformityStatuses } = useSelector<RootReducer, TasksReducer>(state => state.tasks);

    const [currentConformityNumber, setCurrentConformityNumber] = useState<number>(0);
    const tasksLenght = conformititesData.length;

    const navigatePreviousTask = () => {
        if (currentConformityNumber > 0) {
            const newCurrentTask = currentConformityNumber - 1;
            handleChangeConformity(conformititesData[newCurrentTask].id!);
            setCurrentConformityNumber(newCurrentTask);
        }
    };

    const navigateNextTask = () => {
        if (currentConformityNumber < tasksLenght - 1) {
            const newCurrentTask = currentConformityNumber + 1;
            handleChangeConformity(conformititesData[newCurrentTask].id!);
            setCurrentConformityNumber(newCurrentTask);
        } else {
            handleClose();
        }
    };

    const handleDateChange = (date: Date | null, value: string) => {
        handleUpdateConformity({
            ...(currentConformity as Conformity),
            startDate: dayjs(value, DATE_FORMAT).format(SUBMITTING_DATE_FORMAT),
        });
        if (date) {
            setValues({ ...values, startDate: dayjs(value, DATE_FORMAT).format(SUBMITTING_DATE_FORMAT) });
        }
    };

    const handleStatusChange = (value: number) => {
        handleUpdateConformity({
            ...(currentConformity as Conformity),
            status: value,
        });
        setValues({ ...values, conformState: value });
    };

    const handleCommentChange = (value: any) => {
        handleUpdateConformity({
            ...(currentConformity as Conformity),
            comment: value.target.value,
        });
        setValues({ ...values, comment: value.target.value });
    };

    const handleCloseDialog = () => {
        handleChangeConformity(conformititesData[0].id!);
        setCurrentConformityNumber(0);
        handleClose();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const renderTextInput = (
        id: string,
        name: string,
        value: string,
        handleChangeField: (e: any) => void,
        label?: string,
        placeholder?: string,
        multiline?: boolean,
        maxRows?: number,
    ) => {
        if (loading) {
            return <Skeleton animation="wave" height={50} className={classes.skeleton} />;
        }
        return (
            <TextField
                id={id}
                name={name}
                label={label}
                multiline={multiline}
                value={value}
                onChange={handleChangeField}
                rowsMax={maxRows}
                placeholder={placeholder}
                className={classes.textField}
                InputProps={{ disableUnderline: true, className: classes.textFieldWrapper }}
            />
        );
    };

    const renderTextField = (
        inputName: string,
        title: string,
        placeholder: string,
        value: string,
        handleChangeField: (e: any) => void,
        multiline?: boolean,
        paperClass?: string,
        textInputClass?: string,
        titleColumnSize?: ColumnSizes,
        inputColumnSize?: ColumnSizes,
        maxRows?: number,
    ) => {
        return (
            <Paper elevation={0} className={clsx(classes.paperContainer, paperClass)}>
                <Grid container justify="center" alignContent="center" alignItems="center">
                    <Grid item xs={titleColumnSize || 3}>
                        <Typography className={classes.inputLabel}>{title}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={inputColumnSize || 9}
                        className={clsx(classes.textFieldWrapper, textInputClass)}
                    >
                        {renderTextInput(
                            title,
                            inputName,
                            value,
                            handleChangeField,
                            '',
                            placeholder,
                            multiline,
                            maxRows,
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const renderDateField = (title: string, placeholder: string, icon: any, onChange: any, value: any) => {
        return (
            <Grid container item xs={12} alignContent="center" alignItems="center">
                <Paper
                    elevation={0}
                    className={clsx(classes.paperContainer, classes.paperContainerDateField)}
                >
                    <Grid container item xs={12} alignContent="center" alignItems="center">
                        <Grid
                            container
                            item
                            xs={6}
                            justify="flex-start"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Typography className={classes.inputLabel}>{title}</Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            justify="center"
                            alignContent="center"
                            alignItems="center"
                        >
                            <KeyboardDatePicker
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                variant="inline"
                                format={DATE_FORMAT}
                                margin="none"
                                id="date-picker-inline"
                                value={value}
                                // @ts-ignore
                                onChange={onChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                keyboardIcon={icon}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    };

    const handleSubmit = () => {
        submitForm().then(() => {
            if (!isValid) {
                const errorMessage = Object.values(errors).toString();
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
            }
            navigateNextTask();
        });
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            scroll={scroll}
            aria-labelledby="Add-Task-Dialog"
            fullWidth
            maxWidth="lg"
            classes={{
                paper: classes.rootDialog,
            }}
        >
            <Grid container direction="row" alignContent="center" alignItems="center">
                <Grid xs={1} direction="row" item container alignContent="center" alignItems="center">
                    <Grid item xs={6} direction="row" />
                    <Grid item xs={6} direction="row">
                        <CustomButton
                            icon={<ArrowBackIosIcon />}
                            onClick={navigatePreviousTask}
                            labelClassName={classes.addTaskButtonLabel}
                            buttonClassName={clsx(classes.conformityButton, classes.arrowNavigator)}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={10} direction="row" alignContent="center" alignItems="center">
                    <DialogTitle
                        classes={{
                            root: classes.dialogTitleContainer,
                        }}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Typography className={classes.dialogTitle}>
                                {t('Conformity register')}
                            </Typography>
                            <Typography className={classes.counterTitle}>
                                {currentConformityNumber + 1}/{tasksLenght}
                            </Typography>
                        </Grid>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleCloseDialog}
                        >
                            <CloseIcon className={classes.closeButtonIcon} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        classes={{
                            root: classes.dialogContentContainer,
                        }}
                    >
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <Grid container direction="row">
                                <Grid
                                    container
                                    xs={4}
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    {renderTextField(
                                        'projectName',
                                        t('Project'),
                                        t('Project name'),
                                        currentConformity?.projectName || '',
                                        handleChange,
                                        false,
                                        clsx(classes.extraPaperMargin, classes.firstTextField),
                                        classes.specialTextInput,
                                        6,
                                        6,
                                    )}
                                </Grid>
                                <Grid
                                    container
                                    xs={5}
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        xs={12}
                                        direction="row"
                                        alignContent="center"
                                        alignItems="center"
                                    >
                                        {renderTextField(
                                            '',
                                            t('Contract Type'),
                                            t('ContractType'),
                                            currentConformity?.contractType || '',
                                            handleChange,
                                            false,
                                            classes.extraPaperMargin,
                                            undefined,
                                            6,
                                            6,
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    xs={3}
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        xs={12}
                                        direction="row"
                                        alignContent="center"
                                        alignItems="center"
                                    >
                                        {renderTextField(
                                            '',
                                            t('Article'),
                                            t('Art. nº'),
                                            currentConformity?.article1 || '',
                                            handleChange,
                                            false,
                                            classes.extraPaperMargin,
                                            undefined,
                                            6,
                                            6,
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {renderTextField(
                                'description',
                                t('Description'),
                                t('Type a description'),
                                // values.description,
                                currentConformity?.description || '',
                                handleChange,
                                true,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                6,
                            )}
                            {renderTextField(
                                '',
                                t('Previous status'),
                                t('Previous'),
                                currentConformity?.previousStatus || '',
                                handleChange,
                                false,
                            )}
                            {renderTextField(
                                'previousComment',
                                t('Previous comment'),
                                t('Comment'),
                                currentConformity?.previousComment || '',
                                handleChange,
                                true,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                4,
                            )}
                            {renderTextField(
                                'comment',
                                t('Comment'),
                                t('New comment'),
                                currentConformity?.comment || '',
                                handleCommentChange,
                                true,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                4,
                            )}
                            <Grid xs={12} container direction="row" alignContent="center" alignItems="center">
                                <Grid item xs={4} direction="row">
                                    <Paper elevation={0} className={classes.conformSelect}>
                                        <CustomSelect
                                            id="conform"
                                            inputName="conformState"
                                            value={currentConformity?.status || values.conformState}
                                            availableData={conformityStatuses}
                                            // @ts-ignore
                                            onChange={handleStatusChange}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} direction="row">
                                    {renderDateField(
                                        t('Start date'),
                                        dayjs().format(DATE_FORMAT),
                                        <TodayIcon className={classes.calendarIcon} />,
                                        handleDateChange,
                                        currentConformity?.startDate
                                            ? new Date(
                                                  dayjs(
                                                      currentConformity?.startDate,
                                                      SUBMITTING_DATE_FORMAT,
                                                  ).valueOf(),
                                              )
                                            : new Date(),
                                    )}
                                </Grid>

                                <Grid
                                    container
                                    item
                                    xs={2}
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={6}>
                                        <CustomButton
                                            type="submit"
                                            onClick={handleSubmit}
                                            label={t('Confirm')}
                                            labelClassName={classes.addTaskButtonLabel}
                                            buttonClassName={clsx(
                                                classes.confirmButton,
                                                classes.acceptConformity,
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Grid>
                <Grid xs={1} direction="row" container item alignContent="center" alignItems="center">
                    <Grid item xs={8} direction="row">
                        {currentConformityNumber !== tasksLenght - 1 && (
                            <CustomButton
                                icon={<ArrowForwardIosIcon />}
                                onClick={navigateNextTask}
                                labelClassName={classes.addTaskButtonLabel}
                                buttonClassName={clsx(classes.conformityButton, classes.arrowNavigator)}
                            />
                        )}
                    </Grid>
                    <Grid item xs={4} direction="row" />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ConformityRegisterForm;
