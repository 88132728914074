import { FC } from 'react';
import { ProjectDetailsDto } from '../../../api/data-contracts';
import OverviewTab from '../tabs/OverviewTab';
import PersonsTab from '../tabs/PersonsTab';

interface Props {
    project: ProjectDetailsDto;
    editMode?: boolean;
    selectedTab: number;
}

const ProjectDetailsTabs: FC<Props> = ({ project, selectedTab }) => {
    return (
        <>
            <OverviewTab selectedTab={selectedTab} index={0} project={project} />
            <PersonsTab selectedTab={selectedTab} index={1} project={project} />
        </>
    );
};

export default ProjectDetailsTabs;
