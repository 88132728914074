import { FC } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import { Box, IconButton } from '@material-ui/core';
import { Row } from '@tanstack/react-table';
import { useStyles } from './styles';
import { ConformityRowDto } from '../../../api/data-contracts';
import CustomCheckbox from '../../../components/CustomCheckbox';

interface Props {
    row: Row<ConformityRowDto>;
    onRowExpanded: (row: Row<ConformityRowDto>) => void;
    confirmed: boolean;
}

const ConformityActions: FC<Props> = ({ row, onRowExpanded, confirmed }) => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            <CustomCheckbox checked={confirmed} disableRipple disableTooltip defaultCursor />
            <IconButton
                aria-label="more"
                className={classes.actionIconButton}
                disableRipple
                onClick={() => {
                    row.getToggleExpandedHandler()();
                    onRowExpanded(row);
                }}
            >
                <MoreVertIcon className={clsx(classes.actionIcon, classes.actionButton)} />
            </IconButton>
        </Box>
    );
};

export default ConformityActions;
