import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    card: {
        backgroundColor: '#263644',
        borderRadius: '3.125rem 0px',
        [theme.breakpoints.up('md')]: {
            padding: '3.75rem 4.375rem',
        },
        [theme.breakpoints.down('md')]: {
            padding: '2.5rem',
        },
        marginTop: '0.625rem',
        marginBottom: '3.75rem',
    },
    cardMarginBottom: {
        marginTop: 0,
        marginBottom: '3.125rem',
    },
    title: {
        fontSize: '2rem',
        lineHeight: 1.2,
        fontWeight: 'bold',
        marginBottom: '1.25rem',
    },
}));
