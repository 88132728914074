import React, { FC } from 'react';
import { Box, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import { useStyles } from './styles';
import RhfTextField from '../../../components/form-fields/RhfTextField';

const UserFormFields: FC = () => {
    const classes = useStyles();

    const renderTextField = (name: `${string}`, label: string) => (
        <Box display="flex" flexDirection="row" alignItems="center" mb="20px">
            <Typography className={classes.label}>{label}:</Typography>
            <RhfTextField name={name} variant="filled" placeholder={label} />
        </Box>
    );

    const renderCheckbox = (name: `${string}`, label: string) => (
        <Controller
            name={name}
            render={({ field, fieldState: { error }, formState: { isSubmitting } }) => (
                <>
                    <FormControlLabel
                        classes={{ label: classes.checkboxLabel, root: classes.formControl }}
                        control={
                            <Box className={classes.checkbox}>
                                <input
                                    type="checkbox"
                                    {...field}
                                    checked={field.value}
                                    disabled={isSubmitting}
                                />
                                <span className="checkmark">
                                    {field.value && <CheckIcon className={classes.checkIcon} />}
                                </span>
                            </Box>
                        }
                        label={label}
                    />
                    {!!error && <FormHelperText>{error?.message || ''}</FormHelperText>}
                </>
            )}
        />
    );

    return (
        <Box className={clsx(classes.card, classes.editUserCard)}>
            {renderTextField('phoneNo', 'phone number')}
            {renderTextField('email', 'e-mail')}

            <Box display="flex" flexDirection="row" alignItems="flex-start" mb="20px">
                <Typography className={classes.label}>roles:</Typography>
                <Box display="flex" flexDirection="column">
                    {renderCheckbox('authorities.employee', 'team member')}
                    {renderCheckbox('authorities.freelancer', 'freelancer')}
                    {renderCheckbox('authorities.admin', 'admin')}
                </Box>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="flex-start" mb="20px">
                <Typography className={classes.label}>notifications:</Typography>
                <Box display="flex" flexDirection="column">
                    {renderCheckbox('weeklyNotifications', 'send weekly notifications')}
                    {renderCheckbox('twoHoursNotifications', 'send notifications every 2 hours')}
                </Box>
            </Box>
        </Box>
    );
};

export default UserFormFields;
