import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    divider: {
        width: '100%',
        backgroundColor: '#454c51',
        marginTop: 14,
        marginBottom: 14,
        height: 2,
    },
});
