import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import {
    getContractTypesFilters,
    getDisciplineFilters,
    getEmployeesAssignedFilters,
    getProjectFilters,
    getStatusFilters,
    getTasks,
    getTaskTypesFilters,
    getDisciplineToProject,
    getConformityStatues,
    getConformityArticleFilters,
} from '../../services/TasksService';
import { TasksReducer, RootReducer } from '../../models/Redux';
import { TaskFilterQuery } from '../../models/TaskFilterQuery';

export const SET_TASKS = 'SET_TASKS';
export const SET_CONFORMITY_STATUES = 'SET_CONFORMITY_STATUES';
export const SET_DISCIPLINES_FILTERS = 'SET_DISCIPLINES_FILTERS';
export const SET_DISCIPLINES_TO_PROJECTS = 'SET_DISCIPLINES_TO_PROJECTS';
export const SET_DISCIPLINES_FILTERS_ALL = 'SET_DISCIPLINES_FILTERS_ALL';
export const SET_PROJECTS_FILTERS = 'SET_PROJECTS_FILTERS';
export const SET_CONFORMITY_ARTICLE_FILTERS = 'SET_CONFORMITY_ARTICLE_FILTERS';
export const SET_EMPLOYEES_ASSIGNED_FILTERS = 'SET_EMPLOYEES_ASSIGNED_FILTERS';
export const SET_EMPLOYEES_ASSIGNED_FILTERS_ALL = 'SET_EMPLOYEES_ASSIGNED_FILTERS_ALL';
export const SET_TASK_STATUS_FILTERS = 'SET_TASK_STATUS_FILTERS';
export const SET_TASK_TYPES_FILTERS = 'SET_TASK_TYPE_FILTERS';
export const SET_CONTRACT_TYPE_FILTERS = 'SET_CONTRACT_TYPE_FILTERS';
export const SET_LOADING_TASKS = 'SET_LOADING_TASKS';
export const ERROR_FETCHING = 'ERROR_FETCHING';

export const thunkGetDisciplineFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getDisciplineFilters({ showActive: true })
        .then(payload => {
            dispatch({
                type: SET_DISCIPLINES_FILTERS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};
export const thunkGetDisciplineToProject = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getDisciplineToProject()
        .then(payload => {
            dispatch({
                type: SET_DISCIPLINES_TO_PROJECTS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetConformityStatues = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getConformityStatues()
        .then(payload => {
            dispatch({
                type: SET_CONFORMITY_STATUES,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetDisciplineAllFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getDisciplineFilters({ showActive: false })
        .then(payload => {
            dispatch({
                type: SET_DISCIPLINES_FILTERS_ALL,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetProjectFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getProjectFilters()
        .then(payload => {
            dispatch({
                type: SET_PROJECTS_FILTERS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetConformityArticleFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getConformityArticleFilters()
        .then(payload => {
            dispatch({
                type: SET_CONFORMITY_ARTICLE_FILTERS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetEmployeesAssignedFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getEmployeesAssignedFilters({ showActive: true })
        .then(payload => {
            dispatch({
                type: SET_EMPLOYEES_ASSIGNED_FILTERS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetEmployeesAssignedAllFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getEmployeesAssignedFilters({ showActive: false })
        .then(payload => {
            dispatch({
                type: SET_EMPLOYEES_ASSIGNED_FILTERS_ALL,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetTypesFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getTaskTypesFilters()
        .then(payload => {
            dispatch({
                type: SET_TASK_TYPES_FILTERS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetContractTypesFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getContractTypesFilters()
        .then(payload => {
            dispatch({
                type: SET_CONTRACT_TYPE_FILTERS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkGetTaskStatusFilters = (
    callback?: () => void,
): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getStatusFilters()
        .then(payload => {
            dispatch({
                type: SET_TASK_STATUS_FILTERS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const getAllTasksFilters = (): ThunkAction<void, RootReducer, unknown, Action<string>> => dispatch => {
    dispatch(thunkGetDisciplineFilters());
    dispatch(thunkGetDisciplineToProject());
    dispatch(thunkGetDisciplineAllFilters());
    dispatch(thunkGetProjectFilters());
    dispatch(thunkGetConformityArticleFilters());
    dispatch(thunkGetEmployeesAssignedFilters());
    dispatch(thunkGetEmployeesAssignedAllFilters());
    dispatch(thunkGetTaskStatusFilters());
    dispatch(thunkGetTypesFilters());
    dispatch(thunkGetContractTypesFilters());
    dispatch(thunkGetConformityStatues());
};

export const thunkGetAllTasks = (
    query: TaskFilterQuery,
    callback?: () => void,
): ThunkAction<void, TasksReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING_TASKS, payload: true });
    return getTasks(query)
        .then(payload => {
            dispatch({
                type: SET_TASKS,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};
