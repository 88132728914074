import { FC } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { confirmAllConformities, ConformitiesQuery } from '../../../services/TasksService';
import { queryKeys } from '../../../react-query/constants';

interface Props {
    taskId: number;
    query: ConformitiesQuery;
}

const ConformitiesActions: FC<Props> = ({ taskId, query }) => {
    const queryClient = useQueryClient();

    const confirmAllMutation = useMutation(() => confirmAllConformities(taskId, query), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.conformities]);
        },
    });

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={() => confirmAllMutation.mutate()}
            endIcon={confirmAllMutation.isLoading ? undefined : <CheckIcon />}
            disabled={confirmAllMutation.isLoading}
        >
            {confirmAllMutation.isLoading ? (
                <CircularProgress color="secondary" size="2rem" />
            ) : (
                'confirm all'
            )}
        </Button>
    );
};

export default ConformitiesActions;
