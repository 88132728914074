import { FC } from 'react';
import OverviewTabForm from '../OverviewTabForm';
import PersonsTabForm from '../PersonsTabForm';

interface Props {
    selectedTab: number;
}

const ProjectFormTabs: FC<Props> = ({ selectedTab }) => {
    return (
        <>
            <OverviewTabForm selectedTab={selectedTab} index={0} />
            <PersonsTabForm selectedTab={selectedTab} index={1} />
        </>
    );
};

export default ProjectFormTabs;
