import * as yup from 'yup';

export const msClientID = process.env.REACT_APP_MS_CLIENT_ID;
export const tenantID = process.env.REACT_APP_MS_TENANT_ID;
export const msLoginScopes = ['user.read', 'profile', 'offline_access', 'openid'];

export interface LoginFormValues {
    email: string;
    password: string;
}

export const initialValues: LoginFormValues = {
    email: '',
    password: '',
};

export const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email must be valid'),
    password: yup.string().required('Password is required'),
});
