import {
    Box,
    CircularProgress,
    TableRow as MuiTableRow,
    TableBody as MuiTableBody,
    TableCell as MuiTableCell,
} from '@material-ui/core';
import { Row, Table } from '@tanstack/react-table';
import { ReactElement, ReactNode } from 'react';
import TableRow from '../TableRow';

interface Props<V> {
    isLoading?: boolean;
    table: Table<V>;
    renderEmptyTable?: () => ReactNode;
    onRowClick?: (row: V) => void;
    renderSubComponent?: (row: Row<V>) => ReactElement;
}
const TableBody = <V,>({
    isLoading,
    table,
    renderEmptyTable,
    onRowClick,
    renderSubComponent,
}: Props<V>): ReactElement => {
    const renderTableRows = () => {
        const { rows } = table.getRowModel();
        return rows.length === 0 && renderEmptyTable
            ? renderEmptyTable
            : rows.map((row, index) => (
                  <TableRow<V>
                      key={index}
                      row={row}
                      onRowClick={onRowClick}
                      renderSubComponent={renderSubComponent}
                  />
              ));
    };

    return isLoading ? (
        <MuiTableBody>
            <MuiTableRow>
                <MuiTableCell colSpan={table._getColumnDefs().length}>
                    <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
                        <CircularProgress />
                    </Box>
                </MuiTableCell>
            </MuiTableRow>
        </MuiTableBody>
    ) : (
        <MuiTableBody>{renderTableRows()}</MuiTableBody>
    );
};

export default TableBody;
