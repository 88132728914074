import { FC, useCallback, useState } from 'react';
import { Paper, Card, Typography, CardContent, Grid } from '@material-ui/core';
import { SiWindows } from 'react-icons/si';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import { ROUTES } from '../../../../config/constants';
import { thunkGetCurrentUser, thunkLogInUserTest } from '../../../../store/actions';
import { msClientID, tenantID } from '../../config';
import { useLocalStorage } from '../../../../config/hooks/useLocalStorage';
import { Token } from '../../../../models/enums/Token';
import { login } from '../../../../services/config/AuthenticationService';
import { AuthenticationResponse } from '../../../../models/Authentication';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import OauthPopup from '../OauthPopup';
import { getAllTasksFilters } from '../../../../store/actions/Tasks.actions';

const LoginCard: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
    const [, setValue] = useLocalStorage(Token.TOKEN, '');
    const { enqueueSnackbar } = useSnackbar();
    const encodedRedirectURL = window.location.href;

    const handleLogin = useCallback(
        (code: string): void => {
            setLoadingLogin(true);
            login({
                code,
            })
                .then(loginResponse => {
                    const loginData = loginResponse as AuthenticationResponse;
                    setValue(loginData.accessToken);

                    dispatch(getAllTasksFilters());
                    dispatch(
                        thunkGetCurrentUser(() => {
                            dispatch(thunkLogInUserTest());
                            history.push(`${ROUTES.AUTHORIZED.DASHBOARD}`);
                        }),
                    );
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        enqueueSnackbar(t(error?.response?.data?.detail), { variant: 'error' });
                    } else {
                        enqueueSnackbar(t('Failed to login. Please try again.'), { variant: 'error' });
                    }
                })
                .finally(() => {
                    setLoadingLogin(false);
                });
        },
        [setValue, dispatch, history, enqueueSnackbar, t],
    );

    return (
        <Card className={classes.loginCard}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {t('Sign in')}
                </Typography>

                <div role="button" tabIndex={0} onKeyDown={() => {}} onClick={() => setLoading(true)}>
                    <OauthPopup
                        url={`https://login.microsoftonline.com/${tenantID}/oauth2/v2.0/authorize?client_id=${msClientID}&response_type=code&prompt=select_account&redirect_uri=${encodedRedirectURL}&response_mode=query&scope=user.read%20profile%20offline_access%20openid&state=12345`}
                        onCode={handleLogin}
                        width={500}
                        height={700}
                        title=""
                        onOpen={() => {
                            setLoading(true);
                        }}
                        onClose={() => {
                            setLoading(false);
                        }}
                    >
                        {!loading && !loadingLogin && (
                            <Paper
                                square
                                elevation={0}
                                className={classes.signInWithMicrosoftButton}
                                onClick={() => setLoading(true)}
                                // onClick={handleMSLogin}
                            >
                                <SiWindows
                                    onClick={() => setLoading(true)}
                                    className={classes.windowsIcon}
                                    size={50}
                                />
                                <Typography
                                    onClick={() => setLoading(true)}
                                    className={classes.signInWithMicrosoftButtonText}
                                    gutterBottom
                                >
                                    {t('Sign in with Microsoft')}
                                </Typography>
                            </Paper>
                        )}
                    </OauthPopup>
                </div>

                {(loading || loadingLogin) && (
                    <Grid
                        item
                        xs={12}
                        style={{
                            padding: '20px 0px',
                        }}
                    >
                        <LoaderSpinner />
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default LoginCard;
