import { FC, useEffect, useMemo, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import { useStyles } from './styles';
import CustomButton from '../../../../../../components/CustomButton';
import { TaskFilterQuery } from '../../../../../../models/TaskFilterQuery';
import { Contact, Project, ProjectFilters, ReportQuery } from '../../../../../../models/Project';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../../../../config/constants';
import CustomSelect from '../../../../../../components/CustomSelect';
import {
    generateProjectReport,
    removeProjectLogo,
    updateProjectLogo,
} from '../../../../../../services/ProjectService';
import { getBackendErrorMessage } from '../../../../../../config/utils';
import LoaderSpinner from '../../../../../../components/LoaderSpinner';
import { dataURItoBlob, getDataURL } from '../../../../../../config/ImageUtils';
import { uploadImageBlob } from '../../../../../../services/MediaService';
import { CurrentUserReducer, RootReducer } from '../../../../../../models/Redux';
import { UserRoles } from '../../../../../../models/enums/UserRoles';

interface Props {
    handleOpenEditTaskDialog: () => void;
    handleOpenConformitykDialog: () => void;
    currentProject?: Project;
    refreshProjectsList: () => void;
    handleCloseDialog: () => void;
}

const LeftProjectDetailsDialog: FC<Props> = ({ currentProject, refreshProjectsList, handleCloseDialog }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [reportQuery, setReportQuery] = useState<ReportQuery>({
        year: dayjs().year(),
        quarter: dayjs().quarter(),
        projectID: currentProject?.id || -1,
    });
    const [loadingExportPDF, setLoadingExportPDF] = useState<boolean>(false);

    const [imageUrl, setImageUrl] = useState<string>(currentProject?.projectLogo || '');
    const [isPerformingAction, setIsPerformingAction] = useState<boolean>(false);

    const {
        user: { role },
    } = useSelector<RootReducer, CurrentUserReducer>(state => state.currentUser);

    useEffect(() => {
        setImageUrl(currentProject?.projectLogo || '');
    }, [currentProject]);

    const uploadProjectLogo = async (imageUrl: string) => {
        try {
            setIsPerformingAction(true);
            const image = await getDataURL(imageUrl);
            const blob = dataURItoBlob(image);
            const uploadImageBlobResponse = await uploadImageBlob(blob);

            if (currentProject && uploadImageBlobResponse.id) {
                await updateProjectLogo(currentProject.id, uploadImageBlobResponse.id);
                setImageUrl(image);
                refreshProjectsList();
                enqueueSnackbar(t('Project logo successfully updated'), {
                    variant: 'success',
                });
            }
        } catch (error) {
            enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                variant: 'error',
            });
        } finally {
            setIsPerformingAction(false);
        }
    };

    const handleFileUpload = (file: File) => {
        const localImageURL = URL.createObjectURL(file);
        uploadProjectLogo(localImageURL);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        noDrag: true,
        accept: 'image/*',
        onDrop: files => handleFileUpload(files[0]),
    });

    const pageSize = 20;
    const query: TaskFilterQuery = useMemo(() => {
        const projectFilterJson: ProjectFilters = {
            projects: currentProject?.id ? [currentProject.id] : [],
        };
        return {
            filters: JSON.stringify(projectFilterJson),
            week: dayjs().week(),
            year: dayjs().year(),
            page: 0,
            size: pageSize,
        };
    }, [currentProject]);

    const handleClickEmailMember = (emailAddress: string): void => {
        window.location.href = `mailto:${emailAddress}`;
    };

    const handleClickPhoneMember = (phoneNumber: string): void => {
        navigator.clipboard.writeText(phoneNumber);
        enqueueSnackbar(t('Phone number copied to clipboard'), {
            variant: 'success',
        });
    };

    const handleChangeYear = (value: Dayjs) => {
        try {
            if (value.isValid()) {
                setReportQuery({
                    ...reportQuery,
                    year: value.year(),
                });
            }
        } catch (error) {}
    };

    const handleChangeQuarter = (e: any) => {
        setReportQuery({
            ...reportQuery,
            quarter: e.target.value,
        });
    };

    const renderLoadingDialog = () => {
        return (
            <Dialog open={loadingExportPDF} keepMounted maxWidth="sm" fullWidth>
                <DialogContent>
                    <Grid
                        container
                        item
                        xs={12}
                        direction="column"
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                        className={classes.generatingPDFLoader}
                    >
                        <LoaderSpinner />
                        <Typography className={classes.generatingPDFLoaderText}>
                            {t('Generating PDF...')}
                        </Typography>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    };

    const renderDateField = (title: string, placeholder: string, icon: any, onChange: any, value: any) => {
        return (
            <Grid container item sm={12} md={3} alignContent="center" alignItems="center">
                <Paper
                    elevation={0}
                    className={clsx(classes.paperContainer, classes.paperContainerDateField)}
                >
                    <Grid container item xs={12} alignContent="center" alignItems="center">
                        <Grid
                            container
                            item
                            xs={7}
                            justify="flex-start"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Typography className={classes.inputLabel}>{title}</Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={5}
                            justify="center"
                            alignContent="center"
                            alignItems="center"
                        >
                            <KeyboardDatePicker
                                views={['year']}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                variant="inline"
                                disablePast={false}
                                margin="none"
                                format=" "
                                id="date-picker-inline"
                                value={value}
                                allowKeyboardControl={false}
                                // @ts-ignore
                                onChange={onChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                keyboardIcon={icon}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    };

    const handleClickProjectFiles = () => {
        // if (currentProject?.filesUrl) {
        //     window.open(currentProject?.filesUrl, '_blank');
        // }
    };

    const handleClickGenerateReport = () => {
        setLoadingExportPDF(true);
        generateProjectReport(reportQuery)
            .then(response => {
                const dataBlob = response as ArrayBuffer;
                fileDownload(
                    dataBlob,
                    `${t('Report: Project')} ${currentProject?.projectName} - ${dayjs().format(
                        DATE_TIME_FORMAT,
                    )}.pdf`,
                );
            })
            .catch(error => {
                enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setLoadingExportPDF(false);
            });
    };

    const handleDeleteAvatar = async () => {
        try {
            if (currentProject) {
                setIsPerformingAction(true);
                await removeProjectLogo(currentProject.id);
                refreshProjectsList();
                setImageUrl('');
            }
        } catch (error) {
            enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                variant: 'error',
            });
        } finally {
            setIsPerformingAction(false);
        }
    };

    const renderLogoUploader = () => (
        <>
            {imageUrl ? (
                <img alt="Project Logo" src={imageUrl} className={classes.projectLogoImg} />
            ) : (
                <div className={classes.defaultProjectLogo} />
            )}

            {role &&
                role.includes(UserRoles.ADMIN) &&
                (imageUrl ? (
                    <>
                        <IconButton
                            className={classes.buttonEditProjectLogo}
                            onClick={open}
                            title={t('Edit')}
                            disabled={isPerformingAction}
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                        <button
                            type="button"
                            className={classes.removeProjectLogoButton}
                            onClick={handleDeleteAvatar}
                            title={t('Remove')}
                            disabled={isPerformingAction}
                        >
                            {t('Remove')}
                        </button>
                    </>
                ) : (
                    <IconButton
                        className={classes.buttonEditProjectLogo}
                        onClick={open}
                        title={t('Upload')}
                        disabled={isPerformingAction}
                    >
                        <PublishOutlinedIcon />
                    </IconButton>
                ))}
        </>
    );

    const renderUserData = (item: Contact, index: number) => {
        return (
            <Grid
                key={index}
                container
                direction="row"
                justify="center"
                alignContent="center"
                alignItems="center"
                className={classes.memberRow}
            >
                <Grid item xs={12} direction="column">
                    <Typography className={classes.memberName}>
                        {item.contactName || 'Empty contact name'}
                    </Typography>
                    {!!item.companyName && (
                        <Typography className={classes.companyName}>{item.companyName}</Typography>
                    )}
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} className={classes.memberCardLine}>
                        {!!item.phoneNo && (
                            <>
                                <IconButton
                                    onClick={() => {
                                        if (item.phoneNo) {
                                            handleClickPhoneMember(item.phoneNo);
                                        }
                                    }}
                                    className={
                                        item.phoneNo ? classes.contactButton : classes.contactButtonDisabled
                                    }
                                    disableRipple
                                >
                                    <Phone className={classes.contactButtonIcon} />
                                </IconButton>
                                <span>{item.phoneNo || 'No phone number provided'}</span>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {!!item.email && (
                            <>
                                <IconButton
                                    onClick={() => {
                                        if (item.email) {
                                            handleClickEmailMember(item.email);
                                        }
                                    }}
                                    className={
                                        item.email ? classes.contactButton : classes.contactButtonDisabled
                                    }
                                    disableRipple
                                >
                                    <Email className={classes.contactButtonIcon} />
                                </IconButton>
                                <span>{item.email || 'No email provided'}</span>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderContactGroupsAccordionContent = (contactGroupKey: string) => {
        return (
            currentProject &&
            Object.keys(currentProject.contactGroups[contactGroupKey]).map(
                (contactGroupInnerKey, contactGroupInnerIndex) => (
                    <div className={classes.memberCardContainer} key={contactGroupInnerIndex}>
                        <Typography className={classes.contactGroupName}>{contactGroupInnerKey}:</Typography>
                        <Card variant="outlined" className={classes.memberCard}>
                            <CardContent>
                                {currentProject?.contactGroups[contactGroupKey][
                                    contactGroupInnerKey
                                ].map((contactGroup, index: number) => renderUserData(contactGroup, index))}
                            </CardContent>
                        </Card>
                    </div>
                ),
            )
        );
    };

    const renderContactGroupsAccordion = (contactGroupKey: string, contactGroupIndex: number) => (
        <Accordion key={contactGroupIndex}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="keyPeople-content"
                id={`${contactGroupKey}-header`}
            >
                <Typography className={classes.accordionTitle}>{contactGroupKey}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.contactGroupAccordionContent}>
                    <div className={classes.contactGroupsContainer}>
                        {renderContactGroupsAccordionContent(contactGroupKey)}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );

    const renderAccordions = () => {
        return (
            <>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="project-content"
                        id="project-header"
                    >
                        <Typography className={classes.accordionTitle}>{t('Project')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container xs={12} md={6} item direction="column">
                            <div>
                                {currentProject?.projectDetailsLines.map(({ name, description }) => (
                                    <Typography className={classes.detailsEntry}>
                                        {name}:-
                                        <Chip
                                            className={classes.description}
                                            label={description}
                                            variant="outlined"
                                        />
                                    </Typography>
                                ))}
                            </div>
                        </Grid>
                        <Grid container xs={12} md={6} item direction="column">
                            {currentProject?.projectDateLines.map(({ name, date }) => (
                                <Typography className={classes.detailsEntry}>
                                    {name}:-
                                    <Chip
                                        className={classes.description}
                                        label={dayjs(date).format('DD-MM-YYYY')}
                                        variant="outlined"
                                    />
                                </Typography>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {currentProject &&
                    Object.keys(currentProject.contactGroups).map((contactGroupKey, contactGroupIndex) =>
                        renderContactGroupsAccordion(contactGroupKey, contactGroupIndex),
                    )}
            </>
        );
    };

    return (
        <>
            {renderLoadingDialog()}
            <DialogTitle
                classes={{
                    root: classes.dialogTitleContainer,
                }}
            >
                <div className={classes.titleContainer}>
                    <Typography className={classes.dialogTitle}>
                        {t('Project:')} {currentProject?.projectName || t('No project name found')}
                    </Typography>
                    <div className={classes.projectLogoContainer}>
                        <div {...getRootProps()} className={classes.dropzoneArea}>
                            <input {...getInputProps()} />
                            <div className={classes.projectLogoUploaderContainer}>
                                <div className={classes.logoContainer}>{renderLogoUploader()}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
                    <CloseIcon className={classes.closeButtonIcon} />
                </IconButton>
            </DialogTitle>

            <DialogContent
                classes={{
                    root: classes.dialogContentContainer,
                }}
            >
                <DialogContentText>
                    <Divider />
                    <Grid container item xs={12} className={classes.accordionsContainer} direction="column">
                        {renderAccordions()}
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    className={classes.actionButtonsContainer}
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid
                        container
                        item
                        md={9}
                        xs={12}
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                    >
                        <CustomButton
                            label={t('Generate report')}
                            onClick={handleClickGenerateReport}
                            labelClassName={classes.projectFilesLabel}
                            buttonClassName={classes.projectFilesButton}
                        />
                        <Grid container item sm={12} md={3} alignContent="center" alignItems="center">
                            <Paper
                                elevation={0}
                                className={clsx(
                                    classes.paperContainer,
                                    classes.paperContainerDateField,
                                    classes.paperQuarterContainer,
                                )}
                            >
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignContent="center"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <CustomSelect
                                        id="quarter"
                                        inputName="quarter"
                                        defaultValue={reportQuery.quarter}
                                        availableData={[
                                            { id: 1, name: 1 },
                                            { id: 2, name: 2 },
                                            { id: 3, name: 3 },
                                            { id: 4, name: 4 },
                                        ]}
                                        onChangeSpecial={handleChangeQuarter}
                                    />
                                </Grid>
                            </Paper>
                        </Grid>
                        {renderDateField(
                            reportQuery.year.toString(),
                            dayjs().format(DATE_FORMAT),
                            undefined,
                            handleChangeYear,
                            dayjs().set('year', reportQuery.year),
                        )}
                    </Grid>

                    {/* <Grid container item md={3} xs={12} direction="row" justify="flex-end"> */}
                    {/*    <CustomButton */}
                    {/*        label={t('Project files')} */}
                    {/*        disabled={!currentProject?.filesUrl} */}
                    {/*        onClick={handleClickProjectFiles} */}
                    {/*        labelClassName={classes.projectFilesLabel} */}
                    {/*        buttonClassName={classes.projectFilesButton} */}
                    {/*    />* /}
                    {/* </Grid> */}
                </Grid>
            </DialogActions>
        </>
    );
};

export default LeftProjectDetailsDialog;
