import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

interface StylesProps {
    firstImgSize: number;
    otherImgSize: number;
    pictureMargin: number;
}

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    projectPictureImg: (props: StylesProps) => ({
        width: props.otherImgSize,
        height: props.otherImgSize,
        borderRadius: '15px 0',
        objectFit: 'cover',
    }),
    projectFirstPictureImg: (props: StylesProps) => ({
        width: props.firstImgSize,
        height: props.firstImgSize,
        borderRadius: '15px 0',
    }),
    projectPictureContainer: (props: StylesProps) => ({
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: '15px 0',
        margin: props.pictureMargin,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    }),
    projectOtherPictureContainer: (props: StylesProps) => ({
        height: props.otherImgSize,
    }),
    moreImagesTileContainer: (props: StylesProps) => ({
        backgroundColor: theme.palette.secondary.dark,
        width: props.otherImgSize,
        height: props.otherImgSize,
        margin: props.pictureMargin,
        borderRadius: '15px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 30,
        fontWeight: 700,
    }),
    readOnlyContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    otherPicturesContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        cursor: 'pointer',
    },
    firstPictureContainer: {
        cursor: 'pointer',
    },
    carousel: {
        '& .thumbs-wrapper ul': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            '& li': {
                borderRadius: '15px 0',
                marginRight: 16,
                border: `2px solid ${theme.palette.secondary.light}`,
                cursor: 'pointer',
                '&.selected': {
                    border: `2px solid ${theme.palette.primary.main}`,
                },
                '& img': {
                    width: '100%!important',
                },
            },
        },
    },
    carouselImg: {
        width: 'auto!important',
        maxHeight: '80vh',
        objectFit: 'contain',
    },
    paper: {
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.dark,
    },
    dialogAppBar: {
        position: 'relative',
        backgroundColor: theme.palette.secondary.light,
    },
    title: {
        flex: 1,
    },
    removeBtn: {
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 8,
        top: 8,
    },
}));
