import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ProjectDetailsDto } from '../../../api/data-contracts';
import { useStyles } from './styles';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { generateProjectDetails, generateProjectReport } from '../../../services/ProjectService';
import { DATE_TIME_FORMAT } from '../../../config/constants';
import { getBackendErrorMessage } from '../../../config/utils';
import ProjectReportDialog from './ProjectReportDialog';

interface Props {
    project: ProjectDetailsDto | undefined;
    editMode?: boolean;
    setSubmitForm?: Dispatch<SetStateAction<boolean>>;
    isLoading?: boolean;
}

const ProjectActions: FC<Props> = ({ project, editMode, setSubmitForm, isLoading }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [year, setYear] = useState<number>(dayjs().get('year'));
    const [quarter, setQuarter] = useState<number>(1);

    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [isLoadingExportPDF, setIsLoadingExportPDF] = useState<boolean>(false);
    const [isLoadingDetailsPDF, setIsLoadingDetailsPDF] = useState<boolean>(false);
    const [showExportPdfDialog, setShowExportPdfDialog] = useState<boolean>(false);

    const primaryButtonClicked = () => {
        if (editMode && setSubmitForm) {
            setSubmitForm(true);
        } else {
            history.push(`/projects/form/${project?.id}`);
        }
    };

    const handleClickGenerateReport = () => {
        setIsLoadingExportPDF(true);
        generateProjectReport({ projectID: project?.id as number, year, quarter })
            .then(response => {
                const dataBlob = response as ArrayBuffer;
                fileDownload(
                    dataBlob,
                    `${t('Report: Project')} ${project?.projectName} - ${dayjs().format(
                        DATE_TIME_FORMAT,
                    )}.pdf`,
                );
            })
            .catch(error => {
                enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setIsLoadingExportPDF(false);
                setShowExportPdfDialog(false);
            });
    };

    const downloadProjectDetails = async () => {
        setIsLoadingDetailsPDF(true);
        generateProjectDetails(project?.id as number)
            .then(response => {
                const dataBlob = response as ArrayBuffer;
                fileDownload(
                    dataBlob,
                    `${t('Details: Project')} ${project?.projectName} - ${dayjs().format(
                        DATE_TIME_FORMAT,
                    )}.pdf`,
                );
            })
            .catch(error => {
                enqueueSnackbar(t(`errorsEn:${getBackendErrorMessage(error)}`), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setIsLoadingDetailsPDF(false);
            });
    };

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center" gridColumnGap={30} marginTop="3.5rem">
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={isLoading || isLoadingExportPDF}
                    className={classes.generateReportButton}
                    endIcon={
                        isLoadingExportPDF ? null : (
                            <Box display="flex" flexDirection="column" alignItems="center" ml={1}>
                                <ArrowDownwardOutlinedIcon className={classes.uploadAvatarIcon} />
                                <Box className={classes.uploadAvatarIconWrapper} />
                            </Box>
                        )
                    }
                    onClick={() => setShowExportPdfDialog(true)}
                >
                    {isLoadingExportPDF ? (
                        <CircularProgress color="secondary" size="2rem" />
                    ) : (
                        'generate report'
                    )}
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    disabled={isLoading || isLoadingDetailsPDF}
                    className={classes.generateReportButton}
                    endIcon={
                        isLoadingExportPDF ? null : (
                            <Box display="flex" flexDirection="column" alignItems="center" ml={1}>
                                <ArrowDownwardOutlinedIcon className={classes.uploadAvatarIcon} />
                                <Box className={classes.uploadAvatarIconWrapper} />
                            </Box>
                        )
                    }
                    onClick={downloadProjectDetails}
                >
                    {isLoadingDetailsPDF ? (
                        <CircularProgress color="secondary" size="2rem" />
                    ) : (
                        'download project details'
                    )}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={isLoading ? null : <EditIcon />}
                    onClick={primaryButtonClicked}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress color="secondary" size="2rem" />
                    ) : editMode ? (
                        'save'
                    ) : (
                        'edit project'
                    )}
                </Button>
            </Box>

            {confirmDelete && (
                <ConfirmDialog
                    className={classes.removeDialog}
                    open={confirmDelete}
                    confirmLabel="remove"
                    cancelLabel="cancel"
                    title="Are you sure you want to remove this project?"
                    onClickConfirm={() => console.log('delete')}
                    onClickCancel={() => setConfirmDelete(false)}
                />
            )}

            <ProjectReportDialog
                project={project}
                handleClickGenerateReport={handleClickGenerateReport}
                open={showExportPdfDialog}
                onClose={() => setShowExportPdfDialog(false)}
                disabled={isLoadingExportPDF}
                year={year}
                setYear={setYear}
                quarter={quarter}
                setQuarter={setQuarter}
            />
        </>
    );
};

export default ProjectActions;
