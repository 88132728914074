import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    paginationContainer: {
        padding: '3.125rem 0px',
    },
    paginationElement: {
        color: 'white',
        borderRadius: '0.625rem 0',
        width: '2.625rem',
        height: '2.625rem',
        fontSize: '1.125rem',
        fontWeight: 'bold',
    },
    page: {
        backgroundColor: theme.palette.secondary.light,
    },
    selectedPage: {
        backgroundColor: `${theme.palette.primary.main}!important`,
        color: '#fff',
    },
    paginationEllipsis: {
        color: '#cdcdcd',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    rootSelect: {
        color: '#626C74',
        padding: 0,
        fontSize: '1rem',
        fontWeight: 400,
        paddingLeft: '0px!important',
        '&:focus': {
            padding: 0,
            paddingLeft: 0,
        },
        opacity: 1,
    },
    selectedItemPagination: {
        color: '#626C74',
        padding: 0,
        paddingLeft: '0px!important',
        fontSize: '1rem',
        fontWeight: 400,
    },
    iconClass: {
        color: '#626C74',
    },
}));
