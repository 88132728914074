import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../config/theme';

export const useStyles = makeStyles({
    container: {
        margin: '0.313rem 0.625rem',
        backgroundColor: theme.palette.secondary.dark,
        borderTopLeftRadius: '1.25rem',
        borderBottomRightRadius: '1.25rem',
        height: '3rem',
        width: '11.125rem',
        '& .MuiInputBase-root': {
            display: 'flex',
            textAlign: 'right',
            fontSize: '1rem',
            width: '11.125rem',
        },
    },
    tableContainer: {},
    rootSelect: {
        padding: '0.938rem 2.5rem',
        textTransform: 'lowercase',
        textAlign: 'right',
        fontWeight: 'bold',
        '&:focus': {
            padding: '0.625rem 2.5rem',
            backgroundColor: 'transparent',
        },
    },
    rootSelectMenu: {
        borderRadius: 0,
    },
    menuListSelectMenu: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    selected: {
        paddingRight: '2.5rem !important',
    },
    selectedItem: {
        backgroundColor: 'transparent !important',
    },
    selectInputListItemIcon: {
        position: 'absolute',
        right: '0.625rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        justifyItems: 'end',
        color: '#E26A3A',
    },
    checkbox: {
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    iconComponent: {},
    icon: {
        color: 'black',
        marginRight: '0.625rem',
    },
    iconOpen: {
        color: 'black',
    },
});
