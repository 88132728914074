import { TableRow as MuiTableRow, TableHead as MuiTableHead } from '@material-ui/core';
import { Table } from '@tanstack/react-table';
import TableHeadCell from '../TableHeadCell';

interface Props<V> {
    table: Table<V>;
}

const TableHead = <V,>({ table }: Props<V>) => {
    return (
        <MuiTableHead>
            {table.getHeaderGroups().map(headerGroup => (
                <MuiTableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <TableHeadCell header={header} />
                    ))}
                </MuiTableRow>
            ))}
        </MuiTableHead>
    );
};

export default TableHead;
