import i18n, { InitOptions } from 'i18next';
import React, { FC, createContext } from 'react';
import { initReactI18next } from 'react-i18next';

type Props = InitOptions;

const InternationalizationProvider: FC<Props> = ({ children, ...otherProps }) => {
    const InternationalizationContext = createContext('__internationalizationContext');

    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            lng: 'en',
            keySeparator: '.',
            nsSeparator: false,
            ...otherProps,
        });

    return <InternationalizationContext.Provider value="">{children}</InternationalizationContext.Provider>;
};

export default InternationalizationProvider;
