import { FC } from 'react';
import { Grid } from '@material-ui/core';
import RhfTextField from '../../../../components/form-fields/RhfTextField';
import RhfSelect from '../../../../components/form-fields/RhfSelect';
import { UserRoles } from '../../../../models/enums/UserRoles';

const InviteUsersDialogFormFields: FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <RhfTextField name="firstName" placeholder="first name" variant="filled" />
            </Grid>
            <Grid item xs={12} md={6}>
                <RhfTextField name="lastName" placeholder="last name" variant="filled" />
            </Grid>
            <Grid item xs={12} md={6}>
                <RhfTextField name="email" placeholder="e-mail" variant="filled" />
            </Grid>
            <Grid item xs={12} md={6}>
                <RhfSelect
                    name="userType"
                    placeholder="user type"
                    variant="filled"
                    options={Object.values(UserRoles)
                        .filter(option => option !== UserRoles.STAKEHOLDER)
                        .map(option => ({
                            value: option,
                            label: option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(),
                        }))}
                />
            </Grid>
        </Grid>
    );
};

export default InviteUsersDialogFormFields;
