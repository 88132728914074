import { FC } from 'react';
import { Box } from '@material-ui/core';

interface TabPanelProps {
    index: number;
    value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <Box style={{ width: '100%' }} role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box>{children}</Box>}
        </Box>
    );
};

export default TabPanel;
