import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { isEmpty, lowerCase } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../../components/Toolbar';
import FilterSelectButton from '../../../components/FilterSelectButton';
import { Filter } from '../../../models/Filter';
import { useStyles } from './styles';
import { RootReducer, TasksReducer } from '../../../models/Redux';
import Searchbox from '../../DashboardPage/Parts/Searchbox';
import { ConformitiesQuery } from '../../../services/TasksService';

enum FilterType {
    PROJECT = 'PROJECT',
    CONTRACT_TYPE = 'CONTRACT_TYPE',
    ARTICLE = 'ARTICLE',
    PREVIOUS_STATUS = 'PREVIOUS_STATUS',
    CURRENT_STATUS = 'CURRENT_STATUS',
}

interface Props {
    query: ConformitiesQuery;
    handleSearch: (search: string, filters: Record<string, Array<number | string>>) => void;
}

const ConformitiesFilters: FC<Props> = ({ query, handleSearch }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [search, setSearch] = useState<string>('');
    const [projectsFilter, setProjectsFilter] = useState<Filter[]>([]);
    const [previousStatusFilter, setPreviousStatusFilter] = useState<Filter[]>([]);
    const [currentStatusFilter, setCurrentStatusFilter] = useState<Filter[]>([]);
    const [contractTypeFilter, setContractTypeFilter] = useState<Filter[]>([]);
    const [articleFilter, setArticleFilter] = useState<Filter[]>([]);

    const selectedFilters = useRef<any>({});

    const { projects, contractTypes, conformityArticles, conformityStatuses } = useSelector<
        RootReducer,
        TasksReducer
    >(state => state.tasks);

    useEffect(() => {
        setSearch(query.search || '');
        setProjectsFilter(projects?.filter(project => query?.projects?.includes(project.id as number)) || []);
        setPreviousStatusFilter(
            conformityStatuses?.filter(status => query?.previousStatuses?.includes(status.id as number)) ||
                [],
        );
        setCurrentStatusFilter(
            conformityStatuses?.filter(status => query?.currentStatuses?.includes(status.id as number)) || [],
        );
        setContractTypeFilter(
            contractTypes?.filter(contract => query?.contractTypes?.includes(contract.id as number)) || [],
        );
        setArticleFilter(
            conformityArticles
                ?.filter(article => query?.articles?.includes(article))
                ?.map(article => ({ id: article, name: article })) || [],
        );

        selectedFilters.current = {
            projects: query.projects || [],
            currentStatuses: query.currentStatuses || [],
            previousStatuses: query.previousStatuses || [],
            contractTypes: query.contractTypes || [],
            articles: query.articles || [],
        };
    }, [
        query.search,
        query.projects,
        query.currentStatuses,
        query.previousStatuses,
        query.contractTypes,
        query.articles,
        projects,
        conformityStatuses,
        contractTypes,
        conformityArticles,
    ]);

    const allSelectedFilters = useMemo(() => {
        return projectsFilter
            .map(item => {
                return { ...item, filterType: FilterType.PROJECT };
            })
            .concat(
                previousStatusFilter.map(item => {
                    return { ...item, filterType: FilterType.PREVIOUS_STATUS };
                }),
                currentStatusFilter.map(item => {
                    return { ...item, filterType: FilterType.CURRENT_STATUS };
                }),
                contractTypeFilter.map(item => {
                    return { ...item, filterType: FilterType.CONTRACT_TYPE };
                }),
                articleFilter.map(item => {
                    return { ...item, filterType: FilterType.ARTICLE };
                }),
            );
    }, [articleFilter, contractTypeFilter, currentStatusFilter, previousStatusFilter, projectsFilter]);

    const executeQuery = (clearSearch?: boolean) => {
        if (clearSearch) {
            handleSearch('', selectedFilters.current);
            setSearch('');
        } else {
            handleSearch(search, selectedFilters.current);
        }
    };

    const handleSelectFilter = (newArray: Filter[], filterName: string) => {
        /* eslint-disable */
        switch (filterName) {
            case 'project-select':
                const projectNewValues = parseSelectedFilters(projectsFilter, newArray);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }

                setProjectsFilter(projectNewValues.newFilterArray);

                break;
            case 'contract-type-select':
                const contractTypeFilterNewValues = parseSelectedFilters(contractTypeFilter, newArray);

                selectedFilters.current.contractTypes = contractTypeFilterNewValues.newValues;
                if (isEmpty(contractTypeFilterNewValues.newValues)) {
                    delete selectedFilters.current.contractTypes;
                }
                setContractTypeFilter(contractTypeFilterNewValues.newFilterArray);

                break;
            case 'previous-status-select':
                const previousStatusNewValues = parseSelectedFilters(previousStatusFilter, newArray);

                selectedFilters.current.previousStatuses = previousStatusNewValues.newValues;
                if (isEmpty(previousStatusNewValues.newValues)) {
                    delete selectedFilters.current.previousStatuses;
                }
                setPreviousStatusFilter(previousStatusNewValues.newFilterArray);

                break;
            case 'current-status-select':
                const currentStatusNewValues = parseSelectedFilters(currentStatusFilter, newArray);

                selectedFilters.current.currentStatuses = currentStatusNewValues.newValues;
                if (isEmpty(currentStatusNewValues.newValues)) {
                    delete selectedFilters.current.currentStatuses;
                }
                setCurrentStatusFilter(currentStatusNewValues.newFilterArray);

                break;
            case 'article-select':
                const articleNewValues = parseSelectedFilters(articleFilter, newArray);

                selectedFilters.current.articles = articleNewValues.newValues;
                if (isEmpty(articleNewValues.newValues)) {
                    delete selectedFilters.current.articles;
                }

                setArticleFilter(articleNewValues.newFilterArray);

                break;
            default:
                break;
        }
        /* eslint-enable */
    };

    const handleRemoveFilter = (elementToRemove: Filter, filterName: FilterType) => {
        /* eslint-disable */
        switch (filterName) {
            case FilterType.PROJECT:
                const projectNewValues = removeFilterFromArray(projectsFilter, elementToRemove);

                selectedFilters.current.projects = projectNewValues.newValues;
                if (isEmpty(projectNewValues.newValues)) {
                    delete selectedFilters.current.projects;
                }

                setProjectsFilter(projectNewValues.newFilterArray);

                break;
            case FilterType.CONTRACT_TYPE:
                const contractTypeFilterNewValues = removeFilterFromArray(
                    contractTypeFilter,
                    elementToRemove,
                );

                selectedFilters.current.contractTypes = contractTypeFilterNewValues.newValues;
                if (isEmpty(contractTypeFilterNewValues.newValues)) {
                    delete selectedFilters.current.contractTypes;
                }
                setContractTypeFilter(contractTypeFilterNewValues.newFilterArray);

                break;
            case FilterType.PREVIOUS_STATUS:
                const previousStatusNewValues = removeFilterFromArray(previousStatusFilter, elementToRemove);

                selectedFilters.current.previousStatuses = previousStatusNewValues.newValues;
                if (isEmpty(previousStatusNewValues.newValues)) {
                    delete selectedFilters.current.previousStatuses;
                }
                setPreviousStatusFilter(previousStatusNewValues.newFilterArray);

                break;
            case FilterType.CURRENT_STATUS:
                const currentStatusNewValues = removeFilterFromArray(currentStatusFilter, elementToRemove);

                selectedFilters.current.currentStatuses = currentStatusNewValues.newValues;
                if (isEmpty(currentStatusNewValues.newValues)) {
                    delete selectedFilters.current.currentStatuses;
                }
                setCurrentStatusFilter(currentStatusNewValues.newFilterArray);

                break;
            case FilterType.ARTICLE:
                const articleNewValues = removeFilterFromArray(articleFilter, elementToRemove);

                selectedFilters.current.articles = articleNewValues.newValues;
                if (isEmpty(articleNewValues.newValues)) {
                    delete selectedFilters.current.articles;
                }

                setArticleFilter(articleNewValues.newFilterArray);

                break;
            default:
                break;
        }
        /* eslint-enable */
    };

    const removeFilterFromArray = (
        filterArray: Filter[],
        elementToRemove: Filter,
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...filterArray];

        if (filterArray.find(filter => filter.id === elementToRemove.id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== elementToRemove.id);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    const handleDelete = (item: { filterType: FilterType; id: any; name: string }) => {
        handleRemoveFilter({ id: item.id, name: item.name }, item.filterType);

        executeQuery();
    };

    const parseSelectedFilters = (
        previousFilters: Filter[],
        newFiltersArray: Filter[],
    ): { newValues: string[]; newFilterArray: Filter[] } => {
        let updatedFilter = [...previousFilters];

        if (previousFilters.find(filter => filter.id === newFiltersArray[0].id)) {
            updatedFilter = updatedFilter.filter(filter => filter.id !== newFiltersArray[0].id);
        } else {
            updatedFilter.push(newFiltersArray[0]);
        }

        return {
            newValues: updatedFilter.map(item => item.id.toString()),
            newFilterArray: updatedFilter,
        };
    };

    return (
        <>
            <Toolbar>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ gap: 20 }}
                >
                    <FilterSelectButton
                        id="project-select"
                        name="project-select"
                        filterName={t('project')}
                        onChange={newValue => handleSelectFilter(newValue, 'project-select')}
                        onClose={() => executeQuery()}
                        availableFilters={projects}
                        values={projectsFilter}
                    />
                    <FilterSelectButton
                        id="contract-type-select"
                        name="contract-type-select"
                        filterName={t('contract type')}
                        onChange={newValue => handleSelectFilter(newValue, 'contract-type-select')}
                        onClose={() => executeQuery()}
                        availableFilters={contractTypes}
                        values={contractTypeFilter}
                    />
                    <FilterSelectButton
                        id="article-select"
                        name="article-select"
                        filterName={t('article')}
                        onChange={newValue => handleSelectFilter(newValue, 'article-select')}
                        onClose={() => executeQuery()}
                        availableFilters={conformityArticles.map(article => ({ id: article, name: article }))}
                        values={articleFilter}
                    />
                    <FilterSelectButton
                        id="previous-status-select"
                        name="previous-status-select"
                        filterName={t('previous status')}
                        onChange={newValue => handleSelectFilter(newValue, 'previous-status-select')}
                        onClose={() => executeQuery()}
                        availableFilters={conformityStatuses}
                        values={previousStatusFilter}
                    />
                    <FilterSelectButton
                        id="current-status-select"
                        name="current-status-select"
                        filterName={t('current status')}
                        onChange={newValue => handleSelectFilter(newValue, 'current-status-select')}
                        onClose={() => executeQuery()}
                        availableFilters={conformityStatuses}
                        values={currentStatusFilter}
                    />
                    <Searchbox
                        id="search-register"
                        search={search}
                        name="search-register"
                        placeHolder="search register"
                        onChange={e => {
                            setSearch(e.target.value);
                        }}
                        onSubmit={() => executeQuery()}
                        onClear={() => executeQuery(true)}
                    />
                </Box>
            </Toolbar>

            <Grid item xs={12} direction="row" className={classes.chipContainer}>
                {allSelectedFilters.map(item => (
                    <div className={classes.chip}>
                        <div>
                            <Typography className={classes.filterTypeText}>
                                {lowerCase(item.filterType)}:
                            </Typography>
                            <Typography className={classes.filterText}>{item.name}</Typography>
                        </div>
                        <div className={classes.closeIconContainer}>
                            <CloseIcon onClick={() => handleDelete(item)} className={classes.closeIcon} />
                        </div>
                    </div>
                ))}
            </Grid>
        </>
    );
};

export default ConformitiesFilters;
