import { FC, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import dayjs from 'dayjs';
import { Container } from '@material-ui/core';
import { useStyles } from './styles';
import { publicRoutes, authorizedRoutes } from '../../config/routing';
import AuthorizedRoute from '../AuthorizedRoute';
import PublicRoute from '../PublicRoute';
import Header from '../../parts/Header';
import { SET_LOADING, thunkGetCurrentUser, thunkLogoutUser } from '../../store/actions';
import { Token } from '../../models/enums/Token';
import { useLocalStorage } from '../../config/hooks/useLocalStorage';
import { useTokenExpirationDate } from '../../config/hooks/useTokenExpirationDate';
import { getAllTasksFilters } from '../../store/actions/Tasks.actions';

dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(utc);

const App: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [token] = useLocalStorage(Token.TOKEN, null);
    const { tokenExpirationDateValid } = useTokenExpirationDate();

    useEffect(() => {
        if (token) {
            dispatch(thunkGetCurrentUser());
            dispatch(getAllTasksFilters());
        } else {
            dispatch({ type: SET_LOADING, payload: false });
        }
    }, [dispatch, token]);

    // Logout after reload, if persisted token is not valid anymore
    useEffect(() => {
        if (!tokenExpirationDateValid && token) {
            dispatch(thunkLogoutUser());
        }
    }, [dispatch, token, tokenExpirationDateValid]);

    return (
        <Router>
            <div className={classes.app}>
                <Header />
                <Container component="main" className={classes.main} classes={{ root: classes.maxWidthXs }}>
                    <Switch>
                        {publicRoutes.map(route => (
                            <PublicRoute
                                key={route.path}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        ))}
                        {authorizedRoutes.map(route => (
                            <AuthorizedRoute
                                path={route.path}
                                component={route.component}
                                key={route.path}
                                exact={route.exact}
                                requiredRoles={route.requiredRoles}
                            />
                        ))}
                    </Switch>
                </Container>
            </div>
        </Router>
    );
};

export default App;
