import React, { FC, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, FormControl, MenuItem, Select } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import TabPanel from '../../ProjectDetailsPage/tabs/TabPanel';
import PersonsSubGroup from '../ProjectFormTabs/PersonsSubGroup';
import InfoCard from '../../../components/InfoCard';
import { queryKeys } from '../../../react-query/constants';
import { getStakeholderGroups } from '../../../services/ProjectService';

interface Props {
    selectedTab: number;
    index: number;
}

const PersonsTabForm: FC<Props> = ({ selectedTab, index }) => {
    const { data: stakeholderGroups } = useQuery([queryKeys.stakeholderGroups], () => getStakeholderGroups());

    const {
        control,
        setValue,
        formState: { errors },
    } = useFormContext();

    console.log('errors', errors);

    const groupValues = useWatch({
        control,
        name: `contactGroups`,
    });

    const addGroup = () => {
        if (!groupValues.map(({ topLevelGroup }: { topLevelGroup: string }) => topLevelGroup).includes('')) {
            setValue(`contactGroups`, [
                ...groupValues,
                { topLevelGroup: '', role: '', groupId: null, contacts: [] },
            ]);
        }
    };

    const changeGroupName = (newName: string, oldName: string) => {
        const index = groupValues.findIndex(
            ({ topLevelGroup }: { topLevelGroup: string }) => topLevelGroup === oldName,
        );

        const newValues = [...groupValues];
        newValues[index].groupId = null;
        newValues[index].contacts = [];
        newValues[index].topLevelGroup = newName;
        newValues[index].role = '';

        setValue(`contactGroups`, newValues);
    };

    const topLevelGroups = useMemo(
        () =>
            stakeholderGroups
                ?.map(({ stakeholderGroup }) => stakeholderGroup)
                ?.filter(group => group !== null)
                ?.filter((value, index, self) => {
                    return self.indexOf(value) === index;
                }) || [],
        [stakeholderGroups],
    );

    const visibleTopLeveGroups = useMemo(() => {
        return (
            groupValues
                .map(({ topLevelGroup }: { topLevelGroup: string }) => topLevelGroup)
                ?.filter((value: string, index: number, self: string[]) => {
                    return self.indexOf(value) === index;
                }) || []
        );
    }, [groupValues]);

    const availableTopLevelGroups: string[] = useMemo(
        () =>
            topLevelGroups
                .filter(
                    group =>
                        group !== null &&
                        !groupValues
                            .map(({ topLevelGroup }: { topLevelGroup: string | undefined }) => topLevelGroup)
                            .includes(group),
                )
                ?.map(group => group as string)
                ?.filter((value: string, index: number, self: string[]) => {
                    return self.indexOf(value) === index;
                })
                ?.sort() || [],
        [topLevelGroups, groupValues],
    );

    return (
        <TabPanel value={selectedTab} index={index}>
            {visibleTopLeveGroups.map((group: string | undefined, key: number) => (
                <InfoCard marginBottom key={key}>
                    <FormControl variant="filled">
                        <Select
                            value={group}
                            style={{ width: 200 }}
                            onChange={(e: any) => changeGroupName(e.target.value, group as string)}
                        >
                            <MenuItem value={group}>{group}</MenuItem>
                            {availableTopLevelGroups.map(availableTopLevelGroup => (
                                <MenuItem value={availableTopLevelGroup}>{availableTopLevelGroup}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <br />

                    {!!group && <PersonsSubGroup key={group} group={group} />}
                </InfoCard>
            ))}

            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 50, marginBottom: 50 }}
                onClick={addGroup}
            >
                add group
            </Button>
        </TabPanel>
    );
};

export default PersonsTabForm;
