import { FC, useState, MouseEvent, useRef, ChangeEvent } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useQueryClient } from '@tanstack/react-query';
import { UserInfoDto } from '../../../api/data-contracts';
import { useStyles } from './styles';
import { uploadMediaFile } from '../../../services/MediaService';
import Media from '../../../models/Media';
import { removeUserAvatar, updateUserAvatar } from '../../../services/UserService';
import { queryKeys } from '../../../react-query/constants';

interface Props {
    user: UserInfoDto | undefined;
    editMode?: boolean;
}

const UserContact: FC<Props> = ({ user, editMode }) => {
    const classes = useStyles();
    const queryClient = useQueryClient();

    const inputRef = useRef<HTMLInputElement>(null);

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [showDeleteAvatarButton, setShowDeleteAvatarButton] = useState<boolean>(false);
    const [showUploadAvatarButton, setShowUploadAvatarButton] = useState<boolean>(false);

    const onMouseEnter = () => {
        if (editMode) {
            setShowUploadAvatarButton(true);
            setShowDeleteAvatarButton(true);
        }
    };

    const onMouseLeave = () => {
        setShowUploadAvatarButton(false);
        setShowDeleteAvatarButton(false);
    };

    const deleteAvatar = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsUploading(true);
        setShowUploadAvatarButton(false);
        setShowDeleteAvatarButton(false);

        await removeUserAvatar(user?.id as number);
        await queryClient.invalidateQueries([queryKeys.users]);

        setIsUploading(false);
    };

    const uploadAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length && e.target.files?.[0]) {
            setIsUploading(true);
            setShowUploadAvatarButton(false);
            setShowDeleteAvatarButton(false);

            const uploadImage = (await uploadMediaFile(e.target.files[0])) as Media;

            if (uploadImage?.id) {
                await updateUserAvatar(user?.id as number, uploadImage.id);
                await queryClient.invalidateQueries([queryKeys.users]);
                setIsUploading(false);
            }
        }
    };

    const renderAvatar = () =>
        isUploading ? (
            <CircularProgress />
        ) : user?.avatar ? (
            <img alt="user avatar" src={`${process.env.REACT_APP_BLOB_PATH}${user.avatar}`} />
        ) : null;

    return user ? (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box className={classes.avatarContainer} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {showUploadAvatarButton ? (
                    <Button
                        classes={{ root: classes.uploadAvatarButton, label: classes.uploadAvatarLabel }}
                        onClick={() => inputRef.current?.click()}
                    >
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <ArrowUpwardOutlinedIcon className={classes.uploadAvatarIcon} />
                            <Box className={classes.uploadAvatarIconWrapper} />
                        </Box>
                        upload picture
                    </Button>
                ) : (
                    renderAvatar()
                )}

                {showDeleteAvatarButton && user?.avatar && (
                    <Button className={classes.deleteAvatarButton} onClick={e => deleteAvatar(e)}>
                        <CloseIcon className={classes.deleteAvatarIcon} />
                    </Button>
                )}

                <input
                    type="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    accept="image/jpeg, image/png"
                    onChange={uploadAvatar}
                />
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography className={classes.user}>user:</Typography>
                <Typography className={classes.userName}>{user?.name}</Typography>

                {!editMode && (
                    <Box display="flex" flexDirection="row" alignItems="center" gridColumnGap={36}>
                        {!!user?.phoneNo && (
                            <Box className={classes.phoneOrEmail}>
                                <CallOutlinedIcon className={classes.contactIcon} />
                                <Typography>{user.phoneNo}</Typography>
                            </Box>
                        )}
                        {!!user?.email && (
                            <Box className={classes.phoneOrEmail}>
                                <EmailOutlinedIcon className={classes.contactIcon} />
                                <Typography>{user.email}</Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    ) : null;
};

export default UserContact;
